import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";

export const getUserPositions = (id: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.USERS)
            .doc(id)
            .collection(COLLECTIONS.USER_PREFERENCES)
            .orderBy("createTime", "desc")
            .limit(1);
    } catch (error) {
        console.error(error);
        return null;
    }
};
