import React from "react";
import { StyleGuide } from "@constants/StyleGuide";
import { useHistory } from "react-router-dom";

import { resetPassword } from "../../../firebase/auth";
import { Routes } from "../../../constants/routes";
import AuthScreenContainer from "../components/AuthScreenContainer";
import Input from "../components/Input";
import SubmitButton from "../components/Buttons/SubmitButton";

function ForgotPassword() {
    const history = useHistory();

    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [codeSent, setCodeSent] = React.useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);

        const errorMessage = await resetPassword({ email });
        if (!errorMessage) {
            setCodeSent(true);
        }
        setLoading(false);
    };

    const handleGoBack = () => {
        history.push(Routes.SIGN_IN);
    };

    const title = codeSent ? "Check your e-mail" : "Forgot Password";
    const subtitle = codeSent
        ? "A link has been sent to your e-mail to reset your password."
        : "We will send you a code to you via e-mail, so you can reset your password";

    return (
        <AuthScreenContainer title={title} subtitle={subtitle}>
            {codeSent ? (
                //@ts-ignore
                <SubmitButton text="Go back" onClick={handleGoBack} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <Input
                        placeholder="Enter your e-mail"
                        type="email"
                        required
                        onChange={e => setEmail(e.target.value)}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    />

                    <SubmitButton
                        text="Send link"
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        style={{ marginTop: StyleGuide.spacing.medium }}
                    />
                </form>
            )}
        </AuthScreenContainer>
    );
}

export default ForgotPassword;
