import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";

export const ChatPanel = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: "hidden";
    padding-right: ${StyleGuide.spacing.micro}px;
`;
export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const ChatHistory = styled.div`
    overflow: auto;
    padding-right: ${StyleGuide.spacing.micro}px;

    ${CustomScrollBar}
`;

interface ChatBubbleContainerProps {
    isCurrentUserAuthor?: boolean;
}

export const ChatBubbleContainer = styled.div<ChatBubbleContainerProps>`
    display: flex;
    flex-direction: ${props => (props.isCurrentUserAuthor ? "row-reverse" : "row")};
    align-items: flex-end;
    margin-top: ${StyleGuide.spacing.base}px;
`;

export const BubbleMessageContainer = styled.div`
    max-width: 60%;
`;

export const MessageBubbleContainer = styled.div<ChatBubbleContainerProps>`
    width: -webkit-fit-content;
    border-radius: 18px;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    ${() => StyleGuide.typography.body1};
    background-color: ${props =>
        props.isCurrentUserAuthor
            ? StyleGuide.palette.secondaryBasil
            : StyleGuide.palette.baseMsg};
    color: ${props =>
        props.isCurrentUserAuthor
            ? StyleGuide.palette.baseSalt
            : StyleGuide.palette.basePepper};
    text-align: left;
    white-space: pre-wrap;
    word-break: break-word;
`;

export const ProfileImage = styled.img<ChatBubbleContainerProps>`
    object-fit: cover;
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    border-radius: ${StyleGuide.spacing.mini}px;
    margin: 0 ${StyleGuide.spacing.mini}px 0
        ${props =>
            props.isCurrentUserAuthor
                ? StyleGuide.spacing.micro
                : StyleGuide.spacing.small}px;
    margin-left: ${StyleGuide.spacing.micro}px;
    margin-right: ${StyleGuide.spacing.mini}px;
    margin-bottom: 20px;
`;
export const ProfileLetter = styled.div<ChatBubbleContainerProps>`
    justify-content: center;
    display: flex;
    align-items: center;
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    border-radius: 50%;
    font-size: 10px;
    background-color: ${StyleGuide.palette.baseSeaSalt};
    margin: 0 ${StyleGuide.spacing.mini}px 0
        ${props =>
            props.isCurrentUserAuthor
                ? StyleGuide.spacing.micro
                : StyleGuide.spacing.small}px;
    margin-left: ${StyleGuide.spacing.micro}px;
    margin-right: ${StyleGuide.spacing.mini}px;
    margin-bottom: 20px;
`;

export const InfoContainer = styled.div<ChatBubbleContainerProps>`
    display: flex;
    justify-content: ${props => (props.isCurrentUserAuthor ? "flex-end" : "flex-start")};
    align-items: center;
    margin: ${StyleGuide.spacing.micro}px
        ${props => (props.isCurrentUserAuthor ? StyleGuide.spacing.micro : 0)}px 0
        ${props => (!props.isCurrentUserAuthor ? StyleGuide.spacing.micro : 0)}px;
`;

export const BubbleUserName = styled.div`
    ${() => StyleGuide.typography.detail1};
    color: ${StyleGuide.palette.basePepper};
`;

export const MessageInfoSeparator = styled.div`
    ${() => StyleGuide.typography.detail1};
    color: ${StyleGuide.palette.baseVanilla};
    margin: 0 ${StyleGuide.spacing.micro}px;
`;

export const MessageTimeInfo = styled.div`
    ${() => StyleGuide.typography.detail1};
    color: ${StyleGuide.palette.baseVanilla};
`;

export const AutoReplyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${StyleGuide.spacing.xlarge}px;
    padding: ${StyleGuide.spacing.mini}px 0 ${StyleGuide.spacing.large}px 0;
`;

export const AppliedText = styled.div`
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
    margin-top: ${StyleGuide.spacing.small}px;
`;

export const AutoReplyAccordion = styled.div`
    width: 295px;
    border: 1px solid ${StyleGuide.palette.baseMsg};
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: ${StyleGuide.spacing.base}px;
    cursor: pointer;
`;

interface AppliedInfoContainerProps {
    isExpanded?: boolean;
}

export const AppliedInfoContainer = styled.div<AppliedInfoContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    border-bottom: ${props => (props.isExpanded ? "1px" : "0")} solid
        ${StyleGuide.palette.baseMsg};
`;

interface AppliedInfoFlagProps {
    isHiring?: boolean;
}

export const AppliedInfoFlag = styled.div<AppliedInfoFlagProps>`
    width: ${StyleGuide.spacing.xsmall}px;
    height: ${StyleGuide.spacing.xsmall}px;
    border-radius: 6px;
    background-color: ${props =>
        props.isHiring ? StyleGuide.palette.secondaryBasil : StyleGuide.palette.coal};
    margin-right: 10px;
`;

export const AppliedInfoText = styled.div`
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};
`;

interface ArrowContainerProps {
    isExpanded?: boolean;
}

export const ArrowContainer = styled.div<ArrowContainerProps>`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    transform: ${props => (props.isExpanded ? "rotate(0deg)" : "rotate(180deg)")};
`;

export const ExpandedContainer = styled.div`
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseVanilla};
    white-space: pre-wrap;
    text-align: left;
`;

export const NoteText = styled.div`
    margin-top: ${StyleGuide.spacing.micro}px;
    ${() => StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.baseVanilla};
`;

export const ActionIconTextContainer = styled.div<{
    darkText?: boolean;
    isClickable?: boolean;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    cursor: default;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    background-color: ${props =>
        props.darkText ? StyleGuide.palette.baseSalt : StyleGuide.palette.baseSalt};
    cursor: ${props => (props.isClickable ? "pointer" : "auto")};
`;

export const ActionIcon = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    margin-right: ${StyleGuide.spacing.xsmall}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ActionTitle = styled.div`
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
`;

export const ActionDetails = styled.div`
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseVanilla};
    text-align: left;
`;
