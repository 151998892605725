import { useEffect, useState } from "react";
import * as TemplatesAPI from "./api";
import { setTemplates } from "./reducer";
import { useAppSelector, useAppDispatch } from "../../store/store";

export function useTemplates() {
    const [initialLoading, setinitialLoading] = useState(true);
    const userId = useAppSelector(state => state.user.uid);
    const restaurantId = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap?.[state.funnels.focusedChat]?.restaurantId,
    );

    const dispatch = useAppDispatch();
    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (userId && restaurantId) {
            // @ts-ignore
            unsubscribe = TemplatesAPI.getRestaurantUserTemplates(
                restaurantId,
                userId,
            ).onSnapshot(
                doc => {
                    const templates = doc.data() as Contracts.DBRestaurantTemplate;

                    dispatch(setTemplates(templates));
                    setinitialLoading(false);
                },
                err => {
                    // TODO: add error logging with sentry
                    console.log(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [userId, restaurantId]);
    return { initialLoading };
}
