import React from "react";
import { useSelector } from "react-redux";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { getCalendarBookings } from "../../features/bookings/reducer";
import TileContent from "./TileContent";
import { Container, PrevArrow, NextArrow } from "./styled";

const Calendar = ({
    selectedDate,
    setSelectedDate,
    selectedCustomTimes,
    onClickDay,
    forInterview,
}) => {
    const bookings = useSelector(getCalendarBookings);

    return (
        <Container>
            <ReactCalendar
                value={selectedDate}
                onChange={setSelectedDate}
                minDate={new Date()}
                onClickDay={onClickDay}
                prevLabel={<PrevArrow />}
                nextLabel={<NextArrow />}
                tileContent={({ date, view }) => (
                    <TileContent
                        date={date}
                        view={view}
                        selectedDate={selectedDate}
                        selectedCustomTimes={selectedCustomTimes}
                        forInterview={forInterview}
                        bookings={bookings}
                    />
                )}
            />
        </Container>
    );
};

export default Calendar;
