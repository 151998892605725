import { __LOCAL__, __STAGING__ } from "./environments";

// TODO: change all of these to your production env.

const ROOT = {
    LOCAL: "http://localhost:5001/anna-dev-d1a8d/europe-west1",
    STAGING: "https://europe-west1-all-anna.cloudfunctions.net",
    PRODUCTION: "https://europe-west1-all-anna.cloudfunctions.net",
};

export default {
    API_BASE: `${__LOCAL__ ? ROOT.LOCAL : __STAGING__ ? ROOT.STAGING : ROOT.PRODUCTION}`,

    FIREBASE_APP_API_KEY: __STAGING__
        ? "AIzaSyD9Tc9kvOizNgEr_50A5r0QTYqk1JYZS6I"
        : "AIzaSyD9Tc9kvOizNgEr_50A5r0QTYqk1JYZS6I",
    FIREBASE_APP_AUTH_DOMAIN: __STAGING__
        ? "all-anna.firebaseapp.com"
        : "all-anna.firebaseapp.com",
    FIREBASE_APP_DATABASE_URL: __STAGING__
        ? "https://all-anna-default-rtdb.europe-west1.firebasedatabase.app"
        : "https://all-anna-default-rtdb.europe-west1.firebasedatabase.app",
    FIREBASE_APP_PROJECT_ID: __STAGING__ ? "all-anna" : "all-anna",
    FIREBASE_APP_STORAGE_BUCKET: __STAGING__
        ? "all-anna.appspot.com"
        : "all-anna.appspot.com",
    FIREBASE_APP_MESSAGING_SENDER_ID: __STAGING__ ? "457277308274" : "457277308274",
    FIREBASE_APP_ID: __STAGING__
        ? "1:457277308274:web:7dec22a70c18a5a46e71eb"
        : "1:457277308274:web:7dec22a70c18a5a46e71eb",
    SENTRY_CONFIG: "https://343ba82aada54420b70930894d8fd44d@sentry.io/1724088",
    // elastic

    searchKey: __STAGING__
        ? "search-464575tbi9cxv1q9inti8kum"
        : "search-464575tbi9cxv1q9inti8kum",
    endpointBase: "https://anna-search.ent.europe-west3.gcp.cloud.es.io",
    engineName: __STAGING__ ? "staging-search" : "production",
};
