import { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { FunnelState, setYourInbox } from "./reducer";
import { selectFocusedPosition } from "../userPositions/reducer";
import { Pagination, setYourInboxPagination } from "../pagination/reducer";
import useFunnelSearch from "../../features/user/useFunnelSearch";
import { getSearchFilter } from "../../helpers/searchFilters";

export default function useYourInbox() {
    const { loading, onSearchFunnels } = useFunnelSearch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const funnelIds = useAppSelector(state => state.funnels.yourInboxIds);
    const workHourFilter = useAppSelector(state => state.inboxFilters.workHoursFilter);
    const visaFilter = useAppSelector(state => state.inboxFilters.visaFilter);
    const userId = useAppSelector(state => state.user.uid);
    const page = useAppSelector(state => state.pagination.yourInboxPagination.page);
    const focusedPosition = useAppSelector(selectFocusedPosition);
    // filter is default null to select "all"
    const positionsFilter = useMemo(
        () => [focusedPosition].filter(Boolean),
        [focusedPosition],
    ) as string[];
    // const rIds = useMemo(() => restaurantIds.filter((_, i) => i < 9), [restaurantIds]);

    const dispatch = useAppDispatch();

    const filter = getSearchFilter({
        include: {
            subscribeIds: [userId],
            positionsFilter,
            workHourFilter,
            visaFilter,
        },
    });

    useEffect(() => {
        try {
            if (userId && !loading) {
                onSearchFunnels({
                    q: "",
                    filter: filter,
                    page: {
                        current: page,
                        size: 10,
                    },
                }).then(
                    ({
                        data,
                        meta,
                    }: {
                        data: Partial<FunnelState>[];
                        meta: Partial<Pagination>;
                    }) => {
                        dispatch(
                            setYourInboxPagination({
                                current: meta?.current,
                                size: meta?.size,
                                totalPages: meta?.totalPages,
                                totalResults: meta?.totalResults,
                            }),
                        );
                        // @ts-ignore
                        dispatch(setYourInbox({ funnels: data, append: true }));
                        if (initialLoading) {
                            setInitialLoading(false);
                        }
                    },
                );
            }
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [userId, positionsFilter, workHourFilter, visaFilter, page, loading]);
    return { initialLoading, funnelIds };
}
