import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import { Routes } from "@constants/routes";

import MenuDropdown from "../Dropdowns/MenuDropdown";
import { CategoryPickerContainer, ToggleBubble } from "./styled";
import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
    selectUserPositions,
    selectFocusedPosition,
    setFocusedPosition,
} from "../../features/userPositions/reducer";
import CategoryItem, { CategoryWithCount } from "./CategoryItem";
interface Category {
    id: string | null;
    value: string;
}

const CategoryPicker = () => {
    const dispatch = useAppDispatch();
    const positions = useAppSelector(selectUserPositions);
    const selectedCategory = useAppSelector(selectFocusedPosition);
    const isHome = useRouteMatch(Routes.HOME);
    const [frontCategories, setFrontCategories] = useState<Category[]>([]);
    const [dropdownCategories, setDropdownCategories] = useState<Category[]>([]);

    useEffect(() => {
        let positionsWithAll = [...positions];
        if (positions.length > 1) {
            positionsWithAll = ["All", ...positionsWithAll];
        }

        setFrontCategories(
            positionsWithAll
                .filter((_, index) => index < 4)
                .map(p => ({ id: p === "All" ? null : p, value: p })),
        );
        setDropdownCategories(
            positionsWithAll
                .filter((_, index) => index >= 4)
                .map(p => ({ id: p, value: p })),
        );
    }, [positions]);

    const reorderFrontCategories = (option: Category) => {
        //remove last element of front list
        const newFrontCategories = [...frontCategories].splice(
            0,
            frontCategories.length - 1,
        );
        //add selected option in the end of front list
        newFrontCategories.push(option);

        //remove selected option from dropdown list
        const newDropdownCategories = [...dropdownCategories].filter(
            category => category.id !== option.id,
        );
        //add the removed element from front list to the head of dropdown list
        const listLastElement = [...frontCategories].pop();
        listLastElement && newDropdownCategories.unshift(listLastElement);

        //update new front list and dropdown list
        setFrontCategories(newFrontCategories);
        setDropdownCategories(newDropdownCategories);
    };

    const setPosition = (id: string | null) => {
        if (id !== selectedCategory) {
            dispatch(setFocusedPosition(id));
        }
    };

    return (
        <CategoryPickerContainer>
            {frontCategories.map(({ id, value }, index) => (
                <CategoryItem
                    id={id}
                    value={value}
                    isSelected={selectedCategory === id}
                    onClick={() => setPosition(id)}
                    isLast={index === positions.length - 1}
                />
            ))}

            {Boolean(dropdownCategories.length) && (
                <MenuDropdown
                    options={dropdownCategories}
                    onSelect={(event: React.SyntheticEvent, option: Category) => {
                        event.preventDefault();
                        setPosition(option.id);
                        reorderFrontCategories(option);
                    }}
                    selected={[selectedCategory]}
                    renderOptionContent={
                        isHome
                            ? ({ id, value }) => (
                                  <CategoryWithCount id={id} value={value} />
                              )
                            : undefined
                    }
                    renderToggleBlock={(opened: boolean) => (
                        <ToggleBubble>
                            <ArrowUp
                                style={{
                                    transform: opened ? "rotate(0deg)" : "rotate(180deg)",
                                }}
                            />
                        </ToggleBubble>
                    )}></MenuDropdown>
            )}
        </CategoryPickerContainer>
    );
};

export default CategoryPicker;
