import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import type { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../store/store";
import { markActivityEventAsSeen } from "../../../features/activityCenter/api";
import { setFocusedChat } from "../../../features/funnels/reducer";
import { timeDistanceInWords } from "../../../helpers/dates";
import {
    getActivityEventAvatar,
    getActivityEventMessage,
    getActivityEventTouchable,
} from "./helper";
import {
    ActivityItem,
    UserProfilPicture,
    NotificationDot,
    NotificationTextContainer,
    ActivityText,
    UserName,
} from "./styled";

export enum ActivityEventType {
    User = "user",
    Team = "team",
}

export type ActivityEvent =
    | Contracts.TeamActivityEvent
    | Contracts.DBUserFunnelActivityEvent;

export interface ActivityEventProps {
    readonly type: ActivityEventType;
    readonly event: ActivityEvent;
}

const LinkWrapper = ({ isTouchable, children }) => {
    if (isTouchable) {
        return <Link to={{ pathname: "/inbox" }}>{children}</Link>;
    }

    return <>{children}</>;
};

export default ({ type, event, onClose }) => {
    const isUserActivityEvent = type === ActivityEventType.User;

    const dispatch = useAppDispatch();

    const uid = useSelector<RootState, string>(state => state.user.uid);

    const message = getActivityEventMessage({ type, event });

    const userName = message?.bold?.[0] || "";

    const messageText = message.text.replace(userName, "");

    const timeAgo = timeDistanceInWords(event.createTime);

    const isNewNotification = isUserActivityEvent && !event.seen;

    const isTouchable = getActivityEventTouchable(event);

    const { uri } = getActivityEventAvatar(event);

    const action = () => {
        if (!isTouchable) {
            return;
        }
        dispatch(setFocusedChat(event.funnelId));
        markActivityEventAsSeen(uid, event.id);
        onClose();
    };

    return (
        <LinkWrapper isTouchable={isTouchable}>
            <ActivityItem key={event.id} onClick={action}>
                <UserProfilPicture src={uri} isNewNotification={isNewNotification} />

                <NotificationTextContainer>
                    <ActivityText isNewNotification={isNewNotification}>
                        <UserName isNewNotification={isNewNotification}>
                            {userName}
                        </UserName>{" "}
                        {messageText} - {timeAgo}
                    </ActivityText>
                </NotificationTextContainer>

                {isNewNotification && <NotificationDot />}
            </ActivityItem>
        </LinkWrapper>
    );
};
