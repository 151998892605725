import { formatDistanceToNow } from "date-fns";

// TODO: shared with mobile
export function timeDistanceInWords(date: Date) {
    try {
        return `${formatDistanceToNow(date)} `
            .replace("about ", "")
            .replace("over ", "")
            .replace("almost ", "")
            .replace("less than a minute", "1 min")
            .replace(/minute./, "min") // minute and minutes
            .replace(/hour./, "h") // hour and hours
            .replace(/day./, "d") // day and days
            .replace(/month./, "mo") // month and months
            .replace(/year./, "y"); // year and years
    } catch (error) {
        console.error(error);
        return "Invalid time";
    }
}
