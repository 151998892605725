import React from "react";

import {
    ActionIconTextContainer,
    ActionIcon,
    ActionTitle,
    ActionDetails,
} from "./styled";

interface Props {
    Icon: JSX.Element | null;
    darkText?: boolean;
    title: string;
    description?: string;
    onClick?: () => void;
}

const ActionIconText = ({ Icon, darkText, title, description = "", onClick }: Props) => {
    return (
        <ActionIconTextContainer
            darkText={darkText}
            onClick={onClick}
            isClickable={Boolean(onClick)}>
            {Icon !== null && <ActionIcon>{Icon}</ActionIcon>}

            <div>
                <ActionTitle>{title}</ActionTitle>

                {Boolean(description) && <ActionDetails>{description}</ActionDetails>}
            </div>
        </ActionIconTextContainer>
    );
};

export default ActionIconText;
