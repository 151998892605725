import { useEffect } from "react";
import { setNotifications } from "./reducer";
import { useAppSelector, useAppDispatch } from "../../store/store";
import * as NotificationAPI from "./api";

export function useNotifications() {
    const userId = useAppSelector(state => state.user.uid);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (userId) {
            // @ts-ignore
            unsubscribe = NotificationAPI.getNotifications(userId).onSnapshot(
                query => {
                    const notifications = query.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));

                    dispatch(setNotifications(notifications));
                },
                err => {
                    console.log(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [userId]);
}
