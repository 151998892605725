import { useEffect, useState } from "react";
import * as UserAPI from "./api";
import { setUser } from "./reducer";
import { useAppSelector, useAppDispatch } from "../../store/store";

export function useUser() {
    const [initialLoading, setinitialLoading] = useState(true);
    const userId = useAppSelector(state => state.user.uid);

    const dispatch = useAppDispatch();
    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (userId) {
            // @ts-ignore
            unsubscribe = UserAPI.getUser(userId).onSnapshot(
                doc => {
                    const user = doc.data() as Contracts.DBUserShape;

                    dispatch(setUser(user));
                    setinitialLoading(false);
                },
                err => {
                    // TODO: add error logging with sentry
                    console.log(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [userId]);
    return { initialLoading };
}
