import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/store";
import * as ActivityAPI from "./api";
import { selectFocusedChat } from "../funnels/reducer";
import { setActivity } from "./reducer";

export function useActivity() {
    const dispatch = useAppDispatch();
    const funnelId = useAppSelector(selectFocusedChat);
    const [isLoading, setisLoading] = useState(true);

    const limit = 10;

    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (funnelId) {
            try {
                setisLoading(true);
                unsubscribe = ActivityAPI.getActivityByFunnelId(funnelId).onSnapshot(
                    query => {
                        const activity = query.docs.map(
                            doc =>
                                ({
                                    id: doc.id,
                                    ...doc.data(),
                                } as Contracts.DBActivity),
                        );
                        dispatch(setActivity(activity));
                        setisLoading(false);
                    },
                    err => {
                        // TODO: add error logging with sentry
                        console.error(err);
                        setisLoading(false);
                    },
                );
            } catch (error) {
                console.error(error);
            }
        }

        return () => unsubscribe?.();
    }, [funnelId, limit]);
    return { isLoading };
}
