import React from "react";
import { format } from "date-fns";
import { Dates } from "@anna/shared";
import { AutoReplyContainer, AppliedText, NoteText } from "./styled";
import { InterviewIcon } from "./UserTempateMessages/styled";

const getInterviewTimeSlotsText = ({ startTime }: { startTime?: Date }): string => {
    if (!startTime) return "Invalid date";
    return `${format(startTime, "dd LLL", Dates.locale)} at ${format(
        startTime,
        "HH:mm",
        Dates.locale,
    )}`;
};

const InterviewFinishedTemplate = ({ startTime }) => {
    return (
        <AutoReplyContainer>
            <InterviewIcon />

            <AppliedText>Interview started</AppliedText>

            <NoteText>{getInterviewTimeSlotsText({ startTime })}</NoteText>
        </AutoReplyContainer>
    );
};

export default InterviewFinishedTemplate;
