import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

interface PhoneNumberContainerProps {
    isExpanded?: boolean;
}

export const PhoneNumberContainer = styled.div<PhoneNumberContainerProps>`
    width: ${props => (props.isExpanded ? "auto" : StyleGuide.spacing.large)}px;
    height: ${StyleGuide.spacing.large}px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${props => (props.isExpanded ? StyleGuide.spacing.xsmall : 0)}px;
    background: ${StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.xsmall}px;

    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
`;
