import { PlandaySubmitButton, PlandaySubmitButtonIcon } from "../styled";
import React, { useState } from "react";
import { ReactComponent as PlandayIcon } from "@assets/svg/planday.svg";
import { Loader } from "@routes//Auth/components/Buttons/styled";
import { useAppSelector } from "../../../../../store/store";
import { Gender } from "@anna/shared";
import API from "../../../../../api/client";

export default function PlandayAddEmployee({
    funnelId,
    plandayDepartmentId,
    gender,
}: {
    funnelId: string;
    plandayDepartmentId?: number | null;
    gender?: Gender;
}) {
    const [isSubmitting, setisSubmitting] = useState(false);
    const plandayEmployeeId = useAppSelector(
        state => state.funnels.funnelMap[funnelId]?.plandayMetaDataEmployeeId,
    );
    const isFormCompleted = Boolean(plandayDepartmentId) && Boolean(gender);

    const handleAddToPlanday = async () => {
        try {
            setisSubmitting(true);
            if (!funnelId) {
                throw new Error("funnelId is not defined");
            }
            await API.syncEmployeeToPlanday({
                funnelId,
            });
        } catch (error) {
            console.error("Failed to add applicant to planday", error);
        } finally {
            setisSubmitting(false);
        }
    };
    return (
        <PlandaySubmitButton
            isDisabled={!isFormCompleted || isSubmitting || Boolean(plandayEmployeeId)}
            onClick={handleAddToPlanday}>
            <PlandaySubmitButtonIcon>
                <PlandayIcon />
            </PlandaySubmitButtonIcon>
            {plandayEmployeeId ? (
                "Already synced to Planday"
            ) : isSubmitting ? (
                <Loader />
            ) : (
                "Add to planday"
            )}
        </PlandaySubmitButton>
    );
}
