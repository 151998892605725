import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderTrialInviteTemplateDE({
    applicantName = TemplateVariables.APPLICANT_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
    trialLocation = TemplateVariables.LOCATION,
    trialLink = TemplateVariables.TIME_SLOTS,
    senderFirstName = TemplateVariables.SENDER_NAME,
}: TemplateProps) {
    return `Hi ${applicantName}

vielen Dank für deine Bewerbung bei ${restaurantTitle}. Deine Bewerbung hat uns überzeugt und wir würden dich gerne zum Probearbeiten einladen.

Klick hier um einen Termin auszuwählen: ${trialLink}

Die Addresse ist: ${trialLocation}

Wir freuen uns, dich bald kennenzulernen.

Beste Grüße
${senderFirstName}`;
}
