import { isSameWeek, isSameMonth } from "date-fns";
import { Dates } from "@anna/shared";

/**
 * Divides activity events in groups that happened: this week, this month and other.
 * It is assumed that activity events are sorted by their create time in the descending order.
 */
export function divideActivityEventsByTime<
    TActivityEvent extends Contracts.DBActivityEvent
>(activityEvents: TActivityEvent[], today: Date = new Date()) {
    const events = activityEvents
        .slice()
        .sort(
            (eventA, eventB) => eventB.createTime.getTime() - eventA.createTime.getTime(),
        );

    const eventsThisWeek = events.filter(event =>
        isSameWeek(event.createTime, today, { weekStartsOn: 1, ...Dates.locale }),
    );

    const eventsThisMonthExceptThisWeek = events
        .slice(eventsThisWeek.length)
        .filter(event => isSameMonth(event.createTime, today));

    const olderEvents = events.slice(
        eventsThisWeek.length + eventsThisMonthExceptThisWeek.length,
    );

    return {
        thisWeek: eventsThisWeek,
        thisMonthExceptThisWeek: eventsThisMonthExceptThisWeek,
        older: olderEvents,
    };
}
