import React from "react";

import { SubmitButton, SubmitButtonText, Loader } from "./styled";

function SubmitButtonComp({ text, loading, disabled, ...buttonProps }) {
    return (
        <SubmitButton {...buttonProps} disabled={disabled}>
            <SubmitButtonText disabled={disabled}>
                {loading ? <Loader /> : text}
            </SubmitButtonText>
        </SubmitButton>
    );
}

export default SubmitButtonComp;
