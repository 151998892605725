import { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectFocusedPosition } from "../userPositions/reducer";
import { setShortlistCount } from "./reducer";
import { ApplicationState } from "@anna/shared";
import { getSearchFilter } from "../../helpers/searchFilters";
import { Pagination } from "../pagination/reducer";
import useFunnelSearch from "../user/useFunnelSearch";

export default function useShortlistCount() {
    const { loading, onSearchFunnels } = useFunnelSearch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const funnelIds = useAppSelector(state => state.funnels.teamInboxIds);
    const workHourFilter = useAppSelector(state => state.homeFilters.workHoursFilter);
    const visaFilter = useAppSelector(state => state.homeFilters.visaFilter);

    const userId = useAppSelector(state => state.user.uid);

    const focusedPosition = useAppSelector(selectFocusedPosition);
    // filter is default null to select "all"
    const positionsFilter = useMemo(
        () => [focusedPosition].filter(Boolean),
        [focusedPosition],
    ) as string[];
    // const visaFilter = []; //useSelector((state: AppState) => state.filters.inbox.visaFilter);
    const dispatch = useAppDispatch();

    const filter = getSearchFilter({
        include: {
            stateFilter: [ApplicationState.REVIEWED],
            positionsFilter,
            workHourFilter,
            visaFilter,
        },
    });

    useEffect(() => {
        try {
            if (userId && !loading) {
                onSearchFunnels({
                    q: "",
                    filter: filter,
                    page: {
                        current: 1,
                        size: 1,
                    },
                }).then(({ meta }: { meta: Partial<Pagination> }) => {
                    dispatch(setShortlistCount(meta?.totalResults || 0));
                    if (initialLoading) {
                        setInitialLoading(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [loading, userId, positionsFilter, workHourFilter, visaFilter]);
    return { initialLoading, funnelIds };
}
