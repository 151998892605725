import styled from "styled-components";
import { motion } from "framer-motion";

export const Overlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const Container = styled(motion.div)`
    position: relative;
`;
