import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { AnimatePresence } from "framer-motion";

import { Overlay, Container } from "./styled";

const modalContainer = document.getElementById("root");

const Modal = ({
    visible = false,
    onClose = () => {},
    children,
    overlayStyle = {},
    containerStyle = {},
    closeOnOverlayClick = true,
    overlayAnimationProps,
    containerAnimationProps,
}: {
    visible: boolean;
    onClose: () => void;
    children: any;
    overlayStyle?: any;
    containerStyle?: any;
    closeOnOverlayClick?: boolean;
    overlayAnimationProps?: any;
    containerAnimationProps?: any;
}) => {
    const overlay = useRef<HTMLDivElement>(null);
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = ({ target }) => {
        const overlayNode = overlay.current;
        const containerNode = container.current;

        if (
            overlayNode &&
            overlayNode?.contains?.(target) &&
            !containerNode?.contains?.(target) &&
            closeOnOverlayClick
        ) {
            onClose();
        }
    };

    return ReactDOM.createPortal(
        <AnimatePresence>
            {visible && (
                <Overlay ref={overlay} style={overlayStyle} {...overlayAnimationProps}>
                    <Container
                        ref={container}
                        style={containerStyle}
                        {...containerAnimationProps}>
                        {children}
                    </Container>
                </Overlay>
            )}
        </AnimatePresence>,
        modalContainer as HTMLElement,
    );
};

export default Modal;
