import React from "react";

import {
    StyledSlider,
    Thumb,
    Track,
    EmptyStyledSlider,
    EmptyTrack,
    EmptyThumb,
} from "./styled";

const Slider = props => {
    if (props.isFocused) {
        return <StyledSlider renderTrack={Track} renderThumb={Thumb} {...props} />;
    }
    return (
        <EmptyStyledSlider renderTrack={EmptyTrack} renderThumb={EmptyThumb} {...props} />
    );
};

export default Slider;
