import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { activityMapper } from "./mapper";
import keyBy from "lodash.keyby";
import { ActivityType } from "@anna/shared";

interface BookingParticipant {
    id?: string;
    email?: string;
    name?: string;
}

export interface Activity {
    id: string;
    createTime: Date;
    funnelId: string;
    authorId: string;
    authorImage: string;
    authorDisplayName: string;
    authorSource: Contracts.ActivityMessageSource;
    type: ActivityType;
    [ActivityType.USER_MESSAGE]?: string;
    metaDataStartTime?: Date;
    metaDataEndTime?: Date;
    metaDataManual?: boolean;
    metaDataRejectionReason?: Contracts.RejectReason;
    metaDataMessage?: string;
    metaDataBookingId?: string;
    metaDataBookingParticipants?: BookingParticipant[];
    metaDataTimeSlots?: Contracts.CustomTimeSlotsDTO;
    metaDataPreference?: Contracts.CustomPreferenceDTO;
    metaDataLocation?: string;
    metaDataUserName?: string;
    metaDataIsReschedule?: boolean;
    metaDataRequestTime?: Date;
}

interface ActivityState {
    chatIds: string[];
    chatMap: { [id: string]: Activity };
}

// Define the initial state using that type
const initialState: ActivityState = {
    chatIds: [],
    chatMap: {},
};

export const activitySlice = createSlice({
    name: "activity",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setActivity(state, action: PayloadAction<Contracts.DBActivity[]>) {
            const formattedActivity = action.payload.map(activityMapper) as Activity[];
            const chatIds = formattedActivity.map(a => a.id);
            const chatMap = keyBy(formattedActivity, "id");
            return {
                ...state,
                chatIds,
                chatMap,
            };
        },
    },
});

export const { setActivity } = activitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectChatIds = (state: RootState) => state.activity.chatIds;

export default activitySlice.reducer;
