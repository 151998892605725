import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderTrialInviteTemplateDK({
    applicantName = TemplateVariables.APPLICANT_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
    trialLocation = TemplateVariables.LOCATION,
    trialLink = TemplateVariables.TIME_SLOTS,
    senderFirstName = TemplateVariables.SENDER_NAME,
}: TemplateProps) {
    return `Hej ${applicantName}

Vi vil meget gerne se dig til en prøvevagt hos os på ${trialLocation}.

Tryk her for at vælge et tidspunkt, der passer dig: ${trialLink}

Vi glæder os til at se dig.

Bedste hilsner
${senderFirstName} (${restaurantTitle})`;
}
