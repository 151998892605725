import { useEffect } from "react";
import { useAppSelector } from "../../store/store";
import * as NotificationAPI from "./api";

// TODO: add to profile modal
export function useMarkNotificationAsSeen(funnelId: string | null) {
    const notificationIds = useAppSelector(state =>
        // @ts-ignore
        state.notifications.notificationGroupedByFunnelId?.[funnelId]?.map(
            ({ id }) => id,
        ),
    );

    useEffect(() => {
        if (funnelId && notificationIds?.length > 0) {
            // @ts-ignore
            NotificationAPI.markNotificationsAsSeen(notificationIds);
        }

        return () => {};
    }, [funnelId]);
}
