import React, { useState, useEffect, useRef } from "react";
import omit from "lodash.omit";

import { TrialIcon } from "@assets/images/icons";
import { Objects, TemplateVariables, TemplateLinks } from "@anna/shared";
import {
    timeSlotsDateFormat,
    mediumLocalDateFormat,
    stepValueToHalfHourIntervalInMinutes,
} from "@utils/dates";
import TimeSlotsPreview, {
    popUpHeight,
    popUpBottomMargin,
} from "@components/TimeSlotsPreview";
import { InfoButton, SendButton } from "../Template";
import LocationPicker from "../InterviewTemplate/LocationPicker";
import DatePicker from "../InterviewTemplate/DatePicker";
import { ResetTemplateAddon } from "../InterviewTemplate/Popovers";
import TrialRangePickers from "./TrialRangePickers";
import { Container, TimeSlotVariableStyle } from "./styled";
import { useTrialLocations } from "../../../../features/locations/useTrialLocations";
import { useAppSelector } from "../../../../store/store";
import {
    editTrialLocation,
    addTrialLocation,
    removeTrialLocation,
} from "../../../../features/locations/api";
import {
    selectTrialLocations,
    selectDefaultTrialLocation,
} from "../../../../features/locations/reducer";

const defaultStartTimeStep = 16;
const defaultEndTimeStep = 46;

function generateTimesFromSteps(startStep, endStep) {
    return {
        startTimeInSteps: startStep,
        startTimeInMinutes: stepValueToHalfHourIntervalInMinutes(startStep),
        endTimeInSteps: endStep,
        endTimeInMinutes: stepValueToHalfHourIntervalInMinutes(endStep),
    };
}

const TrialTemplate = ({
    closeTemplate,
    sendTemplate,
    onTemplateSendRef,
    setTemplateVariables,
    templateText,
    resetTemplate,
}) => {
    useTrialLocations();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showTimeSlotsDisabledAlert, toggleTimeSlotsDisabledAlert] = useState(false);
    const [showPreviewPopup, togglePreviewPopup] = useState(false);
    const [popupCoordinates, setPopupCoordinates] = useState({ top: 0, left: 0 });
    const [selectedCustomTimes, setSelectedCustomTimes] = useState({});
    const [prevSelectedDates, setPrevSelectedDates] = useState({});
    const restaurantId = useAppSelector(
        state =>
            state.funnels.funnelMap[state.funnels.focusedChat as string]?.restaurantId,
    );
    const isLoadingTrialLocations = useAppSelector(
        state => state.locations.isLoadingTrialLocations,
    );
    const trialLocations = useAppSelector(selectTrialLocations);
    const defaultTrialLocation = useAppSelector(selectDefaultTrialLocation);
    const [selectedLocation, setSelectedLocation] = useState(defaultTrialLocation);

    const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

    const isTimeSlotsEmpty = Object.keys(selectedCustomTimes).length === 0;
    const isLocationEmpty = !Boolean(selectedLocation?.address);
    const isTimeSlotsLinkBroken = !templateText.includes(TemplateVariables.TIME_SLOTS);
    const isLocationBroken = !templateText.includes(TemplateVariables.LOCATION);
    const isSubmitDisabled =
        isTimeSlotsEmpty || isTimeSlotsLinkBroken || isLocationEmpty || isLocationBroken;

    const openTimeSlotsDisabledAlert = () => {
        if (timer?.current) {
            clearTimeout(timer?.current);
        }
        toggleTimeSlotsDisabledAlert(true);

        timer.current = setInterval(() => {
            toggleTimeSlotsDisabledAlert(false);
        }, 3000);
    };

    const getTotalTimeSlots = (customTimes: Contracts.CustomTimeSlotsDTO) => {
        return Object.values(customTimes).length;
    };

    const chooseSelectedDates = dates => {
        setPrevSelectedDates({ ...selectedCustomTimes });
        setSelectedCustomTimes(Objects.withSortedKeys(dates));
    };

    const getSubmitLabel = () => {
        if (isTimeSlotsEmpty || isTimeSlotsLinkBroken) {
            return "Pick at least one time slot";
        }

        if (isLocationEmpty || isLocationBroken) {
            return "Pick at least one location";
        }

        return "Send trial invite";
    };

    const onAddLocation = async (title: string) => {
        await addTrialLocation(restaurantId, title);
    };
    const onUpdateLocation = async (locationId: string, title: string) => {
        await editTrialLocation(restaurantId, locationId, title);
    };
    const onRemoveLocation = async (locationId: string) => {
        await removeTrialLocation(restaurantId, locationId);
    };

    const onSelectDay = date => {
        setSelectedDate(date);
        const day = timeSlotsDateFormat(date);
        const previousTimes = prevSelectedDates[day];

        const isSelected = day in selectedCustomTimes;

        if (isSelected) {
            chooseSelectedDates(omit(selectedCustomTimes, [day]));
            return;
        }

        const selectedDay =
            previousTimes ??
            generateTimesFromSteps(defaultStartTimeStep, defaultEndTimeStep);

        chooseSelectedDates({
            ...selectedCustomTimes,
            [day]: selectedDay,
        });
    };

    const onModifySelectedTimes = (date, startStep, endStep) => {
        chooseSelectedDates({
            ...selectedCustomTimes,
            [date]: generateTimesFromSteps(startStep, endStep),
        });
    };

    const onSubmit = () => {
        if (isTimeSlotsLinkBroken || isLocationBroken) {
            return;
        }
        if (isTimeSlotsEmpty) {
            return openTimeSlotsDisabledAlert();
        }

        if (isLocationEmpty) {
            //TODO: missing location alert
            return;
        }

        sendTemplate({
            locationId: selectedLocation?.id, //selectedLocation?.id || defaultLocation?.id || "",
            customPreference: selectedCustomTimes as Contracts.CustomPreferenceDTO,
        });
    };
    useEffect(() => {
        setTemplateVariables([
            {
                variable: TemplateVariables.TIME_SLOTS,
                customStyle: isTimeSlotsEmpty ? "" : TimeSlotVariableStyle,

                text: isTimeSlotsEmpty
                    ? "[You need to pick time slots]"
                    : TemplateLinks.INVITATION_LINK,
                onPress: (clickedElement?: DOMRect) => {
                    if (!clickedElement?.top || !clickedElement?.left) return;
                    togglePreviewPopup(true);
                    setPopupCoordinates({
                        top: clickedElement?.top,
                        left: clickedElement?.left,
                    });
                    // TODO: add modal here for timeslots
                },
            },
            {
                variable: TemplateVariables.LOCATION,
                text: isLocationEmpty
                    ? "[You need to pick a location]"
                    : selectedLocation?.address || "",
            },
        ]);
    }, [isTimeSlotsEmpty, selectedLocation]);

    useEffect(() => {
        onTemplateSendRef.current = onSubmit;
    }, [onSubmit]);

    const datePickerLabel =
        Object.keys(selectedCustomTimes).length === 0
            ? "Pick a date"
            : mediumLocalDateFormat(selectedDate);

    return (
        <Container>
            <InfoButton label="Trial" Icon={<TrialIcon />} onPress={closeTemplate} />
            {isLoadingTrialLocations ? (
                "Loading locations..."
            ) : (
                <LocationPicker
                    locations={trialLocations}
                    defaultLocation={defaultTrialLocation}
                    selectedLocation={selectedLocation}
                    isLocationBroken={isLocationBroken}
                    resetTemplate={resetTemplate}
                    setSelectedLocation={setSelectedLocation}
                    addLocation={onAddLocation}
                    updateLocation={onUpdateLocation}
                    removeLocation={onRemoveLocation}
                />
            )}

            <DatePicker
                selectedDate={selectedDate}
                label={datePickerLabel}
                setSelectedDate={onSelectDay}
                showWarning={showTimeSlotsDisabledAlert}
                selectedCustomTimes={selectedCustomTimes}
                totalTimeslots={getTotalTimeSlots(selectedCustomTimes)}
                isTimeSlotsLinkBroken={isTimeSlotsLinkBroken}
                forInterview={false}
            />

            <TrialRangePickers
                selectedCustomTimes={selectedCustomTimes}
                onModifySelectedTimes={onModifySelectedTimes}
                isTimeSlotsLinkBroken={isTimeSlotsLinkBroken}
            />

            {isTimeSlotsLinkBroken ? (
                <ResetTemplateAddon
                    title="Time slot link is disabled"
                    description="Because you changed the time slot link. To use this template, you need the time slot addon."
                    resetTemplate={resetTemplate}
                />
            ) : (
                <SendButton
                    label={getSubmitLabel()}
                    onPress={onSubmit}
                    isDisabled={isSubmitDisabled}
                />
            )}

            {showPreviewPopup && (
                <TimeSlotsPreview
                    style={{
                        top: popupCoordinates.top - popUpHeight - popUpBottomMargin,
                        left: popupCoordinates.left,
                    }}
                    onClose={() => togglePreviewPopup(false)}
                    customTrialTimes={selectedCustomTimes}
                    forInterview={false}
                />
            )}
        </Container>
    );
};

export default TrialTemplate;
