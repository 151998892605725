import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as TickIcon } from "@assets/svg/tick.svg";
import {
    Container,
    DropdownContainer,
    ListContainer,
    SectionContainer,
    SectionTitle,
    FilterOption,
    FilterCheckbox,
    ClearFiltersButton,
} from "./styled";

const Option = ({ onSelect, option, isSelected, isLast }) => {
    const onOptionSelect = e => {
        e.stopPropagation();

        onSelect(option);
    };

    return (
        <FilterOption onClick={onOptionSelect} isLast={isLast}>
            {option.title}
            <FilterCheckbox checked={isSelected}>
                {isSelected && <TickIcon />}
            </FilterCheckbox>
        </FilterOption>
    );
};

const FiltersDropdown = ({
    options,
    selectedFilters,
    onSelect,
    renderToggleBlock,
    resetFilters,
}) => {
    const [opened, setOpened] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isEmptyFilters = selectedFilters.length === 0;

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpened(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const clearFilters = e => {
        e.stopPropagation();
        if (!isEmptyFilters) {
            resetFilters();
        }
    };

    return (
        <Container onClick={() => setOpened(!opened)} ref={wrapperRef}>
            {renderToggleBlock && renderToggleBlock(opened)}

            {opened && (
                <DropdownContainer>
                    <ListContainer>
                        {options.map((option, index) => (
                            <SectionContainer isFirst={index === 0} key={option.id}>
                                <SectionTitle>{option.section}</SectionTitle>

                                {option.filters.map((filter, i) => {
                                    const isSelected = selectedFilters.some(
                                        selected => selected === filter.id,
                                    );

                                    return (
                                        <Option
                                            key={filter.id}
                                            isSelected={isSelected}
                                            isLast={i >= option.filters.length - 1}
                                            option={filter}
                                            onSelect={onSelect}
                                        />
                                    );
                                })}
                            </SectionContainer>
                        ))}
                    </ListContainer>

                    <ClearFiltersButton onClick={clearFilters} disabled={isEmptyFilters}>
                        Clear filters
                    </ClearFiltersButton>
                </DropdownContainer>
            )}
        </Container>
    );
};

export default FiltersDropdown;
