import { zonedTimeToUtc } from "date-fns-tz";

export function minutesToHoursAndMinutes(totalMinutes: number): string {
    const hours = totalMinutes / 60;
    const resultHours = Math.floor(hours);
    const derivedMinutes = (hours - resultHours) * 60;
    const resultMinutes = Math.round(derivedMinutes);
    return `${resultHours
        .toString()
        .padStart(2, "0")}:${resultMinutes.toString().padStart(2, "0")}`;
}

export const dayMinutesToDate = (day: string, timeInMinutes: number): Date => {
    const startTimeFormat = minutesToHoursAndMinutes(timeInMinutes);
    return new Date(`${day}T${startTimeFormat}:00.000Z`);
};

/**
 * @param day
 * @param timeInMinutes
 * @param timeZone
 */
export const dayMinutesToDateWithTimezone = (
    day: string,
    timeInMinutes: number,
    timeZone: Contracts.SupportedTimeZones,
): Date => {
    const startTimeFormat = minutesToHoursAndMinutes(timeInMinutes);
    /**
     * zone the time as we get it in the restaurants preferred
     * time but we want to store it in UTC time
     */
    return zonedTimeToUtc(new Date(`${day}T${startTimeFormat}:00.000Z`), timeZone);
};
