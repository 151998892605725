import React, { useState, useEffect, useRef } from "react";
import htmlToFormattedText from "html-to-formatted-text";
import { useResizeDetector } from "react-resize-detector";

import { ReactComponent as SendIcon } from "@assets/svg/send.svg";
import { TemplateVariables } from "@anna/shared";
import {
    ChatInputToolbar,
    ChatInputContainer,
    ChatInputTemplateComponent,
    SendButton,
    InputPlaceholder,
} from "./styled";

const TemplateInput = ({
    sendNewMessage,
    scrollBottomRef,
    message,
    setMessage,
    hideSendButton = false,
    templateVariables = [] as {
        variable: TemplateVariables;
        customStyle?: string;
        text: string;
        onPress?: (clickedElement?: DOMRect) => void;
    }[],
}) => {
    const [html, setHtml] = useState("");
    const innerRef = useRef<HTMLDivElement | null>(null);
    const eventsRef = useRef({});

    const disabled = message.trim() === "";

    const { height, ref } = useResizeDetector();

    const getVariablesRegex = () => {
        const variablesText = templateVariables.reduce((acc, nextVar) => {
            if (acc === "") {
                return nextVar.variable;
            }
            return `${acc}|${nextVar.variable}`;
        }, "");

        return new RegExp(variablesText, "gi");
    };

    const regexVariables = getVariablesRegex();
    //calculate variables count so we can keep track of new event handlers
    const variablesCount = [...message.matchAll(regexVariables)].length;

    const getVariableId = (variable, index) => `${variable}_${index}`;

    const handleClick = (innerVariable, onPress) => {
        //get clicked element so we can use element's coordinates
        const clickedElement = innerVariable?.getBoundingClientRect();

        onPress && onPress(clickedElement);
        innerRef?.current?.blur?.();
    };

    useEffect(() => {
        //replace \n with  <br /> for showing new lines on the editable div input
        let textWithReplacements = `${message}`.split("\n").join("<br>");
        //iterate through all variables and replace them with a custom text
        templateVariables?.forEach(({ variable, text, customStyle }, index) => {
            const variableId = getVariableId(variable, index);
            //remove all the variables and replace them with a span tag (which is better for aligning with the other text)
            //give a unique variable id, so we can use it for event handlers
            textWithReplacements = textWithReplacements
                .split(variable)
                .join(
                    Boolean(customStyle)
                        ? `<span style="${customStyle}" id="${variableId}">${text}</span>`
                        : `<span>${text}</span>`,
                );
        });
        setHtml(textWithReplacements);
    }, [templateVariables, variablesCount]);

    useEffect(() => {
        innerRef?.current?.blur?.();
    }, [variablesCount]);

    useEffect(() => {
        scrollBottomRef?.current?.();
    }, [height]);

    //TODO: Timeslots preview popup handler

    useEffect(() => {
        //get variables with onpress handler
        const clickableVariables = templateVariables
            .map((variable, index) => ({
                ...variable,
                id: getVariableId(variable.variable, index),
            }))
            .filter(variable => Boolean(variable.onPress));

        clickableVariables.forEach(({ id, onPress }) => {
            //get all variables html tag by id, so we can keep an onclick listener for each of them
            const variablesList = document?.querySelectorAll?.(`#${id}`);

            //iterate through  html tag and set the onclick listeneronclick listener
            variablesList.forEach((innerVariable, index) => {
                //check if there is already a handler on the ref for this variable, and remove it
                if (eventsRef.current[`${id}_${index}`]) {
                    innerVariable?.removeEventListener?.(
                        "click",
                        eventsRef.current[`${id}_${index}`],
                    );
                }

                //create a function handler and save it to a ref so it's easier to remove later
                //we are forced to save the function in a ref, because removeEventListener requires the same unique function declared in addEventListener
                const onClickHandler = handleClick.bind(null, innerVariable, onPress);
                eventsRef.current = {
                    ...(eventsRef.current || {}),
                    [`${id}_${index}`]: onClickHandler,
                };

                innerVariable?.addEventListener?.("click", onClickHandler);
            });
        });

        return () => {
            clickableVariables.forEach(({ id }) => {
                const variablesList = document?.querySelectorAll?.(`#${id}`);

                variablesList.forEach((innerVariable, index) => {
                    //same logic as above
                    if (eventsRef.current[`${id}_${index}`]) {
                        innerVariable?.removeEventListener?.(
                            "click",
                            eventsRef.current[`${id}_${index}`],
                        );
                    }
                });
            });
        };
    }, [html, height, templateVariables]);

    const handleKeyDown = event => {
        if (event.key === "Enter") {
            //if user clicks Ctrl or CMD fire onSend
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                onSend();
                return;
            }
        }
    };

    const onSend = async () => {
        if (disabled) return;
        await sendNewMessage(message);
    };

    return (
        <ChatInputToolbar hideSendButton={hideSendButton}>
            <ChatInputContainer ref={ref}>
                <ChatInputTemplateComponent
                    html={html}
                    onChange={event => {
                        //replace <br /> with <div /><br/> so htmlToFormattedText can give a better result
                        const formatted = event.target.value
                            .split("<br>")
                            .join("<div /><br/>");
                        //get the message text from the html
                        let textWithVariables = htmlToFormattedText(formatted);
                        //replace variables custom text with the variables key
                        templateVariables?.forEach(({ variable, text }) => {
                            textWithVariables = textWithVariables
                                .split(text)
                                .join(variable);
                        });
                        setMessage(textWithVariables);
                        setHtml(event.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    ref={ref}
                    innerRef={innerRef}
                />

                {disabled && (
                    <InputPlaceholder>Click ctrl + enter to send</InputPlaceholder>
                )}

                {!hideSendButton && (
                    <SendButton disabled={disabled} onClick={onSend}>
                        <SendIcon />
                    </SendButton>
                )}
            </ChatInputContainer>
        </ChatInputToolbar>
    );
};

export default TemplateInput;
