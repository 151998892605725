import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "./configure";
import { useAppDispatch } from "../store/store";
import { setFirebaseUser } from "../features/user/reducer";
import { Routes } from "../constants/routes";

function authRedirect(history) {
    if (
        window.location.pathname === Routes.SIGN_IN ||
        window.location.pathname === Routes.ROOT
    ) {
        return history.replace(Routes.HOME);
    }
}

function notAuthRedirect(history) {
    if (
        window.location.pathname !== Routes.SIGN_IN &&
        window.location.pathname !== Routes.FORGOT_PASSWORD
    ) {
        return history.replace(Routes.SIGN_IN);
    }
}

/**
 * Docs: https://firebase.google.com/docs/auth/web/start#set_an_authentication_state_observer_and_get_user_data
 */
export function useAuthentication() {
    const history = useHistory();
    const dispatch = useAppDispatch();

    // default not authenticated
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // default is loading
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            // if user exists it means authenticated
            if (user) {
                dispatch(
                    setFirebaseUser({ uid: user.uid, email: user?.email || undefined }),
                );
                setIsAuthenticated(true);
                setIsLoading(false);
                authRedirect(history);
            } else {
                setIsAuthenticated(false);
                setIsLoading(false);
                notAuthRedirect(history);
            }
        });
    }, []);

    return { isAuthenticated, isLoading };
}
