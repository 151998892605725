import React, { useState, useEffect } from "react";

import TemplatesDropdown from "@components/Dropdowns/TemplatesDropdown";
import ManageLocations from "@components/CustomModals/ManageLocationsModal";
import { ResetTemplateAddon } from "./Popovers";
import {
    LocationPickerContainer,
    DatePickerTitle,
    ManageLocationsButton,
} from "./styled";

const LocationPicker = ({
    locations,
    defaultLocation,
    isLocationBroken,
    resetTemplate,
    addLocation,
    updateLocation,
    removeLocation,
    selectedLocation,
    setSelectedLocation,
}) => {
    const [showManageLocationModal, toggleManageLocationModal] = useState(false);

    useEffect(() => {
        // if there is no default location
        const defaultFallback = locations[0];
        setSelectedLocation(defaultLocation || defaultFallback);
        return () => {};
    }, [locations]);

    const addNewLocation = address => {
        if (address.trim() === "") {
            return;
        }

        addLocation(address);
        // updateLocations(newLocations);
    };

    const onLocationEdit = (selectedLocation, newAddress) => {
        if (newAddress.trim() === "") {
            return;
        }
        updateLocation(selectedLocation.id, newAddress);
        // updateLocations(newLocations);
    };

    const onLocationDelete = (locationId: string) => {
        // addLocation
        removeLocation(locationId);
        // updateLocations(locations.filter(location => location.id !== deletedLocation.id));
    };

    if (isLocationBroken) {
        return (
            <LocationPickerContainer>
                <ResetTemplateAddon
                    title="Location addon disabled"
                    description="Because you manually changed the address in the text."
                    resetTemplate={resetTemplate}
                />
            </LocationPickerContainer>
        );
    }

    return (
        <LocationPickerContainer>
            <DatePickerTitle>Location</DatePickerTitle>

            <TemplatesDropdown
                placeholder="Select Location"
                selected={selectedLocation}
                optionsList={locations}
                getOptionLabel={option => option?.address || ""}
                getOptionKey={option => option?.id}
                getOptionSelected={option => option?.id === selectedLocation?.id}
                onOptionSelect={option => {
                    setSelectedLocation(option);
                }}
                renderCustomOptions={closeDropdown => {
                    return (
                        <ManageLocationsButton
                            onClick={() => {
                                closeDropdown();
                                toggleManageLocationModal(true);
                            }}>
                            Manage Locations
                        </ManageLocationsButton>
                    );
                }}
            />

            {showManageLocationModal && (
                <ManageLocations
                    locations={locations}
                    onClose={() => toggleManageLocationModal(false)}
                    onLocationDelete={onLocationDelete}
                    onLocationEdit={onLocationEdit}
                    addNewLocation={addNewLocation}
                />
            )}
        </LocationPickerContainer>
    );
};

export default LocationPicker;
