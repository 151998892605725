import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";

export const getNotifications = (id: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.NOTIFICATIONS)
            .where("recipientId", "==", id)
            .where("seen", "==", false)
            .orderBy("createTime", "desc");
    } catch (error) {
        console.error(error);
        return null;
    }
};
//TODO: for profile
export const markNotificationsAsSeen = async (ids: string[]) => {
    try {
        const batch = firestore.batch();
        ids.forEach(id => {
            const ref = firestore.collection(COLLECTIONS.NOTIFICATIONS).doc(id);
            batch.update(ref, { seen: true });
        });
        await batch.commit();
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};
