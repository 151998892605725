import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderRejectionTemplateDK({
    applicantName = TemplateVariables.APPLICANT_NAME,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hej ${applicantName},

Endnu en gang tusind tak for din ansøgning hos ${restaurantTitle}.

Vi har desværre valgt at fortsætte processen med en anden kandidat.
Vi ønsker dig alt det bedste.

Bedste hilsner
${senderFirstName}`;
}
