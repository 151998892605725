import mappet from "mappet";
import {
    ApplicationTypeValue,
    ApplicationTypes,
    TypeformWorkHours,
    MappedWorkHours,
} from "@anna/shared";
enum WorkhoursValue {
    UNDER_10_HOURS = "5-10 hours",
    UNDER_20_HOURS = "10-20 hours",
    UNDER_30_HOURS = "20-30 hours",
    UNDER_40_HOURS = "30-40 hours",
}
export function mapWorkHours(value: TypeformWorkHours | WorkhoursValue) {
    switch (value) {
        case TypeformWorkHours.FULL_TIME:
        case WorkhoursValue.UNDER_40_HOURS:
            return MappedWorkHours.FULL_TIME;
        case TypeformWorkHours.PART_TIME:
        case WorkhoursValue.UNDER_30_HOURS:
        case WorkhoursValue.UNDER_20_HOURS:
        case WorkhoursValue.UNDER_10_HOURS:
            return MappedWorkHours.PART_TIME;
        case TypeformWorkHours.PART_TIME_SU_MIN_10_HOURS:
            return MappedWorkHours.PART_TIME_MIN_10_HOURS;
        case TypeformWorkHours.PART_TIME_12_HOURS:
            return MappedWorkHours.PART_TIME_12_HOURS;
        case TypeformWorkHours.PART_TIME_OR_FULL_TIME:
            return MappedWorkHours.PART_TIME_OR_FULL_TIME;
        case TypeformWorkHours.INTERNSHIP:
            return MappedWorkHours.INTERNSHIP;
        case TypeformWorkHours.U18:
            return MappedWorkHours.U18;

        case TypeformWorkHours.UNDER_10_HOURS:
            return MappedWorkHours.UNDER_10_HOURS;
        case TypeformWorkHours.UNDER_20_HOURS:
            return MappedWorkHours.UNDER_20_HOURS;
        case TypeformWorkHours.UNDER_30_HOURS:
            return MappedWorkHours.UNDER_30_HOURS;
        case TypeformWorkHours.UNDER_40_HOURS:
            return MappedWorkHours.UNDER_40_HOURS;
        default:
            return value;
    }
}

export const getFormatedWorkHours = (target: Contracts.TypeformWorkHours) =>
    mapWorkHours(target);

export const determineIsIntern = (value: ApplicationTypeValue) =>
    value?.toLowerCase()?.includes("intern") || null;

const determinePosition = (applicationPosition: ApplicationTypeValue) => {
    if (ApplicationTypes.boh.includes(applicationPosition)) {
        return true;
    }
    if (ApplicationTypes.foh.includes(applicationPosition)) {
        return false;
    }
    if (ApplicationTypes.administrative.includes(applicationPosition)) {
        return false;
    }
    return false;
};

// to prevent typeform corupted data
const determineProfileImage = (target: string) =>
    typeof target === "string" ? target : null;

const determineSortTime = (sortTime: any, source: any) => {
    if (sortTime) {
        return sortTime.toDate();
    }
    if (source.updateTime) {
        return source.updateTime.toDate();
    }
    return source.createTime.toDate();
};

function transformDate(updateTime: any) {
    return updateTime.toDate();
}

const schema = {
    id: "id",
    createTime: { path: "createTime", modifier: transformDate },
    applicantId: "applicantId",
    restaurantId: "restaurantId",
    interviewerId: "interviewerId",
    interviewLocationId: "interviewLocationId",
    trialLocationId: "trialLocationId",
    state: "state",
    previousState: "previousState",
    seen: "seen",
    favourite: "favourite",
    updateTime: { path: "updateTime", modifier: transformDate },
    sortTime: { path: "metaData.sortTime", modifier: determineSortTime },
    userMetaFirstName: "userMeta.firstName",
    userMetaLastName: "userMeta.lastName",
    userMetaProfileImage: { path: "userMeta.image", modifier: determineProfileImage },
    userMetaPosition: "userMeta.position",
    userMetaPositionDetail: {
        path: "userMeta",
        modifier: (userMeta: {
            positionDetail: string;
            positionBoh: string;
            positionFoh: string;
            positionAdmin: string;
        }): string => {
            const postionDetail =
                userMeta?.positionDetail ||
                userMeta?.positionBoh ||
                userMeta?.positionFoh ||
                userMeta?.positionAdmin;
            return postionDetail;
        },
    },
    userMetaPositionBoh: "userMeta.positionBoh",
    userMetaPositionFoh: "userMeta.positionFoh",
    userMetaPositionAdmin: "userMeta.positionAdmin",
    userMetaPositionInternDuration: "userMeta.positionInternDuration",
    userMetaWorkHours: {
        path: "userMeta.workHours",
        modifier: getFormatedWorkHours,
    },
    workhourFilter: "workhourFilter",
    userMetaVisaType: "userMeta.visaType",
    reasonRejected: "reason.rejected",
    isBackOfHouse: { path: "userMeta.position", modifier: determinePosition },
    isAdministrative: { path: "userMeta.position", modifier: determinePosition },
    isIntern: {
        path: "userMeta.position",
        modifier: determineIsIntern,
    },
    plandayMetaDataDepartmentId: "plandayMetaData.departmentId",
    plandayMetaDataEmployeeId: "plandayMetaData.employeeId",
    subscribers: "subscribers",
    restaurantCategory: "restaurantCategory",
    addedToPool: "addedToPool",
};

export const funnelMapper = mappet(schema, { greedy: true });

const schemaElastic = {
    id: "id",
    createTime: { path: "createTime", modifier: transformDate },
    updateTime: { path: "updateTime", modifier: transformDate },
};

export const funnelElasticMapper = mappet(schemaElastic, { greedy: true });
