import styled from "styled-components";
import { Link } from "react-router-dom";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.base}px;
    display: flex;
`;

export const RestaurantPickerContainer = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

type RestaurantBubbleProps = {
    isSelected?: boolean;
};

export const RestaurantBubble = styled.div<RestaurantBubbleProps>`
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px;
    background-color: ${props =>
        props.isSelected
            ? StyleGuide.palette.secondaryBasil
            : StyleGuide.palette.baseSugar};
    color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSalt : StyleGuide.palette.baseVanilla};
    border-radius: ${StyleGuide.spacing.base}px;
    margin-right: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
    ${props =>
        props.isSelected ? StyleGuide.typography.headline1 : StyleGuide.typography.body1};
    white-space: nowrap;

    :hover {
        background-color: ${props =>
            props.isSelected
                ? StyleGuide.palette.secondaryBasil
                : StyleGuide.palette.baseMsg};
    }
`;

export const TabNavigatorContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 80px;
    white-space: nowrap;
`;

export const TabLink = styled(Link)`
    text-decoration: none;
`;
export const DisbaledTabLink = styled.div`
    text-decoration: none;
`;

type TabBubbleProps = {
    isSelected?: boolean;
};

export const TabBubble = styled.div<TabBubbleProps>`
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSugar : "transparent"};
    color: ${props =>
        props.isSelected
            ? StyleGuide.palette.secondaryCucumber
            : StyleGuide.palette.baseVanilla};
    border-radius: ${StyleGuide.spacing.xsmall}px;
    margin-right: ${StyleGuide.spacing.xsmall}px;
    cursor: pointer;
    ${props =>
        props.isSelected ? StyleGuide.typography.headline1 : StyleGuide.typography.body1};
    :hover {
        background-color: ${StyleGuide.palette.baseSugar};
    }
`;

export const DisbaledTabBubble = styled.div`
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    margin-right: ${StyleGuide.spacing.xsmall}px;
    cursor: not-allowed;
    ${StyleGuide.typography.body1};
`;

export const TabBubbleIcon = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${StyleGuide.spacing.mini}px;
`;

export const NotificationFlagContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const HeaderBellBubble = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    align-self: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const HeaderBellCount = styled.div`
    position: absolute;
    top: -${StyleGuide.spacing.xsmall}px;
    right: -${StyleGuide.spacing.xsmall}px;
    width: 20px;
    height: 20px;
    border-radius: ${StyleGuide.spacing.small}px;
    background-color: ${StyleGuide.palette.primaryAnanas};
    ${() => StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.baseSalt};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const ToggleBubble = styled.div`
    width: ${StyleGuide.spacing.large}px;
    height: ${StyleGuide.spacing.large}px;
    background-color: ${StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ToggleBubbleCounter = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: ${StyleGuide.spacing.small}px;
    background-color: ${StyleGuide.palette.secondaryBasil};
    ${() => StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.baseSalt};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const NotificationsActionsDivider = styled.div`
    width: 20px;
`;
