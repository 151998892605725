import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderIntroTemplateDK({
    applicantName = TemplateVariables.APPLICANT_NAME,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hej ${applicantName},

Endnu en gang tak for at du har søgt job hos ${restaurantTitle}. Vi synes din ansøgning er rigtig fin, hvornår har du tid til at komme forbi til en snak?

Vi ser frem til at høre fra dig.

Bedste hilsner
${senderFirstName}`;
}
