import React from "react";
// import useCollapse from "react-collapsed";
import { AutoSizer, InfiniteLoader } from "react-virtualized";

import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import ApplicantCard from "./ApplicantCard";
import { setFocusedProfile } from "../../features/funnels/reducer";
import { useAppDispatch } from "../../store/store";
import {
    Container,
    ListContainer,
    VListContainer,
    ApplicantListItem,
    ToggleHeader,
    ArrowIcon,
    CategoryContainer,
    CountContainer,
    CategoryTitle,
    rowHeight,
} from "./styled";

const remoteRowCount = 1000;

const ApplicantsList = ({
    title,
    applicantIds,
    isOpenedByDefault,
    colorTheme,
    count,
    handleLoadMore,
}) => {
    const dispatch = useAppDispatch();
    // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    //     defaultExpanded: isOpenedByDefault,
    // });

    const openProfileModal = (funnelId: string) => {
        dispatch(setFocusedProfile({ funnelId, funnelIds: applicantIds }));
    };

    const renderRow = ({ index, key, style }) => {
        const id = applicantIds[index];
        return (
            <ApplicantListItem key={key} style={style} className="row">
                <ApplicantCard
                    id={id}
                    onPress={() => openProfileModal(id)}
                    // only display new applicants notification itn applicants list
                    displayNewApplicantNotifications={title === "Applicants"}
                />
            </ApplicantListItem>
        );
    };

    const isRowLoaded = ({ index }) => {
        return !!applicantIds[index];
    };

    return (
        <Container>
            <ToggleHeader /*{...getToggleProps()}*/ isOpened={isOpenedByDefault}>
                {/* //{isExpanded}> */}
                <CategoryContainer>
                    <CountContainer background={colorTheme}>{count}</CountContainer>

                    <CategoryTitle>{title}</CategoryTitle>
                </CategoryContainer>
                <ArrowIcon isOpened={isOpenedByDefault}>
                {/* {isExpanded}> */}
                    <ArrowUp />
                </ArrowIcon>
            </ToggleHeader>

            {isOpenedByDefault && (
                <ListContainer /*{...getCollapseProps()}*/>
                    <InfiniteLoader
                        isRowLoaded={isRowLoaded}
                        loadMoreRows={handleLoadMore}
                        rowCount={remoteRowCount}>
                        {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                                {({ width, height }) => {
                                    return (
                                        <VListContainer
                                            width={width}
                                            height={height}
                                            ref={registerChild}
                                            onRowsRendered={onRowsRendered}
                                            rowHeight={rowHeight}
                                            rowRenderer={renderRow}
                                            rowCount={applicantIds.length}
                                            // overscanRowCount={20}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </ListContainer>
            )}
        </Container>
    );
};

export default ApplicantsList;
