import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import Modal from "@components/Modal";
import {
    ManageLocationPopup,
    ManageLocationTitle,
    ManageLocationCloseButton,
    LocationInputContainer,
    LocationInputComponent,
    LocationInputCloseButton,
    LocationInputDeleteButton,
    LocationInputSaveButton,
    AddNewAddressButton,
    PlusIconContainer,
    PlusIcon,
} from "./styled";

const LocationInput = ({
    location,
    onLocationDelete,
    onLocationEdit,
    locationToRemove,
    setLocationToRemove,
}) => {
    const [localInputValue, setLocalInputValue] = useState(location.address);
    const [isInputFocused, toggleInputFocused] = useState(false);
    const showDeleteButton = locationToRemove === location.id;

    const validateSaveButton =
        location.address !== localInputValue && localInputValue.trim().length;

    const renderActionButton = () => {
        if (validateSaveButton) {
            return (
                <LocationInputSaveButton
                    onClick={() => onLocationEdit(location, localInputValue)}>
                    Save
                </LocationInputSaveButton>
            );
        }

        if (showDeleteButton) {
            return (
                <LocationInputDeleteButton onClick={() => onLocationDelete(location)}>
                    Remove
                </LocationInputDeleteButton>
            );
        }

        return (
            <LocationInputCloseButton
                onClick={e => {
                    e.stopPropagation();
                    setLocationToRemove(location.id);
                }}>
                <CloseIcon />
            </LocationInputCloseButton>
        );
    };

    return (
        <LocationInputContainer isFocused={isInputFocused}>
            <LocationInputComponent
                value={localInputValue}
                onChange={e => setLocalInputValue(e.target.value)}
                onFocus={() => toggleInputFocused(true)}
                onBlur={() => toggleInputFocused(false)}
                disabled={showDeleteButton}
            />

            {renderActionButton()}
        </LocationInputContainer>
    );
};

const AddNewAddressInput = ({ addNewLocation, toggleNewAddressInput }) => {
    const [newAddress, setNewAddresss] = useState("");

    return (
        <LocationInputContainer isFocused>
            <LocationInputComponent
                value={newAddress}
                onChange={e => setNewAddresss(e.target.value)}
                placeholder="Add the full address"
            />

            {newAddress.trim() !== "" && (
                <LocationInputSaveButton
                    onClick={() => {
                        addNewLocation(newAddress);
                        toggleNewAddressInput(false);
                    }}>
                    Save
                </LocationInputSaveButton>
            )}
        </LocationInputContainer>
    );
};

const ManageLocations = ({
    onClose,
    onLocationDelete,
    onLocationEdit,
    addNewLocation,
    locations,
}) => {
    const [showNewAddressInput, toggleNewAddressInput] = useState(false);
    const [locationToRemove, setLocationToRemove] = useState(null);

    return (
        <Modal visible onClose={onClose}>
            <ManageLocationPopup
                onClick={e => {
                    e.stopPropagation();
                    setLocationToRemove(null);
                }}>
                <ManageLocationCloseButton onClick={onClose}>
                    <CloseIcon />
                </ManageLocationCloseButton>

                <ManageLocationTitle>Manage Locations</ManageLocationTitle>

                {locations.map(location => (
                    <LocationInput
                        key={location.id}
                        location={location}
                        onLocationDelete={() => onLocationDelete(location.id)}
                        onLocationEdit={onLocationEdit}
                        locationToRemove={locationToRemove}
                        setLocationToRemove={setLocationToRemove}
                    />
                ))}

                {showNewAddressInput && (
                    <AddNewAddressInput
                        addNewLocation={addNewLocation}
                        toggleNewAddressInput={toggleNewAddressInput}
                    />
                )}

                <AddNewAddressButton
                    onClick={() => toggleNewAddressInput(true)}
                    disabled={showNewAddressInput}>
                    <PlusIconContainer>
                        <PlusIcon disabled={showNewAddressInput} />
                    </PlusIconContainer>
                    Add a new location
                </AddNewAddressButton>
            </ManageLocationPopup>
        </Modal>
    );
};

export default ManageLocations;
