import React from "react";

import ProfileHeader from "./ProfileHeader";
import ProfileContent from "./ProfileContent";
import { ProfileContainer } from "./styled";

const ApplicantProfile = () => {
    return (
        <ProfileContainer>
            <ProfileHeader />

            <ProfileContent />
        </ProfileContainer>
    );
};

export default ApplicantProfile;
