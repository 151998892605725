import React, { useEffect } from "react";

import { RejectIcon } from "@assets/images/icons";
import { InfoButton, SendButton } from "./Template";
import { IntroTemplateContainer } from "./styled";

const RejectTemplate = ({ closeTemplate, sendTemplate, onTemplateSendRef }) => {
    useEffect(() => {
        onTemplateSendRef.current = sendTemplate;
    }, []);

    return (
        <IntroTemplateContainer>
            <InfoButton label="Reject" Icon={<RejectIcon />} onPress={closeTemplate} />

            <SendButton label="Send reject message" onPress={sendTemplate} />
        </IntroTemplateContainer>
    );
};

export default RejectTemplate;
