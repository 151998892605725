import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, TemplateBubbleMessage } from "./styled";
import { CancelledIcon } from "../../../assets/images/icons";

const TrialCancelTemplate = ({ message }) => {
    return (
        <TemplateBubbleContainer darkText>
            <ActionIconText darkText title="Cancelled trial" Icon={<CancelledIcon />} />

            {Boolean(message) && (
                <TemplateBubbleMessage darkText>{message}</TemplateBubbleMessage>
            )}
        </TemplateBubbleContainer>
    );
};

export default TrialCancelTemplate;
