import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import ContentEditable from "react-contenteditable";

import { StyleGuide } from "@constants/StyleGuide";

export const ChatComponentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-flow: column;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    background-color: ${StyleGuide.palette.baseSalt};
`;

interface TemplatesListContainerProps {
    isModalChat?: boolean;
}

const TemplatesListModal = `
    padding-top: ${StyleGuide.spacing.mini}px;
    border-left: 1px solid ${StyleGuide.palette.baseSugar};
    margin-left: 0px;
    border-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
`;

export const TemplatesListContainer = styled.div<TemplatesListContainerProps>`
    box-sizing: border-box;
    width: 280px;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    margin-left: ${StyleGuide.spacing.small}px;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    background-color: ${StyleGuide.palette.baseSalt};
    ${props => (props.isModalChat ? TemplatesListModal : "")}
`;

export const ChatContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    padding-left: ${StyleGuide.spacing.small}px;
`;

export const ChatHeaderContainer = styled.div`
    padding: ${StyleGuide.spacing.small}px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const EmptyChat = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    padding-left: ${StyleGuide.spacing.small}px;
`;
export const EmptyText = styled.div`
    ${() => StyleGuide.typography.headline1};
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${StyleGuide.palette.coal};
`;

export const HeaderUserInfo = styled.div`
    padding: 2px 0;
    display: flex;
    align-items: center;
`;

export const HeaderUserProfilePic = styled.img`
    object-fit: cover;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-right: ${StyleGuide.spacing.mini}px;
`;

export const HeaderUserName = styled.div`
    ${() => StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
`;

export const HeaderUserJob = styled.div`
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
    text-align: left;
`;

export const OpenProfileButton = styled.div`
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    border-radius: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.baseVanilla};
    text-align: center;

    :hover {
        background-color: ${StyleGuide.palette.baseSugar};
        color: ${StyleGuide.palette.basePepper};
    }
`;

interface ChatInputToolbarProps {
    hideSendButton?: boolean;
}

export const ChatInputToolbar = styled.div<ChatInputToolbarProps>`
    margin-top: ${StyleGuide.spacing.mini}px;
    padding: ${StyleGuide.spacing.mini}px
        ${props =>
            props.hideSendButton ? StyleGuide.spacing.small : StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.small}px;
`;

export const ChatInputContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-end;
    min-height: ${StyleGuide.spacing.base}px;
`;

export const ChatInputComponent = styled(TextareaAutosize)`
    width: 100%;
    min-height: ${StyleGuide.spacing.base}px;

    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;

    background: ${StyleGuide.palette.baseMsg};
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${StyleGuide.palette.baseVanilla};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${StyleGuide.palette.baseVanilla};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${StyleGuide.palette.baseVanilla};
    }

    border-radius: 18px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;

    ::-webkit-scrollbar {
        width: 14px;
        background: ${StyleGuide.palette.baseMsg};
        border-bottom-right-radius: 18px;
        border-top-right-radius: 18px;
    }
    ::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: ${StyleGuide.spacing.mini}px;
        background-color: ${StyleGuide.palette.baseSeaSalt};
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    /* Mozilla Firefox support */
    scrollbar-width: thin;
    scrollbar-color: ${StyleGuide.palette.baseSeaSalt} ${StyleGuide.palette.baseMsg};
`;

export const ChatInputTemplateComponent = styled(ContentEditable)`
    width: 100%;
    min-height: ${StyleGuide.spacing.base}px;
    max-height: 280px;

    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;

    background: ${StyleGuide.palette.baseMsg};
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
    overflow-wrap: anywhere;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${StyleGuide.palette.baseVanilla};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${StyleGuide.palette.baseVanilla};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${StyleGuide.palette.baseVanilla};
    }

    border-radius: 18px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;

    ::-webkit-scrollbar {
        width: 14px;
        background: ${StyleGuide.palette.baseMsg};
        border-bottom-right-radius: 18px;
        border-top-right-radius: 18px;
    }
    ::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: ${StyleGuide.spacing.mini}px;
        background-color: ${StyleGuide.palette.baseSeaSalt};
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    /* Mozilla Firefox support */
    scrollbar-width: thin;
    scrollbar-color: ${StyleGuide.palette.baseSeaSalt} ${StyleGuide.palette.baseMsg};
`;

export const InputPlaceholder = styled.div`
    position: absolute;
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.baseVanilla};
    pointer-events: none;
    margin-bottom: ${StyleGuide.spacing.mini}px;
    margin-left: ${StyleGuide.spacing.xsmall}px;
`;

interface SendButtonProps {
    disabled?: boolean;
}

export const SendButton = styled.div<SendButtonProps>`
    width: ${StyleGuide.spacing.large}px;
    height: ${StyleGuide.spacing.large}px;
    margin-left: ${StyleGuide.spacing.mini}px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    svg:nth-child(1) {
        fill: ${props =>
            props.disabled
                ? StyleGuide.palette.baseSeaSalt
                : StyleGuide.palette.secondaryCucumber};
    }
`;

export const PlaceholderFixedContainer = styled.div`
    display: flex;
    position: absolute;
    height: 26px;
    top: -40px;
`;

export const SendPlaceholderContainer = styled.div`
    display: flex;
    padding: ${StyleGuide.spacing.micro}px 0 ${StyleGuide.spacing.micro}px
        ${StyleGuide.spacing.mini}px;
    background: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1);
    border-radius: ${StyleGuide.spacing.mini}px;
    ${() => StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.baseVanilla};
`;

export const PlaceHolderFocusedText = styled.div`
    padding: 0 ${StyleGuide.spacing.micro}px;
    margin: 0 ${StyleGuide.spacing.micro}px;
    background-color: ${StyleGuide.palette.baseSeaSalt};
    border-radius: ${StyleGuide.spacing.micro}px;
    color: ${StyleGuide.palette.basePepper};
`;
