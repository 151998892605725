import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { StyleGuide } from "@constants/StyleGuide";
import { Loader } from "../../../routes/Auth/components/Buttons/styled";
import type { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../store/store";
import { setActivityRestaurant } from "../../../features/restaurants/reducer";
import { updateUserLastSeenActivityCenter } from "../../../features/activityCenter/api";
import { divideActivityEventsByTime } from "./activity";
import Modal from "@components/Modal";
import RestaurantDropdown from "@components/Dropdowns/RestaurantDropdown";
import { ActivityEventType } from "./ActivityEvent";
import ActivityList from "./ActivityList";
import {
    ModalOverlay,
    ModalContainer,
    Container,
    ActivityHeader,
    CloseButton,
    TabsContainer,
    TabBubble,
    RestaurantPickerContainer,
} from "./styled";

const ActivityCenterModal = ({ visible, onClose }) => {
    const [selectedTab, setSelectedTab] = useState(ActivityEventType.User);

    const dispatch = useAppDispatch();

    const selectedActivityRestaurant = useSelector<RootState, string | null>(
        state => state.restaurants.selectedActivityRestaurant,
    );

    const uid = useSelector<RootState, string>(state => state.user.uid);

    const userRefreshing = useSelector<RootState, boolean>(
        state => state.activityCenter.userRefreshing,
    );
    const teamRefreshing = useSelector<RootState, boolean>(
        state => state.activityCenter.teamRefreshing,
    );

    const allUserActivity = useSelector<RootState, Contracts.DBUserFunnelActivityEvent[]>(
        state => state.activityCenter.user,
    );
    const allTeamActivity = useSelector<RootState, Contracts.TeamActivityEvent[]>(
        state => state.activityCenter.team,
    );

    const userActivity = useMemo(() => divideActivityEventsByTime(allUserActivity), [
        allUserActivity,
    ]);

    const teamActivity = useMemo(() => divideActivityEventsByTime(allTeamActivity), [
        allTeamActivity,
    ]);

    // Updates the time when the user has last seen the activity center
    useEffect(() => {
        updateUserLastSeenActivityCenter(uid, new Date());
    }, [uid, allUserActivity, allTeamActivity]);

    const renderActionsList = () => {
        const dataSource = {
            list: selectedTab === ActivityEventType.User ? userActivity : teamActivity,
            isRefreshing:
                selectedTab === ActivityEventType.User ? userRefreshing : teamRefreshing,
        };

        const { thisWeek, thisMonthExceptThisWeek, older } = dataSource.list;

        return (
            <>
                {dataSource.isRefreshing && (
                    <Loader
                        color={StyleGuide.palette.secondaryBasil}
                        size={24}
                        style={{ alignSelf: "center" }}
                    />
                )}

                <ActivityList
                    sectionList={[
                        { label: "This week", list: thisWeek },
                        { label: "This month", list: thisMonthExceptThisWeek },
                        { label: "Older", list: older },
                    ]}
                    selectedTab={selectedTab}
                    onClose={onClose}
                />
            </>
        );
    };

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            overlayStyle={ModalOverlay}
            containerStyle={ModalContainer}
            overlayAnimationProps={{
                key: "activityOverlay",
                initial: { backgroundColor: "rgba(0,0,0,0)" },
                animate: { backgroundColor: "rgba(0,0,0,0.7)" },
                exit: { backgroundColor: "rgba(0,0,0,0)" },
                transition: { bounce: 0 },
            }}
            containerAnimationProps={{
                key: "activityContainer",
                initial: { transform: "translateX(100%)" },
                animate: { transform: "translateX(0%)" },
                exit: { transform: "translateX(100%)" },
                transition: { bounce: 0 },
            }}>
            <Container>
                <ActivityHeader>
                    <TabsContainer>
                        <TabBubble
                            isSelected={selectedTab === ActivityEventType.User}
                            onClick={() => setSelectedTab(ActivityEventType.User)}>
                            Notifications
                        </TabBubble>

                        <TabBubble
                            isSelected={selectedTab === ActivityEventType.Team}
                            onClick={() => setSelectedTab(ActivityEventType.Team)}>
                            Team Activity
                        </TabBubble>
                    </TabsContainer>

                    <CloseButton onClick={onClose}>
                        <CloseIcon />
                    </CloseButton>
                </ActivityHeader>

                <RestaurantPickerContainer>
                    <RestaurantDropdown
                        placeholder={"Select Restaurant"}
                        selected={selectedActivityRestaurant}
                        onRestaurantSelect={restaurantId =>
                            dispatch(setActivityRestaurant(restaurantId))
                        }
                    />
                </RestaurantPickerContainer>

                {renderActionsList()}
            </Container>
        </Modal>
    );
};

export default ActivityCenterModal;
