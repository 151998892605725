import React from "react";
import { StyleGuide } from "../../../constants/StyleGuide";
import ApplicantsList from "../../../components/ApplicantsList/index";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { selectRejectedlist } from "../../../features/funnels/reducer";
import { useRejectedlist } from "./useRejectedlist";
import useRejectedlistCount from "../../../features/counts/useRejectedCount";
import { selectRejectedlistCount } from "../../../features/counts/reducer";
import { setRejectedPagination } from "../../../features/pagination/reducer";
import useRejectedlistWithFilters from "./useRejectedlistWithFilters";
import { hasFiltersSelected } from "../../../features/filters/homeFilterReducer";

export default function RejectedlistContainer() {
    useRejectedlistCount();

    const rejectedlistIds = useAppSelector(selectRejectedlist);
    const count = useAppSelector(selectRejectedlistCount);
    const isFiltersUsed = useAppSelector(hasFiltersSelected);

    if (isFiltersUsed) {
        return <ElasticList count={count} rejectedlistIds={rejectedlistIds} />;
    }
    return <FirebaseList count={count} rejectedlistIds={rejectedlistIds} />;
}

function FirebaseList({ rejectedlistIds, count }) {
    useRejectedlist();

    const dispatch = useAppDispatch();

    const limit = useAppSelector(state => state.pagination.rejectedPagination.limit);
    const isLoading = useAppSelector(
        state => state.pagination.rejectedPagination.isLoading,
    );
    const isEndReached = useAppSelector(
        state => state.pagination.rejectedPagination.isEndReached,
    );

    const handleLoadMore = () => {
        if (isLoading || isEndReached) return;
        dispatch(setRejectedPagination({ limit: limit + 10 }));
    };
    return (
        <ApplicantsList
            key="rejectedList"
            title="Rejected"
            applicantIds={rejectedlistIds}
            isOpenedByDefault={true}
            count={count}
            handleLoadMore={handleLoadMore}
            colorTheme={StyleGuide.palette.coal}
        />
    );
}

function ElasticList({ count, rejectedlistIds }) {
    useRejectedlistWithFilters();
    const dispatch = useAppDispatch();
    const pagination = useAppSelector(state => state.pagination.contactedPagination);
    const noMoreDataToLoad = pagination?.totalPages <= pagination.page;

    const handleLoadMore = () => {
        if (noMoreDataToLoad) return;
        dispatch(setRejectedPagination({ page: pagination.page + 1 }));
    };
    return (
        <ApplicantsList
            key="rejectedListElastic"
            title="Rejected"
            applicantIds={rejectedlistIds}
            isOpenedByDefault={false}
            count={count}
            handleLoadMore={handleLoadMore}
            colorTheme={StyleGuide.palette.coal}
        />
    );
}
