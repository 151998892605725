import React, { useState, useEffect, useRef } from "react";
import isEqual from "lodash.isequal";

import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { useAppSelector } from "../../../store/store";
import {
    selectSortedRestaurantOptions,
    selectSelectedRestaurantIds,
} from "../../../features/restaurants/reducer";
import {
    Container,
    PickerSelector,
    ArrowIcon,
    DropdownContainer,
    DropdownItem,
} from "./styled";

const RestaurantDropdown = ({ placeholder, selected, onRestaurantSelect }) => {
    const [isDropdownExpanded, toggleDropdownExpanded] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    // isEqual as custom comparison func to prevent unnecessary re-renders
    // see https://react-redux.js.org/api/hooks#equality-comparisons-and-updates.
    const restaurants = useAppSelector(selectSortedRestaurantOptions, isEqual);
    const selectedRestaurants = useAppSelector(selectSelectedRestaurantIds, isEqual);

    const sortedRestaurants = React.useMemo(() => {
        return [...restaurants].sort((r1, r2) => {
            const r1Selected = selectedRestaurants.findIndex(sId => sId === r1.id) !== -1;
            const r2Selected = selectedRestaurants.findIndex(sId => sId === r2.id) !== -1;

            return r1Selected && r2Selected ? 0 : r1Selected ? -1 : 1;
        });
    }, [restaurants, selectedRestaurants]);

    const closeDropDown = () => {
        toggleDropdownExpanded(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closeDropDown();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const label =
        sortedRestaurants.find(({ id }) => id === selected)?.value || placeholder;

    return (
        <Container ref={wrapperRef}>
            <PickerSelector onClick={() => toggleDropdownExpanded(!isDropdownExpanded)}>
                {label}
                <ArrowIcon isExpanded={isDropdownExpanded}>
                    <ArrowUp />
                </ArrowIcon>
            </PickerSelector>

            {isDropdownExpanded && (
                <DropdownContainer>
                    {sortedRestaurants.map(restaurant => (
                        <DropdownItem
                            key={restaurant.id}
                            isSelected={restaurant.id === selected}
                            onClick={() => {
                                onRestaurantSelect(restaurant.id);
                                closeDropDown();
                            }}>
                            {restaurant.value}
                        </DropdownItem>
                    ))}
                </DropdownContainer>
            )}
        </Container>
    );
};

export default RestaurantDropdown;
