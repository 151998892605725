import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/store";
import API from "../../api/client";
import * as RestaurantsAPI from "../restaurants/api";

const TAG = "planday/integration";

async function getDepartments(integrationId: string): Promise<Contracts.Department[]> {
    try {
        const response = await API.getPlandayDepartments({ integrationId });

        if (!response.isSuccess) {
            throw new Error(`Failed to load departments from: ${integrationId}`);
        }
        return response.result;
    } catch (error) {
        console.error(`${TAG}: Failed to get departments`, error);
        // Logger.notify(`${TAG}: Failed to get departments`);
        return [];
    }
}

export function usePlandayDepartments() {
    const funnel = useAppSelector(state =>
        state.funnels.focusedProfile
            ? state.funnels.funnelMap[state.funnels.focusedProfile]
            : null,
    );

    const [isLoading, setIsLoading] = useState(true);
    const [departments, setDepartments] = useState<Contracts.Department[]>([]);

    useEffect(() => {
        if (!funnel?.restaurantId) {
            console.error("restaurantId is not defined in the funnel");
            return;
        }
        const fetchDepartmentData = async (restaurantId: string) => {
            const plandayDoc = await RestaurantsAPI.getPlandayIntegrationOnce(
                restaurantId,
            );

            const { integrationId } = plandayDoc.data() as PlandayIntegration;

            const data = await getDepartments(integrationId);

            setDepartments(data);
            setIsLoading(false);
        };
        fetchDepartmentData(funnel.restaurantId);
        return () => {};
    }, [funnel?.restaurantId]);
    return { departments, isLoading };
}
