import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectRestaurantIdsToQuery } from "../restaurants/reducer";
import { setHomeAllCount } from "./reducer";
import { NotificationType } from "@anna/shared";

export default function useHomeAllCount() {
    const newApplicants = useAppSelector(
        state => state.notifications.notificationByType[NotificationType.NEW_APPLICATION],
    );
    const restaurantIds = useAppSelector(selectRestaurantIdsToQuery);

    const rIds = useMemo(() => restaurantIds.filter((_, i) => i < 9), [restaurantIds]);

    const count = useMemo(
        () => newApplicants?.filter(n => restaurantIds.includes(n?.restaurantId))?.length,
        [newApplicants],
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        try {
            dispatch(setHomeAllCount(count));
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [rIds, count]);
}
