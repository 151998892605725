import React from "react";
import ApplicantsList from "../../../components/ApplicantsList/index";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { selectContactedlist } from "../../../features/funnels/reducer";
import { useContactedlist } from "./useContactedlist";
import useContactedlistCount from "../../../features/counts/useContactedlistCount";
import { selectContactedlistCount } from "../../../features/counts/reducer";
import { setContactedPagination } from "../../../features/pagination/reducer";
import { hasFiltersSelected } from "../../../features/filters/homeFilterReducer";
import useContactedlistWithFilters from "./useContactedlistWithFilters";

export default function ContactedlistContainer() {
    useContactedlistCount();

    const contactedlistIds = useAppSelector(selectContactedlist);
    const count = useAppSelector(selectContactedlistCount);
    const isFiltersUsed = useAppSelector(hasFiltersSelected);

    if (isFiltersUsed) {
        return <ElasticList count={count} contactedlistIds={contactedlistIds} />;
    }
    return <FirebaseList count={count} contactedlistIds={contactedlistIds} />;
}

function FirebaseList({ contactedlistIds, count }) {
    useContactedlist();

    const dispatch = useAppDispatch();

    const limit = useAppSelector(state => state.pagination.contactedPagination.limit);
    const isLoading = useAppSelector(
        state => state.pagination.contactedPagination.isLoading,
    );
    const isEndReached = useAppSelector(
        state => state.pagination.contactedPagination.isEndReached,
    );

    const handleLoadMore = () => {
        if (isLoading || isEndReached) return;
        dispatch(setContactedPagination({ limit: limit + 10 }));
    };
    return (
        <ApplicantsList
            key="contactedList"
            title="Contacted"
            applicantIds={contactedlistIds}
            isOpenedByDefault
            handleLoadMore={handleLoadMore}
            count={count}
            colorTheme={"#4184D3"}
        />
    );
}

function ElasticList({ count, contactedlistIds }) {
    useContactedlistWithFilters();
    const dispatch = useAppDispatch();
    const pagination = useAppSelector(state => state.pagination.contactedPagination);
    const noMoreDataToLoad = pagination?.totalPages <= pagination.page;

    const handleLoadMore = () => {
        if (noMoreDataToLoad) return;
        dispatch(setContactedPagination({ page: pagination.page + 1 }));
    };
    return (
        <ApplicantsList
            key="contactedListElastic"
            title="Contacted"
            applicantIds={contactedlistIds}
            isOpenedByDefault
            handleLoadMore={handleLoadMore}
            count={count}
            colorTheme={"#4184D3"}
        />
    );
}
