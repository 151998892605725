import React, { useState, useEffect, useRef } from "react";

import { timeSlotsDateFormat, mediumLocalDateFormat } from "@utils/dates";
import { TemplateVariables, TemplateLinks } from "@anna/shared";
import { InterviewIcon } from "@assets/images/icons";
import TimeSlotsPreview, {
    popUpHeight,
    popUpBottomMargin,
} from "@components/TimeSlotsPreview";
import { InfoButton, SendButton } from "../Template";
import { ResetTemplateAddon } from "./Popovers";
import LocationPicker from "./LocationPicker";
import DatePicker from "./DatePicker";
import TimeSlotsPicker from "./TimeSlotsPicker";
import { Container, TimeSlotsShadow, TimeSlotVariableStyle } from "./styled";
import { useInterviewLocations } from "../../../../features/locations/useInterviewLocations";
import { useAppSelector } from "../../../../store/store";
import { editInterviewLocation } from "../../../../features/locations/api";
import {
    addInterviewLocation,
    removeInterviewLocation,
} from "../../../../features/locations/api";
import {
    selectInterviewLocations,
    selectDefaultInterviewLocation,
} from "../../../../features/locations/reducer";

const InterviewTemplate = ({
    closeTemplate,
    sendTemplate,
    customTimes = {},
    onTemplateSendRef,
    setTemplateVariables,
    templateText,
    resetTemplate,
}) => {
    useInterviewLocations();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCustomTimes, setSelectedCustomTimes] = useState(customTimes || {});
    const [showTimeSlotsDisabledAlert, toggleTimeSlotsDisabledAlert] = useState(false);
    const [showPreviewPopup, togglePreviewPopup] = useState(false);
    const [popupCoordinates, setPopupCoordinates] = useState({ top: 0, left: 0 });
    const userId = useAppSelector(state => state.user.uid);
    const isLoadingInterviewLocations = useAppSelector(
        state => state.locations.isLoadingInterviewLocations,
    );
    const interviewLocations = useAppSelector(selectInterviewLocations);
    const defaultInterviewLocation = useAppSelector(selectDefaultInterviewLocation);
    const [selectedLocation, setSelectedLocation] = useState(defaultInterviewLocation);
    const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

    const isTimeSlotsEmpty = Object.keys(selectedCustomTimes).length === 0;
    const isLocationEmpty = !Boolean(selectedLocation?.address);
    const isTimeSlotsLinkBroken = !templateText.includes(TemplateVariables.TIME_SLOTS);
    const isLocationBroken = !templateText.includes(TemplateVariables.LOCATION);
    const isSubmitDisabled =
        isTimeSlotsEmpty || isTimeSlotsLinkBroken || isLocationEmpty || isLocationBroken;

    useEffect(() => {
        onTemplateSendRef.current = onSubmit;
    }, []);

    const openTimeSlotsDisabledAlert = () => {
        if (timer?.current) {
            clearTimeout(timer?.current);
        }
        toggleTimeSlotsDisabledAlert(true);

        timer.current = setInterval(() => {
            toggleTimeSlotsDisabledAlert(false);
        }, 3000);
    };

    const getTotalTimeSlots = (customTimes: Contracts.CustomTimeSlotsDTO) => {
        return Object.values(customTimes).reduce((acc, dayValue) => {
            return acc + Object.keys(dayValue).length;
        }, 0);
    };

    const getSubmitLabel = () => {
        if (isTimeSlotsEmpty || isTimeSlotsLinkBroken) {
            return "Pick at least one time slot";
        }

        if (isLocationEmpty || isLocationBroken) {
            return "Pick at least one location";
        }

        return "Send interview invite";
    };

    const onAddLocation = async (title: string) => {
        await addInterviewLocation(userId, title);
    };
    const onUpdateLocation = async (locationId: string, title: string) => {
        await editInterviewLocation(userId, locationId, title);
    };
    const onRemoveLocation = async (locationId: string) => {
        await removeInterviewLocation(userId, locationId);
    };

    const onSubmit = () => {
        if (isTimeSlotsLinkBroken || isLocationBroken) {
            return;
        }
        if (isTimeSlotsEmpty) {
            return openTimeSlotsDisabledAlert();
        }

        if (isLocationEmpty) {
            //TODO: missing location alert
            return;
        }

        sendTemplate({
            locationId: selectedLocation?.id, //selectedLocation?.id || defaultLocation?.id || "",
            customTimeSlots: selectedCustomTimes as Contracts.CustomTimeSlotsDTO,
        });
    };

    useEffect(() => {
        onTemplateSendRef.current = onSubmit;
    }, [onSubmit]);

    useEffect(() => {
        setTemplateVariables([
            {
                variable: TemplateVariables.TIME_SLOTS,
                customStyle: isTimeSlotsEmpty ? "" : TimeSlotVariableStyle,

                text: isTimeSlotsEmpty
                    ? "[You need to pick time slots]"
                    : TemplateLinks.INVITATION_LINK,
                onPress: (clickedElement?: DOMRect) => {
                    if (!clickedElement?.top || !clickedElement?.left) return;
                    togglePreviewPopup(true);
                    setPopupCoordinates({
                        top: clickedElement?.top,
                        left: clickedElement?.left,
                    });
                    // TODO: add modal here for timeslots
                },
            },
            {
                variable: TemplateVariables.LOCATION,
                text: isLocationEmpty
                    ? "[You need to pick a location]"
                    : selectedLocation?.address || "",
            },
        ]);
    }, [isTimeSlotsEmpty, selectedLocation]);

    return (
        <Container>
            <InfoButton
                label="Interview"
                Icon={<InterviewIcon />}
                onPress={closeTemplate}
            />

            {isLoadingInterviewLocations ? (
                "Loading locations..."
            ) : (
                <LocationPicker
                    locations={interviewLocations}
                    defaultLocation={defaultInterviewLocation}
                    selectedLocation={selectedLocation}
                    isLocationBroken={isLocationBroken}
                    resetTemplate={resetTemplate}
                    setSelectedLocation={setSelectedLocation}
                    addLocation={onAddLocation}
                    updateLocation={onUpdateLocation}
                    removeLocation={onRemoveLocation}
                />
            )}

            <DatePicker
                selectedDate={selectedDate}
                label={mediumLocalDateFormat(selectedDate)}
                setSelectedDate={setSelectedDate}
                showWarning={showTimeSlotsDisabledAlert}
                selectedCustomTimes={selectedCustomTimes}
                totalTimeslots={getTotalTimeSlots(selectedCustomTimes)}
                isTimeSlotsLinkBroken={isTimeSlotsLinkBroken}
                forInterview
            />

            {Boolean(selectedDate) && (
                <TimeSlotsPicker
                    selectedDate={timeSlotsDateFormat(selectedDate)}
                    selectedCustomTimes={selectedCustomTimes}
                    setSelectedCustomTimes={setSelectedCustomTimes}
                    isTimeSlotsLinkBroken={isTimeSlotsLinkBroken}
                />
            )}

            {isTimeSlotsLinkBroken ? (
                <ResetTemplateAddon
                    title="Time slot link is disabled"
                    description="Because you changed the time slot link. To use this template, you need the time slot addon."
                    resetTemplate={resetTemplate}
                />
            ) : (
                <>
                    <TimeSlotsShadow />

                    <SendButton
                        label={getSubmitLabel()}
                        onPress={onSubmit}
                        isDisabled={isSubmitDisabled}
                    />
                </>
            )}

            {showPreviewPopup && (
                <TimeSlotsPreview
                    style={{
                        top: popupCoordinates.top - popUpHeight - popUpBottomMargin,
                        left: popupCoordinates.left,
                    }}
                    onClose={() => togglePreviewPopup(false)}
                    customTimes={selectedCustomTimes}
                    forInterview
                />
            )}
        </Container>
    );
};

export default InterviewTemplate;
