import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { motion, AnimateSharedLayout } from "framer-motion";
import { StyleGuide } from "@constants/StyleGuide";

import { facebookLogin } from "../../../firebase/auth";
import AuthScreenContainer from "../components/AuthScreenContainer";
import Input from "../components/Input";
import FacebookButton from "../components/Buttons/FacebookButton";
import SubmitButton from "../components/Buttons/SubmitButton";
import EmailForm from "./EmailForm";
import PhoneForm from "./PhoneForm";
import {
    Link,
    OrDividerContainer,
    OrDivider,
    OrText,
    TabsRow,
    Tab,
    TabUnderline,
    TabContent,
    TabPagesContainer,
    TabPage,
} from "./styled";

const TABS = ["Email & Password", "Phone number"];

const TabItem = ({ active, title, disabled, onClick }) => (
    <Tab active={active} onClick={onClick} disabled={disabled}>
        {title}

        {active && (
            <TabUnderline
                layoutId="underline"
                transition={{ type: "tween", duration: 0.2 }}
            />
        )}
    </Tab>
);

function SignIn() {
    const [activeTab, setActiveTab] = React.useState<string>(TABS[0]);
    const [loading, setLoading] = React.useState(false);

    const responseFacebook = response => {
        facebookLogin(response);
    };

    return (
        <AuthScreenContainer
            title="Welcome back!"
            subtitle={
                <span>
                    Don’t have anna?{" "}
                    <Link
                        href="https://helloapplication.typeform.com/to/TOQt2HQl"
                        target="_blank">
                        Help me with hiring
                    </Link>
                </span>
            }>
            <TabsRow>
                <AnimateSharedLayout>
                    {TABS.map(key => (
                        <TabItem
                            key={key}
                            title={key}
                            active={activeTab === key}
                            disabled={loading}
                            onClick={() => setActiveTab(key)}
                        />
                    ))}
                </AnimateSharedLayout>
            </TabsRow>

            <TabContent>
                <TabPagesContainer
                    activeTabIndex={TABS.findIndex(el => el === activeTab)}>
                    <TabPage key="emailForm" visible={activeTab === TABS[0]}>
                        <EmailForm loading={loading} setLoading={setLoading} />
                    </TabPage>

                    <TabPage key="phoneForm" visible={activeTab === TABS[1]}>
                        <PhoneForm loading={loading} setLoading={setLoading} />
                    </TabPage>
                </TabPagesContainer>
            </TabContent>

            <OrDividerContainer>
                <OrDivider />
                <OrText>or</OrText>
                <OrDivider />
            </OrDividerContainer>

            <FacebookLogin
                appId="376247359633123"
                fields="email"
                autoLoad={false}
                callback={responseFacebook}
                render={({ onClick }) => <FacebookButton onClick={onClick} />}
            />
        </AuthScreenContainer>
    );
}

export default SignIn;
