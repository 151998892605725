import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

const CONTENT_WIDTH = 400;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: ${StyleGuide.palette.baseMsg};
    align-items: center;
    justify-content: center;
`;

export const Header = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: ${StyleGuide.spacing.medium}px;
    text-align: start;
`;

export const HeaderLogo = styled.img`
    height: 24px;
    object-fit: contain;
    vertical-align: bottom;
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: ${StyleGuide.spacing.medium}px;
    text-align: start;
`;

export const Content = styled.div`
    box-sizing: border-box;
    max-width: ${CONTENT_WIDTH}px;
    width: 100%;
    background-color: ${StyleGuide.palette.baseSalt};
    padding: ${StyleGuide.spacing.large}px ${StyleGuide.spacing.medium}px
        ${StyleGuide.spacing.medium}px;
    border: 1px solid ${StyleGuide.palette.baseSeaSalt};
    border-radius: 12px;
`;

export const ContentTitle = styled.div`
    ${StyleGuide.typography.largeTitle1};
    margin-bottom: ${StyleGuide.spacing.mini}px;
`;

export const ContentSubtitle = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
    margin-bottom: ${StyleGuide.spacing.medium}px;
`;

export const IntroLogoComp = styled.img`
    position: absolute;
    left: calc((100vw - 400px) / 6);
    width: calc((100vw - 400px) / 6);
    object-fit: contain;
    bottom: 104px;

    @media only screen and (max-width: 768px) {
        display: none;
    }
`;
