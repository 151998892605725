import React, { useState, useEffect, useRef } from "react";

import { Container, ListContainer, OptionItem } from "./styled";

const Option = ({ renderOptionContent, onSelect, option, isSelected, isLast }) => {
    return (
        <OptionItem
            onClick={e => onSelect(e, option)}
            isSelected={isSelected}
            isLast={isLast}>
            {renderOptionContent ? renderOptionContent(option) : option.value}
        </OptionItem>
    );
};

type MenuDropdownOption = {
    id: string | null;
    value: string;
};

interface MenuDropdownProps {
    options: MenuDropdownOption[];
    selected: MenuDropdownOption["id"][];
    onSelect: (event: React.SyntheticEvent, option: MenuDropdownOption) => void;
    renderOptionContent?: (option: MenuDropdownOption) => any;
    renderToggleBlock?: (opened: boolean) => any;
}

const MenuDropdown = ({
    options,
    selected,
    onSelect,
    renderOptionContent,
    renderToggleBlock,
}: MenuDropdownProps) => {
    const [opened, setOpened] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpened(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    // handle left overflow on smaller screens
    React.useLayoutEffect(() => {
        if (opened && containerRef.current) {
            const position = containerRef.current.getBoundingClientRect();
            if (position && position.x < 24) {
                containerRef.current.style.left = "0px";
            }
        }
    }, [opened, options, selected]);

    return (
        <Container onClick={() => setOpened(!opened)} ref={wrapperRef}>
            {renderToggleBlock && renderToggleBlock(opened)}

            {opened && (
                <ListContainer ref={containerRef}>
                    {options.map((option, index) => (
                        <Option
                            key={option.id}
                            isSelected={selected.some(item => item === option.id)}
                            option={option}
                            onSelect={onSelect}
                            isLast={index === options.length - 1}
                            renderOptionContent={renderOptionContent}
                        />
                    ))}
                </ListContainer>
            )}
        </Container>
    );
};

export default MenuDropdown;
