import React from "react";

import CategoryPicker from "./CategoryPicker";
import FilterPicker from "./FilterPicker";
import { Container } from "./styled";

const Filters = () => {
    return (
        <Container>
            <CategoryPicker />

            <FilterPicker />
        </Container>
    );
};

export default Filters;
