import React from "react";
import { useSelector } from "react-redux";
import chunk from "lodash.chunk";

import {
    generateTimeSlots,
    convertMinutesToHoursAndMinutes,
    omitDatesAndTime,
    shortTimeFormat,
} from "@utils/dates";
import { getCalendarBookings } from "../../../../features/bookings/reducer";
import {
    TimeSlotsContainer,
    TimeSlotsRow,
    TimeSlotContainer,
    EmptyTimeSlot,
    TimeSlotSeparator,
} from "./styled";

const startTimeInMinutes = 480; // 08:00
const endTimeInMinutes = 1290; // 21:00
const interval = 30; // 30 minutes
const timeslots = generateTimeSlots(startTimeInMinutes, endTimeInMinutes, interval);
const timeslotsChunks = chunk(timeslots, 2);

const TimeSlot = ({ time, isTimeSlotSelected, onSelect, isBroken, isTimeSlotBooked }) => {
    const timeLabel = convertMinutesToHoursAndMinutes(time);
    const isDisabled = isTimeSlotBooked(timeLabel);

    if (typeof time !== "number") {
        return <EmptyTimeSlot />;
    }
    return (
        <TimeSlotContainer
            isSelected={isTimeSlotSelected(time)}
            isDisabled={isDisabled}
            isBroken={isBroken}
            onClick={() => {
                if (isDisabled || isBroken) {
                    return;
                }
                onSelect(time);
            }}>
            {timeLabel}
        </TimeSlotContainer>
    );
};

const TimeSlotsPicker = ({
    selectedDate,
    selectedCustomTimes,
    setSelectedCustomTimes,
    isTimeSlotsLinkBroken = false,
}) => {
    const selectedTimes = !!selectedDate ? selectedCustomTimes[selectedDate] : undefined;

    const bookings = useSelector(getCalendarBookings);

    const isTimeSlotSelected = time => {
        return !!(selectedTimes && +selectedTimes[time] === time);
    };

    const isTimeSlotBooked = time => {
        if (selectedDate && bookings && bookings[selectedDate]) {
            //check if any of the booked dates, include this specific time
            return bookings[selectedDate].some(
                ({ startTime }) => shortTimeFormat(startTime) === time,
            );
        }
        return false;
    };

    const onSelect = time => {
        const day = selectedDate;
        if (!day) {
            return;
        }
        if (selectedCustomTimes[day] && selectedCustomTimes[day].hasOwnProperty(time)) {
            const cleanedSelectedTimes = omitDatesAndTime(selectedCustomTimes, day, time);
            setSelectedCustomTimes(cleanedSelectedTimes);
        } else {
            setSelectedCustomTimes({
                ...selectedCustomTimes,
                [day]: {
                    ...selectedCustomTimes[day],
                    [time]: time,
                },
            });
        }
    };

    return (
        <TimeSlotsContainer>
            {timeslotsChunks.map((time, index) => (
                <TimeSlotsRow key={index}>
                    <TimeSlot
                        time={time[0]}
                        onSelect={onSelect}
                        isTimeSlotSelected={isTimeSlotSelected}
                        isBroken={isTimeSlotsLinkBroken}
                        isTimeSlotBooked={isTimeSlotBooked}
                    />

                    <TimeSlotSeparator />

                    <TimeSlot
                        time={time[1]}
                        onSelect={onSelect}
                        isTimeSlotSelected={isTimeSlotSelected}
                        isBroken={isTimeSlotsLinkBroken}
                        isTimeSlotBooked={isTimeSlotBooked}
                    />
                </TimeSlotsRow>
            ))}
        </TimeSlotsContainer>
    );
};

export default TimeSlotsPicker;
