import { WorkhoursValue } from "../../backend/src/lib/helpers/typeform";
import {
    ApplicationTypes,
    BookingType,
    TypeformWorkHours,
    MappedWorkHours,
    RestaurantCategory,
    TypeformVisaType,
} from "../contracts/fixedTypes";

// TODO: Do we actually use this for any thing?
/**
 * This is used to ensure parelle time booking
 * @param applicationPosition
 */
export function determineTrialType(
    applicationPosition: Contracts.ApplicationTypeValue,
): Contracts.BookingType {
    if (ApplicationTypes.brewery.includes(applicationPosition)) {
        return BookingType.TRIAL_BREWERY;
    }
    if (ApplicationTypes.management.includes(applicationPosition)) {
        return BookingType.TRIAL_MANAGEMENT;
    }
    if (ApplicationTypes.administrative.includes(applicationPosition)) {
        return BookingType.TRIAL_ADMINISTRATIVE;
    }
    if (ApplicationTypes.under18.includes(applicationPosition)) {
        return BookingType.TRIAL_UNDER_18;
    }
    if (ApplicationTypes.boh.includes(applicationPosition)) {
        return BookingType.TRIAL_BOH;
    }
    if (ApplicationTypes.eventCommunication.includes(applicationPosition)) {
        return BookingType.TRIAL_EVENT_COMMUNICATION;
    }
    if (ApplicationTypes.host.includes(applicationPosition)) {
        return BookingType.TRIAL_HOST;
    }
    if (ApplicationTypes.bakery.includes(applicationPosition)) {
        return BookingType.TRIAL_BAKERY;
    }
    if (ApplicationTypes.bread.includes(applicationPosition)) {
        return BookingType.TRIAL_BREAD;
    }
    if (ApplicationTypes.pastry.includes(applicationPosition)) {
        return BookingType.TRIAL_PASTRY;
    }
    if (ApplicationTypes.crewMember.includes(applicationPosition)) {
        return BookingType.TRIAL_CREW_MEMBER;
    }
    if (ApplicationTypes.foh.includes(applicationPosition)) {
        return BookingType.TRIAL_FOH;
    }
    if (ApplicationTypes.internshipBoh.includes(applicationPosition)) {
        return BookingType.TRIAL_INTERNSHIP_BOH;
    }
    if (ApplicationTypes.internshipFoh.includes(applicationPosition)) {
        return BookingType.TRIAL_INTERNSHIP_FOH;
    }
    if (ApplicationTypes.internshipBakery.includes(applicationPosition)) {
        return BookingType.TRIAL_INTERNSHIP_BAKERY;
    }
    if (ApplicationTypes.internshipBread.includes(applicationPosition)) {
        return BookingType.TRIAL_INTERNSHIP_BREAD;
    }
    if (ApplicationTypes.internshipPastry.includes(applicationPosition)) {
        return BookingType.TRIAL_INTERNSHIP_PASTRY;
    }
    if (ApplicationTypes.internshipFoh.includes(applicationPosition)) {
        return BookingType.TRIAL_INTERNSHIP_FOH;
    }
    return BookingType.TRIAL_BOH;
}

export function mapWorkHours(value: TypeformWorkHours | WorkhoursValue) {
    switch (value) {
        case TypeformWorkHours.FULL_TIME:
        case WorkhoursValue.UNDER_40_HOURS:
            return MappedWorkHours.FULL_TIME;
        case TypeformWorkHours.PART_TIME:
        case WorkhoursValue.UNDER_30_HOURS:
        case WorkhoursValue.UNDER_20_HOURS:
        case WorkhoursValue.UNDER_10_HOURS:
            return MappedWorkHours.PART_TIME;
        case TypeformWorkHours.PART_TIME_SU_MIN_10_HOURS:
            return MappedWorkHours.PART_TIME_MIN_10_HOURS;
        case TypeformWorkHours.PART_TIME_12_HOURS:
            return MappedWorkHours.PART_TIME_12_HOURS;
        case TypeformWorkHours.PART_TIME_OR_FULL_TIME:
            return MappedWorkHours.PART_TIME_OR_FULL_TIME;
        case TypeformWorkHours.INTERNSHIP:
            return MappedWorkHours.INTERNSHIP;
        case TypeformWorkHours.U18:
            return MappedWorkHours.U18;

        case TypeformWorkHours.UNDER_10_HOURS:
            return MappedWorkHours.UNDER_10_HOURS;
        case TypeformWorkHours.UNDER_20_HOURS:
            return MappedWorkHours.UNDER_20_HOURS;
        case TypeformWorkHours.UNDER_30_HOURS:
            return MappedWorkHours.UNDER_30_HOURS;
        case TypeformWorkHours.UNDER_40_HOURS:
            return MappedWorkHours.UNDER_40_HOURS;
        default:
            return value;
    }
}

export function mapVisaExpirationDate(
    visaType: TypeformVisaType | undefined,
    source: any,
) {
    switch (visaType) {
        case TypeformVisaType.UNLIMITED_EU:
            return "None";
        case TypeformVisaType.UNRESTRICTED:
        case TypeformVisaType.WORK_HOLIDAY:
        case TypeformVisaType.RESTRICTED_HOURS_15_HOURS:
        case TypeformVisaType.RESTRICTED_HOURS_20_HOURS:
            return source.answers.application_visa_type_holiday?.content || "N/A";
        default:
            return "N/A";
    }
}

export function mapVisaWorkHours(visaType?: TypeformVisaType) {
    switch (visaType) {
        case TypeformVisaType.UNLIMITED_EU:
        case TypeformVisaType.UNRESTRICTED:
        case TypeformVisaType.WORK_HOLIDAY:
            return "37.5h / week";
        case TypeformVisaType.RESTRICTED_HOURS_15_HOURS:
            return "15h / week";
        case TypeformVisaType.RESTRICTED_HOURS_20_HOURS:
            return "20h / week";
        default:
            return "N/A";
    }
}

export function mapVisaSectionType(visaType?: TypeformVisaType) {
    switch (visaType) {
        case TypeformVisaType.UNLIMITED_EU:
        case TypeformVisaType.UNRESTRICTED:
            return "Visa - Unlimited";
        case TypeformVisaType.WORK_HOLIDAY:
        case TypeformVisaType.RESTRICTED_HOURS_15_HOURS:
        case TypeformVisaType.RESTRICTED_HOURS_20_HOURS:
        case TypeformVisaType.NONE:
            return "Restricted";

        default:
            return "Visa";
    }
}

export function mapVisaType(visaType: TypeformVisaType) {
    switch (visaType) {
        case TypeformVisaType.UNLIMITED_EU:
            return "EU Citizen";
        case TypeformVisaType.UNRESTRICTED:
            return "Full Work Permit";
        case TypeformVisaType.WORK_HOLIDAY:
            return "Work Holiday";
        case TypeformVisaType.RESTRICTED_HOURS_15_HOURS:
        case TypeformVisaType.RESTRICTED_HOURS_20_HOURS:
            return "Study abroad";

        default:
            return visaType;
    }
}

interface Document {
    readonly id: string;
    readonly data: () => { [key: string]: any };
}

export function mapDocumentWithId<TContract extends { id: string }>(document: Document) {
    return {
        id: document.id,
        ...document.data(),
    } as TContract;
}

export function restaurantCategoryFormatted(
    restaurantCategory: Contracts.RestaurantCategory,
) {
    switch (restaurantCategory) {
        case RestaurantCategory.BAKERY:
            return "Bakery";
        case RestaurantCategory.FAST_CASUAL:
            return "Fast casual";
        case RestaurantCategory.FAST_FOOD:
            return "Fast food";
        case RestaurantCategory.FINE_DINING:
            return "Fine dining";

        default:
            return "Unspecified";
    }
}
