import { Dates, ActivityEventType } from "@anna/shared";

export function getMessageForUserActivityEvent(
    event: Contracts.DBUserFunnelActivityEvent,
) {
    const { applicantFullName } = event;
    const date =
        event.activityDate && Dates.mediumLocalDateAndTimeFormat(event.activityDate);

    switch (event.type) {
        case ActivityEventType.INTERVIEW_SCHEDULED:
            return {
                text: `${applicantFullName} confirmed the interview, ${date}`,
                bold: [applicantFullName],
            };
        case ActivityEventType.INTERVIEW_CANCELED:
            return {
                text: `${applicantFullName} cancelled the interview on ${date}`,
                bold: [applicantFullName],
            };
        case ActivityEventType.INTERVIEW_REQUESTED_RESCHEDULE:
            return {
                text: `${applicantFullName} requested to reschedule the interview`,
                bold: [applicantFullName],
            };
        case ActivityEventType.TRIAL_SCHEDULED:
            return {
                text: `${applicantFullName} confirmed the trial, ${date}`,
                bold: [applicantFullName],
            };
        case ActivityEventType.TRIAL_CANCELED:
            return {
                text: `${applicantFullName} cancelled the trial on ${date}`,
                bold: [applicantFullName],
            };
        case ActivityEventType.TRIAL_REQUESTED_RESCHEDULE:
            return {
                text: `${applicantFullName} requested to reschedule the trial`,
                bold: [applicantFullName],
            };
        case ActivityEventType.CONTRACT_CONFIRMED:
            return {
                text: `${applicantFullName} sent their contract information. Congratulations! 🎉`,
                bold: [applicantFullName],
            };
        case ActivityEventType.CONTACTED_ACCEPTED:
            return {
                text: `${applicantFullName} accepted your contact request, invite them for an interview`,
                bold: [applicantFullName],
            };
        default:
            throw new Error(`Unhandled activity event of type ${event.type}`);
    }
}

export function getMessageForTeamActivityEvent(event: Contracts.TeamActivityEvent) {
    switch (event.type) {
        case ActivityEventType.INTERVIEW_PENDING:
        case ActivityEventType.INTERVIEW_CANCELED:
        case ActivityEventType.INTERVIEW_RESCHEDULED:
        case ActivityEventType.TRIAL_PENDING:
        case ActivityEventType.TRIAL_CANCELED:
        case ActivityEventType.TRIAL_RESCHEDULED:
        case ActivityEventType.CONTRACT_PENDING:
        case ActivityEventType.HIRED:
        case ActivityEventType.REJECTED:
        case ActivityEventType.USER_CALL:
            return getMessageForTeamFunnelActivityEvent(
                event as Contracts.DBTeamFunnelActivityEvent,
            );
        case ActivityEventType.BULK_REJECTED:
            return getMessageForBulkTeamActivityEvent(
                event as Contracts.DBTeamBulkRejectActivityEvent,
            );
        case ActivityEventType.MANUAL_RECRUITMENT_ON:
        case ActivityEventType.MANUAL_RECRUITMENT_OFF:
        case ActivityEventType.SYSTEM_RECRUITMENT_OFF:
            return getMessageForRecruitmentTeamActivityEvent(
                event as Contracts.DBTeamRecruitmentActivityEvent,
            );
        default:
            throw new Error(`Unhandled activity event of type ${event.type}`);
    }
}

export function getMessageForTeamFunnelActivityEvent(
    event: Contracts.DBTeamFunnelActivityEvent,
) {
    const { userFullName, applicantFullName } = event;

    switch (event.type) {
        case ActivityEventType.INTERVIEW_PENDING:
            return {
                text: `${userFullName} invited ${applicantFullName} for an interview`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.INTERVIEW_CANCELED:
            return {
                text: `${userFullName} cancelled the interview with ${applicantFullName}`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.INTERVIEW_RESCHEDULED:
            return {
                text: `${userFullName} rescheduled the interview with ${applicantFullName}`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.TRIAL_PENDING:
            return {
                text: `${userFullName} invited ${applicantFullName} for a trial`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.TRIAL_CANCELED:
            return {
                text: `${userFullName} cancelled the trial with ${applicantFullName}`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.TRIAL_RESCHEDULED:
            return {
                text: `${userFullName} rescheduled the trial with ${applicantFullName}`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.CONTRACT_PENDING:
            return {
                text: `${userFullName} requested contract information from ${applicantFullName}`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.HIRED:
            return {
                text: `${userFullName} hired ${applicantFullName}. Congratulations! 🎉`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.REJECTED:
            return {
                text: `${userFullName} rejected ${applicantFullName}`,
                bold: [userFullName, applicantFullName],
            };
        case ActivityEventType.USER_CALL:
            return {
                text: `${userFullName} called ${applicantFullName}`,
                bold: [userFullName, applicantFullName],
            };
        default:
            throw new Error(`Unhandled activity event of type ${event.type}`);
    }
}

export function getMessageForBulkTeamActivityEvent(
    event: Contracts.DBTeamBulkRejectActivityEvent,
) {
    const { userFullName, metaData } = event;
    switch (event.type) {
        case ActivityEventType.BULK_REJECTED:
            const suffix = Number(metaData?.rejectCount) > 1 ? "s" : "";
            return {
                text: `${userFullName} sent a mass reply and rejected ${metaData?.rejectCount} applicant${suffix}`,
                bold: [userFullName],
            };

        default:
            throw new Error(`Unhandled activity event of type ${event.type}`);
    }
}

export function getMessageForRecruitmentTeamActivityEvent(
    event: Contracts.DBTeamRecruitmentActivityEvent,
) {
    const { userFullName, metaData } = event;
    switch (event.type) {
        case ActivityEventType.MANUAL_RECRUITMENT_ON:
            return {
                text: `${userFullName} flipped ${metaData.position} auto reply to "Hiring"`,
                bold: [userFullName],
            };
        case ActivityEventType.MANUAL_RECRUITMENT_OFF:
            return {
                text: `${userFullName} flipped ${metaData.position} auto reply to "Not hiring"`,
                bold: [userFullName],
            };
        case ActivityEventType.SYSTEM_RECRUITMENT_OFF:
            return {
                text: `Your ${metaData?.inactiveDays} timer triggered and set the ${metaData.position} auto reply to "Not hiring"`,
                bold: [userFullName, `${metaData.position} auto reply`],
            };

        default:
            throw new Error(`Unhandled activity event of type ${event.type}`);
    }
}
