import { StatusCode } from "./statusCodes";

export class MalformedRequest extends Error {
    constructor(m: string) {
        super(m);
        Object.setPrototypeOf(this, MalformedRequest.prototype);
    }
}

export class RequestNotFound extends Error {
    constructor(m: string) {
        super(m);
        Object.setPrototypeOf(this, RequestNotFound.prototype);
    }
}

export class RequestExecutionFailed extends Error {
    constructor(m: string) {
        super(m);
        Object.setPrototypeOf(this, RequestExecutionFailed.prototype);
    }
}

export type ApiSuccess<TResult> = {
    readonly isSuccess: true;
    readonly result: TResult;
};

export type ApiError = {
    readonly isSuccess: false;
    readonly error: any;
};

export type ApiResponse<TResult = any> = ApiSuccess<TResult> | ApiError;

export interface ClientLogger {
    readonly notify: (error: string) => void;
}

export interface ClientAuth {
    readonly getToken: () => Promise<string | null>;
}

export class Client {
    private readonly TAG = "Client";

    public constructor(
        private endpoint: string,
        private logger?: ClientLogger,
        private auth?: ClientAuth,
    ) {}

    public async execute<TResult>(
        type: string,
        dto: object,
        isQuery: boolean = false,
    ): Promise<ApiResponse<TResult>> {
        const path = this.endpoint + type;
        return this.checkForError(path, dto, isQuery);
    }

    private async checkForError(
        path: string,
        dto: object,
        isQuery: boolean,
    ): Promise<ApiResponse<any>> {
        try {
            const result = await this.makeRequest(path, dto, isQuery);
            return {
                isSuccess: true,
                result,
            };
        } catch (error) {
            this.logger?.notify(
                `${this.TAG}: Error occured while executing the request: "${path}". ${error}`,
            );
            return {
                isSuccess: false,
                error,
            };
        }
    }

    private async makeRequest(url: string, dto: object, isQuery: boolean) {
        const init = await this.prepareRequest(dto);
        let result = await fetch(url, init);

        if (!result.ok) {
            if (result.status === StatusCode.RequestMalformed) {
                throw new MalformedRequest("The request was malformed.");
            }
            if (result.status === StatusCode.RequestNotFound) {
                throw new RequestNotFound("The request was not found.");
            }
            throw new RequestExecutionFailed(
                `Cannot execute the request, server returned a ${result.status} code.`,
            );
        }

        return isQuery ? await result.json() : null;
    }

    private async prepareRequest(dto: object): Promise<RequestInit> {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        if (this.auth) {
            const idToken = await this.auth.getToken();
            headers.append("Authorization", `Bearer ${idToken}`);
        }

        return {
            method: "POST",
            mode: "cors",
            headers,
            body: JSON.stringify(dto),
        };
    }
}
