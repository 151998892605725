import React from "react";
import Header from "@components/Header";
import Filters from "@components/Filters";
import ApplicantProfileModal from "@components/CustomModals/ApplicantProfileModal";
import Applicants from "./Applicants";
import { Container } from "./styled";
import useHomeAllCount from "../../features/counts/useHomeAllCount";

const Home = () => {
    useHomeAllCount();
    return (
        <Container>
            <Header />

            <Filters />

            <Applicants />
        </Container>
    );
};

export default Home;
