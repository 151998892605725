import React from "react";
import { AutoSizer, InfiniteLoader } from "react-virtualized";
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import ApplicantCard from "../../../../components/ApplicantsList/ApplicantCard";
import {
    selectTeamInbox,
    setFocusedChat,
    selectFocusedChat,
} from "../../../../features/funnels/reducer";
import useTeamInbox from "../../../../features/funnels/useTeamInbox";
import {
    ListContainer,
    VListContainer,
    rowHeight,
    InboxCardItem,
    inboxCardStyle,
} from "../styled";
import { setTeamInboxPagination } from "../../../../features/pagination/reducer";

const remoteRowCount = 1000;

export default function TeamInboxContainer() {
    useTeamInbox();
    const teamInboxIds = useAppSelector(selectTeamInbox);
    const focusedChat = useAppSelector(selectFocusedChat);
    const dispatch = useAppDispatch();
    const pagination = useAppSelector(state => state.pagination.teamInboxPagination);

    const noMoreDataToLoad = pagination?.totalPages <= pagination.page;

    const isRowLoaded = ({ index }) => {
        return !!teamInboxIds[index];
    };

    const renderRow = ({ index, key, style }) => {
        const id = teamInboxIds[index];
        const onSelectChat = () => {
            dispatch(setFocusedChat(id));
        };
        return (
            <InboxCardItem key={key} style={style} className="row">
                <ApplicantCard
                    id={id}
                    extraStyle={inboxCardStyle}
                    selected={focusedChat === id}
                    onPress={onSelectChat} //setSelectedChat(list[index].id)}
                />
            </InboxCardItem>
        );
    };

    const handleLoadMore = () => {
        if (noMoreDataToLoad) return;
        dispatch(setTeamInboxPagination({ page: pagination.page + 1 }));
    };

    return (
        <ListContainer>
            <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={handleLoadMore}
                rowCount={remoteRowCount}>
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <VListContainer
                                    width={width}
                                    height={height}
                                    ref={registerChild}
                                    onRowsRendered={onRowsRendered}
                                    rowHeight={rowHeight}
                                    rowRenderer={renderRow}
                                    rowCount={teamInboxIds.length}
                                    overscanRowCount={3}
                                />
                            );
                        }}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        </ListContainer>
    );
}
