import styled from "styled-components";

import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { StyleGuide } from "@constants/StyleGuide";

export const ModalContainer = styled.div`
    width: 824px;
    height: 664px;

    background: ${StyleGuide.palette.baseSalt};
    box-shadow: 0px 28px 72px -24px rgba(0, 0, 0, 0.4);
    border-radius: ${StyleGuide.spacing.xsmall}px;
    display: flex;
    align-items: center;
`;

export const CloseButton = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ${StyleGuide.spacing.mini}px;
    right: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
`;

export const UserProfileContainer = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
`;

interface ArrowContainerProps {
    isDirectionLeft?: boolean;
}

export const ArrowContainer = styled.div<ArrowContainerProps>`
    width: ${StyleGuide.spacing.large}px;
    height: ${StyleGuide.spacing.large}px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.isDirectionLeft ? "rotate(-90deg)" : "rotate(90deg)")};
    position: absolute;
    ${props => (props.isDirectionLeft ? "left: -56px" : "right: -56px")};
    cursor: pointer;
    svg:first-child {
        fill: ${StyleGuide.palette.baseSalt};
    }
`;
