import React from "react";

import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, TemplateBubbleMessage, RejectIcon } from "./styled";

const RejectTemplate = ({ message }) => {
    return (
        <TemplateBubbleContainer>
            <ActionIconText title="Rejected" Icon={<RejectIcon />} />

            {Boolean(message) && <TemplateBubbleMessage>{message}</TemplateBubbleMessage>}
        </TemplateBubbleContainer>
    );
};

export default RejectTemplate;
