import React, { useState } from "react";

import {
    InfoSectionContainer,
    InfoSectionSeparator,
    InfoSectionDetails,
    InfoSectionTitle,
    InfoSectionDescription,
    WorkExperienceContainer,
    WorkExperienceHeader,
    WorkExperienceTitle,
    CvButton,
    ApplicantStatus,
    WorkExperienceItemContainer,
    WorkExperienceItemTitle,
    WorkExperienceItemDot,
    WorkExperienceShowFullButton,
    ArrowIcon,
} from "./styled";

export const InfoSection = ({
    title,
    description,
}: {
    title?: string;
    description?: any;
}) => {
    const hasTitle = Boolean(title);
    if (!Boolean(description)) {
        return <div />;
    }
    return (
        <>
            <InfoSectionContainer>
                <InfoSectionSeparator />

                <InfoSectionDetails>
                    {hasTitle && <InfoSectionTitle>{title}</InfoSectionTitle>}

                    <InfoSectionDescription hasTitle={hasTitle}>
                        {description}
                    </InfoSectionDescription>
                </InfoSectionDetails>
            </InfoSectionContainer>
        </>
    );
};

const WorkExperienceItem = ({ workHistory, isLast }) => {
    return (
        <WorkExperienceItemContainer isLast={isLast}>
            <WorkExperienceItemDot />

            <WorkExperienceItemTitle>{workHistory.role}</WorkExperienceItemTitle>

            <ApplicantStatus>
                {workHistory.name} • {workHistory.duration.years} years{" "}
                {workHistory.duration.months} months{" "}
                {workHistory.duration.isCurrentlyWorking && " - now"}
            </ApplicantStatus>

            {workHistory.comments && (
                <InfoSectionDescription>{workHistory.comments}</InfoSectionDescription>
            )}
        </WorkExperienceItemContainer>
    );
};

export const WorkExperienceSection = ({
    workexperience,
    workexperiences,
    industryExperience,
    cv,
}) => {
    const limit = 3;
    const [showFullList, toggleAllList] = useState(false);
    const dataSource =
        workexperiences &&
        (showFullList ? workexperiences : [...workexperiences].splice(0, limit));
    const isDisplayingOldWorkexperince = !workexperiences;
    const onClickCV = () => {
        window.open(`https://docs.google.com/viewer?url=${cv}`, "_newtab");
    };

    if (
        !Boolean(workexperience?.length) &&
        !Boolean(workexperiences?.length) &&
        !Boolean(cv)
    ) {
        return <div />;
    }

    return (
        <>
            <InfoSectionContainer>
                <InfoSectionSeparator />

                <WorkExperienceContainer>
                    <WorkExperienceHeader>
                        <WorkExperienceTitle>
                            <InfoSectionTitle>Work experience -</InfoSectionTitle>

                            <CvButton onClick={onClickCV}>View CV</CvButton>
                        </WorkExperienceTitle>

                        <ApplicantStatus>{industryExperience}</ApplicantStatus>
                    </WorkExperienceHeader>

                    {isDisplayingOldWorkexperince
                        ? workexperience
                        : dataSource.map((workHistory, i) => (
                              <WorkExperienceItem
                                  key={workHistory.id}
                                  workHistory={workHistory}
                                  isLast={i >= dataSource.length - 1}
                              />
                          ))}

                    {!showFullList && workexperiences?.length > limit && (
                        <WorkExperienceShowFullButton onClick={() => toggleAllList(true)}>
                            See {workexperiences?.length - limit} more
                            <ArrowIcon />
                        </WorkExperienceShowFullButton>
                    )}
                </WorkExperienceContainer>
            </InfoSectionContainer>
        </>
    );
};
