import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { StyleGuide } from "@constants/StyleGuide";

import { signIn } from "../../../firebase/auth";
import Input from "../components/Input";
import SubmitButton from "../components/Buttons/SubmitButton";
import Checkbox from "@components/Checkbox";
import { Link, SpacedRow } from "./styled";

const ForgotPasswordLink = props => (
    <Link onClick={props.navigate}>Forgot password?</Link>
);

function EmailForm({ loading, setLoading }) {
    const [formState, setFormState] = React.useState({
        email: "",
        password: "",
        rememberMe: true,
    });

    const updateField = (fieldName, fieldValue) => {
        setFormState({
            ...formState,
            [fieldName]: fieldValue,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const { email, password, rememberMe } = formState;
        setLoading(true);
        await signIn({ email, password, rememberMe });
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Input
                placeholder="Enter your e-mail"
                type="email"
                required
                onChange={e => updateField("email", e.target.value)}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />

            <Input
                style={{ marginTop: StyleGuide.spacing.mini }}
                placeholder="Password"
                type="password"
                required
                onChange={e => updateField("password", e.target.value)}
            />

            <SpacedRow>
                <Checkbox
                    checked={formState.rememberMe}
                    label="Remember me"
                    onChange={checked => updateField("rememberMe", checked)}
                />

                <RouterLink to="/forgot-password" component={ForgotPasswordLink} />
            </SpacedRow>

            <SubmitButton
                text="Sign in"
                loading={loading}
                disabled={loading}
                type="submit"
                style={{ marginTop: StyleGuide.spacing.medium }}
            />
        </form>
    );
}

export default EmailForm;
