import React, { useState } from "react";

import { StyleGuide } from "@constants/StyleGuide";
import FiltersDropdown from "../Dropdowns/FiltersDropdown";
import {
    CategoryPickerContainer,
    TimeFilterRowOption,
    TimeOptionIcon,
    FilterBubble,
    ToggleBubbleCounter,
} from "./styled";
import { ReactComponent as FilterIcon } from "@assets/svg/filter.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { ReactComponent as TimeIcon } from "@assets/svg/time.svg";
import { ReactComponent as HalfTimeIcon } from "@assets/svg/half_time.svg";
import { VisaType, WorkHourFilter, TypeformVisaType } from "@anna/shared";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { setVisaFilter as setInboxVisaFilter } from "../../features/filters/inboxFilterReducer";
import { setWorkhoursFilter as setInboxWorkhoursFilter } from "../../features/filters/inboxFilterReducer";
import { resetFilters as resetInboxFilters } from "../../features/filters/inboxFilterReducer";
import { setVisaFilter as setHomeVisaFilter } from "../../features/filters/homeFilterReducer";
import { setWorkhoursFilter as setHomeWorkhoursFilter } from "../../features/filters/homeFilterReducer";
import { resetFilters as resetHomeFilters } from "../../features/filters/homeFilterReducer";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "../../constants/routes";

enum FilterType {
    TIME = "time",
    VISA = "visa",
}

interface Filter {
    id: string;
    title: string;
    type: FilterType;
    Icon?: React.ElementType;
}
const previewFilters = [
    {
        id: WorkHourFilter.FULL_TIME,
        title: "Full time",
        type: FilterType.TIME,
        Icon: TimeIcon,
    },
    {
        id: WorkHourFilter.PART_TIME,
        title: "Part time",
        type: FilterType.TIME,
        Icon: HalfTimeIcon,
    },
];
const options = [
    {
        section: "Time",
        id: 100,
        filters: previewFilters,
    },
    {
        section: "VISA",
        id: 101,
        filters: [
            {
                id: VisaType.EU,
                title: TypeformVisaType.UNLIMITED_EU,
                type: FilterType.VISA,
            },
            {
                id: VisaType.FULL,
                title: TypeformVisaType.UNRESTRICTED,
                type: FilterType.VISA,
            },
            {
                id: VisaType.WORK_HOLIDAY,
                title: TypeformVisaType.WORK_HOLIDAY,
                type: FilterType.VISA,
            },
            {
                id: VisaType.RESTRICTED,
                title: TypeformVisaType.RESTRICTED_HOURS_15_HOURS,
                type: FilterType.VISA,
            },
            { id: VisaType.NO_VISA, title: TypeformVisaType.NONE, type: FilterType.VISA },
        ],
    },
];

function FilterContainer() {
    const isHome = useRouteMatch(Routes.HOME);
    const dispatch = useAppDispatch();
    const selectedWorkHoursFilter = useAppSelector(state =>
        isHome ? state.homeFilters.workHoursFilter : state.inboxFilters.workHoursFilter,
    );
    const selectedVisaFilter = useAppSelector(state =>
        isHome ? state.homeFilters.visaFilter : state.inboxFilters.visaFilter,
    );

    const toggleHomeFilter = ({ id, type }) => {
        // home filters
        if (type === FilterType.VISA) return dispatch(setHomeVisaFilter(id));
        if (type === FilterType.TIME) return dispatch(setHomeWorkhoursFilter(id));
        return null;
    };
    const toggleInboxFilter = ({ id, type }) => {
        // inbox filters
        if (type === FilterType.VISA) return dispatch(setInboxVisaFilter(id));
        if (type === FilterType.TIME) return dispatch(setInboxWorkhoursFilter(id));
        return null;
    };

    const resetFilters = () =>
        isHome ? dispatch(resetHomeFilters()) : dispatch(resetInboxFilters());

    if (isHome) {
        return (
            <FilterPicker
                selectedWorkHoursFilter={selectedWorkHoursFilter}
                selectedVisaFilter={selectedVisaFilter}
                toggleFilter={toggleHomeFilter}
                resetFilters={resetFilters}
            />
        );
    }
    return (
        <FilterPicker
            selectedWorkHoursFilter={selectedWorkHoursFilter}
            selectedVisaFilter={selectedVisaFilter}
            toggleFilter={toggleInboxFilter}
            resetFilters={resetFilters}
        />
    );
}

const FilterPicker = ({
    selectedWorkHoursFilter,
    selectedVisaFilter,
    toggleFilter,
    resetFilters,
}) => {
    const selectedFilters = [...selectedWorkHoursFilter, ...selectedVisaFilter];
    const filtersCount = selectedFilters.length;
    return (
        <CategoryPickerContainer>
            {previewFilters.map((filter: Filter, index) => {
                const { id, title, type, Icon } = filter;
                const isSelected = selectedWorkHoursFilter.some(id => id === filter.id);
                const iconFill = isSelected
                    ? StyleGuide.palette.baseSalt
                    : StyleGuide.palette.baseVanilla;

                return (
                    <TimeFilterRowOption
                        key={id}
                        onClick={() => toggleFilter({ id, type })}
                        isSelected={isSelected}
                        isLast={index === previewFilters.length - 1}>
                        {Icon && (
                            <TimeOptionIcon>
                                <Icon fill={iconFill} />
                            </TimeOptionIcon>
                        )}
                        {title}
                    </TimeFilterRowOption>
                );
            })}

            <FiltersDropdown
                options={options}
                onSelect={({ id, type }) => toggleFilter({ id, type })}
                selectedFilters={selectedFilters}
                resetFilters={resetFilters}
                renderToggleBlock={(opened: boolean) => (
                    <FilterBubble isSelected={opened}>
                        {opened ? <CloseIcon /> : <FilterIcon />}

                        {Boolean(filtersCount) && (
                            <ToggleBubbleCounter>{filtersCount}</ToggleBubbleCounter>
                        )}
                    </FilterBubble>
                )}></FiltersDropdown>
        </CategoryPickerContainer>
    );
};

export default FilterContainer;
