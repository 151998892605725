import React, { useEffect, useState } from "react";
import Typesense, { Client } from "typesense";
import { useAppSelector } from "../../store/store";
import { FunnelState } from "src/features/funnels/reducer";
import { mapWorkHours } from "@anna/shared";
import { Pagination } from "../../features/pagination/reducer";
import { RequestState } from "@constants/enums";

/**
 * Responsible for using search keys to query data
 */

let client: Client | null = null;

export default function useFunnelSearch() {
    // const groupId = query.get("groupId");
    const [loading, setLoading] = useState(true);
    const funnelSearchKey = useAppSelector(state => state.user.funnelSearchKey?.key);

    useEffect(() => {
        if (funnelSearchKey && !client) {
            const typesenseClient = new Typesense.Client({
                nodes: [
                    {
                        host: "215lsf8nqhr0bityp-1.a1.typesense.net",
                        port: 443,
                        protocol: "https",
                    },
                ],
                apiKey: funnelSearchKey,
                connectionTimeoutSeconds: 2,
            });
            client = typesenseClient;
        }
        return () => {};
    }, [funnelSearchKey]);

    const onSearchFunnels = async ({
        q = "",
        filter,
        page,
    }: {
        q: string;
        filter?: string;
        page?: {
            size: number;
            current: number;
        };
    }): Promise<{ data: Partial<FunnelState>[]; meta: Partial<Pagination> }> => {
        if (!client) return { data: [], meta: {} };
        try {
            const results = await client.collections("funnels").documents().search({
                q,
                query_by: "userMeta.firstName",
                page: page?.current,
                per_page: page?.size,
                // infix: "fallback",
                filter_by: filter,
                sort_by: "updateTime:desc",
            });
            const data = results.hits?.map((hit: any) => {
                const doc = hit.document;
                return {
                    id: hit.document.id,
                    userMetaFirstName: doc["userMeta.firstName"],
                    userMetaLastName: doc["userMeta.lastName"],
                    userMetaWorkHours: mapWorkHours(doc["userMeta.workHours"]),
                    userMetaPosition: doc["userMeta.position"],
                    userMetaPositionDetail: doc["userMeta.positionDetail"],
                    userMetaProfileImage: doc["userMeta.image"],
                    // firstSubscriberProfileImage:
                    userMetaVisaType: doc["userMeta.visaType"],
                    createTime: new Date(doc.createTime),
                    updateTime: new Date(doc.updateTime),
                    applicantId: doc.applicantId,
                    communicationState: doc.communicationState,
                    favourite: doc.favourite,
                    state: doc.state,
                    restaurantId: doc.restaurantId,
                    workhourFilter: doc.workhourFilter,
                };
            }) as Partial<FunnelState>[];

            return {
                data,
                meta: {
                    current: results.page,
                    size: results.request_params.per_page,
                    totalPages: Math.ceil(
                        // TODO: lets see if per_page is always present
                        results.found / (results.request_params.per_page || 10),
                    ),
                    totalResults: results.found,
                },
            };
        } catch (error) {
            console.error(error);
            return { data: [], meta: {} };
        }
    };

    return {
        loading,
        onSearchFunnels,
    };
}
