import { TemplateVariables } from "@anna/shared";
import { TemplateProps } from "./types";
export default ({
    applicantName = TemplateVariables.APPLICANT_NAME,
    hiredLink = TemplateVariables.HIRE_LINK,
    senderFirstName = TemplateVariables.SENDER_NAME,
}: TemplateProps) => `Hi ${applicantName},

Formalitäten müssen auch sein, daher bitten wir dich, uns über den folgenden Link deine Vertragsdaten mitzuteilen: ${hiredLink}

Danke und beste Grüße
${senderFirstName}`;
