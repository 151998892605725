export enum ApplicationTypeValue {
    Administrative = "Administrative",
    Baker = "Baker",
    Bakery = "Bakery",
    // Atelier specific
    Barista = "Barista", // maps to FOH
    Bread = "Bread",
    Brewery = "Brewery",
    Chef = "Chef", // maps to Kitchen
    CoffeeBar = "Coffee Bar",
    CoffeeRoastery = "Roastery",
    CrewMember = "Crew Member",
    Dishwasher = "Dishwasher",
    EventCommunication = "Events & Communications",
    Favourite = "favourite",
    FrontofHouse = "Front of House",
    Host = "Host",
    InternshipBakery = "Internship - Bakery",
    InternshipBoh = "Internship - Kitchen",
    InternshipBread = "Internship - Bread",
    InternshipCommunications = "Internship - Communications",
    InternshipFoh = "Internship - Front of House",
    InternshipPastry = "Internship - Pastry",
    Kitchen = "Kitchen",
    Driver = "Driver",
    Køkkenassistent = "Køkkenassistent", // maps to Kitchen assistant (maybe?)
    Management = "Management",
    Manager = "Manager", // maps to management
    Medarbejder = "Medarbejder", // maps to Crew member
    Pastry = "Pastry",
    RetailAssistant = "Retail Assistant",
    Under18 = "U18",
    // TODO: WeDo specific
}

export enum FirestoreDocuments {
    ExpiredApplicationsReport = "expiredApplicationsReport",
    ExpiredIntroductionsReport = "expiredIntroductionsReport",
    ExpiredFavouritesReport = "expiredFavouritesReport",
}

export enum WorkHourFilter {
    FULL_TIME = "full-time",
    PART_TIME = "part-time",
}

export const ApplicationTypes = {
    administrative: [ApplicationTypeValue.Administrative],
    boh: [
        ApplicationTypeValue.Kitchen,
        ApplicationTypeValue.Baker,
        ApplicationTypeValue.Bakery,
        ApplicationTypeValue.Dishwasher,
    ],
    eventCommunication: [ApplicationTypeValue.EventCommunication],
    host: [ApplicationTypeValue.Host],
    bakery: [ApplicationTypeValue.Bakery],
    bread: [ApplicationTypeValue.Bread],
    pastry: [ApplicationTypeValue.Pastry],
    crewMember: [ApplicationTypeValue.CrewMember],
    brewery: [ApplicationTypeValue.Brewery],
    foh: [ApplicationTypeValue.FrontofHouse],
    internshipBoh: [ApplicationTypeValue.InternshipBoh],
    internshipCommunications: [ApplicationTypeValue.InternshipCommunications],
    internshipFoh: [ApplicationTypeValue.InternshipFoh],
    internshipBakery: [ApplicationTypeValue.InternshipBakery],
    internshipBread: [ApplicationTypeValue.InternshipBread],
    internshipPastry: [ApplicationTypeValue.InternshipPastry],
    management: [ApplicationTypeValue.Management],
    under18: [ApplicationTypeValue.Under18],
};

export enum ApplicantSharedPoolAction {
    Accept = "Accept",
    Decline = "Decline",
    Optout = "Optout",
}

export enum BookingType {
    TRIAL_FOH = "TRIAL_FOH",
    TRIAL_UNDER_18 = "TRIAL_UNDER_18",
    TRIAL_BREWERY = "TRIAL_BREWERY",
    TRIAL_BOH = "TRIAL_BOH",
    TRIAL_EVENT_COMMUNICATION = "TRIAL_EVENT_COMMUNICATION",
    TRIAL_HOST = "TRIAL_HOST",
    TRIAL_BAKERY = "TRIAL_BAKERY",
    TRIAL_BREAD = "TRIAL_BREAD",
    TRIAL_PASTRY = "TRIAL_PASTRY",
    TRIAL_CREW_MEMBER = "TRIAL_CREW_MEMBER",
    TRIAL_MANAGEMENT = "TRIAL_MANAGEMENT",
    TRIAL_ADMINISTRATIVE = "TRIAL_ADMINISTRATIVE",
    TRIAL_INTERNSHIP_FOH = "TRIAL_INTERNSHIP_FOH",
    TRIAL_INTERNSHIP_BOH = "TRIAL_INTERNSHIP_BOH",
    TRIAL_INTERNSHIP_BAKERY = "TRIAL_INTERNSHIP_BAKERY",
    TRIAL_INTERNSHIP_BREAD = "TRIAL_INTERNSHIP_BREAD",
    TRIAL_INTERNSHIP_PASTRY = "TRIAL_INTERNSHIP_PASTRY",
    INTERVIEW = "INTERVIEW",
}

export enum RejectReason {
    SNOOZED = "SNOOZED",
    NO_FIT = "NO_FIT",
    NOT_AVAILABLE = "NOT_AVAILABLE",
    NO_SHOW = "NO_SHOW",
    NOT_INTERESTED = "NOT_INTERESTED",
    BULK_REJECT = "BULK_REJECT",
}

export enum ApplicationState {
    APPLIED = "APPLIED",
    // used mainly for elastic search
    CONTACTED_ACCEPTED = "CONTACTED_ACCEPTED",
    CONTACTED_DECLINED = "CONTACTED_DECLINED",
    CONTRACT_PENDING = "CONTRACT_PENDING",
    CONTRACT_CANCELED = "CONTRACT_CANCELED",
    CONTRACT_CONFIRMED = "CONTRACT_CONFIRMED",
    HIRED = "HIRED",
    INTERESTED = "INTERESTED", // contacted via. SMS/email from APPLIED/REVIEWED state
    INTERVIEW_PENDING = "INTERVIEW_PENDING",
    INTERVIEW_SCHEDULED = "INTERVIEW_SCHEDULED",
    INTERVIEW_CANCELED = "INTERVIEW_CANCELED",
    INTERVIEWED = "INTERVIEWED",
    REJECTED = "REJECTED",
    REVIEWED = "REVIEWED",
    TRIAL_PENDING = "TRIAL_PENDING",
    TRIAL_SCHEDULED = "TRIAL_SCHEDULED",
    TRIAL_CANCELED = "TRIAL_CANCELED",
    TRIALED = "TRIALED",
    AUTO_REJECTED = "AUTO_REJECTED",
}

export type AllowedManualApplicationStateType =
    | ApplicationState.APPLIED
    | ApplicationState.REVIEWED
    | ApplicationState.INTERESTED
    | ApplicationState.INTERVIEW_PENDING
    | ApplicationState.INTERVIEW_CANCELED
    | ApplicationState.INTERVIEWED
    | ApplicationState.TRIAL_PENDING
    | ApplicationState.TRIAL_CANCELED
    | ApplicationState.TRIALED
    | ApplicationState.HIRED
    | ApplicationState.REJECTED;

export enum TypeformWorkHours {
    FULL_TIME = "Full-time (37.5h weekly)",
    PART_TIME_12_HOURS = "Part-time (max 12h)",
    PART_TIME_OR_FULL_TIME = "Part-time or Full-time",
    PART_TIME_SU_MIN_10_HOURS = "Part-time (min 10h - SU)", // Part-time student hours
    PART_TIME = "Part-time (15-20h weekly)",
    INTERNSHIP = "Internship",
    U18 = "U18",
    UNDER_10_HOURS = "5-10 timer",
    UNDER_20_HOURS = "10-20 timer",
    UNDER_30_HOURS = "20-30 timer",
    UNDER_40_HOURS = "30-40 timer",
}

export enum TypeformVisaType {
    RESTRICTED_HOURS_15_HOURS = "Restricted Visa 15 Hours",
    RESTRICTED_HOURS_20_HOURS = "Restricted Visa - 20 Hours",
    WORK_HOLIDAY = "Work Holiday Visa - 37.5",
    UNRESTRICTED = "Unrestricted Full Visa - 37.5 Hours",
    UNLIMITED_EU = "EU/DK Citizen - Unlimited",
    NONE = "No Visa",
}

export type Gender = "male" | "female" | null;

export enum ImageUploadType {
    PROFILE_IMAGE = "PROFILE_IMAGE",
    RESTAURANT_LOGO = "RESTAURANT_LOGO",
}

export enum ActivityMessageSource {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL",
    SYSTEM = "SYSTEM",
}

// TODO Next: Use this enum instead of backend's one
export enum CommunicationSource {
    EMAIL = "EMAIL",
    SMS = "SMS",
    VOICE_CALL = "VOICE_CALL",
    APP = "APP",
}

export enum ActivityType {
    ACTION_APPLIED = "ACTION_APPLIED",
    ACTION_INTRO = "ACTION_INTRO",
    ACTION_CONTACTED_PENDING = "ACTION_CONTACTED_PENDING",
    ACTION_CONTACTED_ACCEPTED = "ACTION_CONTACTED_ACCEPTED",
    ACTION_CONTACTED_DECLINED = "ACTION_CONTACTED_DECLINED",
    ACTION_CONTRACT_PENDING = "ACTION_CONTRACT_PENDING",
    ACTION_CONTRACT_CANCELED = "ACTION_CONTRACT_CANCELED",
    ACTION_CONTRACT_CONFIRMED = "ACTION_CONTRACT_CONFIRMED",
    ACTION_HIRED = "ACTION_HIRED",
    ACTION_INTERVIEW_PENDING = "ACTION_INTERVIEW_PENDING",
    ACTION_INTERVIEW_CONFIRMATION = "ACTION_INTERVIEW_CONFIRMATION", // TODO Next: Remove
    ACTION_INTERVIEW_SCHEDULED = "ACTION_INTERVIEW_SCHEDULED",
    ACTION_INTERVIEW_RESCHEDULED = "ACTION_INTERVIEW_RESCHEDULED",
    ACTION_INTERVIEW_CANCELED = "ACTION_INTERVIEW_CANCELED",
    ACTION_INTERVIEWED = "ACTION_INTERVIEWED",
    ACTION_REJECTED = "ACTION_REJECTED",
    ACTION_REVIEWED = "ACTION_REVIEWED",
    ACTION_INTERESTED = "ACTION_INTERESTED", // contacted
    ACTION_SHARED_POOL_FAVOURITE = "ACTION_SHARED_POOL_FAVOURITE",
    ACTION_SHARED_POOL_INTRODUCTION = "ACTION_SHARED_POOL_INTRODUCTION",
    ACTION_UNDO_REVIEWED = "ACTION_UNDO_REVIEWED",
    ACTION_TRIAL_PENDING = "ACTION_TRIAL_PENDING",
    ACTION_TRIAL_CONFIRMATION = "ACTION_TRIAL_CONFIRMATION", // TODO Next: Remove
    ACTION_TRIAL_SCHEDULED = "ACTION_TRIAL_SCHEDULED",
    ACTION_TRIAL_RESCHEDULED = "ACTION_TRIAL_RESCHEDULED",
    ACTION_TRIAL_CANCELED = "ACTION_TRIAL_CANCELED",
    ACTION_TRIALED = "ACTION_TRIALED",
    ACTION_USER_MESSAGE = "ACTION_USER_MESSAGE",
    ACTION_USER_CALL = "ACTION_USER_CALL",
    ACTION_UNFAVOURITED = "ACTION_UNFAVOURITED",

    // TODO: Migrate to the above
    ACTION_HIRE = "ACTION_HIRE",
    ACTION_INTERVIEW = "ACTION_INTERVIEW",
    ACTION_TRIAL = "ACTION_TRIAL",
    ACTION_REJECT = "ACTION_REJECT",
    USER_MESSAGE = "USER_MESSAGE",
}

// IMPORTANT: Do not re-arrange the order of these enums
export enum ActivityEventType {
    INTERVIEW_PENDING,
    TRIAL_PENDING,
    INTERVIEW_SCHEDULED,
    TRIAL_SCHEDULED,
    INTERVIEW_CANCELED,
    TRIAL_CANCELED,
    INTERVIEW_REQUESTED_RESCHEDULE,
    TRIAL_REQUESTED_RESCHEDULE,
    INTERVIEW_RESCHEDULED,
    TRIAL_RESCHEDULED,
    CONTRACT_PENDING,
    CONTRACT_CONFIRMED,
    HIRED,
    REJECTED,
    CONTACTED_ACCEPTED,
    BULK_REJECTED,
    MANUAL_RECRUITMENT_ON,
    MANUAL_RECRUITMENT_OFF,
    SYSTEM_RECRUITMENT_OFF,
    USER_CALL,
}

export enum CommunicationState {
    CONTACTED_ACCEPTED = "CONTACTED_ACCEPTED",
    CONTACTED_DECLINED = "CONTACTED_DECLINED",
    CONTACTED_PENDING = "CONTACTED_PENDING",
    CONTACTED_OPTED_OUT = "CONTACTED_OPTED_OUT",
}

export enum ExternalLinkType {
    INTERVIEW_INVITE = "INTERVIEW_INVITE",
    INTERVIEW_CANCEL = "INTERVIEW_CANCEL",
    INTERVIEW_CONFIRM = "INTERVIEW_CONFIRM",
    INTERVIEW_REQUEST_NEW_TIMESLOTS = "INTERVIEW_REQUEST_NEW_TIMESLOTS",
    INTERVIEW_RESCHEDULE = "INTERVIEW_RESCHEDULE",
    TRIAL_INVITE = "TRIAL_INVITE",
    TRIAL_CANCEL = "TRIAL_CANCEL",
    TRIAL_CONFIRM = "TRIAL_CONFIRM",
    TRIAL_REQUEST_NEW_TIMESLOTS = "TRIAL_REQUEST_NEW_TIMESLOTS",
    TRIAL_RESCHEDULE = "TRIAL_RESCHEDULE",
    SHARED_POOL_INTRODUCTION = "SHARED_POOL_INTRODUCTION",
}

export enum RestaurantCategory {
    BAKERY = "BAKERY",
    FAST_CASUAL = "FAST_CASUAL",
    FAST_FOOD = "FAST_FOOD",
    FINE_DINING = "FINE_DINING",
    UNKNOWN = "UNKNOWN",
}

export enum SharedPoolApplicantResponse {
    ACCEPTED = "accepted",
}

export enum SharedPoolApplicationStatus {
    AlreadyFavourited = 0,
    Favourited = 1,
    Unfavourited = 2,
    IntroductionRequested = 3,
    IntroductionAccepted = 4,
    IntroductionDeclined = 5,
    IntroductionUnavailable = 6,
    InvalidApplicationId = 7,
    Removed = 8,
}

export enum NotificationMetaType {
    NEW_APPLICATION = "NEW_APPLICATION",
    NEW_MESSAGE = "NEW_MESSAGE",
    NEW_HIRE_DETAILS = "NEW_HIRE_DETAILS",
    NEW_INTERVIEW_SCHEDULED = "NEW_INTERVIEW_SCHEDULED",
    NEW_INTERVIEW_CANCELED = "NEW_INTERVIEW_CANCELED",
    NEW_TRIAL_SCHEDULED = "NEW_TRIAL_SCHEDULED",
    NEW_TRIAL_CANCELED = "NEW_TRIAL_CANCELED",
    NEW_APPLICATION_DAILY_DIGEST = "NEW_APPLICATION_DAILY_DIGEST",
    POSITION_ABOUT_TO_EXPIRE = "POSITION_ABOUT_TO_EXPIRE",
    POSITION_EXPIRED = "POSITION_EXPIRED",
    MANUAL_RECRUITMENT_ON = "MANUAL_RECRUITMENT_ON",
    MANUAL_RECRUITMENT_OFF = "MANUAL_RECRUITMENT_OFF",
    SHARED_POOL_CONTACT_ACCEPTED = "SHARED_POOL_CONTACT_ACCEPTED",
    SHARED_POOL_CONTACT_DECLINED = "SHARED_POOL_CONTACT_DECLINED",
}

export enum NotificationType {
    NEW_APPLICATION = "NEW_APPLICATION",
    NEW_MESSAGE = "NEW_MESSAGE",
    NEW_SCHEDULED = "NEW_SCHEDULED", // Interview or trial scheduled
    NEW_HIRE_DETAILS = "NEW_HIRE_DETAILS",
    SHARED_POOL_APPLICANT_RESPONSE = "SHARED_POOL_APPLICANT_RESPONSE", // When applicant accepts/declines user's contact request
}

export interface PositionExpirationNotificationMetaData {
    type: NotificationMetaType.POSITION_ABOUT_TO_EXPIRE;
    restaurantId: string;
    position: ApplicationTypeValue;
    profileImage?: string;
}

export interface ManualRecruitmentNotificationMetaData {
    type:
        | NotificationMetaType.MANUAL_RECRUITMENT_ON
        | NotificationMetaType.MANUAL_RECRUITMENT_OFF;
    userName: string;
    position: ApplicationTypeValue;
}

export interface NewMessageNotificationMetaData {
    type: NotificationMetaType.NEW_MESSAGE;
    funnelId: string;
    applicantId: string;
    profileImage?: string;
    firstName?: string;
    lastName?: string;
    restaurantId?: string;
}

export interface NewApplicationNotificationMetaData {
    type: NotificationMetaType.NEW_APPLICATION;
    funnelId: string;
    applicantId: string;
    restaurantId: string;
    position: ApplicationTypeValue;
}

export interface NewScheduledNotificationMetaData {
    type:
        | NotificationMetaType.NEW_INTERVIEW_SCHEDULED
        | NotificationMetaType.NEW_TRIAL_SCHEDULED;
    funnelId: string;
    applicantId: string;
}

export interface NewHireDetailsNotificationMetaData {
    type: NotificationMetaType.NEW_HIRE_DETAILS;
    funnelId: string;
    applicantId: string;
}

export interface NewApplicantMessageSharedPoolMetaData {
    type:
        | NotificationMetaType.SHARED_POOL_CONTACT_ACCEPTED
        | NotificationMetaType.SHARED_POOL_CONTACT_DECLINED;
    funnelId: string;
    applicantId: string;
}

export type NotificationMetaData =
    | NewMessageNotificationMetaData
    | NewApplicationNotificationMetaData
    | NewScheduledNotificationMetaData
    | NewHireDetailsNotificationMetaData
    | NewApplicantMessageSharedPoolMetaData;

// also see TypeformVisaType
export enum VisaType {
    EU = "EU", // EU/DK Citizen - Unlimited
    FULL = "FULL", // Unrestricted Full Visa - 37.5 Hours
    WORK_HOLIDAY = "WORK_HOLIDAY", // Work Holiday Visa - 37.5
    RESTRICTED = "RESTRICTED", // Restricted Visa 15 Hours
    NO_VISA = "NO_VISA", // No Visa
}

export enum SchedulingType {
    Interview = "interview",
    Trial = "trial",
}

export type PlandayGender = "Male" | "Female";

export enum mapGenderToPlandayFormat {
    male = "Male",
    female = "Female",
}

export enum Intl {
    da_DK = "da_DK",
    en_US = "en_US",
    de_DE = "de_DE",
}

export enum MappedWorkHours {
    FULL_TIME = "Full-time",
    PART_TIME_OR_FULL_TIME = "Part/Full-time",
    PART_TIME = "Part-time",
    PART_TIME_12_HOURS = "Part-time (max 12h)",
    PART_TIME_MIN_10_HOURS = "Part-time (min 10h)",
    INTERNSHIP = "Internship",
    U18 = "U18",
    UNDER_10_HOURS = "5-10 hours",
    UNDER_20_HOURS = "10-20 hours",
    UNDER_30_HOURS = "20-30 hours",
    UNDER_40_HOURS = "30-40 hours",
}

export enum LastActivityType {
    Action = "action",
    Message = "message",
}
export enum QueueStatus {
    PROCESSING = "PROCESSING",
    FAILED = "FAILED",
    SKIPPED = "SKIPPED",
    COMPLETED = "COMPLETED",
}

export enum TemplateVariables {
    APPLICANT_NAME = "_ApplicantName_",
    LOCATION = "_Location_",
    TIME_SLOTS = "_TimeSlots_",
    RESTAURANT_TITLE = "_Restaurant_",
    HIRE_LINK = "_Onboarding_",
    SENDER_NAME = "_YourName_",
    // TODO: To be deprecated for attachments
    FILE_LINK = "_FileLink_",
}

export enum TemplateLinks {
    BASE_LINK = "www.anna.co",
    INVITATION_LINK = "www.anna.co/preview",
    ONBOARDING_LINK = "www.anna.co/onboarding",
}

export enum JobType {
    BULK_REJECTION = "BULK_REJECTION",
}

export enum TemplateType {
    INTRO = "INTRO",
    HIRED = "HIRED",
    INTERVIEW_CANCEL = "INTERVIEW_CANCEL",
    INTERVIEW_CANCEL_INVITE = "INTERVIEW_CANCEL_INVITE",
    INTERVIEW_INVITE = "INTERVIEW_INVITE",
    INTERVIEW_RESCHEDULE = "INTERVIEW_RESCHEDULE",
    TRIAL_CANCEL = "TRIAL_CANCEL",
    TRIAL_CANCEL_INVITE = "TRIAL_CANCEL_INVITE",
    TRIAL_INVITE_FILE = "TRIAL_INVITE_FILE",
    TRIAL_INVITE_WO_FILE = "TRIAL_INVITE_WO_FILE",
    TRIAL_RESCHEDULE = "TRIAL_RESCHEDULE",
    REJECTION = "REJECTION",
    BULK_REJECTION = "BULK_REJECTION",
    AUTO_REPLY = "AUTO_REPLY",
    AUTO_REJECTION = "AUTO_REJECTION",
}

export enum SupportedTimeZones {
    EUROPE_COPENHAGEN = "Europe/Copenhagen",
}

export enum TalentPoolRestaurants {
    ANNA = "ksGFDq8KK7v8ZwSMYwWZ",
    ANNA_STAGING = "3SDWbEseLZPYXsju50pL",
}

// https://www.twilio.com/docs/sms/send-messages#monitor-the-status-of-your-message
export enum TwilioMessageStatus {
    Delivered = "delivered",
    Failed = "failed",
    Sent = "sent",
    Queued = "queued",
    Undelivered = "undelivered",
}

export enum FunnelListView {
    ApplicantsList = "applicantsList",
    ContactedList = "contactedList",
    HiredList = "hiredList",
    RejectedList = "rejectedList",
    ShortList = "shortList",
}
