import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userReducer from "../features/user/reducer";
import userPositionsReducer from "../features/userPositions/reducer";
import restaurantsReducer from "../features/restaurants/reducer";
import funnelsReducer from "../features/funnels/reducer";
import notificationsReducer from "../features/notifications/reducer";
import countReducer from "../features/counts/reducer";
import inboxFilterReducer from "../features/filters/inboxFilterReducer";
import homeFilterReducer from "../features/filters/homeFilterReducer";
import paginationReducer from "../features/pagination/reducer";
import { resetPaginationOnFilterUpdateMiddleware } from "./middleware";
import activityReducer from "../features/activity/reducer";
import templatesReducer from "../features/templates/reducer";
import applicationsReducer from "../features/applications/reducer";
import locationsReducer from "../features/locations/reducer";
import bookingsReducer from "../features/bookings/reducer";
import activityCenterReducer from "../features/activityCenter/reducer";

const store = configureStore({
    reducer: {
        user: userReducer,
        userPositions: userPositionsReducer,
        restaurants: restaurantsReducer,
        funnels: funnelsReducer,
        notifications: notificationsReducer,
        count: countReducer,
        inboxFilters: inboxFilterReducer,
        homeFilters: homeFilterReducer,
        activity: activityReducer,
        pagination: paginationReducer,
        templates: templatesReducer,
        applications: applicationsReducer,
        locations: locationsReducer,
        bookings: bookingsReducer,
        activityCenter: activityCenterReducer,
    },
    middleware: [resetPaginationOnFilterUpdateMiddleware] as const,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
