import React from "react";
import { AutoSizer, InfiniteLoader } from "react-virtualized";
import useYourInbox from "../../../../features/funnels/useYourInbox";
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import ApplicantCard from "../../../../components/ApplicantsList/ApplicantCard";
import {
    selectYourInbox,
    setFocusedChat,
    selectYourInboxLatest,
} from "../../../../features/funnels/reducer";
import { selectFocusedChat } from "../../../../features/funnels/reducer";
import { selectNewMessageNotifications } from "../../../../features/notifications/reducer";
import {
    ListContainer,
    VListContainer,
    rowHeight,
    InboxCardItem,
    inboxCardStyle,
    InboxBadge,
} from "../styled";
import { setYourInboxPagination } from "../../../../features/pagination/reducer";

const remoteRowCount = 1000;

export default function YourInboxContainer() {
    useYourInbox();
    const dispatch = useAppDispatch();

    const yourInboxIds = useAppSelector(selectYourInbox);
    const focusedChat = useAppSelector(selectFocusedChat);
    const pagination = useAppSelector(state => state.pagination.yourInboxPagination);
    const noMoreDataToLoad = pagination?.totalPages <= pagination.page;

    const isRowLoaded = ({ index }) => {
        return !!yourInboxIds[index];
    };

    const renderRow = ({ index, key, style }) => {
        const id = yourInboxIds[index];
        const onSelectChat = () => {
            dispatch(setFocusedChat(id));
        };
        return (
            <InboxCardItem key={key} style={style} className="row">
                <ApplicantCard
                    id={id}
                    extraStyle={inboxCardStyle}
                    selected={focusedChat === id}
                    onPress={onSelectChat} //setSelectedChat(list[index].id)}
                />
            </InboxCardItem>
        );
    };
    const handleLoadMore = () => {
        if (noMoreDataToLoad) return;
        dispatch(setYourInboxPagination({ page: pagination.page + 1 }));
    };

    return (
        <>
            <ListContainer>
                {/* <NewMessages /> */}
                <InfiniteLoader
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={handleLoadMore}
                    rowCount={remoteRowCount}>
                    {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <VListContainer
                                        width={width}
                                        height={height}
                                        ref={registerChild}
                                        onRowsRendered={onRowsRendered}
                                        rowHeight={rowHeight}
                                        rowRenderer={renderRow}
                                        rowCount={yourInboxIds.length}
                                        overscanRowCount={3}
                                    />
                                );
                            }}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
            </ListContainer>
        </>
    );
}

function NewMessages() {
    const latestItem = useAppSelector(selectYourInboxLatest);
    const newestItem = useAppSelector(state =>
        state.funnels.funnelMap?.[latestItem]?.updateTime.getTime(),
    );
    const newMessageIds = useAppSelector(selectNewMessageNotifications);

    const moreMessagesToLoad = newMessageIds?.filter(n => n.createTime > newestItem);
    if (Boolean(moreMessagesToLoad?.length)) {
        return <InboxBadge>{moreMessagesToLoad?.length} new</InboxBadge>;
    }
    return null;
}
