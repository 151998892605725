import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";

import { Times, Dates } from "@anna/shared";
import TimeSlotsPreview from "@components/TimeSlotsPreview";
import ActionIconText from "../ActionIconText";
import {
    TemplateBubbleContainer,
    TemplateBubbleMessage,
    InterviewIcon,
    previewStyle,
} from "./styled";

const interval = 30; // minutes
const getInterviewTimeSlotsText = ({
    timeSlots = {},
    isReschedule = false,
}: {
    timeSlots: Contracts.CustomTimeSlotsDTO | undefined;
    isReschedule?: boolean;
}): string => {
    const totalTimeSlots = Object.keys(timeSlots).reduce(
        (acc: Times.TimeSlot[], day: string) => {
            const times = Times.convertTimeSlotsForDay(day, timeSlots, interval);
            return acc.concat(times);
        },
        [],
    );

    if (totalTimeSlots.length > 1) {
        return `Sent ${totalTimeSlots.length} time slots`;
    }

    if (totalTimeSlots.length === 1) {
        const timeslot = totalTimeSlots[0];
        return `${isReschedule ? "to " : ""}${format(
            timeslot.startTime,
            "dd LLL",
            Dates.locale,
        )} at ${format(timeslot.startTime, "HH:mm", Dates.locale)}`;
    }

    return " ";
};

const InterviewTemplate = ({ message, timeSlots }) => {
    const [showTimeslotsPreview, toggleTimeslotsPreview] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                toggleTimeslotsPreview(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <TemplateBubbleContainer ref={wrapperRef}>
            {showTimeslotsPreview && (
                <TimeSlotsPreview
                    onClose={() => {}}
                    forInterview
                    customTimes={timeSlots}
                    style={previewStyle}
                />
            )}

            <ActionIconText
                title="Invited for interview"
                description={getInterviewTimeSlotsText({ timeSlots })}
                Icon={<InterviewIcon />}
                onClick={() => toggleTimeslotsPreview(!showTimeslotsPreview)}
            />

            <TemplateBubbleMessage>{message}</TemplateBubbleMessage>
        </TemplateBubbleContainer>
    );
};

export default InterviewTemplate;
