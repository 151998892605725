import React from "react";

import { Container, CheckboxContainer, Icon, CheckboxLabel } from "./styled";

interface CheckboxProps {
    checked: boolean;
    label: string;
    className?: any;
    onChange: (e: any) => void;
}

function Checkbox({ checked, label, className, onChange }: CheckboxProps) {
    const onButtonToggle = () => {
        onChange(!checked);
    };

    const onKeyDown = e => {
        if (e.key === "") {
            e.preventDefault();
            onButtonToggle();
        }
    };

    return (
        <Container className={className} onClick={onButtonToggle}>
            <CheckboxContainer
                checked={checked}
                onClick={onButtonToggle}
                type="button"
                onKeyDown={onKeyDown}>
                {Boolean(checked) && <Icon />}
            </CheckboxContainer>
            <CheckboxLabel>{label}</CheckboxLabel>
        </Container>
    );
}

export default Checkbox;
