export enum TranslationKeys {
    APPLICATION_POSITION = "application_position",
    APPLICATION_POSITION_BAKERY = "application_position_bakery",
    APPLICATION_POSITION_RETAIL = "application_position_retail",
    APPLICATION_POSITION_FOH = "application_position_foh",
    APPLICATION_POSITION_BOH = "application_position_boh",
    APPLICATION_POSITION_ADMIN = "application_position_admin",
    APPLICATION_POSITION_DETAIL = "application_position_detail",

    APPLICATION_WORKHOURS = "application_workhours",
    APPLICATION_AVAILABILITY_YEAR = "application_availability_year",
    APPLICATION_WORKEXPERIENCE_TOTAL = "application_workexperience_total",
    APPLICATION_SPOKEN_LANGUAGES = "application_spoken_languages",
    APPLICATION_SHARED_POOL_CONSENT = "application_shared_pool_consent",
}

export default {
    [TranslationKeys.APPLICATION_POSITION]: [
        ["Bakery", "Backstube"],
        ["Retail Assistant", "Laden"],
        ["Front of House", "Café und Restaurant"],
        ["Kitchen", "Küche"],
        ["Administrative", "Büro"],
    ],
    // TODO: to be deprecated in favour of application_position_detail when we have our own form (but we still need to map this from the typeform)
    // map to application_position_detail
    [TranslationKeys.APPLICATION_POSITION_BAKERY]: [
        ["Baker", "Bäcker/in"],
        ["Pastry chef", "Konditor/in"],
        ["Baking assistant", "Aushilfe"],
        ["Dishwasher", "Spüler/in"],
    ],
    // TODO: to be deprecated in favour of application_position_detail when we have our own form (but we still need to map this from the typeform)
    // map to application_position_detail
    [TranslationKeys.APPLICATION_POSITION_RETAIL]: [
        ["Retail assistant", "Verkäufer/in"],
        ["Icecream seller", "Eisverkäufer/in"],
    ],
    // TODO: to be deprecated in favour of application_position_detail when we have our own form (but we still need to map this from the typeform)
    // map to application_position_detail
    [TranslationKeys.APPLICATION_POSITION_FOH]: [
        ["Server", "Servicemitarbeiter/in"],
        ["Bar/Buffet server", "Bar/Buffetmitarbeiter/in"],
    ],
    // TODO: to be deprecated in favour of application_position_detail when we have our own form (but we still need to map this from the typeform)
    // map to application_position_detail
    [TranslationKeys.APPLICATION_POSITION_BOH]: [
        ["Cook", "Koch/Köchin"],
        ["Dishwasher", "Spüler/in"],
    ],
    // TODO: to be deprecated in favour of application_position_detail when we have our own form (but we still need to map this from the typeform)
    // map to application_position_detail
    [TranslationKeys.APPLICATION_POSITION_ADMIN]: [
        ["Bookkeeper", "Personal und Lohnbuchhaltung"],
        ["Office assistant", "Büro Aushilfe"],
    ],
    [TranslationKeys.APPLICATION_POSITION_DETAIL]: [
        ["Bookkeeper", "Personal und Lohnbuchhaltung"],
        ["Office assistant", "Büro Aushilfe"],
        ["Cook", "Koch/Köchin"],
        ["Dishwasher", "Spüler/in"],
        ["Server", "Servicemitarbeiter/in"],
        ["Bar/Buffet server", "Bar/Buffetmitarbeiter/in"],
        ["Retail assistant", "Verkäufer/in"],
        ["Icecream seller", "Eisverkäufer/in"],
        ["Baker", "Bäcker/in"],
        ["Pastry chef", "Konditor/in"],
        ["Baking assistant", "Aushilfe"],
        ["Dishwasher", "Spüler/in"],
    ],
    // migrating to this
    [TranslationKeys.APPLICATION_WORKHOURS]: [
        ["Full-time", "Vollzeit", "Fuldtid"],
        ["Part-time", "Teilzeit", "Deltid"],
        ["450 Euro base", "450€ Basis"],
        ["Apprenticeship", "Lehrstelle/Ausbildung"],
    ],
    [TranslationKeys.APPLICATION_AVAILABILITY_YEAR]: [
        ["For one season", "Nur fuer eine Saison"],
        ["All year round", "Das ganze Jahr"],
    ],
    [TranslationKeys.APPLICATION_WORKEXPERIENCE_TOTAL]: [
        ["0-1 years", "0-1 Jahre"],
        ["1-2 years", "1-2 Jahre"],
        ["2-3 years", "2-3 Jahre"],
        ["3-5 years", "3-5 Jahre"],
        ["4-5 years", "4-5 Jahre"],
        ["5+ years", "5+ Jahre"],
    ],
    [TranslationKeys.APPLICATION_SPOKEN_LANGUAGES]: [
        ["German", "Deutsch"],
        ["English", "Englisch"],
        ["Danish", "Dänisch"],
    ],
    [TranslationKeys.APPLICATION_SHARED_POOL_CONSENT]: [
        ["Yes, please.", "Ja, bitte"],
        ["No.", "Nein"],
    ],
};
