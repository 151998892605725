import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";

import { StyleGuide } from "@constants/StyleGuide";

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: ${StyleGuide.spacing.mini}px;
`;

export const EmptyStyledSlider = styled(ReactSlider)`
    width: 100%;
    height: ${StyleGuide.spacing.mini}px;
    background: ${StyleGuide.palette.baseSeaSalt};
    border-radius: ${StyleGuide.spacing.base}px;
`;

const StyledThumb = styled.div`
    height: ${StyleGuide.spacing.small}px;
    width: ${StyleGuide.spacing.small}px;
    background-color: ${StyleGuide.palette.secondaryCream};

    border: ${StyleGuide.spacing.micro}px solid ${StyleGuide.palette.baseSalt};
    box-sizing: border-box;
    box-shadow: 0px 3px 9px -2px rgba(0, 0, 0, 0.25);
    border-radius: ${StyleGuide.spacing.base}px;
    cursor: grab;
    top: -${StyleGuide.spacing.micro}px;

    :focus-visible {
        outline: none;
    }
`;

export const Thumb = props => <StyledThumb {...props} />;

export const EmptyThumb = props => <div {...props} />;

interface StyledTrackProps {
    index?: number;
}

const StyledTrack = styled.div<StyledTrackProps>`
    top: 0;
    bottom: 0;
    background: ${props =>
        props?.index === 2
            ? StyleGuide.palette.baseSeaSalt
            : props?.index === 1
            ? StyleGuide.palette.secondaryBasil
            : StyleGuide.palette.baseSeaSalt};
    border-radius: 999px;
`;

const EmptyStyledTrack = styled.div<StyledTrackProps>`
    top: 0;
    bottom: 0;
    background: ${props =>
        props?.index === 2
            ? StyleGuide.palette.baseSeaSalt
            : props?.index === 1
            ? StyleGuide.palette.baseVanilla
            : StyleGuide.palette.baseSeaSalt};
    z-index: 5;
    border-radius: 999px;
`;

export const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

export const EmptyTrack = (props, state) => (
    <EmptyStyledTrack {...props} index={state.index} />
);
