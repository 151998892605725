import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, HoorayIcon } from "./styled";

const ContractInfoTemplate = () => {
    //TODO: Use method above "getInterviewTimeSlotsText" to get description text based on timeslots list
    return (
        <TemplateBubbleContainer>
            <ActionIconText
                title="Sent contract info!"
                description="Added to your team"
                Icon={<HoorayIcon />}
            />

            {/* <TemplateBubbleMessage>{message}</TemplateBubbleMessage> */}
        </TemplateBubbleContainer>
    );
};

export default ContractInfoTemplate;
