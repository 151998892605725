import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface NotificationState {
    homeAllCount: number;
    applicantsCount: number;
    shortlistCount: number;
    contactedlistCount: number;
    rejectedlistCount: number;
}

// Define the initial state using that type
const initialState: NotificationState = {
    homeAllCount: 0,
    applicantsCount: 0,
    shortlistCount: 0,
    contactedlistCount: 0,
    rejectedlistCount: 0,
};

export const countSlice = createSlice({
    name: "counts",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setHomeAllCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                homeAllCount: action.payload,
            };
        },
        setApplicantsCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                applicantsCount: action.payload,
            };
        },
        setShortlistCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                shortlistCount: action.payload,
            };
        },
        setContactedlistCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                contactedlistCount: action.payload,
            };
        },
        setRejectedlistCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                rejectedlistCount: action.payload,
            };
        },
        setHomePositionCount(
            state,
            action: PayloadAction<{ position: string; count: number }>,
        ) {
            return {
                ...state,
                [action.payload.position]: action.payload.count,
            };
        },
    },
});

export const {
    setHomeAllCount,
    setHomePositionCount,
    setApplicantsCount,
    setShortlistCount,
    setContactedlistCount,
    setRejectedlistCount,
} = countSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectApplicantCount = (state: RootState) => state.count.applicantsCount;
export const selectShortlistCount = (state: RootState) => state.count.shortlistCount;
export const selectContactedlistCount = (state: RootState) =>
    state.count.contactedlistCount;
export const selectRejectedlistCount = (state: RootState) =>
    state.count.rejectedlistCount;

export default countSlice.reducer;
