import React from "react";

import TemplatesList from "./TemplatesList";
import IntroTemplate from "./IntroTemplate";
import InterviewTemplate from "./InterviewTemplate";
import TrialTemplate from "./TrialTemplate";
import RejectTemplate from "./RejectTemplate";
import OnboardTemplate from "./OnboardTemplate";
import { Container } from "./styled";
import { useTemplates } from "../../../features/templates/useTemplates";
import { TemplateType } from "@anna/shared";

const TemplatesToolbar = ({
    isModalChat,
    activeTemplate,
    onTemplateSelect,
    closeTemplate,
    sendTemplate,
    onTemplateSendRef,
    BackToProfileButton,
    setTemplateVariables,
    templateText,
    resetTemplate,
}) => {
    const { initialLoading } = useTemplates();
    const templateProps = {
        closeTemplate,
        sendTemplate,
        onTemplateSendRef,
        setTemplateVariables,
        templateText,
        resetTemplate,
    };
    const renderActiveTemplate = () => {
        switch (activeTemplate) {
            case TemplateType.INTRO:
                return <IntroTemplate {...templateProps} />;
            case TemplateType.REJECTION:
                return <RejectTemplate {...templateProps} />;
            case TemplateType.INTERVIEW_INVITE:
                return <InterviewTemplate {...templateProps} />;
            case TemplateType.TRIAL_INVITE_WO_FILE:
                return <TrialTemplate {...templateProps} />;
            case TemplateType.HIRED:
                return <OnboardTemplate {...templateProps} />;
            default:
                return null;
        }
    };

    if (initialLoading) {
        return <Container>Loading templates...</Container>;
    }

    const activeTemplateRender = renderActiveTemplate();

    if (activeTemplateRender) {
        return <Container topMargin={isModalChat}>{activeTemplateRender}</Container>;
    }

    return (
        <Container>
            <TemplatesList
                onTemplateSelect={onTemplateSelect}
                BackToProfileButton={BackToProfileButton}
            />
        </Container>
    );
};

export default TemplatesToolbar;
