import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderIntroTemplateDE({
    applicantName = TemplateVariables.APPLICANT_NAME,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hi ${applicantName},

vielen Dank für deine Bewerbung bei ${restaurantTitle}. Deine Bewerbung hat uns überzeugt, wann hättest du Zeit fuer ein persönliches Gespräch?

Beste Grüße
${senderFirstName}`;
}
