import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import { ReactComponent as LongArrowRightIcon } from "@assets/svg/long_arrow_right.svg";
import { ReactComponent as StarOutlinedIcon } from "@assets/svg/star_outlined.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { StyleGuide } from "@constants/StyleGuide";

export const ApplicantActionsContainer = styled.div`
    width: 280px;
    border-left: 1px solid ${StyleGuide.palette.baseSugar};
    padding: ${StyleGuide.spacing.medium}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.small}px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const Title = styled.div`
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
    margin: 0 0 ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px;
`;

export const Description = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
    margin: 0 0 ${StyleGuide.spacing.small}px ${StyleGuide.spacing.mini}px;
`;

export const MoveActionContainer = styled.button`
    padding: ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.mini}px;
    border-radius: ${StyleGuide.spacing.mini}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: transparent;

    :hover {
        background-color: ${StyleGuide.palette.baseSugar};
    }
`;

interface ActionTitleProps {
    isHovered?: boolean;
}

interface IconProps {
    isFocused?: boolean;
}

export const ActionTitle = styled.div<ActionTitleProps>`
    display: flex;
    align-items: center;
    ${StyleGuide.typography.body2};
    color: ${props =>
        props.isHovered ? StyleGuide.palette.basePepper : StyleGuide.palette.coal};
`;

export const ActionIcon = styled.div`
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    margin-right: ${StyleGuide.spacing.mini}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MoveLabel = styled.div`
    ${StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.coal};
`;

export const LongArrowIcon = styled(LongArrowRightIcon)<IconProps>`
    ${props => (props.isFocused ? `fill: ${StyleGuide.palette.secondaryBasil}` : "")}
`;

export const StarIcon = styled(StarOutlinedIcon)<IconProps>`
    ${props => (props.isFocused ? `fill: ${StyleGuide.palette.primaryAnanas}` : "")}
`;

export const ActionsSeparator = styled.div`
    height: 1px;
    background-color: ${StyleGuide.palette.baseSugar};
    margin: ${StyleGuide.spacing.small}px 0;
`;

export const PlusIcon = styled(CloseIcon)<IconProps>`
    width: ${StyleGuide.spacing.mini}px;
    height: ${StyleGuide.spacing.mini}px;
    fill: ${props => (props.isFocused ? StyleGuide.palette.secondaryBasil : "#d0d0d0")};
    transform: rotate(45deg);
`;

export const CtaContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
`;

interface CtaButtonProps {
    isPrimary?: boolean;
}

export const CtaButton = styled.button<CtaButtonProps>`
    padding: ${StyleGuide.spacing.xsmall}px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: ${StyleGuide.spacing.mini}px;
    margin-top: ${StyleGuide.spacing.mini}px;
    background-color: ${props =>
        props.isPrimary
            ? StyleGuide.palette.secondaryBasil
            : StyleGuide.palette.baseSugar};
    cursor: pointer;
    border: none;
`;

export const CtaIcon = styled.div`
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: ${StyleGuide.spacing.xsmall}px;
`;

export const SendButtonText = styled.div`
    ${StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.baseSalt};
`;

export const BackIcon = styled(ArrowUp)`
    transform: rotate(-90deg);
`;

export const BackToProfileTitle = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
`;

export const CustomRejectTitle = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
    display: flex;
    align-items: center;
`;

export const CustomRejectSubTitle = styled.div`
    ${StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.coal};
    margin-left: ${StyleGuide.spacing.micro}px;
`;

export const CustomRejectContainer = styled.div`
    background: ${StyleGuide.palette.baseSalt};

    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07),
        0px 20px 60px -16px rgba(134, 134, 134, 0.35);
    border-radius: ${StyleGuide.spacing.mini}px;
    position: absolute;
    margin-left: -20px;
    top: -${StyleGuide.spacing.mini}px;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.xsmall}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 324px;
    width: 289px;
`;

export const CloseButton = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ${StyleGuide.spacing.mini}px;
    right: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
`;

export const PopupTitle = styled.div`
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
    margin-bottom: ${StyleGuide.spacing.small}px;
`;

export const PopupNote = styled.div`
    ${StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.baseVanilla};
    text-align: center;
`;

const focusedInput = `
    background: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.secondaryMint};
    box-shadow: 0px 0px 0px 3px #eef5f5;

    ::-webkit-scrollbar {
        width: 14px;
        background: ${StyleGuide.palette.baseSalt};
        border-bottom-right-radius: ${StyleGuide.spacing.mini}px;
        border-top-right-radius: ${StyleGuide.spacing.mini}px;
    }

    scrollbar-color: ${StyleGuide.palette.baseSeaSalt} ${StyleGuide.palette.baseSalt};
`;

interface CustomRejectInputProps {
    isFocused?: boolean;
}

export const CustomRejectInput = styled(TextareaAutosize)<CustomRejectInputProps>`
    display: flex;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    min-height: ${StyleGuide.spacing.base}px;

    padding: ${StyleGuide.spacing.mini}px;
    margin-bottom: ${StyleGuide.spacing.small}px;

    background: ${StyleGuide.palette.baseSugar};
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${StyleGuide.palette.baseVanilla};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${StyleGuide.palette.baseVanilla};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${StyleGuide.palette.baseVanilla};
    }

    border-radius: ${StyleGuide.spacing.mini}px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    border: 1px solid transparent;

    ::-webkit-scrollbar {
        width: 14px;
        background: ${StyleGuide.palette.baseSugar};
        border-bottom-right-radius: ${StyleGuide.spacing.mini}px;
        border-top-right-radius: ${StyleGuide.spacing.mini}px;
    }
    ::-webkit-scrollbar-thumb {
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: ${StyleGuide.spacing.mini}px;
        background-color: ${StyleGuide.palette.baseSeaSalt};
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    /* Mozilla Firefox support */
    scrollbar-width: thin;
    scrollbar-color: ${StyleGuide.palette.baseSeaSalt} ${StyleGuide.palette.baseSugar};

    ${props => (props.isFocused ? focusedInput : "")};
    :hover {
        ${focusedInput}
    }
`;

export const PlandayPopupContainer = styled.div`
    background: ${StyleGuide.palette.baseSalt};

    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07),
        0px 20px 60px -16px rgba(134, 134, 134, 0.35);
    border-radius: ${StyleGuide.spacing.mini}px;
    position: absolute;
    margin-left: -28px;
    margin-top: 65px;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.base}px
        ${StyleGuide.spacing.base}px ${StyleGuide.spacing.base}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    height: 440px;
    width: 300px;
`;

export const PlandayFormContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.micro}px 0 0;
`;

export const PlandaySectionTitle = styled.div`
    ${StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.basePepper};
    margin-bottom: ${StyleGuide.spacing.micro}px;
`;

export const PlandaySectionInfo = styled.div`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.coal};
    margin-bottom: ${StyleGuide.spacing.xsmall}px;
`;

export const PlandayDropdown = styled.div`
    margin: ${StyleGuide.spacing.xsmall}px 0 ${StyleGuide.spacing.small}px 0;
`;

export const PlandayGenderPicker = styled.div`
    display: flex;
    align-items: center;
`;

interface PlandayGenderItemProps {
    isSelected?: boolean;
}

export const PlandayGenderItem = styled.div<PlandayGenderItemProps>`
    border: 1px solid
        ${props =>
            props.isSelected ? StyleGuide.palette.primary : StyleGuide.palette.baseSeaSalt};
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.primary : StyleGuide.palette.baseSalt};

    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.mini}px;
    height: ${StyleGuide.spacing.large}px;
    ${StyleGuide.typography.body1};
    color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSalt : StyleGuide.palette.coal};
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const PlandayGenderSeparator = styled.div`
    width: ${StyleGuide.spacing.small}px;
`;

interface PlandaySubmitButtonProps {
    isDisabled?: boolean;
}

export const PlandaySubmitButton = styled.div<PlandaySubmitButtonProps>`
    width: 100%;
    height: ${StyleGuide.spacing.large}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${StyleGuide.spacing.mini}px;
    position: relative;
    ${props =>
        props.isDisabled ? StyleGuide.typography.body2 : StyleGuide.typography.headline2};
    background-color: ${props =>
        props.isDisabled
            ? StyleGuide.palette.baseSugar
            : StyleGuide.palette.secondaryBasil};
    color: ${props =>
        props.isDisabled ? StyleGuide.palette.muted : StyleGuide.palette.baseSalt};

    cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};

    svg:nth-child(1) {
        fill: ${props =>
            props.isDisabled
                ? StyleGuide.palette.baseSeaSalt
                : StyleGuide.palette.baseSalt};
    }
`;

export const PlandaySubmitButtonIcon = styled.div`
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    position: absolute;
    left: ${StyleGuide.spacing.xsmall}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
