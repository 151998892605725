import { css } from "styled-components";

export enum Space {
    micro = 4,
    mini = 8,
    xsmall = 12,
    small = 16,
    base = 24,
    medium = 32,
    large = 40,
    xlarge = 48,
}

const spacing = {
    micro: Space.micro,
    mini: Space.mini,
    xsmall: Space.xsmall,
    small: Space.small,
    base: Space.base,
    medium: Space.medium,
    large: Space.large,
    xlarge: Space.xlarge,
};

const palette = {
    primaryAnanas: "#FFB432",
    lemon: "#ffcf7c",
    egg: "#fff6e7",
    primary: "#4c9999",
    secondaryBasil: "#4c9999",
    cucumber: "#0b7375",
    secondaryCucumber: "#0B7375",
    secondary: "rgb(76,153,153)",
    backgroundPrimary: "#d5e5ff",
    secondaryMilk: "#EEF5F5",
    secondaryMintAlpha: "rgba(222,236,236, 0.2)",

    secondaryCream: "#F8FBFB",
    veryLightBorder: "rgba(102, 102, 102, 0.05)",
    error: "#e46161",
    danger: "#ee6464",
    baseSugar: "#F8F8F8",
    sugar: "#FAFAFA",
    white: "#fff",
    black: "#000",
    baseSeaSalt: "#EDEDED",
    border: "#f2f2f2",
    background: "#f2f2f2",
    baseMsg: "#F5F5F5",
    baseSalt: "#FFFFFF",
    light: "#ffffff",
    secondaryMint: "#deecec",
    systemMessageBg: "rgba(237, 237, 237, 0.2)",
    tabIconDefault: "#ccc",
    muted: "#c1c1c1",
    // OldSchool
    vanilla: "#839090",
    baseVanilla: "#808080",
    coal: "#535C5C",
    basePepperAlpha: (alpha: number) => `rgba(36,40,40,${alpha})`,
    basePepper: "#242828",
    body: "#4f4f4f",
    // integration
    plandayBlue: "#199BD7",
};

const typography = {
    largeTitle1: css`
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: -0.015em;
        color: ${palette.basePepper};
    `,
    title: css`
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.0015em;
        color: ${palette.basePepper};
    `,
    titleMobile: css`
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0015em;
        color: ${palette.basePepper};
    `,
    body1: css`
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0025em;
        color: ${palette.vanilla};
    `,
    body2: css`
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0125em;
    `,
    footnote: css`
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
    `,
    headline1: css`
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
    `,
    headline2: css`
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0125em;
    `,
    detail1: css`
        font-family: "Roboto", sans-serif;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.02em;
    `,
};

export const StyleGuide = {
    spacing,
    palette,
    typography,
};

type PaletteColorNames = keyof typeof StyleGuide.palette;
export type PaletteColors = typeof StyleGuide.palette[PaletteColorNames];
