import React from "react";

import {
    TimeSlotsInfo,
    TimeSlotsWarning,
    WarningContainer,
    WarningTriangle,
    ResetTemplateAddonContainer,
    AddonTitle,
    AddonDescription,
    AddonResetButton,
} from "./styled";

export const TimeSlotsWarningMessage = () => {
    return (
        <TimeSlotsWarning>
            <WarningContainer>You need to select a time slot</WarningContainer>

            <WarningTriangle />
        </TimeSlotsWarning>
    );
};

export const TimeSlotsInfoMessage = () => {
    return (
        <TimeSlotsInfo>
            <div>1. Select timeslots{"\n"}</div>
            <div>2. Applicants receives a link and picks a time{"\n"}</div>
            <div>3. We add the time to your and their calendar{"\n"}</div>
        </TimeSlotsInfo>
    );
};

export const ResetTemplateAddon = ({ title, description, resetTemplate }) => {
    return (
        <ResetTemplateAddonContainer>
            <AddonTitle>{title}</AddonTitle>

            <AddonDescription>{description}</AddonDescription>

            <AddonResetButton onClick={resetTemplate}>Reset template</AddonResetButton>
        </ResetTemplateAddonContainer>
    );
};
