import styled from "styled-components";
import { List } from "react-virtualized";

import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";
import { ReactComponent as TimeIcon } from "@assets/svg/time.svg";
import { ReactComponent as HalfTimeIcon } from "@assets/svg/half_time.svg";

export const rowHeight = 94;

export const Container = styled.div`
    min-width: 367px;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    flex: 1;
    margin-right: ${StyleGuide.spacing.small}px;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    overflow: hidden;
`;

export const ListContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px;
    background-color: ${StyleGuide.palette.baseSalt};
`;

export const VListContainer = styled(List)`
    ${CustomScrollBar}
`;

type ToggleHeaderProps = {
    isOpened?: boolean;
};

export const ToggleHeader = styled.div<ToggleHeaderProps>`
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.small}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: ${StyleGuide.palette.baseSalt};
    border-bottom-left-radius: ${props =>
        props.isOpened ? 0 : StyleGuide.spacing.xsmall}px;
    border-bottom-right-radius: ${props =>
        props.isOpened ? 0 : StyleGuide.spacing.xsmall}px;
`;

type ArrowIconProps = {
    isOpened?: boolean;
};

export const ArrowIcon = styled.div<ArrowIconProps>`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.isOpened ? "rotate(0deg)" : "rotate(180deg)")};
`;

export const CategoryContainer = styled.div`
    display: flex;
    align-items: center;
`;

type CountContainerProps = {
    background: string;
};

export const CountContainer = styled.div<CountContainerProps>`
    padding: 1px ${StyleGuide.spacing.mini}px;
    border-radius: ${StyleGuide.spacing.mini}px;
    background-color: ${props => props.background};
    color: ${StyleGuide.palette.baseSalt};
    ${() => StyleGuide.typography.headline1};
`;

export const CategoryTitle = styled.div`
    margin-left: ${StyleGuide.spacing.mini}px;
    color: ${StyleGuide.palette.coal};
    ${() => StyleGuide.typography.headline1};
`;

export const ApplicantListItem = styled.div`
    box-sizing: border-box;
    padding-right: ${StyleGuide.spacing.mini}px;
`;

type ApplicantCardContainerProps = {
    selected?: boolean;
};

export const ApplicantCardContainer = styled.div<ApplicantCardContainerProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.mini}px;
    height: ${rowHeight};
    cursor: pointer;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    background-color: ${props =>
        props.selected ? StyleGuide.palette.baseMsg : StyleGuide.palette.baseSalt};
    :hover {
        background-color: ${props =>
            props.selected ? StyleGuide.palette.baseMsg : StyleGuide.palette.baseSugar};
    }
`;

export const ApplicantInfo = styled.div`
    display: flex;
`;

export const ApplicantPicture = styled.img`
    object-fit: cover;
    width: ${StyleGuide.spacing.large}px;
    height: ${StyleGuide.spacing.large}px;
    border-radius: ${StyleGuide.spacing.base}px;
    margin-right: ${StyleGuide.spacing.small}px;
`;

type ApplicantNameProps = {
    hasNotification?: boolean;
};

export const ApplicantName = styled.div<ApplicantNameProps>`
    ${props =>
        props.hasNotification
            ? StyleGuide.typography.headline1
            : StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};

    text-align: left;
`;

export const ApplicantStatus = styled.div<ApplicantNameProps>`
    ${props =>
        props.hasNotification
            ? StyleGuide.typography.headline2
            : StyleGuide.typography.body2};
    color: ${props =>
        props.hasNotification ? StyleGuide.palette.basePepper : StyleGuide.palette.coal};
    text-align: left;
    word-break: break-word;
`;

export const ApplicantNewFlag = styled.div`
    width: ${StyleGuide.spacing.xsmall}px;
    height: ${StyleGuide.spacing.xsmall}px;
    border-radius: 6px;
    background-color: ${StyleGuide.palette.secondaryCucumber};
`;

export const ApplicantBubblesRow = styled.div`
    display: flex;
    margin-top: ${StyleGuide.spacing.micro}px;
`;

export const ApplicantRestaurantBubble = styled.div`
    padding: 0px ${StyleGuide.spacing.mini}px;
    background: ${StyleGuide.palette.secondaryMilk};
    border-radius: ${StyleGuide.spacing.small}px;
    margin-right: ${StyleGuide.spacing.mini}px;
    color: ${StyleGuide.palette.secondaryCucumber};
    ${() => StyleGuide.typography.footnote};
`;

export const ApplicantRestaurantRole = styled.div`
    display: flex;
    align-items: center;
    padding: 0px ${StyleGuide.spacing.mini}px 0px ${StyleGuide.spacing.micro}px;
    background: ${StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.medium}px;
    color: ${StyleGuide.palette.baseVanilla};
    ${() => StyleGuide.typography.footnote};
`;

export const ApplicantFullTimeIcon = styled(TimeIcon)`
    width: 10px;
    height: 10px;
    margin-right: ${StyleGuide.spacing.micro}px;
`;
export const ApplicantPartTimeIcon = styled(HalfTimeIcon)`
    width: 10px;
    height: 10px;
    margin-right: ${StyleGuide.spacing.micro}px;
`;
