import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";

export const popUpBottomMargin = StyleGuide.spacing.mini;

export const popUpHeight = 344;

export const TimeSlotsPreviewContainer = styled.div`
    width: 325px;
    height: ${popUpHeight}px;
    background-color: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    box-shadow: 0px 16px 52px -18px rgba(0, 0, 0, 0.42);
    border-radius: ${StyleGuide.spacing.xsmall}px;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 100;
`;

export const PreviewHeader = styled.div`
    border-bottom: 1px solid ${StyleGuide.palette.baseSeaSalt};
    padding: ${StyleGuide.spacing.small}px;
`;

export const PreviewTitle = styled.div`
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
    margin-bottom: ${StyleGuide.spacing.mini}px;
`;

export const PreviewDescription = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseVanilla};
    text-align: left;
`;

export const PreviewListContainer = styled.div`
    display: flex;
    flex: 1;
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.mini}px 0
        ${StyleGuide.spacing.small}px;
    overflow: hidden;
`;

export const PreviewList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;

    ${CustomScrollBar}
`;

export const TimeSlotsPreviewItem = styled.div`
    margin-bottom: ${StyleGuide.spacing.base}px;
`;

export const TimeSlotsPreviewDate = styled.div`
    ${StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
`;

export const TimeSlotPreviewRow = styled.div`
    display: flex;
    align-items: center;
`;

export const TimeSlotPreviewTime = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 64px;
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    padding: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
    background-color: ${StyleGuide.palette.coal};
    margin: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px 0 0;
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseSalt};
`;
