import { useEffect, useState } from "react";
import * as ApplicationsAPI from "./api";

const TAG = "EnrichedData/hook";

export default function useEnrichedApplicantData(applicantId: string) {
    const [loading, setLoading] = useState(false);
    const [enrichedApplicantData, setEnrichedApplicantData] =
        useState<With.Id<Contracts.EnrichedApplicationData>>();
    useEffect(() => {
        let unsubscribe: null | Function = null;
        if (applicantId) {
            setLoading(true);
            unsubscribe = ApplicationsAPI.getEnrichedApplicantData(
                applicantId,
            ).onSnapshot(
                (query: firebase.firestore.QuerySnapshot) => {
                    const [enrichedData] = query.docs.map(doc => ({
                        id: doc.id,
                        ...(doc.data() as Contracts.EnrichedApplicationData),
                    }));
                    setEnrichedApplicantData(enrichedData);
                    setLoading(false);
                },
                error => {
                    console.error(`${TAG}: ${error}`);
                    setLoading(false);
                },
            );
        }

        // returning the unsubscribe function will ensure that
        // we unsubscribe from document changes when our id
        // changes to a different value.
        return () => unsubscribe?.();
    }, [applicantId]);
    return { enrichedApplicantData, loadingEnrichedApplicant: loading };
}
