import {
    setWorkhoursFilter as setHomeWorkhoursFilter,
    setVisaFilter as setHomeVisaFilter,
} from "../features/filters/homeFilterReducer";
import {
    setWorkhoursFilter as setInboxWorkhoursFilter,
    setVisaFilter as setInboxVisaFilter,
} from "../features/filters/inboxFilterReducer";
import { resetPagination } from "../features/pagination/reducer";
import { resetLists } from "../features/funnels/reducer";
import { setSelectedRestaurants } from "../features/restaurants/reducer";
import { setFocusedPosition } from "../features/userPositions/reducer";

export const resetPaginationOnFilterUpdateMiddleware = storeAPI => next => action => {
    switch (action.type) {
        case setHomeWorkhoursFilter.type:
        case setHomeVisaFilter.type:
        case setInboxWorkhoursFilter.type:
        case setInboxVisaFilter.type:
        case setSelectedRestaurants.type:
        case setFocusedPosition.type:
            storeAPI.dispatch(resetLists());
            storeAPI.dispatch(resetPagination());
            break;
        default:
            break;
    }

    return next(action);
};
