import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderInterviewInviteTemplateEN({
    applicantName = TemplateVariables.APPLICANT_NAME,
    interviewLocation = TemplateVariables.LOCATION,
    inviteLink = TemplateVariables.TIME_SLOTS,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hey ${applicantName},

Thanks for your application! Do you have time to come in for an interview at ${restaurantTitle}?

Here is my availability, just pick a time: ${inviteLink}

This is the address: ${interviewLocation}

Best,
${senderFirstName}`;
}
