import React, { useState, useRef } from "react";
import ChatComponent from "@components/ChatFeed";
import ChatHeader from "./ChatHeader";
import InputToolbar from "./InputToolbar";
import { ChatComponentContainer, ChatContainer, EmptyChat, EmptyText } from "./styled";
import { useAppSelector } from "../../../store/store";
import { useMarkNotificationAsSeen } from "../../../features/notifications/useMarkNotificationAsSeen";

const ChatView = ({ sendNewMessage, renderTemplateInput, isModalChat }) => {
    const scrollBottomRef = useRef();
    const funnelId = useAppSelector(state => state.funnels.focusedChat);
    useMarkNotificationAsSeen(funnelId);
    return (
        <ChatComponentContainer>
            {!funnelId ? (
                <EmptyChat>
                    <EmptyText>Select a chat in the list to the left</EmptyText>
                </EmptyChat>
            ) : (
                <>
                    <ChatHeader isModalChat={isModalChat} />

                    <ChatContainer>
                        <ChatComponent scrollBottomRef={scrollBottomRef} />
                    </ChatContainer>
                </>
            )}

            {renderTemplateInput ? (
                renderTemplateInput({ scrollBottomRef })
            ) : (
                <InputToolbar
                    sendNewMessage={sendNewMessage}
                    scrollBottomRef={scrollBottomRef}
                />
            )}
        </ChatComponentContainer>
    );
};

export default ChatView;
