import styled from "styled-components";
import { List } from "react-virtualized";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    top: 0;
    margin: 0;
`;

export const SearchToggleBlock = styled.div`
    width: ${StyleGuide.spacing.large}px;
    height: ${StyleGuide.spacing.large}px;
    border-radius: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: ${StyleGuide.palette.baseMsg};
    }
`;

export const SearchBarExpanded = styled.div`
    height: ${StyleGuide.spacing.large}px;
    width: 13vw;
    background: ${StyleGuide.palette.baseMsg};
    border-radius: ${StyleGuide.spacing.medium}px;
    padding-right: ${StyleGuide.spacing.xsmall}px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 30px;
    @media (min-width: 1440px) {
        width: 240px;
    }
`;

export const SearchBarInput = styled.input`
    display: flex;
    flex: 1;
    border-width: 0;
    background: transparent;
    outline: none;
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
`;

export const SearchClearButton = styled.div`
    position: absolute;
    right: 12px;
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    border-radius: ${StyleGuide.spacing.small}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${StyleGuide.palette.baseVanilla};
`;

export const DropdownContainer = styled.div`
    height: 384px;
    width: 336px;
    margin: 0;
    position: absolute;
    top: ${StyleGuide.spacing.xlarge}px;
    background: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    box-shadow: 0px 12px 44px -8px rgba(0, 0, 0, 0.25);
    border-radius: ${StyleGuide.spacing.xsmall}px;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.micro}px;
    overflow: hidden;

    z-index: 100;
`;

export const VListContainer = styled(List)`
    ::-webkit-scrollbar {
        width: ${StyleGuide.spacing.micro}px;
        background: ${StyleGuide.palette.baseSalt};
    }

    ::-webkit-scrollbar-track {
        border-radius: ${StyleGuide.spacing.micro}px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${StyleGuide.palette.baseSeaSalt};
        border-radius: ${StyleGuide.spacing.micro}px;
    }

    /* Mozilla Firefox support */
    scrollbar-width: thin;
    scrollbar-color: ${StyleGuide.palette.baseSeaSalt} ${StyleGuide.palette.baseSalt};
`;

export const ApplicantListItem = styled.div`
    box-sizing: border-box;
    padding-right: ${StyleGuide.spacing.mini}px;
`;
