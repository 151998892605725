import React, { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { ReactComponent as Phone } from "@assets/svg/phone.svg";
import { PhoneNumberContainer } from "./styled";
import { useAppSelector } from "../../store/store";

const PhoneNumber = () => {
    const [isExpanded, toggleExpanded] = useState(false);
    const applicantId = useAppSelector(
        state =>
            state.funnels.funnelMap[state.funnels.focusedProfile as string]?.applicantId,
    );
    const phoneNumber = useAppSelector(
        state => state.applications.applicationMap[applicantId]?.phoneNumber,
    );
    return (
        <PhoneNumberContainer
            onMouseEnter={() => toggleExpanded(true)}
            onMouseLeave={() => toggleExpanded(false)}
            isExpanded={isExpanded}>
            {isExpanded && phoneNumber ? formatPhoneNumberIntl(phoneNumber) : <Phone />}
        </PhoneNumberContainer>
    );
};

export default PhoneNumber;
