import { useEffect } from "react";
import * as RestaurantsAPI from "./api";
import { setRestaurants } from "./reducer";
import { useAppSelector, useAppDispatch } from "../../store/store";

export function useRestaurants() {
    const userId = useAppSelector(state => state.user.uid);
    const dispatch = useAppDispatch();
    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (userId) {
            // @ts-ignore
            unsubscribe = RestaurantsAPI.getRestaurantsByUserId(userId).onSnapshot(
                query => {
                    const restaurants = query.docs.map(
                        doc =>
                            ({
                                id: doc.id,
                                ...doc.data(),
                            } as Contracts.DBRestaurantShape),
                    );

                    dispatch(setRestaurants(restaurants));
                },
                err => {
                    // TODO: add error logging with sentry
                    console.log(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [userId]);
}
