import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";

const TAG = "Firestore_ActivityEvents";

const MaxActivityEventsToLoad = 100;

export const getUserActivityEvents = (uid: string, rid: string) =>
    firestore
        .collection(COLLECTIONS.USERS)
        .doc(uid)
        .collection(COLLECTIONS.USER_ACTIVITY)
        .where("restaurantId", "==", rid)
        .orderBy("createTime", "desc")
        .limit(MaxActivityEventsToLoad);

export const getUserActivityEventsAfter = (uid: string, rid: string, date: Date) =>
    firestore
        .collection(COLLECTIONS.USERS)
        .doc(uid)
        .collection(COLLECTIONS.USER_ACTIVITY)
        .where("restaurantId", "==", rid)
        .where("createTime", ">", date)
        .orderBy("createTime", "desc");

export const getTeamActivityEvents = (rid: string) =>
    firestore
        .collection(COLLECTIONS.RESTAURANTS)
        .doc(rid)
        .collection(COLLECTIONS.RESTAURANT_TEAM_ACTIVITY)
        .orderBy("createTime", "desc")
        .limit(MaxActivityEventsToLoad);

export const markActivityEventAsSeen = (uid: string, activityEventId: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.USERS)
            .doc(uid)
            .collection(COLLECTIONS.USER_ACTIVITY)
            .doc(activityEventId)
            .update({ seen: true });
    } catch (error) {
        // TODO: add error logging
        // Logger.notify(`${TAG}: Failed to update activity event. ${error}`);
        // @ts-ignore
        throw new Error(error);
    }
};

export const updateUserLastSeenActivityCenter = (uid: string, date: Date) => {
    try {
        return firestore.collection(COLLECTIONS.USERS).doc(uid).set(
            {
                lastSeenActivityCenter: date,
            },
            { merge: true },
        );
    } catch (error) {
        // TODO: add error logging
        // Logger.notify(`${TAG}: Failed to update activity event. ${error}`);
        // @ts-ignore
        throw new Error(error);
    }
};
