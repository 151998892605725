import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../store/store";

import * as ActivityEventAPI from "./api";
import { ActivityEventType } from "@anna/shared";
import {
    fetchUserActivityEvents,
    fetchTeamActivityEvents,
    toggleUserRefreshing,
    toggleTeamRefreshing,
} from "./reducer";

const TAG = "ActivityEvents";

/**
 * * List of allowed user activity events
 * Only events of these types will be displayed, other are omitted
 */

const AllowedUserActivityEventTypes = [
    ActivityEventType.INTERVIEW_SCHEDULED,
    ActivityEventType.INTERVIEW_CANCELED,
    ActivityEventType.INTERVIEW_REQUESTED_RESCHEDULE,
    ActivityEventType.TRIAL_SCHEDULED,
    ActivityEventType.TRIAL_CANCELED,
    ActivityEventType.TRIAL_REQUESTED_RESCHEDULE,
    ActivityEventType.CONTRACT_CONFIRMED,

    // Shared talent pool
    ActivityEventType.CONTACTED_ACCEPTED, // An applicant accepted the shared talent pool introduction
];

/**
 * * List of allowed team activity events
 * Only events of these types will be displayed, other are omitted
 */
const AllowedTeamActivityEventTypes = [
    ActivityEventType.INTERVIEW_PENDING,
    ActivityEventType.INTERVIEW_CANCELED,
    ActivityEventType.INTERVIEW_RESCHEDULED,
    ActivityEventType.TRIAL_PENDING,
    ActivityEventType.TRIAL_CANCELED,
    ActivityEventType.TRIAL_RESCHEDULED,
    ActivityEventType.CONTRACT_PENDING,
    ActivityEventType.HIRED,
    ActivityEventType.REJECTED,
    ActivityEventType.BULK_REJECTED,
    ActivityEventType.MANUAL_RECRUITMENT_ON,
    ActivityEventType.MANUAL_RECRUITMENT_OFF,
    ActivityEventType.SYSTEM_RECRUITMENT_OFF,
    ActivityEventType.USER_CALL,
];

/**
 * Fetches user and team activity events and dispatches to store
 */
export function useActivityEvents() {
    const rid = useSelector<RootState, string | null>(
        state => state.restaurants.selectedActivityRestaurant,
    ) as string;
    const uid = useSelector<RootState, string>(state => state.user.uid);
    const dispatch = useDispatch();

    useEffect(() => {
        let unsubscribeUser: () => void | undefined;
        let unsubscribeTeam: () => void | undefined;

        if (rid && uid) {
            dispatch(toggleUserRefreshing(true));
            dispatch(toggleTeamRefreshing(true));
            unsubscribeUser = ActivityEventAPI.getUserActivityEvents(uid, rid).onSnapshot(
                query =>
                    dispatch(
                        fetchUserActivityEvents(
                            filterUserActivityEvents(
                                transformActivityEvent<
                                    Contracts.DBUserFunnelActivityEvent
                                >(query),
                            ),
                        ),
                    ),
                //error
                () => {
                    dispatch(toggleUserRefreshing(false));
                    // TODO: add error logging
                    // Logger.notify(
                    //     `${TAG}: Failed to fetch user activity events for user ${uid}. ${error}`,
                    // ),
                },
            );

            unsubscribeTeam = ActivityEventAPI.getTeamActivityEvents(rid).onSnapshot(
                query =>
                    dispatch(
                        fetchTeamActivityEvents(
                            filterTeamActivityEvents(
                                uid,
                                transformActivityEvent<Contracts.TeamActivityEvent>(
                                    query,
                                ),
                            ),
                        ),
                    ),
                //error
                () => {
                    dispatch(toggleTeamRefreshing(false));
                    // TODO: add error logging
                    // Logger.notify(
                    //     `${TAG}: Failed to fetch user activity events for user ${uid}. ${error}`,
                    // ),
                },
            );
        }

        return () => {
            unsubscribeUser?.();
            unsubscribeTeam?.();
        };
    }, [uid, rid, dispatch]);
}

export function transformActivityEvent<TActivityEvent extends Contracts.DBActivityEvent>(
    query,
): TActivityEvent[] {
    return query.docs.map(doc => {
        const data = doc.data();
        return {
            ...data,
            id: doc.id,
            createTime: data.createTime.toDate(),
            activityDate: data.activityDate?.toDate(),
        } as TActivityEvent;
    });
}

export function filterUserActivityEvents<
    TActivityEvent extends Contracts.DBActivityEvent
>(events: TActivityEvent[]) {
    return events.filter(event => AllowedUserActivityEventTypes.includes(event.type));
}

function filterTeamActivityEvents(uid: string, events: Contracts.TeamActivityEvent[]) {
    return events.filter(
        event =>
            event.userId !== uid && AllowedTeamActivityEventTypes.includes(event.type),
    );
}
