import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "@constants/routes";

import { WorkHourFilter, NotificationMetaType } from "@anna/shared";
import { useAppSelector } from "../../store/store";
import { timeDistanceInWords } from "../../helpers/dates";
import { getFunnelState } from "../../helpers/states";
import placeholderAvatar from "../../assets/images/placeholder_avatar.png";
import {
    ApplicantCardContainer,
    ApplicantInfo,
    ApplicantPicture,
    ApplicantName,
    ApplicantStatus,
    ApplicantNewFlag,
    ApplicantBubblesRow,
    ApplicantRestaurantBubble,
    ApplicantRestaurantRole,
    ApplicantFullTimeIcon,
    ApplicantPartTimeIcon,
    rowHeight,
} from "./styled";

interface Props {
    id: string;
    extraStyle?: {};
    selected?: boolean;
    onPress: () => void;
    displayNewApplicantNotifications?: boolean;
}

const ApplicantCard = ({
    id,
    extraStyle = {},
    selected = false,
    onPress = () => {},
    displayNewApplicantNotifications = false,
}: Props) => {
    const isHome = useRouteMatch(Routes.HOME);

    const funnel = useAppSelector(state => state.funnels.funnelMap[id]);
    const restaurantTitle = useAppSelector(
        state => state.restaurants.restaurantMap?.[funnel?.restaurantId]?.title,
    );
    const hasNotification = useAppSelector(state =>
        displayNewApplicantNotifications
            ? state.notifications.notificationFunnelIdMap[id]
            : state.notifications.notificationByType?.[
                  NotificationMetaType.NEW_MESSAGE
              ]?.find(n => n.funnelId == id),
    );
    const userId = useAppSelector(state => state.user.uid);

    const renderTimeIcon = (workhourFilter: WorkHourFilter[] = []) => {
        if (
            workhourFilter?.includes(WorkHourFilter.FULL_TIME) &&
            workhourFilter?.includes(WorkHourFilter.PART_TIME)
        ) {
            return (
                <>
                    <ApplicantFullTimeIcon />
                    <ApplicantPartTimeIcon />
                </>
            );
        }
        if (workhourFilter?.includes(WorkHourFilter.FULL_TIME))
            return <ApplicantFullTimeIcon />;
        if (workhourFilter?.includes(WorkHourFilter.PART_TIME))
            return <ApplicantPartTimeIcon />;
        return null;
    };

    return (
        <ApplicantCardContainer style={extraStyle} selected={selected} onClick={onPress}>
            <ApplicantInfo>
                <ApplicantPicture
                    src={funnel?.userMetaProfileImage || placeholderAvatar}
                />

                <div>
                    <ApplicantName hasNotification={hasNotification}>
                        {funnel?.userMetaFirstName} {funnel?.userMetaLastName}
                    </ApplicantName>

                    <ApplicantStatus hasNotification={hasNotification}>
                        {getFunnelState({
                            lastActivity: funnel?.lastActivity,
                            state: funnel.state,
                            reasonRejected: funnel?.reasonRejected,
                            currentUserId: userId,
                            onlyDisplayLastAction: Boolean(isHome),
                        })}{" "}
                        • {timeDistanceInWords(funnel?.updateTime)}
                    </ApplicantStatus>

                    <ApplicantBubblesRow>
                        <ApplicantRestaurantBubble>
                            {restaurantTitle}
                        </ApplicantRestaurantBubble>

                        <ApplicantRestaurantRole>
                            {renderTimeIcon(funnel?.workhourFilter)}{" "}
                            {funnel?.userMetaPosition}
                        </ApplicantRestaurantRole>
                    </ApplicantBubblesRow>
                </div>
            </ApplicantInfo>

            {hasNotification && <ApplicantNewFlag />}
        </ApplicantCardContainer>
    );
};

export default ApplicantCard;
export { rowHeight };
