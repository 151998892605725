import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { PickerSelector, ArrowIcon, DropdownContainer, DropdownItem } from "./styled";
import { Loader } from "@routes//Auth/components/Buttons/styled";
import { StyleGuide } from "@constants/StyleGuide";

const TemplatesDropdown = ({
    loading,
    placeholder,
    selected,
    optionsList,
    getOptionLabel,
    getOptionKey,
    getOptionSelected,
    onOptionSelect,
    renderCustomOptions,
}) => {
    const [isDropdownExpanded, toggleDropdownExpanded] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const closeDropDown = () => {
        toggleDropdownExpanded(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closeDropDown();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <div ref={wrapperRef}>
            <PickerSelector
                onClick={() => !loading && toggleDropdownExpanded(!isDropdownExpanded)}>
                {getOptionLabel(selected) || placeholder}
                <ArrowIcon isExpanded={isDropdownExpanded}>
                    {loading ? (
                        <Loader color={StyleGuide.palette.secondaryBasil} />
                    ) : (
                        <ArrowUp />
                    )}
                </ArrowIcon>
            </PickerSelector>

            {isDropdownExpanded && (
                <DropdownContainer>
                    {optionsList.map(option => (
                        <DropdownItem
                            key={getOptionKey(option)}
                            isSelected={getOptionSelected(option)}
                            onClick={() => {
                                onOptionSelect(option);
                                closeDropDown();
                            }}>
                            {getOptionLabel(option)}
                        </DropdownItem>
                    ))}

                    {!!renderCustomOptions && renderCustomOptions(closeDropDown)}
                </DropdownContainer>
            )}
        </div>
    );
};

export default TemplatesDropdown;
