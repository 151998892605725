import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleBorder } from "./styled";
import { UnFavoriteIcon } from "../../../assets/images/icons";

const UnFavoriteTemplate = () => {
    //TODO: Use method above "getFavoriteTimeSlotsText" to get description text based on timeslots list
    return (
        <TemplateBubbleBorder>
            <ActionIconText title={`Removed favorite`} Icon={<UnFavoriteIcon />} />
        </TemplateBubbleBorder>
    );
};

export default UnFavoriteTemplate;
