import { useState, useEffect, useMemo } from "react";
import * as BookingsAPI from "./api";
import { Booking } from "./types";
// import Logger from "@core/services/logger";
import sortBy from "lodash.sortby";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "../../store/store";
import { FunnelState } from "../funnels/reducer";
import { fetchBookings } from "./reducer";

const TAG = "Bookings";

export function useBookings() {
    const funnelId = useSelector<RootState, string | null>(
        state => state.funnels.focusedChat,
    ) as string;
    const funnel = useSelector<RootState, FunnelState>(
        state => state.funnels.funnelMap[funnelId],
    );
    const rid = funnel?.restaurantId;
    const uid = useSelector<RootState, string>(state => state.user.uid);
    const dispatch = useDispatch();

    const [trialBookings, setTrialBookings] = useState<Booking[] | null>(null);
    const [interviewBookings, setInterviewBookings] = useState<Booking[] | null>(null);

    const bookings = useMemo(() => {
        return sortBy(
            [...(trialBookings ?? []), ...(interviewBookings ?? [])],
            booking => booking.startTime,
        );
    }, [trialBookings, interviewBookings]);

    useEffect(() => {
        dispatch(fetchBookings(bookings));
    }, [bookings, dispatch]);

    useEffect(() => {
        let unsubscribeTrials: () => void | undefined;
        let unsubscribeInterviews: () => void | undefined;
        if (rid) {
            unsubscribeTrials = BookingsAPI.getTrialBookingsByRestaurantId(
                rid,
            ).onSnapshot(
                query => setTrialBookings(transformBookings(query)),
                // TODO: add error logging
                // Logger.notify(
                //     `${TAG}: Failed to fetch trial bookings for restaurant ${rid}. ${error}`,
                // ),
            );
        }
        if (rid && uid) {
            unsubscribeInterviews = BookingsAPI.getInterviewBookingsByRestaurantAndUserId(
                rid,
                uid,
            ).onSnapshot(
                query => setInterviewBookings(transformBookings(query)),
                // TODO: add error logging
                // Logger.notify(
                //     `${TAG}: Failed to fetch interview bookings for restaurant ${rid}, user ${uid}. ${error}`,
                // ),
            );
        }

        return () => {
            unsubscribeTrials?.();
            unsubscribeInterviews?.();
        };
    }, [rid, uid]);
}

function transformBookings(query): Booking[] {
    return query.docs.map(doc => {
        const data = doc.data();
        return {
            id: doc.id,
            funnelId: data.funnelId,
            startTime: data.startTime.toDate(),
            endTime: data.endTime.toDate(),
            type: data.type,
        };
    });
}
