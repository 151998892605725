import React from "react";
import { Link } from "react-router-dom";

import AnnaLogo from "@assets/svg/anna.svg";
import IntroLogo from "@assets/images/intro_logo.png";

import {
    Container,
    Header,
    HeaderLogo,
    ContentContainer,
    Content,
    ContentTitle,
    ContentSubtitle,
    IntroLogoComp,
} from "./styled";

interface AuthScreenContainerProps {
    title?: string | JSX.Element;
    subtitle?: string | JSX.Element;
    children?: JSX.Element | JSX.Element[];
}

function AuthScreenContainer({ title, subtitle, children }: AuthScreenContainerProps) {
    return (
        <Container>
            <Header>
                <Link to="/sign-in">
                    <HeaderLogo src={AnnaLogo} />
                </Link>
            </Header>

            <ContentContainer>
                <Content>
                    {Boolean(title) && <ContentTitle>{title}</ContentTitle>}
                    {Boolean(subtitle) && <ContentSubtitle>{subtitle}</ContentSubtitle>}

                    {children}
                </Content>
            </ContentContainer>

            <IntroLogoComp src={IntroLogo} />
        </Container>
    );
}

export default AuthScreenContainer;
