import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { StyleGuide } from "@constants/StyleGuide";

export const ManageLocationPopup = styled.div`
    width: 525px;
    padding: ${StyleGuide.spacing.base}px;

    background: ${StyleGuide.palette.baseSalt};
    box-shadow: 0px 28px 72px -24px rgba(0, 0, 0, 0.4);
    border-radius: ${StyleGuide.spacing.xsmall}px;
    position: relative;
`;

export const ManageLocationTitle = styled.div`
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
    margin-left: ${StyleGuide.spacing.mini}px;
    margin-bottom: ${StyleGuide.spacing.base}px;
    text-align: left;
`;

export const ManageLocationCloseButton = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ${StyleGuide.spacing.mini}px;
    right: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
`;

interface LocationInputContainerProps {
    isFocused?: boolean;
}

const focusedInput = `
    background: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.secondaryMint};
    box-shadow: 0px 0px 0px 3px #eef5f5;
`;

export const LocationInputContainer = styled.div<LocationInputContainerProps>`
    background: ${StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.mini}px;
    padding: ${StyleGuide.spacing.xsmall}px;
    margin-bottom: ${StyleGuide.spacing.mini}px;
    border: 1px solid ${StyleGuide.palette.baseSugar};
    display: flex;

    ${props => (props.isFocused ? focusedInput : "")};

    :hover {
        ${focusedInput}
    }
`;

export const LocationInputComponent = styled(TextareaAutosize)`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};
    display: flex;
    align-items: center;
    flex: 1;
    border: 0;
    outline: none;
    padding: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
`;

export const LocationInputCloseButton = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const LocationInputDeleteButton = styled.div`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.error};
    padding-left: ${StyleGuide.spacing.large}px;
    cursor: pointer;
`;

export const LocationInputSaveButton = styled.div`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.secondaryCucumber};
    padding-left: ${StyleGuide.spacing.large}px;
    cursor: pointer;
`;

interface AddNewAddressButtonProps {
    disabled?: boolean;
}

export const AddNewAddressButton = styled.div<AddNewAddressButtonProps>`
    width: fit-content;
    border-radius: ${StyleGuide.spacing.mini}px;
    background-color: ${props =>
        props.disabled ? StyleGuide.palette.baseMsg : StyleGuide.palette.secondaryBasil};
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px;
    margin-top: ${StyleGuide.spacing.large}px;
    ${props =>
        props.disabled ? StyleGuide.typography.body2 : StyleGuide.typography.headline2};
    color: ${props => (props.disabled ? "#D0D0D0" : StyleGuide.palette.baseSalt)};
    cursor: pointer;
`;

export const PlusIconContainer = styled.div`
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    margin-right: ${StyleGuide.spacing.mini}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PlusIcon = styled(CloseIcon)<AddNewAddressButtonProps>`
    width: 10px;
    height: 10px;
    fill: ${props => (props.disabled ? "#D0D0D0" : StyleGuide.palette.baseSalt)};
    transform: rotate(45deg);
`;
