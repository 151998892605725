import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleBorder } from "./styled";
import { RejectIcon } from "./styled";

const RejectedManualTemplate = ({ applicantName }) => {
    //TODO: Use method above "getFavoriteTimeSlotsText" to get description text based on timeslots list
    return (
        <TemplateBubbleBorder>
            <ActionIconText
                title={`Rejected ${applicantName}`}
                description="without a message"
                Icon={<RejectIcon />}
            />
        </TemplateBubbleBorder>
    );
};

export default RejectedManualTemplate;
