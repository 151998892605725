import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import keyBy from "lodash.keyby";

interface LocationState {
    isLoadingInterviewLocations: boolean;
    interviewLocationIds: string[];
    interviewLocationMap: { [id: string]: any };
    isLoadingTrialLocations: boolean;
    trialLocationIds: string[];
    trialLocationMap: { [id: string]: any };
}

// Define the initial state using that type
const initialState: LocationState = {
    isLoadingInterviewLocations: true,
    interviewLocationIds: [],
    interviewLocationMap: {},
    isLoadingTrialLocations: true,
    trialLocationIds: [],
    trialLocationMap: {},
};

export const locationsSlice = createSlice({
    name: "locations",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setInterviewLocations(
            state,
            action: PayloadAction<Contracts.RestaurantLocation[]>,
        ) {
            const ids = action.payload.map(n => n.id);
            const byId = keyBy(action.payload, "id");
            return {
                ...state,
                interviewLocationIds: ids,
                interviewLocationMap: byId,
                isLoadingInterviewLocations: false,
            };
        },
        setTrialLocations(state, action: PayloadAction<Contracts.RestaurantLocation[]>) {
            const ids = action.payload.map(n => n.id);
            const byId = keyBy(action.payload, "id");
            return {
                ...state,
                trialLocationIds: ids,
                trialLocationMap: byId,
                isLoadingTrialLocations: false,
            };
        },
    },
});

export const { setInterviewLocations, setTrialLocations } = locationsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// INTERVIEW
export const selectInterviewLocations = (state: RootState) =>
    state.locations.interviewLocationIds.map(id => ({
        id,
        address: state.locations.interviewLocationMap[id]?.address,
    }));
export const selectDefaultInterviewLocation = (state: RootState) =>
    state.user?.preferences?.defaultInterviewLocationId
        ? state.locations.interviewLocationMap[
              state.user?.preferences?.defaultInterviewLocationId
          ]
        : null;
//TRIAL
export const selectTrialLocations = (state: RootState) =>
    state.locations.trialLocationIds.map(id => ({
        id,
        address: state.locations.trialLocationMap[id]?.address,
    }));
export const selectDefaultTrialLocation = (state: RootState) => {
    const restaurantId =
        state.funnels.funnelMap[state.funnels.focusedChat as string]?.restaurantId;
    const defaultLocationId =
        state.restaurants.restaurantMap[restaurantId]?.preferences
            ?.defaultTrialLocationId;
    return defaultLocationId
        ? state.locations.interviewLocationMap[defaultLocationId]
        : null;
};
export default locationsSlice.reducer;
