import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: hidden;
    position: static;
`;

export const TrialRangePickersContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: ${StyleGuide.spacing.base}px 0;
    overflow: auto;

    padding-right: ${StyleGuide.spacing.micro}px;

    ${CustomScrollBar}
`;

export const RangePickerContainer = styled.div`
    padding: ${StyleGuide.spacing.micro}px 0;
    margin-bottom: ${StyleGuide.spacing.small}px;
`;

export const RangePickerTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${StyleGuide.spacing.mini}px;

    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
`;

export const EmptyTrack = styled.div`
    width: 100%;
    height: ${StyleGuide.spacing.mini}px;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    background: ${StyleGuide.palette.baseVanilla};
`;

export const TimeSlotVariableStyle = `color: ${StyleGuide.palette.secondaryBasil};`;
