import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

interface PositionsState {
    positions: string[];
    focusedPosition: string | null;
}

// Define the initial state using that type
const initialState: PositionsState = { positions: [], focusedPosition: null };

export const userPositionsSlice = createSlice({
    name: "userPositions",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setUserPositions(state, action: PayloadAction<string[]>) {
            let nextState = {
                ...state,
                positions: action.payload,
                // default to first loaded position if none selected
                focusedPosition: state.focusedPosition,
            };

            // set focus if only 1 position available
            if (nextState.positions.length === 1) {
                nextState = {
                    ...nextState,
                    focusedPosition: nextState.positions[0],
                };
            }

            return nextState;
        },
        setFocusedPosition(state, action: PayloadAction<string | null>) {
            return {
                ...state,
                focusedPosition: action.payload,
            };
        },
    },
});

export const { setUserPositions, setFocusedPosition } = userPositionsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUserPositions = (state: RootState) => state.userPositions.positions;
export const selectFocusedPosition = (state: RootState) =>
    state.userPositions.focusedPosition;

export default userPositionsSlice.reducer;
