import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, TemplateBubbleMessage } from "./styled";
import { CancelledIcon } from "../../../assets/images/icons";

const InterviewCancelTemplate = ({ message }) => {
    return (
        <TemplateBubbleContainer darkText>
            <ActionIconText
                darkText
                title="Cancelled interview"
                Icon={<CancelledIcon />}
            />

            {Boolean(message) && (
                <TemplateBubbleMessage darkText>{message}</TemplateBubbleMessage>
            )}
        </TemplateBubbleContainer>
    );
};

export default InterviewCancelTemplate;
