import { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { FunnelState, setOtherInbox } from "./reducer";
import { selectMembersToQuery } from "../restaurants/reducer";
import { selectFocusedPosition } from "../userPositions/reducer";
import { CommunicationState } from "@anna/shared";
import { Pagination, setOtherInboxPagination } from "../pagination/reducer";
import { getSearchFilter } from "../../helpers/searchFilters";
import useFunnelSearch from "../user/useFunnelSearch";

export default function useOtherInbox() {
    const { loading, onSearchFunnels } = useFunnelSearch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    // const restaurantIds = useAppSelector(selectRestaurantIdsToQuery);
    const colleagues = useAppSelector(selectMembersToQuery);

    const userId = useAppSelector(state => state.user.uid);

    const workHourFilter = useAppSelector(state => state.inboxFilters.workHoursFilter);

    const focusedPosition = useAppSelector(selectFocusedPosition);
    // filter is default null to select "all"
    const positionsFilter = useMemo(
        () => [focusedPosition].filter(Boolean),
        [focusedPosition],
    ) as string[];

    const visaFilter = useAppSelector(state => state.inboxFilters.visaFilter);

    const page = useAppSelector(state => state.pagination.otherInboxPagination.page);
    const dispatch = useAppDispatch();

    const filter = getSearchFilter({
        include: {
            communicationStateFilter: [CommunicationState.CONTACTED_ACCEPTED],
        },
        exclude: {
            subscribeIds: [...colleagues, userId],
        },
    });

    useEffect(() => {
        try {
            if (!loading && userId) {
                onSearchFunnels({
                    q: "",
                    filter: filter,
                    page: {
                        current: page,
                        size: 10,
                    },
                }).then(
                    ({
                        data,
                        meta,
                    }: {
                        data: Partial<FunnelState>[];
                        meta: Partial<Pagination>;
                    }) => {
                        dispatch(
                            setOtherInboxPagination({
                                current: meta?.current,
                                size: meta?.size,
                                totalPages: meta?.totalPages,
                                totalResults: meta?.totalResults,
                            }),
                        );
                        // @ts-ignore
                        dispatch(setOtherInbox({ funnels: data, append: true }));
                        if (initialLoading) {
                            setInitialLoading(false);
                        }
                    },
                );
            }
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [userId, loading, page, positionsFilter, workHourFilter, visaFilter]);
    return { initialLoading };
}
