import React from "react";

import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, TemplateBubbleMessage, OnboardingIcon } from "./styled";

const OnboardingTemplate = ({ message }) => {
    return (
        <TemplateBubbleContainer>
            <ActionIconText
                title="Onboarding"
                description="Requested contract info"
                Icon={<OnboardingIcon />}
            />

            <TemplateBubbleMessage>{message}</TemplateBubbleMessage>
        </TemplateBubbleContainer>
    );
};

export default OnboardingTemplate;
