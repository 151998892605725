import React from "react";

import { useAppSelector } from "../../store/store";
import { selectUserPositions } from "../../features/userPositions/reducer";

import { ApplicantsWrapper } from "./styled";
import ApplicantsContainer from "./Applicants/Container";
import ShortlistContainer from "./Shortlist/Container";
import ContactedlistContainer from "./Contactedlist/Container";
import RejectedlistContainer from "./Rejectedlist/Container";

const favoritedList = Array(5)
    .fill(0)
    .map((val, idx) => {
        return {
            name: `Livia Workman ${val} ${idx}`,
            profilePic: `https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80`,
            status: "Applied",
            createdAt: "1 min",
            restaurant: "Pixie",
            role: "Chef",
        };
    });

const contactedList = Array(24)
    .fill(0)
    .map((val, idx) => {
        return {
            name: `Livia Workman ${val} ${idx}`,
            profilePic: `https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80`,
            status: "Applied",
            createdAt: "1 min",
            restaurant: "Pixie",
            role: "Chef",
        };
    });

const rejectedList = Array(204)
    .fill(0)
    .map((val, idx) => {
        return {
            name: `Livia Workman ${val} ${idx}`,
            profilePic: `https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZmlsZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80`,
            status: "Applied",
            createdAt: "1 min",
            restaurant: "Pixie",
            role: "Chef",
        };
    });

const Applicants = () => {
    const positions = useAppSelector(selectUserPositions);

    if (!positions.length) {
        return <ApplicantsWrapper />;
    }

    return (
        <ApplicantsWrapper>
            <ApplicantsContainer />
            <ShortlistContainer />
            <ContactedlistContainer />
            <RejectedlistContainer />
            {/* <ApplicantsList
                title="Favourite"
                applicantsList={favoritedList}
                isOpenedByDefault
                colorTheme={StyleGuide.palette.primaryAnanas}
            />

            <ApplicantsList
                title="Contacted"
                applicantsList={contactedList}
                isOpenedByDefault
                colorTheme={"#4184D3"}
            />

            <ApplicantsList
                title="Rejected"
                applicantsList={rejectedList}
                isOpenedByDefault={false}
                colorTheme={StyleGuide.palette.coal}
            /> */}
        </ApplicantsWrapper>
    );
};

export default Applicants;
