import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { CustomRejectButton, RejectWithMessageButton } from "./CtaButton";
import {
    Intl,
    RejectionTemplates,
    TemplateType,
    TemplateVariables,
    RejectReason,
} from "@anna/shared";
import { useAppSelector } from "../../../../store/store";
import API from "../../../../api/client";

import {
    CustomRejectContainer,
    CloseButton,
    PopupTitle,
    PopupNote,
    CustomRejectInput,
} from "./styled";

function getTemplate(restaurant, funnel, senderFirstName) {
    switch (restaurant?.preferences?.language) {
        case Intl.da_DK:
            return RejectionTemplates.renderRejectionTemplateDK({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
        case Intl.de_DE:
            return RejectionTemplates.renderRejectionTemplateDE({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });

        default:
            return RejectionTemplates.renderRejectionTemplateEN({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
    }
}

const CustomRejectPopup = () => {
    const [message, setMessage] = useState("");
    const [showRejectPopup, toggleRejectPopup] = useState(false);
    const [isInputFocused, toggleInputFocused] = useState(false);
    const userName = useAppSelector(state => state.user.name) as string;
    const funnelId = useAppSelector(state => state.funnels.focusedProfile) as string;
    const funnel = useAppSelector(state => state.funnels.funnelMap[funnelId]);
    const restaurant = useAppSelector(
        state => state.restaurants.restaurantMap[funnel.restaurantId],
    );
    const customRejectTemplate = useAppSelector(
        state => state.templates.templateMap?.[TemplateType.REJECTION],
    );
    const defaultTemplate = getTemplate(restaurant, funnel, userName);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const closePopup = () => {
        toggleRejectPopup(false);
    };

    useEffect(() => {
        setMessage(
            customRejectTemplate
                ?.replace(TemplateVariables.APPLICANT_NAME, funnel?.userMetaFirstName)
                ?.replace(TemplateVariables.RESTAURANT_TITLE, restaurant.title)
                ?.replace(TemplateVariables.SENDER_NAME, userName) || defaultTemplate,
        );
        return () => {};
    }, [funnelId]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                // closePopup();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const onReject = async () => {
        try {
            await API.reject({
                funnelId,
                customMessage: message,
                rejectionReason: RejectReason.NO_FIT,
            });
            closePopup();
        } catch (error) {
            console.error(error);
        }
    };

    if (!showRejectPopup) {
        return <CustomRejectButton onClick={() => toggleRejectPopup(true)} />;
    }

    return (
        <>
            <CustomRejectContainer ref={wrapperRef}>
                <CloseButton onClick={closePopup}>
                    <CloseIcon />
                </CloseButton>

                <PopupTitle>Your standard reply</PopupTitle>

                <CustomRejectInput
                    minRows={1}
                    maxRows={10}
                    placeholder="Type here"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                    onFocus={() => toggleInputFocused(true)}
                    onBlur={() => toggleInputFocused(false)}
                    isFocused={isInputFocused}
                />

                <PopupNote>
                    Customize this template in the mobile app, desktop support coming
                    soon!
                </PopupNote>
            </CustomRejectContainer>

            <RejectWithMessageButton onClick={onReject} />
        </>
    );
};

export default CustomRejectPopup;
