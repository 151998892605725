import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";
export function renderRejectionTemplateEN({
    applicantName = TemplateVariables.APPLICANT_NAME,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hi ${applicantName},

Thanks again for your application to ${restaurantTitle}. Unfortunately, we decided to proceed with another applicant, but we wish you all the best!

${senderFirstName}`;
}
