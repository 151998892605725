import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: 0;
    margin: 0;
`;

export const ListContainer = styled.ul`
    list-style: none;
    padding: ${StyleGuide.spacing.small}px;
    margin: 0;
    position: absolute;
    left: -70px;
    top: ${StyleGuide.spacing.xlarge}px;
    filter: drop-shadow(0px 9px 26px rgba(0, 0, 0, 0.08));
    background: ${StyleGuide.palette.baseSalt};
    border-radius: ${StyleGuide.spacing.xsmall}px;
    z-index: 10;
`;

type OptionItemProps = {
    isSelected?: boolean;
    isLast?: boolean;
};

export const OptionItem = styled.li<OptionItemProps>`
    min-width: 135px;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px;
    background: ${props =>
        props.isSelected
            ? StyleGuide.palette.secondaryBasil
            : StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.base}px;
    margin-bottom: ${props => (props.isLast ? 0 : StyleGuide.spacing.mini)}px;

    color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSugar : StyleGuide.palette.baseVanilla};
    cursor: pointer;
    text-align: left;
    ${props =>
        props.isSelected ? StyleGuide.typography.headline1 : StyleGuide.typography.body1};
    white-space: nowrap;
`;
