import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";

export const getInterviewLocations = (userId: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.USERS)
            .doc(userId)
            .collection(COLLECTIONS.INTERVIEW_LOCATIONS)
            .orderBy("createTime", "desc");
    } catch (error) {
        console.error(error);
        return null;
    }
};
export const addInterviewLocation = (userId: string, title: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.USERS)
            .doc(userId)
            .collection(COLLECTIONS.INTERVIEW_LOCATIONS)
            .add({
                createTime: new Date(),
                name: title,
                address: title,
            });
    } catch (error) {
        console.error(error);
        return null;
    }
};
export const editInterviewLocation = (
    userId: string,
    locationId: string,
    title: string,
) => {
    try {
        return firestore
            .collection(COLLECTIONS.USERS)
            .doc(userId)
            .collection(COLLECTIONS.INTERVIEW_LOCATIONS)
            .doc(locationId)
            .update({
                name: title,
                address: title,
            });
    } catch (error) {
        console.error(error);
        return null;
    }
};
export const removeInterviewLocation = (userId: string, locationId: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.USERS)
            .doc(userId)
            .collection(COLLECTIONS.INTERVIEW_LOCATIONS)
            .doc(locationId)
            .delete();
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getTrialLocations = (userId: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.RESTAURANTS)
            .doc(userId)
            .collection(COLLECTIONS.RESTAURANT_TRIAL_LOCATIONS)
            .orderBy("createTime", "desc");
    } catch (error) {
        console.error(error);
        return null;
    }
};
export const addTrialLocation = (userId: string, title: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.RESTAURANTS)
            .doc(userId)
            .collection(COLLECTIONS.RESTAURANT_TRIAL_LOCATIONS)
            .add({
                createTime: new Date(),
                name: title,
                address: title,
            });
    } catch (error) {
        console.error(error);
        return null;
    }
};
export const editTrialLocation = (userId: string, locationId: string, title: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.RESTAURANTS)
            .doc(userId)
            .collection(COLLECTIONS.RESTAURANT_TRIAL_LOCATIONS)
            .doc(locationId)
            .update({
                name: title,
                address: title,
            });
    } catch (error) {
        console.error(error);
        return null;
    }
};
export const removeTrialLocation = (restaurantId: string, locationId: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.RESTAURANTS)
            .doc(restaurantId)
            .collection(COLLECTIONS.RESTAURANT_TRIAL_LOCATIONS)
            .doc(locationId)
            .delete();
    } catch (error) {
        console.error(error);
        return null;
    }
};
