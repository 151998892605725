import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleBorder } from "./styled";

const ContactedManualTemplate = () => {
    //TODO: Use method above "getFavoriteTimeSlotsText" to get description text based on timeslots list
    return (
        <TemplateBubbleBorder>
            <ActionIconText title={`Moved to Contacted`} Icon={null} />
        </TemplateBubbleBorder>
    );
};

export default ContactedManualTemplate;
