import styled from "styled-components";
import { StyleGuide } from "@constants/StyleGuide";

import TickIcon from "@assets/svg/tick.svg";

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

interface CheckboxContainerProps {
    checked: boolean;
}

export const CheckboxContainer = styled.button<CheckboxContainerProps>`
    border: 0;
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background: ${props =>
        props.checked ? StyleGuide.palette.secondaryBasil : StyleGuide.palette.baseMsg};
`;

export const Icon = styled.img.attrs({ src: TickIcon })``;

export const CheckboxLabel = styled.span`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
    margin: 0 ${StyleGuide.spacing.mini}px;

    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
`;
