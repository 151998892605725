import React from "react";

import FacebookLogo from "@assets/svg/facebook.svg";

import { FacebookButton, FacebookButtonLogo, FacebookButtonText } from "./styled";

function FacebookButtonComp({ onClick }) {
    return (
        <FacebookButton onClick={onClick}>
            <FacebookButtonLogo src={FacebookLogo} />

            <FacebookButtonText>Sign in with Facebook</FacebookButtonText>
        </FacebookButton>
    );
}

export default FacebookButtonComp;
