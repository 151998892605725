import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuthentication } from "./firebase/useAuthentication";
import Home from "./routes/Home";
import Inbox from "./routes/Inbox";
import SignIn from "./routes/Auth/SignIn";
import ForgotPassword from "./routes/Auth/ForgotPassword";
import { useRestaurants } from "./features/restaurants/useRestaurants";
import { useUserPositions } from "./features/userPositions/useUserPositions";
import { useNotifications } from "./features/notifications/useNotifications";
import { useBookings } from "./features/bookings/useBookings";
import { useActivityEvents } from "./features/activityCenter/useActivityEvents";
import { Routes } from "./constants/routes";
import { useUser } from "./features/user/useUser";
import ApplicantProfileModal from "./components/CustomModals/ApplicantProfileModal";
import useSearchKeys from "./features/user/useFunnelSearchKey";

function App() {
    const { isLoading } = useAuthentication();
    useRestaurants();
    useUserPositions();
    useNotifications();
    useBookings();
    useUser();
    useSearchKeys();
    useActivityEvents();
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="App">
            <Switch>
                <Route exact path={Routes.ROOT}>
                    <Home />
                </Route>
                <Route path={Routes.HOME}>
                    <Home />
                </Route>
                <Route path={Routes.INBOX}>
                    <Inbox />
                </Route>
                <Route path={Routes.FORGOT_PASSWORD}>
                    <ForgotPassword />
                </Route>
                <Route path={Routes.SIGN_IN}>
                    <SignIn />
                </Route>
            </Switch>
            <ApplicantProfileModal />
        </div>
    );
}

export default App;
