import { PlandayDropdown } from "../styled";
import TemplatesDropdown from "@components/Dropdowns/TemplatesDropdown";
import React from "react";
import { usePlandayDepartments } from "../../../../../features/planday/usePlandayDepartments";
import * as FunnelsAPI from "../../../../../features/funnels/api";

export default function PlandayDepartmentSelect({
    funnelId,
    plandayDepartmentId,
}: {
    funnelId: string;
    plandayDepartmentId?: number | null;
}) {
    const { departments, isLoading } = usePlandayDepartments();
    const selectedDepartment = departments.find(d => d.id === plandayDepartmentId);
    const handleSelectDepartment = async (departmentId: number) => {
        try {
            await FunnelsAPI.updateFunnelMetaPlanday(funnelId, { departmentId });
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <PlandayDropdown>
            <TemplatesDropdown
                loading={isLoading}
                placeholder="Select Department"
                selected={selectedDepartment}
                optionsList={departments}
                getOptionLabel={option => option?.name || ""}
                getOptionKey={option => option?.id}
                getOptionSelected={option => option?.id === plandayDepartmentId}
                onOptionSelect={option => {
                    handleSelectDepartment(option.id);
                }}
                renderCustomOptions={() => {}}
            />
        </PlandayDropdown>
    );
}
