import React from "react";

import ActivityEvent from "./ActivityEvent";
import { ActivityListContainer, ActivitySectionText } from "./styled";

const Section = ({ label, list, selectedTab, onClose }) => {
    if (!list.length) {
        return <div />;
    }
    return (
        <>
            <ActivitySectionText>{label}</ActivitySectionText>

            {list.map(event => (
                <ActivityEvent
                    key={event.id}
                    type={selectedTab}
                    event={event}
                    onClose={onClose}
                />
            ))}
        </>
    );
};

const ActivityList = ({ sectionList, selectedTab, onClose }) => {
    //TODO: Pagination and virtualized list
    return (
        <ActivityListContainer>
            {sectionList.map(({ label, list }, index) => (
                <Section
                    key={index}
                    label={label}
                    list={list}
                    selectedTab={selectedTab}
                    onClose={onClose}
                />
            ))}
        </ActivityListContainer>
    );
};

export default ActivityList;
