import { StyleGuide } from "./StyleGuide";

export const CustomScrollBar = `
  ::-webkit-scrollbar {
    width: 5px;
    background: ${StyleGuide.palette.baseSalt};
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${StyleGuide.palette.baseSeaSalt};
    border-radius: 5px;
  }

  /* Mozilla Firefox support */
  scrollbar-width: thin;
  scrollbar-color: ${StyleGuide.palette.baseSeaSalt} ${StyleGuide.palette.baseSalt};
`;
