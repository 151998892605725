import { useEffect } from "react";
import { setTrialLocations } from "./reducer";
import { useAppSelector, useAppDispatch } from "../../store/store";
import * as LocationAPI from "./api";

export function useTrialLocations() {
    const restaurantId = useAppSelector(
        state =>
            state.funnels.funnelMap[state.funnels.focusedChat as string]?.restaurantId,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (restaurantId) {
            // @ts-ignore
            unsubscribe = LocationAPI.getTrialLocations(restaurantId).onSnapshot(
                query => {
                    const locations = query.docs.map(
                        doc =>
                            ({
                                id: doc.id,
                                ...doc.data(),
                            } as Contracts.RestaurantLocation),
                    );

                    dispatch(setTrialLocations(locations));
                },
                err => {
                    console.log(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [restaurantId]);
}
