import React from "react";
import { ActivityEventType as DBActivityEventType } from "@anna/shared";
import { ActivityEventType, ActivityEventProps, ActivityEvent } from "./ActivityEvent";
import {
    getMessageForUserActivityEvent,
    getMessageForTeamActivityEvent,
} from "./message";

export function getActivityEventMessage({ type, event }: ActivityEventProps) {
    switch (type) {
        case ActivityEventType.User:
            return getMessageForUserActivityEvent(
                event as Contracts.DBUserFunnelActivityEvent,
            );
        case ActivityEventType.Team:
            return getMessageForTeamActivityEvent(event as Contracts.TeamActivityEvent);

        default:
            throw new Error(
                `ActivityEventType didn't match: type=${type}, eventType=${event.type}`,
            );
    }
}

export function getActivityEventAvatar(
    event: ActivityEvent,
): { uri?: string; icon?: any } {
    if (event.type === DBActivityEventType.BULK_REJECTED) {
        return { icon: <div /> /* <Skip width={22} height={22} /> */ };
    } else if (
        [
            DBActivityEventType.MANUAL_RECRUITMENT_ON,
            DBActivityEventType.MANUAL_RECRUITMENT_OFF,
            DBActivityEventType.SYSTEM_RECRUITMENT_OFF,
        ].includes(event.type)
    ) {
        return {
            icon: <div /> /* <Info width={22} height={22} /> */,
        };
    }
    return { uri: (event as Contracts.DBTeamFunnelActivityEvent).applicantImage };
}

export function getActivityEventSmallAvatar(
    isUserActivityEvent: boolean,
    event: ActivityEvent,
) {
    if (isUserActivityEvent) {
        return undefined;
    }
    return (event as Contracts.DBTeamFunnelActivityEvent).userImage;
}

export function getActivityEventTouchable(event: ActivityEvent) {
    if (event.type === DBActivityEventType.BULK_REJECTED) {
        return false;
    }
    return true;
}
