import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderTrialInviteTemplateEN({
    applicantName = TemplateVariables.APPLICANT_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
    trialLocation = TemplateVariables.LOCATION,
    trialLink = TemplateVariables.TIME_SLOTS,
    senderFirstName = TemplateVariables.SENDER_NAME,
}: TemplateProps) {
    return `Hi ${applicantName},

Thanks for you application! Do you have time to come in for a trial at ${restaurantTitle}?

Here is our availability, just pick a time to confirm: ${trialLink}

This is the address: ${trialLocation}

Looking forward to have you over,
${senderFirstName}`;
}
