import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ActivityCenterState } from "./types";

// Define the initial state using that type
const initialState: ActivityCenterState = {
    user: [],
    team: [],
    userRefreshing: false,
    teamRefreshing: false,
};

export const activityCenterSlice = createSlice({
    name: "activityCenter",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        toggleUserRefreshing(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                userRefreshing: action.payload,
            };
        },
        toggleTeamRefreshing(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                teamRefreshing: action.payload,
            };
        },
        fetchUserActivityEvents(
            state,
            action: PayloadAction<Contracts.DBUserFunnelActivityEvent[]>,
        ) {
            return {
                ...state,
                user: action.payload,
                userRefreshing: false,
            };
        },
        fetchTeamActivityEvents(
            state,
            action: PayloadAction<Contracts.TeamActivityEvent[]>,
        ) {
            return {
                ...state,
                team: action.payload,
                teamRefreshing: false,
            };
        },
    },
});

export const {
    fetchUserActivityEvents,
    fetchTeamActivityEvents,
    toggleUserRefreshing,
    toggleTeamRefreshing,
} = activityCenterSlice.actions;

export default activityCenterSlice.reducer;
