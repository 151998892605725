import { DateTimeConversion } from ".";
import { startOfDay, addMinutes, format } from "date-fns";

export type TimeSlot = {
    day: string;
    startTime: Date;
    endTime: Date;
    startTimeInMinutes: number;
    endTimeInMinutes: number;
    startTimeFormatted: string;
};

/**
 * Converts interview time slots to a more usable format
 * @param day The day to convert the time slots for
 * @param interval The interval between each time slot
 */
export function convertTimeSlotsForDay(
    day: string,
    customTimes: Contracts.CustomTimeSlotsDTO,
    interval: number,
): TimeSlot[] {
    return Object.keys(customTimes[day])
        .sort()
        .map(time => {
            const minutes = Number(time);
            const date = startOfDay(new Date(day));

            const startTime = addMinutes(date, minutes);
            const endTime = addMinutes(startTime, interval);
            const startTimeInMinutes = minutes;
            const endTimeInMinutes = startTimeInMinutes + interval;
            const startTimeFormatted = format(startTime, "HH:mm");

            return {
                day,
                startTime,
                endTime,
                startTimeInMinutes,
                endTimeInMinutes,
                startTimeFormatted,
            };
        })
        .sort((a, b) => (a.startTimeInMinutes > b.startTimeInMinutes ? 1 : -1));
}

/**
 * Converts trial preference to a more usable format
 * @param day The day to convert the time slots for
 * @param interval The interval between each time slot
 */
export function convertPreferenceForDay(
    day: string,
    preference: Contracts.CustomPreferenceDTO,
) {
    const startTimeInMinutes = preference[day].startTimeInMinutes;
    const endTimeInMinutes = preference[day].endTimeInMinutes;
    const startTime = DateTimeConversion.dayMinutesToDate(day, startTimeInMinutes);
    const endTime = DateTimeConversion.dayMinutesToDate(day, endTimeInMinutes);
    const startTimeFormatted = DateTimeConversion.minutesToHoursAndMinutes(
        startTimeInMinutes,
    );
    const endTimeFormatted = DateTimeConversion.minutesToHoursAndMinutes(
        endTimeInMinutes,
    );

    return {
        day,
        startTime,
        endTime,
        startTimeInMinutes,
        endTimeInMinutes,
        startTimeFormatted,
        endTimeFormatted,
    };
}
