import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

interface InboxFilterState {
    workHoursFilter: string[];
    visaFilter: string[];
}

// Define the initial state using that type
const initialState: InboxFilterState = {
    workHoursFilter: [],
    visaFilter: [],
};

export const inboxFilterSlice = createSlice({
    name: "inboxFilter",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setWorkhoursFilter(state, action: PayloadAction<string>) {
            if (state.workHoursFilter.includes(action.payload)) {
                return {
                    ...state,
                    workHoursFilter: state.workHoursFilter.filter(
                        f => f !== action.payload,
                    ),
                };
            }
            return {
                ...state,
                workHoursFilter: [...state.workHoursFilter, action.payload],
            };
        },
        setVisaFilter(state, action: PayloadAction<string>) {
            if (state.visaFilter.includes(action.payload)) {
                return {
                    ...state,
                    visaFilter: state.visaFilter.filter(f => f !== action.payload),
                };
            }
            return {
                ...state,
                visaFilter: [...state.visaFilter, action.payload],
            };
        },
        resetFilters(state) {
            return {
                ...state,
                workHoursFilter: [],
                visaFilter: [],
            };
        },
    },
});

export const {
    setWorkhoursFilter,
    setVisaFilter,
    resetFilters,
} = inboxFilterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export default inboxFilterSlice.reducer;
