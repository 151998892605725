import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    top: 0;
    margin: 0;
`;

interface ToggleBlockProps {
    isSelected?: boolean;
}

export const ToggleBlock = styled.div<ToggleBlockProps>`
    width: ${StyleGuide.spacing.medium}px;
    height: ${StyleGuide.spacing.medium}px;
    border-radius: ${StyleGuide.spacing.small}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSugar : "transparent"};

    :hover {
        background-color: ${StyleGuide.palette.baseSugar};
    }
`;

export const DropdownContainer = styled.div`
    width: 167px;
    margin: 0;
    position: absolute;
    right: 0;
    top: ${StyleGuide.spacing.xlarge}px;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.1);
    background: ${StyleGuide.palette.baseSalt};
    border-radius: ${StyleGuide.spacing.mini}px;

    z-index: 100;
`;

export const LogoutAction = styled.div`
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.small}px;
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.coal};
`;

export const LogoutIconContainer = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${StyleGuide.spacing.xsmall}px;
`;
