import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { isSameMinute, isAfter } from "date-fns";
import groupBy from "lodash.groupby";
import mapValues from "lodash.mapvalues";
import isEmpty from "lodash.isempty";
import { Dates } from "@anna/shared";

import { FunnelState, getFunnelPosition } from "../funnels/reducer";
import type { RootState } from "../../store/store";
import { Booking, BookingsState, CalendarBooking } from "./types";

// Define the initial state using that type
const initialState: BookingsState = {
    byDate: {},
};

export const bookingsSlice = createSlice({
    name: "bookings",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        fetchBookings(state, action: PayloadAction<Booking[]>) {
            return {
                ...state,
                byDate: groupBy(action.payload, booking =>
                    Dates.getShortDate(booking.startTime),
                ),
            };
        },
    },
});

export const { fetchBookings } = bookingsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

const getBookings = (state: RootState) => state.bookings.byDate;
const getFunnelMap = (state: RootState) => state.funnels.funnelMap;

export function getBookingsAfter(bookings: Booking[], date: Date) {
    return bookings.filter(
        booking =>
            isAfter(booking.startTime, date) || isSameMinute(booking.startTime, date),
    );
}

const mergeApplicantDataWithBookings = (
    bookingsByDate: { [date: string]: Booking[] },
    funnels: { [id: string]: FunnelState },
) => {
    if (isEmpty(funnels)) {
        return {};
    }

    return mapValues(bookingsByDate, (dailyBookings: CalendarBooking[], date: string) =>
        dailyBookings.map<CalendarBooking>((booking, index) => {
            const funnel = funnels[booking.funnelId] || {};
            return {
                ...booking,
                applicantId: funnel.applicantId,
                applicantName: funnel.userMetaFirstName,
                profileImage: funnel.userMetaProfileImage,
                position: getFunnelPosition(funnel),
                firstBookingOnDay: index === 0,
                startTimeShort: date,
            };
        }),
    );
};

export const getCalendarBookings = createSelector(
    [getBookings, getFunnelMap],
    mergeApplicantDataWithBookings,
);

export default bookingsSlice.reducer;
