import { useEffect, useState, useMemo } from "react";
import { ApplicationState } from "@anna/shared";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { selectFocusedPosition } from "../../../features/userPositions/reducer";
import { Pagination, setContactedPagination } from "../../../features/pagination/reducer";
import useFunnelSearch from "../../../features/user/useFunnelSearch";
import { getSearchFilter } from "../../../helpers/searchFilters";
import { FunnelState, setContactedlist } from "../../../features/funnels/reducer";

export default function useContactedlistWithFilters() {
    const { loading, onSearchFunnels } = useFunnelSearch();
    const [initialLoading, setInitialLoading] = useState(true);
    const dispatch = useAppDispatch();
    const page = useAppSelector(state => state.pagination.contactedPagination.page);

    const focusedPosition = useAppSelector(selectFocusedPosition);
    // filter is default null to select "all"
    const positionsFilter = useMemo(
        () => [focusedPosition].filter(Boolean),
        [focusedPosition],
    ) as string[];

    const workHourFilter = useAppSelector(state => state.homeFilters.workHoursFilter);

    const visaFilter = useAppSelector(state => state.homeFilters.visaFilter);

    const filter = getSearchFilter({
        include: {
            stateFilter: [
                ApplicationState.INTERESTED,
                ApplicationState.INTERVIEW_PENDING,
                ApplicationState.INTERVIEW_CANCELED,
                ApplicationState.INTERVIEW_SCHEDULED,
                ApplicationState.INTERVIEWED,
                ApplicationState.CONTACTED_ACCEPTED,
                ApplicationState.TRIAL_PENDING,
                ApplicationState.TRIAL_CANCELED,
                ApplicationState.TRIAL_SCHEDULED,
                ApplicationState.TRIALED,
            ],
            positionsFilter,
            workHourFilter,
            visaFilter,
        },
    });

    useEffect(() => {
        try {
            if (!loading) {
                onSearchFunnels({
                    q: "",
                    filter: filter,
                    page: { current: page, size: 10 },
                }).then(
                    ({
                        data,
                        meta,
                    }: {
                        data: Partial<FunnelState>[];
                        meta: Partial<Pagination>;
                    }) => {
                        dispatch(
                            setContactedPagination({
                                current: meta?.current,
                                size: meta?.size,
                                totalPages: meta?.totalPages,
                                totalResults: meta?.totalResults,
                            }),
                        );
                        // @ts-ignore
                        dispatch(setContactedlist({ funnels: data, append: true }));
                        if (initialLoading) {
                            setInitialLoading(false);
                        }
                    },
                );
            }
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [dispatch, loading, positionsFilter, workHourFilter, visaFilter, page]);
    return { initialLoading };
}
