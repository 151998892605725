import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderInterviewInviteTemplateDK({
    applicantName = TemplateVariables.APPLICANT_NAME,
    interviewLocation = TemplateVariables.LOCATION,
    inviteLink = TemplateVariables.TIME_SLOTS,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hej ${applicantName}

Endnu en gang tak for at du har søgt job hos ${restaurantTitle}.
Vi synes din ansøgning er rigtig fin, og vi vil rigtig gerne se dig til en snak hos os på ${interviewLocation}.

Tryk her for at vælge et tidspunkt der passer dig: ${inviteLink}

Vi ser frem til at høre fra dig.

Bedste hilsner
${senderFirstName}`;
}
