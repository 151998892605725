import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, TemplateBubbleMessage, TrialIcon } from "./styled";

const TrialRescheduleTemplate = ({ message }) => {
    return (
        <TemplateBubbleContainer darkText>
            <ActionIconText darkText title="Needs to reschedule" Icon={<TrialIcon />} />

            {Boolean(message) && (
                <TemplateBubbleMessage darkText>{message}</TemplateBubbleMessage>
            )}
        </TemplateBubbleContainer>
    );
};

export default TrialRescheduleTemplate;
