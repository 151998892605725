import mappet from "mappet";

function transformDate(date: any) {
    if (!date) return null;
    return date.toDate();
}

const schema = {
    type: "type",
    funnelId: "funnelId",
    authorDisplayName: "authorMetadata.displayName",
    authorSource: "authorMetadata.source",
    authorImage: "authorMetadata.image",
    authorId: "authorMetadata.uid",
    metaDataStartTime: { path: "metaData.startTime", modifier: transformDate },
    metaDataEndTime: { path: "metaData.endTime", modifier: transformDate },
    metaDataManual: "metaData.manual",
    metaDataRejectionReason: "metaData.rejectionReason",
    metaDataMessage: "metaData.message",
    metaDataBookingId: "metaData.bookingId",
    metaDataBookingParticipants: "metaData.bookingParticipants",
    metaDataTimeSlots: "metaData.timeSlots",
    metaDataPreference: "metaData.preference",
    metaDataLocation: "metaData.address",
    metaDataUserName: "metaData.userName",
    metaDataIsReschedule: "metaData.isReschedule",
    metaDataRequestTime: { path: "metaData.requestTime", modifier: transformDate },
    createTime: { path: "createTime", modifier: transformDate },
};

export const activityMapper = mappet(schema, { greedy: true });
