import React from "react";

import Header from "@components/Header";
import Filters from "@components/Filters";
import InboxList from "./InboxList";
import Chat from "./Chat";
import { Container, InboxContainer, InboxListContainer } from "./styled";

const Inbox = () => {
    return (
        <Container>
            <Header />

            <Filters />

            <InboxContainer>
                <InboxListContainer>
                    <InboxList />
                </InboxListContainer>

                <Chat />
            </InboxContainer>
        </Container>
    );
};

export default Inbox;
