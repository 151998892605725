import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InboxFilterState {
    workHoursFilter: string[];
    visaFilter: string[];
}

// Define the initial state using that type
const initialState: InboxFilterState = {
    workHoursFilter: [],
    visaFilter: [],
};

export const homeFilterSlice = createSlice({
    name: "homeFilter",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setWorkhoursFilter(state, action: PayloadAction<string>) {
            if (state.workHoursFilter.includes(action.payload)) {
                return {
                    ...state,
                    workHoursFilter: state.workHoursFilter.filter(
                        f => f !== action.payload,
                    ),
                };
            }
            return {
                ...state,
                workHoursFilter: [...state.workHoursFilter, action.payload],
            };
        },
        setVisaFilter(state, action: PayloadAction<string>) {
            if (state.visaFilter.includes(action.payload)) {
                return {
                    ...state,
                    visaFilter: state.visaFilter.filter(f => f !== action.payload),
                };
            }
            return {
                ...state,
                visaFilter: [...state.visaFilter, action.payload],
            };
        },
        resetFilters(state) {
            return {
                ...state,
                workHoursFilter: [],
                visaFilter: [],
            };
        },
    },
});

export const {
    setWorkhoursFilter,
    setVisaFilter,
    resetFilters,
} = homeFilterSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const hasFiltersSelected = state => {
    const hasWorkhourFilters = state.homeFilters.workHoursFilter.length > 0;
    const hasVisaFilters = state.homeFilters.visaFilter.length > 0;
    return hasWorkhourFilters || hasVisaFilters;
};
export default homeFilterSlice.reducer;
