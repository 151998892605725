import React from "react";
import { timeDistanceInWords } from "../../helpers/dates";

import {
    ChatBubbleContainer,
    BubbleMessageContainer,
    ProfileImage,
    ProfileLetter,
    MessageBubbleContainer,
    InfoContainer,
    BubbleUserName,
    MessageInfoSeparator,
    MessageTimeInfo,
} from "./styled";

interface BubbleProps {
    isInternal: boolean;
    isCurrentUser: boolean;
    image?: string;
    name?: string | JSX.Element;
    createTime: Date;
    loading?: boolean;
    children: JSX.Element;
}

export const Bubble = ({
    isInternal,
    isCurrentUser,
    image,
    name,
    createTime,
    loading,
    children,
}: BubbleProps) => {
    return (
        <ChatBubbleContainer isCurrentUserAuthor={isInternal}>
            {image ? (
                <ProfileImage isCurrentUserAuthor={isInternal} src={image} />
            ) : (
                <ProfileLetter>{name?.[0]}</ProfileLetter>
            )}

            <BubbleMessageContainer>
                {children}

                <InfoContainer isCurrentUserAuthor={isInternal}>
                    {!isCurrentUser && (
                        <>
                            <BubbleUserName>{name}</BubbleUserName>

                            <MessageInfoSeparator>-</MessageInfoSeparator>
                        </>
                    )}
                    {loading && (
                        <>
                            <BubbleUserName>{name}</BubbleUserName>

                            <MessageInfoSeparator>-</MessageInfoSeparator>
                        </>
                    )}

                    <MessageTimeInfo>{timeDistanceInWords(createTime)}</MessageTimeInfo>
                </InfoContainer>
            </BubbleMessageContainer>
        </ChatBubbleContainer>
    );
};

export const MessageBubble = ({ message, isCurrentUser = false }) => {
    return (
        <MessageBubbleContainer isCurrentUserAuthor={isCurrentUser}>
            {message}
        </MessageBubbleContainer>
    );
};
