import { useEffect, useMemo } from "react";
import { setApplicants } from "../../../features/funnels/reducer";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import * as FunnelAPI from "../../../features/funnels/api";
import { selectFocusedPosition } from "../../../features/userPositions/reducer";
import { selectRestaurantIdsToQuery } from "../../../features/restaurants/reducer";
import { setApplicantPagination } from "../../../features/pagination/reducer";
import { funnelMapper } from "../../../features/funnels/mapper";

export function useApplicants() {
    const restaurantIds = useAppSelector(selectRestaurantIdsToQuery);
    const position = useAppSelector(selectFocusedPosition);
    const funnelIds = useAppSelector(state => state.funnels.applicantIds);
    const dispatch = useAppDispatch();
    const limit = useAppSelector(state => state.pagination.applicantPagination.limit);

    const rIds = useMemo(() => restaurantIds.filter((_, i) => i < 9), [restaurantIds]);

    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (rIds?.length) {
            try {
                dispatch(setApplicantPagination({ isLoading: true }));
                unsubscribe = FunnelAPI.getApplicants({
                    restaurantIds: rIds,
                    position,
                    limit,
                }).onSnapshot(
                    query => {
                        const funnels = query.docs.map(doc =>
                            funnelMapper({
                                id: doc.id,
                                ...doc.data(),
                            } as Contracts.DBFunnelShape),
                        );

                        dispatch(
                            setApplicantPagination({
                                isLoading: false,
                                // TODO: figure a better way than this
                                isEndReached:
                                    limit > funnels?.length ||
                                    funnelIds?.length >= funnels?.length,
                            }),
                        );
                        dispatch(setApplicants({ funnels }));
                    },
                    err => {
                        // TODO: add error logging with sentry
                        console.error(err);
                    },
                );
            } catch (error) {
                console.error(error);
            }
        }

        return () => unsubscribe?.();
    }, [rIds, position, limit]);
}
