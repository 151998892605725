import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import { NotificationType } from "@anna/shared";
import keyBy from "lodash.keyby";
import groupBy from "lodash.groupby";
import { notificationMapper } from "./mapper";

interface NotificationState {
    notificationIds: string[];
    notificationFunnelIdMap: { [id: string]: any };
    notificationGroupedByFunnelId: { [funnelId: string]: any[] };
    notificationByType: { [type: string]: any };
}

// Define the initial state using that type
const initialState: NotificationState = {
    notificationIds: [],
    notificationFunnelIdMap: {},
    notificationGroupedByFunnelId: {},
    notificationByType: {},
};

export const notificationSlice = createSlice({
    name: "notifications",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setNotifications(state, action: PayloadAction<any>) {
            const formated = action.payload.map(notificationMapper);

            const ids = formated.map(n => n.id);
            const byType = groupBy(formated, "type");
            const byFunnelId = keyBy(formated, "funnelId");
            const groupByFunnelId = groupBy(formated, "funnelId");

            return {
                ...state,
                notificationIds: ids,
                notificationFunnelIdMap: byFunnelId,
                notificationGroupedByFunnelId: groupByFunnelId,
                notificationByType: byType,
            };
        },
    },
});

export const { setNotifications } = notificationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNewMessageNotifications = (state: RootState) => {
    const newFunnelIds = state.notifications?.notificationByType.NEW_MESSAGE?.map(
        ({ id, createTime }) => ({ id, createTime: createTime.getTime() }),
    ); //?.map(n => ({ createTime: n.createTime, funnelId: n?.metaData?.funnelId }));

    return newFunnelIds;
};
export const selectUnreadMessages = (state: RootState) => {
    /**
     * Might have to filter based on select restaurants ?
     */
    const count = state.notifications?.notificationByType.NEW_MESSAGE?.length;

    return count;
};

export default notificationSlice.reducer;
