import { useEffect } from "react";
import { setApplications, ApplicationState } from "./reducer";
import { useAppDispatch } from "../../store/store";
import * as NotificationAPI from "./api";
import { applicationMapper } from "./mapper";

export function useApplication(applicantId: string) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (applicantId) {
            // @ts-ignore
            unsubscribe = NotificationAPI.getApplication(applicantId).onSnapshot(
                doc => {
                    const application = applicationMapper({
                        id: doc.id,
                        ...doc.data(),
                    }) as ApplicationState;

                    dispatch(setApplications({ applications: [application] }));
                },
                err => {
                    console.log(err);
                },
            );
        }

        return () => {
            unsubscribe?.();
            console.log(`UNSUBSCRIBE: ${applicantId}`);
        };
    }, [applicantId]);
}
