import { firestore } from "../../firebase/configure";
import { COLLECTIONS, TrialBookingPrefix } from "../../constants/enums";
import { BookingType } from "@anna/shared";

// BOOKINGS
export const getBookingByFunnelId = (funnelId: string) =>
    firestore
        .collection(COLLECTIONS.BOOKINGS)
        .where("funnelId", "==", funnelId)
        .orderBy("createTime", "desc")
        .limit(1);
export const getBooking = (bookingId: string) =>
    firestore.collection(COLLECTIONS.BOOKINGS).doc(bookingId);

export const getTrialBookingsByRestaurantId = (restaurantId: string) => {
    // Note: https://stackoverflow.com/questions/46573804/firestore-query-documents-startswith-a-string
    const typeStart = TrialBookingPrefix;
    const typeLastSymbol = TrialBookingPrefix.length - 1;
    const typeEnd =
        TrialBookingPrefix.slice(0, typeLastSymbol) +
        String.fromCharCode(TrialBookingPrefix[typeLastSymbol].charCodeAt(0) + 1);

    return firestore
        .collection(COLLECTIONS.BOOKINGS)
        .where("restaurantId", "==", restaurantId)
        .where("type", ">=", typeStart)
        .where("type", "<", typeEnd);
};

export const getInterviewBookingsByRestaurantAndUserId = (
    restaurantId: string,
    userId: string,
) =>
    firestore
        .collection(COLLECTIONS.BOOKINGS)
        .where("type", "==", BookingType.INTERVIEW)
        .where("restaurantId", "==", restaurantId)
        .where("userId", "==", userId);
