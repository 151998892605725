import React, { Dispatch } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import * as firebase from "firebase";

import { StyleGuide } from "@constants/StyleGuide";

import { signInWithPhone } from "../../../firebase/auth";
import Input from "../components/Input";
import SubmitButton from "../components/Buttons/SubmitButton";
import { SpacedRow, CodeSentToText, ResendCode } from "./styled";

const KEY_CODE = {
    backspace: 8,
    left: 37,
    right: 39,
};

const simpleNumRegex = new RegExp("^[0-9]+$");

const CustomPhoneInput = React.forwardRef<HTMLInputElement>((props, ref) => (
    <Input ref={ref} {...props} required />
));

let confirmResult: any;

async function signIn(
    phoneNumber: string,
    recaptchaVerifier: any,
    setDisplayConfirmationCode: Dispatch<boolean>,
) {
    try {
        confirmResult = await signInWithPhone(phoneNumber, recaptchaVerifier);
        if (confirmResult) {
            setDisplayConfirmationCode(true);
        }
    } catch (error) {
        console.log(error);
        alert(error.message);
    }
}

async function verifyConfirmationCode(confirmCode: string) {
    if (confirmResult) {
        try {
            const userCredentials = await confirmResult.confirm(confirmCode);
            const user = userCredentials?.user;
        } catch (error) {
            console.log(error);
        }
    }
}

function PhoneForm({ loading, setLoading }) {
    const [formState, setFormState] = React.useState({
        phone: "",
        code: new Array(6).fill(""),
    });
    const [displayConfirmationCode, setDisplayConfirmationCode] = React.useState(false);
    const [resetCodeCooldown, setResetCodeCooldown] = React.useState(0);
    const isCodeValid = React.useMemo(
        () => formState.code.every(codeDigit => codeDigit !== ""),
        [formState.code],
    );

    const focusedCodeIndex = React.useRef(0);
    const codeFieldRefs = React.useRef(
        new Array(6).fill(null).map(() => React.createRef()),
    ).current as React.RefObject<HTMLInputElement>[];

    const recaptchaVerifier = React.useRef<firebase.auth.RecaptchaVerifier>();
    const recaptchaResendVerifier = React.useRef<firebase.auth.RecaptchaVerifier>();

    React.useEffect(() => {
        return () => {
            recaptchaVerifier.current?.clear();
            recaptchaResendVerifier.current?.clear();
        };
    }, []);

    React.useEffect(() => {
        if (resetCodeCooldown > 0) {
            const timeout = setTimeout(() => {
                setResetCodeCooldown(resetCodeCooldown - 1);
                clearTimeout(timeout);
            }, 1000);
        }
    }, [resetCodeCooldown]);

    const updateField = (fieldName, fieldValue) => {
        setFormState({
            ...formState,
            [fieldName]: fieldValue,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (formState.phone && isPossiblePhoneNumber(formState.phone)) {
            setLoading(true);

            if (!recaptchaVerifier.current) {
                recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier(
                    "sign-in-button",
                    {
                        size: "invisible",
                    },
                );
            }
            await signIn(
                formState.phone,
                recaptchaVerifier.current,
                setDisplayConfirmationCode,
            );
            setLoading(false);
        } else {
            alert("Invalid phone number!");
        }
    };

    const handleCodeFieldChange = (index, newValue) => {
        if (newValue && !simpleNumRegex.test(newValue)) {
            return;
        }

        const nextCode = [...formState.code];
        if (newValue.length > 1) {
            if (index + 1 < nextCode.length && nextCode[index + 1] === "") {
                nextCode[index + 1] = newValue.charAt(newValue.length - 1);
            } else {
                nextCode[index] = newValue.charAt(newValue.length - 1);
            }
        } else {
            nextCode[index] = newValue;
        }

        if (index + 1 < nextCode.length) {
            codeFieldRefs[index + 1].current?.focus();
        }

        updateField("code", nextCode);
    };

    const handleCodeFieldKeyDown = (index, e) => {
        const prevIndex = index - 1;
        const nextIndex = index + 1;
        const prev = codeFieldRefs[prevIndex];
        const next = codeFieldRefs[nextIndex];

        switch (e.keyCode) {
            case KEY_CODE.backspace:
                e.preventDefault();
                const nextCode = [...formState.code];

                if (nextCode[index] !== "") {
                    nextCode[index] = "";
                    updateField("code", nextCode);
                } else if (prev) {
                    nextCode[prevIndex] = "";
                    prev.current?.focus();
                    updateField("code", nextCode);
                }
                break;
            case KEY_CODE.left:
                e.preventDefault();
                if (prev) {
                    prev.current?.focus();
                }
                break;
            case KEY_CODE.right:
                e.preventDefault();
                if (next) {
                    next.current?.focus();
                }
                break;

            default:
                break;
        }
    };

    const handleCodeFieldFocus = index => {
        focusedCodeIndex.current = index;
    };

    const handleCodeSubmit = async e => {
        e.preventDefault();

        const codeArray = formState.code;
        if (codeArray.some(codeDigit => codeDigit === "")) {
            alert("Please fill the code");
        } else {
            setLoading(true);
            await verifyConfirmationCode(codeArray.join(""));
            setLoading(false);
        }
    };

    const resendCode = async () => {
        if (resetCodeCooldown > 0) {
            return;
        }

        setResetCodeCooldown(60);

        if (!recaptchaResendVerifier.current) {
            recaptchaResendVerifier.current = new firebase.auth.RecaptchaVerifier(
                "resend-code-button",
                {
                    size: "invisible",
                },
            );
        }

        await signIn(
            formState.phone,
            recaptchaResendVerifier.current,
            setDisplayConfirmationCode,
        );
    };

    if (displayConfirmationCode) {
        return (
            <form onSubmit={handleCodeSubmit}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {new Array(6).fill(0).map((_, index) => (
                        <Input
                            type="tel"
                            autoFocus={index === 0}
                            ref={codeFieldRefs[index]}
                            key={index}
                            style={{ width: 46, textAlign: "center" }}
                            value={formState.code[index]}
                            onChange={e => handleCodeFieldChange(index, e.target.value)}
                            onKeyDown={e => handleCodeFieldKeyDown(index, e)}
                            onFocus={() => handleCodeFieldFocus(index)}
                            required
                        />
                    ))}
                </div>

                <SpacedRow>
                    <CodeSentToText>Code sent to {formState.phone}</CodeSentToText>

                    <ResendCode
                        id="resend-code-button"
                        disabled={resetCodeCooldown !== 0}
                        type="button"
                        onClick={resendCode}>
                        Resend code{" "}
                        {Boolean(resetCodeCooldown) && `${resetCodeCooldown}s`}
                    </ResendCode>
                </SpacedRow>

                <SubmitButton
                    text="Confirm code"
                    loading={loading}
                    disabled={loading || !isCodeValid}
                    type="submit"
                    style={{ marginTop: StyleGuide.spacing.medium }}
                />
            </form>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ paddingLeft: 1 }}>
                <PhoneInput
                    inputComponent={CustomPhoneInput}
                    placeholder="22 33 44 55"
                    defaultCountry="DK"
                    value={formState.phone}
                    onChange={phone => updateField("phone", phone)}
                />
            </div>

            <SubmitButton
                text="Sign in"
                id="sign-in-button"
                loading={loading}
                disabled={loading}
                type="submit"
                style={{ marginTop: StyleGuide.spacing.medium }}
            />
        </form>
    );
}

export default PhoneForm;
