import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderIntroTemplateEN({
    applicantName = TemplateVariables.APPLICANT_NAME,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hi ${applicantName},

Thanks for your application! When would you be available to come by for an interview at ${restaurantTitle}?

Best,
${senderFirstName}`;
}
