import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import MoveAction from "../MoveAction";
import {
    PlandayPopupContainer,
    CloseButton,
    PopupTitle,
    PlusIcon,
    PlandayFormContainer,
} from "../styled";
import { useAppSelector } from "../../../../../store/store";
import useEnrichedApplicantData from "../../../../../features/applications/useEnrichedApplicantData";
import PlandayAddEmployee from "./PlandayAddEmployee";
import PlandayDepartmentSelect from "./PlandayDepartmentSelect";
import PlandayGenderSelect from "./PlandayGenderSelect";
import PlandayInfoSection from "./PlandayInfoSection";
import {
    selectApplicantIdByFunnelId,
    selectFunnelMetaDataPlandayDepartmentId,
} from "../../../../../features/funnels/reducer";

const PlandayPopup = () => {
    const funnelId = useAppSelector(
        state => (state.funnels.focusedProfile || state.funnels.focusedChat) as string,
    );
    const applicantId = useAppSelector(selectApplicantIdByFunnelId) as string;
    const plandayDepartmentId = useAppSelector(selectFunnelMetaDataPlandayDepartmentId);

    const { enrichedApplicantData, loadingEnrichedApplicant } =
        useEnrichedApplicantData(applicantId);
    const [showPlandayPopup, togglePlandayPopup] = useState(false);

    const gender = enrichedApplicantData?.gender?.value || undefined;

    const wrapperRef = useRef<HTMLDivElement>(null);

    const closePopup = () => {
        togglePlandayPopup(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closePopup();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    if (!showPlandayPopup) {
        return (
            <MoveAction
                title="Add to Planday"
                Icon={PlusIcon}
                label="Add"
                onClick={() => togglePlandayPopup(true)}
            />
        );
    }

    return (
        <PlandayPopupContainer ref={wrapperRef}>
            <CloseButton onClick={closePopup}>
                <CloseIcon />
            </CloseButton>

            <PopupTitle>Add to your planday</PopupTitle>

            <PlandayFormContainer>
                <PlandayInfoSection applicantId={applicantId} />
                <PlandayDepartmentSelect
                    funnelId={funnelId}
                    plandayDepartmentId={plandayDepartmentId}
                />
                <PlandayGenderSelect
                    applicantId={applicantId}
                    enrichedApplicantId={enrichedApplicantData?.id}
                    loading={loadingEnrichedApplicant}
                    gender={gender}
                />

                <div style={{ flex: 1 }} />

                <PlandayAddEmployee
                    funnelId={funnelId}
                    plandayDepartmentId={plandayDepartmentId}
                    gender={gender}
                />
            </PlandayFormContainer>
        </PlandayPopupContainer>
    );
};

export default PlandayPopup;
