import { useEffect } from "react";
import { setInterviewLocations } from "./reducer";
import { useAppSelector, useAppDispatch } from "../../store/store";
import * as LocationAPI from "./api";

export function useInterviewLocations() {
    const userId = useAppSelector(state => state.user.uid);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (userId) {
            // @ts-ignore
            unsubscribe = LocationAPI.getInterviewLocations(userId).onSnapshot(
                query => {
                    const locations = query.docs.map(
                        doc =>
                            ({
                                id: doc.id,
                                ...doc.data(),
                            } as Contracts.RestaurantLocation),
                    );

                    dispatch(setInterviewLocations(locations));
                },
                err => {
                    console.log(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [userId]);
}
