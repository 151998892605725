import React, { useState, useEffect, useRef } from "react";
import { AutoSizer, InfiniteLoader } from "react-virtualized";

import { ReactComponent as SearchIcon } from "@assets/svg/search.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { StyleGuide } from "@constants/StyleGuide";
import {
    selectSearchResults,
    setFocusedProfile,
} from "../../../features/funnels/reducer";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import ApplicantCard, { rowHeight } from "@components/ApplicantsList/ApplicantCard";
import useSearch from "./useSearch";
import {
    Container,
    SearchToggleBlock,
    SearchBarExpanded,
    SearchBarInput,
    SearchClearButton,
    DropdownContainer,
    VListContainer,
    ApplicantListItem,
} from "./styled";

const SearchDropdown = () => {
    const [opened, setOpened] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const wrapperRef = useRef<HTMLDivElement>(null);
    useSearch(searchTerm, opened);

    const clearInput = () => {
        if (searchTerm === "") return;
        setSearchTerm("");
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpened(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    //TODO: Search Wiring

    const dispatch = useAppDispatch();

    //Right now this component is using data from the Applicants list as dummy data
    const applicantIds = useAppSelector(selectSearchResults);

    const isRowLoaded = ({ index }) => {
        return !!applicantIds[index];
    };

    const openProfileModal = (funnelId: string) => {
        dispatch(setFocusedProfile({ funnelId, funnelIds: applicantIds }));
    };

    const renderRow = ({ index, key, style }) => {
        const id = applicantIds[index];

        return (
            <ApplicantListItem key={key} style={style} className="row">
                <ApplicantCard
                    id={id}
                    onPress={() => openProfileModal(id)}
                    // only display new applicants notification itn applicants list
                    displayNewApplicantNotifications
                />
            </ApplicantListItem>
        );
    };

    return (
        <Container ref={wrapperRef}>
            {!opened && (
                <SearchToggleBlock onClick={() => setOpened(!opened)}>
                    <SearchIcon />
                </SearchToggleBlock>
            )}

            {opened && (
                <SearchBarExpanded>
                    <SearchToggleBlock onClick={() => setOpened(!opened)}>
                        <SearchIcon />
                    </SearchToggleBlock>

                    <SearchBarInput
                        autoFocus
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />

                    <SearchClearButton onClick={clearInput}>
                        <CloseIcon
                            width={StyleGuide.spacing.mini}
                            height={StyleGuide.spacing.mini}
                            fill={StyleGuide.palette.baseSalt}
                        />
                    </SearchClearButton>
                </SearchBarExpanded>
            )}

            {opened && (
                <DropdownContainer>
                    <InfiniteLoader
                        isRowLoaded={isRowLoaded}
                        loadMoreRows={() => {}}
                        rowCount={applicantIds.length}>
                        {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                                {({ width, height }) => {
                                    return (
                                        <VListContainer
                                            width={width}
                                            height={height}
                                            ref={registerChild}
                                            onRowsRendered={onRowsRendered}
                                            rowHeight={rowHeight}
                                            rowRenderer={renderRow}
                                            rowCount={applicantIds.length}
                                            // overscanRowCount={20}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                </DropdownContainer>
            )}
        </Container>
    );
};

export default SearchDropdown;
