import mappet from "mappet";

function transformDate(updateTime: any) {
    return updateTime.toDate();
}
const schema = {
    id: "id",
    createTime: { path: "createTime", modifier: transformDate },
    type: "type",
    funnelId: "metaData.funnelId",
    applicantId: "metaData.applicantId",
    restaurantId: "metaData.restaurantId",
    position: "metaData.position",
};

export const notificationMapper = mappet(schema);
