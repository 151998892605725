import React from "react";
import PhoneNumber from "@components/PhoneNumber";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { setFocusedProfile } from "../../../features/funnels/reducer";
import placeholderAvatar from "../../../assets/images/placeholder_avatar.png";
import {
    ChatHeaderContainer,
    HeaderUserInfo,
    HeaderUserProfilePic,
    HeaderUserName,
    HeaderUserJob,
    OpenProfileButton,
} from "./styled";

const ChatHeader = ({ isModalChat }) => {
    const funnel = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap?.[state.funnels.focusedChat],
    );
    const dispatch = useAppDispatch();

    const openProfile = () => {
        dispatch(setFocusedProfile({ funnelId: funnel.id, funnelIds: [funnel.id] }));
    };

    return (
        <ChatHeaderContainer>
            <HeaderUserInfo>
                <HeaderUserProfilePic
                    src={funnel?.userMetaProfileImage || placeholderAvatar}
                />

                <div>
                    <HeaderUserName>
                        {funnel?.userMetaFirstName} {funnel?.userMetaLastName}
                    </HeaderUserName>

                    <HeaderUserJob>
                        {funnel?.userMetaPositionDetail || funnel?.userMetaPosition}
                    </HeaderUserJob>
                </div>
            </HeaderUserInfo>

            {isModalChat ? (
                <PhoneNumber />
            ) : (
                <OpenProfileButton onClick={openProfile}>Open Profile</OpenProfileButton>
            )}
        </ChatHeaderContainer>
    );
};

export default ChatHeader;
