import React, { useState } from "react";

import { StyleGuide } from "@constants/StyleGuide";
import {
    TemplateType,
    IntroTemplates,
    InterviewInviteTemplates,
    TrialInviteTemplates,
    HiredTemplates,
    Intl,
} from "@anna/shared";
import { useAppSelector } from "../../../store/store";
import { RejectionTemplates } from "@anna/shared";
import {
    IntroIcon,
    InterviewIcon,
    TrialIcon,
    OnboardingIcon,
    RejectIcon,
} from "@assets/images/icons";

import {
    SectionTitle,
    SectionDescription,
    TemplateItem,
    TemplateIcon,
    UseTemplateText,
    PlusIcon,
    ModalCtaContainer,
} from "./styled";
import PlandayPopup from "../../../components/CustomModals/ApplicantProfileModal/ApplicantActions/Planday/PlandayPopup";

const InterviewInviteTemplate = ({ onTemplateSelect }) => {
    const [showHoverLabel, toggleHoverLabel] = useState(false);
    const funnel = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap[state.funnels.focusedChat],
    );

    const restaurant = useAppSelector(
        state => state.restaurants.restaurantMap[funnel.restaurantId],
    );
    const senderFirstName = useAppSelector(state => state.user?.name);

    let template = "";
    switch (restaurant?.preferences?.language) {
        case Intl.da_DK:
            template = InterviewInviteTemplates.renderInterviewInviteTemplateDK({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;
        case Intl.de_DE:
            template = InterviewInviteTemplates.renderInterviewInviteTemplateDE({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;

        default:
            template = InterviewInviteTemplates.renderInterviewInviteTemplateEN({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;
    }

    return (
        <TemplateItem
            onClick={() => onTemplateSelect(TemplateType.INTERVIEW_INVITE, template)}
            onMouseEnter={() => toggleHoverLabel(true)}
            onMouseLeave={() => toggleHoverLabel(false)}>
            <TemplateIcon>
                <InterviewIcon />
            </TemplateIcon>
            Interview
            {showHoverLabel && <UseTemplateText>Use template</UseTemplateText>}
        </TemplateItem>
    );
};
const TrialInviteTemplate = ({ onTemplateSelect }) => {
    const [showHoverLabel, toggleHoverLabel] = useState(false);
    const funnel = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap[state.funnels.focusedChat],
    );

    const restaurant = useAppSelector(
        state => state.restaurants.restaurantMap[funnel.restaurantId],
    );
    const senderFirstName = useAppSelector(state => state.user?.name);

    let template = "";
    switch (restaurant?.preferences?.language) {
        case Intl.da_DK:
            template = TrialInviteTemplates.renderTrialInviteTemplateDK({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;
        case Intl.de_DE:
            template = TrialInviteTemplates.renderTrialInviteTemplateDE({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;

        default:
            template = TrialInviteTemplates.renderTrialInviteTemplateEN({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;
    }

    return (
        <TemplateItem
            onClick={() => onTemplateSelect(TemplateType.TRIAL_INVITE_WO_FILE, template)}
            onMouseEnter={() => toggleHoverLabel(true)}
            onMouseLeave={() => toggleHoverLabel(false)}>
            <TemplateIcon>
                <TrialIcon />
            </TemplateIcon>
            Trial
            {showHoverLabel && <UseTemplateText>Use template</UseTemplateText>}
        </TemplateItem>
    );
};
const HiredTemplate = ({ onTemplateSelect }) => {
    const [showHoverLabel, toggleHoverLabel] = useState(false);
    const funnel = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap[state.funnels.focusedChat],
    );

    const restaurant = useAppSelector(
        state => state.restaurants.restaurantMap[funnel.restaurantId],
    );
    const senderFirstName = useAppSelector(state => state.user?.name);

    let template = "";
    switch (restaurant?.preferences?.language) {
        case Intl.da_DK:
            template = HiredTemplates.danishTemplate({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
            break;
        case Intl.de_DE:
            template = HiredTemplates.germanTemplate({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
            break;

        default:
            template = HiredTemplates.englishTemplate({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
            break;
    }

    return (
        <TemplateItem
            onClick={() => onTemplateSelect(TemplateType.HIRED, template)}
            onMouseEnter={() => toggleHoverLabel(true)}
            onMouseLeave={() => toggleHoverLabel(false)}>
            <TemplateIcon>
                <OnboardingIcon />
            </TemplateIcon>
            Onboard
            {showHoverLabel && <UseTemplateText>Use template</UseTemplateText>}
        </TemplateItem>
    );
};
const RejectTemplate = ({ onTemplateSelect }) => {
    const [showHoverLabel, toggleHoverLabel] = useState(false);
    const funnel = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap[state.funnels.focusedChat],
    );

    const restaurant = useAppSelector(
        state => state.restaurants.restaurantMap[funnel.restaurantId],
    );
    const senderFirstName = useAppSelector(state => state.user?.name);

    let template = "";
    switch (restaurant?.preferences?.language) {
        case Intl.da_DK:
            template = RejectionTemplates.renderRejectionTemplateDK({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
            break;
        case Intl.de_DE:
            template = RejectionTemplates.renderRejectionTemplateDE({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
            break;

        default:
            template = RejectionTemplates.renderRejectionTemplateEN({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                // @ts-ignore
                senderFirstName,
            });
            break;
    }

    return (
        <TemplateItem
            onClick={() => onTemplateSelect(TemplateType.REJECTION, template)}
            onMouseEnter={() => toggleHoverLabel(true)}
            onMouseLeave={() => toggleHoverLabel(false)}>
            <TemplateIcon>
                <RejectIcon />
            </TemplateIcon>
            Reject
            {showHoverLabel && <UseTemplateText>Use template</UseTemplateText>}
        </TemplateItem>
    );
};
const IntroTemplate = ({ onTemplateSelect }) => {
    const [showHoverLabel, toggleHoverLabel] = useState(false);
    const funnel = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap[state.funnels.focusedChat],
    );

    const restaurant = useAppSelector(
        state => state.restaurants.restaurantMap[funnel.restaurantId],
    );
    const senderFirstName = useAppSelector(state => state.user?.name);

    let template = "";
    switch (restaurant?.preferences?.language) {
        case Intl.da_DK:
            template = IntroTemplates.renderIntroTemplateDK({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;
        case Intl.de_DE:
            template = IntroTemplates.renderIntroTemplateDE({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;

        default:
            template = IntroTemplates.renderIntroTemplateEN({
                applicantName: funnel?.userMetaFirstName,
                restaurantTitle: restaurant.title,
                senderFirstName,
            });
            break;
    }

    return (
        <TemplateItem
            onClick={() => onTemplateSelect(TemplateType.INTRO, template)}
            onMouseEnter={() => toggleHoverLabel(true)}
            onMouseLeave={() => toggleHoverLabel(false)}>
            <TemplateIcon>
                <IntroIcon />
            </TemplateIcon>
            Intro
            {showHoverLabel && <UseTemplateText>Use template</UseTemplateText>}
        </TemplateItem>
    );
};

const TemplateCard = ({
    label,
    Icon,
    fieldKey,
    defaultTemplateText,
    onTemplateSelect,
}) => {
    const [showHoverLabel, toggleHoverLabel] = useState(false);

    return (
        <TemplateItem
            onClick={() => onTemplateSelect(fieldKey, defaultTemplateText)}
            onMouseEnter={() => toggleHoverLabel(true)}
            onMouseLeave={() => toggleHoverLabel(false)}>
            <TemplateIcon>
                <Icon />
            </TemplateIcon>

            {label}

            {showHoverLabel && <UseTemplateText>Use template</UseTemplateText>}
        </TemplateItem>
    );
};

const TemplatesList = ({ onTemplateSelect, BackToProfileButton }) => {
    return (
        <>
            <SectionTitle>Select a template</SectionTitle>

            <SectionDescription>
                Edit your templates in the mobile app, desktop coming soon!
            </SectionDescription>
            <IntroTemplate onTemplateSelect={onTemplateSelect} />
            <InterviewInviteTemplate onTemplateSelect={onTemplateSelect} />
            <TrialInviteTemplate onTemplateSelect={onTemplateSelect} />
            <HiredTemplate onTemplateSelect={onTemplateSelect} />
            <RejectTemplate onTemplateSelect={onTemplateSelect} />

            {/* <TemplateItem marginTop={StyleGuide.spacing.base}>
                <TemplateIcon>
                    <PlusIcon />
                </TemplateIcon>
                Add to Planday
            </TemplateItem> */}
            {/* <PlandayPopup /> */}

            {BackToProfileButton && (
                <ModalCtaContainer>{BackToProfileButton}</ModalCtaContainer>
            )}
        </>
    );
};

export default TemplatesList;
