import React, { useState } from "react";

import { StyleGuide } from "@constants/StyleGuide";
import { ReactComponent as SendIcon } from "@assets/svg/send.svg";
import { Loader } from "../../Auth/components/Buttons/styled";
import {
    TemplateInfoButton,
    InfoContainer,
    InfoIcon,
    CancelButton,
    TemplateSendButton,
    SendIconContainer,
} from "./styled";

export const InfoButton = ({ label, Icon, onPress }) => {
    return (
        <TemplateInfoButton onClick={onPress}>
            <InfoContainer>
                <InfoIcon>{Icon}</InfoIcon>
                {label}
            </InfoContainer>

            <CancelButton>Cancel</CancelButton>
        </TemplateInfoButton>
    );
};

export const SendButton = ({ label, onPress, isDisabled = false }) => {
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const handleClick = async () => {
        setIsSendingMessage(true);
        await onPress();
        setIsSendingMessage(false);
    };
    return (
        <TemplateSendButton onClick={handleClick} isDisabled={isDisabled}>
            {label}
            {!isDisabled && (
                <SendIconContainer>
                    {isSendingMessage ? (
                        <Loader
                            color={StyleGuide.palette.baseSalt}
                            backgroundColor={StyleGuide.palette.secondaryBasil}
                        />
                    ) : (
                        <SendIcon fill={StyleGuide.palette.baseSalt} />
                    )}
                </SendIconContainer>
            )}
        </TemplateSendButton>
    );
};
