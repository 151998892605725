import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";
import { RequestState } from "@constants/enums";
import { Key } from "./useFunnelSearchKey";

interface FirebaseUser {
    uid: string;
    email?: string;
    lastSeenActivityCenter?: Date;
}

// Define the initial state using that type
const initialState: FirebaseUser &
    Partial<
        Contracts.DBUserShape & {
            funnelSearchKey: Key | undefined;
            funnelSearchKeyState: RequestState;
        }
    > = {
    uid: "",
    isAccountSetup: false,
    name: "",
    preferences: {},
    funnelSearchKey: undefined,
    funnelSearchKeyState: RequestState.IDLE,
};

export const userSlice = createSlice({
    name: "user",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setFirebaseUser(state, action: PayloadAction<FirebaseUser>) {
            return {
                ...state,
                ...action.payload,
            };
        },
        setUser(state, action: PayloadAction<Partial<Contracts.DBUserShape>>) {
            return {
                ...state,
                ...action.payload,
            };
        },
        setFunnelSearchKey(state, action: PayloadAction<Key | undefined>) {
            return {
                ...state,
                funnelSearchKey: action.payload,
                funnelSearchKeyState: RequestState.SUCCESS,
            };
        },
        setFunnelSearchKeyState(
            state,
            action: PayloadAction<RequestState.LOADING | RequestState.ERROR>,
        ) {
            return {
                ...state,
                funnelSearchKeyState: action.payload,
            };
        },
    },
});

export const { setFirebaseUser, setUser, setFunnelSearchKey, setFunnelSearchKeyState } =
    userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user;
export const selectUserId = (state: RootState) => state.user.uid;

export default userSlice.reducer;
