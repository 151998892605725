import { useAppSelector } from "../../store/store";
import { selectFocusedChat } from "../funnels/reducer";

export function useChatBubble(id: string) {
    const funnelId = useAppSelector(selectFocusedChat);
    const applicantName = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap?.[funnelId]?.userMetaFirstName,
    );
    const applicantImage = useAppSelector(
        // @ts-ignore
        state => state.funnels.funnelMap?.[funnelId]?.userMetaProfileImage,
    );
    const message = useAppSelector(state => state.activity.chatMap[id]);
    const userId = useAppSelector(state => state.user.uid);
    return { message, userId, applicantName, applicantImage };
}
