import React from "react";
import isEqual from "lodash.isequal";

import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { StyleGuide } from "@constants/StyleGuide";

import { useAppSelector, useAppDispatch } from "../../store/store";
import {
    selectSortedRestaurantOptions,
    selectSelectedRestaurantIds,
    setSelectedRestaurants,
} from "../../features/restaurants/reducer";
import MenuDropdown from "../Dropdowns/MenuDropdown";
import {
    RestaurantPickerContainer,
    RestaurantBubble,
    ToggleBubble,
    ToggleBubbleCounter,
} from "./styled";

const RestaurantsPicker = () => {
    const dispatch = useAppDispatch();
    // isEqual as custom comparison func to prevent unnecessary re-renders
    // see https://react-redux.js.org/api/hooks#equality-comparisons-and-updates.
    const restaurants = useAppSelector(selectSortedRestaurantOptions, isEqual);
    const selectedRestaurants = useAppSelector(selectSelectedRestaurantIds, isEqual);

    const sortedRestaurants = React.useMemo(() => {
        return [...restaurants].sort((r1, r2) => {
            const r1Selected = selectedRestaurants.findIndex(sId => sId === r1.id) !== -1;
            const r2Selected = selectedRestaurants.findIndex(sId => sId === r2.id) !== -1;

            return r1Selected && r2Selected ? 0 : r1Selected ? -1 : 1;
        });
    }, [restaurants, selectedRestaurants]);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const [frontList, setFrontList] = React.useState<any[]>([]);
    const [dropdownList, setDropdownList] = React.useState<any[]>([]);

    React.useLayoutEffect(() => {
        if (sortedRestaurants?.length && containerRef.current) {
            const maxWidth = containerRef.current.offsetWidth;

            const widths = sortedRestaurants.map(restaurant => {
                const ruler = document.createElement("div");
                ruler.style.width = "auto";
                ruler.style.position = "absolute";
                ruler.style.whiteSpace = "nowrap";
                ruler.style.fontFamily = '"Roboto", sans-serif';
                ruler.style.fontSize = "16px";
                ruler.style.fontWeight = "500";
                ruler.style.padding = `${StyleGuide.spacing.small}px`;
                ruler.innerText = restaurant.value;

                containerRef.current!.appendChild(ruler);
                const textWidth = ruler.offsetWidth;
                containerRef.current!.removeChild(ruler);

                return textWidth;
            });

            let currentWidth = 0;
            let maxIndex = 0;
            widths.findIndex(width => {
                let nextWidth = currentWidth;
                // add margin if not first child
                if (nextWidth !== 0) {
                    nextWidth += StyleGuide.spacing.mini;
                }
                nextWidth += width;

                if (nextWidth > maxWidth) {
                    return true;
                }

                maxIndex++;
                currentWidth = nextWidth;
                return false;
            });

            if (maxIndex !== 0) {
                if (currentWidth + StyleGuide.spacing.mini + 40 > maxWidth) {
                    maxIndex -= 1;
                }
            }

            setFrontList(maxIndex <= 0 ? [] : sortedRestaurants.slice(0, maxIndex));
            setDropdownList(
                maxIndex <= 0
                    ? [...sortedRestaurants]
                    : sortedRestaurants.slice(maxIndex),
            );
        }
    }, [sortedRestaurants]);

    const toggleRestaurant = (selectedRestaurant: string) => {
        if (restaurants.length <= 1) {
            return;
        }
        dispatch(setSelectedRestaurants(selectedRestaurant));
    };

    const dropdownSelectedCount = dropdownList.filter(option =>
        selectedRestaurants.some(restaurantId => restaurantId === option.id),
    ).length;

    return (
        <RestaurantPickerContainer ref={containerRef}>
            {frontList.map(({ id, value }) => {
                const isSelected = selectedRestaurants.some(
                    restaurantId => restaurantId === id,
                );
                return (
                    <RestaurantBubble
                        key={id}
                        isSelected={isSelected}
                        onClick={() => toggleRestaurant(id)}>
                        {value}
                    </RestaurantBubble>
                );
            })}

            {dropdownList.length ? (
                <MenuDropdown
                    options={dropdownList}
                    onSelect={(event, option: { id; value }) => {
                        event.stopPropagation();
                        toggleRestaurant(option.id);
                    }}
                    selected={selectedRestaurants}
                    renderToggleBlock={(opened: boolean) => (
                        <ToggleBubble>
                            <ArrowUp
                                style={{
                                    transform: opened ? "rotate(0deg)" : "rotate(180deg)",
                                }}
                            />

                            {Boolean(dropdownSelectedCount) && (
                                <ToggleBubbleCounter>
                                    {dropdownSelectedCount}
                                </ToggleBubbleCounter>
                            )}
                        </ToggleBubble>
                    )}></MenuDropdown>
            ) : null}
        </RestaurantPickerContainer>
    );
};

export default RestaurantsPicker;
