export function sortRestaurantOptionsAlphabetically(a, b) {
    const aLowerCased = a.value?.toLowerCase();
    const bLowerCased = b.value?.toLowerCase();
    if (aLowerCased < bLowerCased) {
        return -1;
    }
    if (aLowerCased > bLowerCased) {
        return 1;
    }
    return 0;
}
