import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderInterviewInviteTemplateDE({
    applicantName = TemplateVariables.APPLICANT_NAME,
    interviewLocation = TemplateVariables.LOCATION,
    inviteLink = TemplateVariables.TIME_SLOTS,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hi ${applicantName}

vielen Dank für deine Bewerbung als bei ${restaurantTitle}.
Deine Bewerbung hat uns überzeugt und wir würden dich gerne persönlich treffen und uns mit dir unterhalten.

Klick hier um einen Termin zu bestaetigen: ${inviteLink}

Die Addresse ist: ${interviewLocation}.

Wir freuen uns, dich bald kennenzulernen.

Beste Grüße
${senderFirstName}`;
}
