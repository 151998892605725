import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { selectRestaurantIdsToQuery } from "../restaurants/reducer";
import { setHomePositionCount } from "./reducer";
import { NotificationType } from "@anna/shared";

export default function useHomePositionCount(position: string) {
    const dispatch = useAppDispatch();
    const restaurantIds = useAppSelector(selectRestaurantIdsToQuery);

    const newApplicants = useAppSelector(
        state => state.notifications.notificationByType[NotificationType.NEW_APPLICATION],
    );

    const rIds = useMemo(() => restaurantIds.filter((_, i) => i < 9), [restaurantIds]);

    const count = useMemo(
        () =>
            newApplicants
                ?.filter(n => rIds.includes(n?.restaurantId))
                ?.filter(n => n?.position === position)?.length,
        [newApplicants, rIds, position],
    );

    useEffect(() => {
        try {
            dispatch(
                setHomePositionCount({
                    position,
                    count,
                }),
            );
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [count]);
}
