import {
    PlandayGenderItem,
    PlandayGenderPicker,
    PlandayGenderSeparator,
} from "../styled";
import React from "react";
import * as ApplicationsAPI from "../../../../../features/applications/api";
import { Gender } from "@anna/shared";

type PlandayGenderSelectProps = {
    loading: boolean;
    applicantId: string;
    enrichedApplicantId?: string;
    gender?: Gender;
};

export default function PlandayGenderSelect({
    loading,
    applicantId,
    enrichedApplicantId,
    gender,
}: PlandayGenderSelectProps) {
    if (loading) return null;
    return (
        <PlandayGenderPicker>
            <PlandayGenderItem
                isSelected={gender === "male"}
                onClick={() =>
                    ApplicationsAPI.updateGender({
                        applicantId,
                        enrichedId: enrichedApplicantId,
                        gender: "male",
                    })
                }>
                Male
            </PlandayGenderItem>

            <PlandayGenderSeparator />

            <PlandayGenderItem
                isSelected={gender === "female"}
                onClick={() =>
                    ApplicationsAPI.updateGender({
                        applicantId,
                        enrichedId: enrichedApplicantId,
                        gender: "female",
                    })
                }>
                Female
            </PlandayGenderItem>
        </PlandayGenderPicker>
    );
}
