import { Client } from "./client";
// prettier-ignore
export function Api(client: Client) {
    return {
        sendChatMessage: (dto: Contracts.SendChatMessage) => client.execute("/api/app/messaging/twilio/send", dto),
        callApplicant: (dto: Contracts.CallApplicant) => client.execute("/api/app/messaging/applicant/call", dto),
        intro: (dto: Contracts.Intro) => client.execute("/api/app/applicant/intro", dto),
        inviteForInterview: (dto: Contracts.InviteForInterview) => client.execute("/api/app/applicant/interview/invite", dto),
        rescheduleInterview: (dto: Contracts.RescheduleInterview) => client.execute("/api/app/applicant/interview/reschedule", dto),
        inviteForTrial: (dto: Contracts.InviteForTrial) => client.execute("/api/app/applicant/invite/trial/custom", dto),
        reject: (dto: Contracts.Reject) => client.execute("/api/app/applicant/rejection", dto),
        review: (dto: Contracts.Review) => client.execute("/api/app/applicant/review", dto),
        undoReview: (dto: Contracts.Review) => client.execute("/api/app/applicant/review/undo", dto),
        hire: (dto: Contracts.Hire) => client.execute("/api/app/applicant/hire", dto),
        cancelInterview: (dto: Contracts.CancelInterview) => client.execute("/api/app/applicant/interview/cancel", dto),
        rescheduleTrial: (dto: Contracts.RescheduleTrial) => client.execute("/api/app/applicant/trial/reschedule", dto),
        cancelTrial: (dto: Contracts.CancelTrial) => client.execute("/api/app/applicant/trial/cancel", dto),
        revokeCalendar: (dto: Contracts.RevokeCalendar) => client.execute("/api/app/user/calendars/revoke", dto),
        bulkRejectNewApplicantsByPosition: (dto: Contracts.BulkRejectNewApplicantsByPosition) => client.execute("/bulkRejectNewApplicantsOnSnooze", dto),
        syncEmployeeToPlanday: (dto: Contracts.SyncEmployeeToPlanday) => client.execute("/addEmployeeToPlanday", dto),
        manualStateChange: (dto: Contracts.ManualStateChange) => client.execute("/manualStateChange", dto),
        inAppFeedback: (dto: Contracts.Feedback) => client.execute("/inAppFeedback", dto),
        getPlandayDepartments: (dto: Contracts.PlandayDepartments) => client.execute<Contracts.Department[]>("/getPlandayDepartments", dto, true),
        getSharedPoolApplications: (dto: Contracts.SharedPoolQueryRequest) => client.execute<Contracts.BQSharedPoolApplication>("/api/app/user/sharedPool/applications", dto, true),
        favoriteSharedPoolApplicant: (dto: Contracts.SharedPoolActionFavourite) => client.execute("/api/app/user/sharedPool/favourite", dto),
        unfavoriteSharedPoolApplicant: (dto: Contracts.SharedPoolActionUnfavourite) => client.execute("/api/app/user/sharedPool/unfavourite", dto),
        getIntroductionSharedPoolApplicant: (dto: Contracts.SharedPoolActionIntroduction) => client.execute("/api/app/user/sharedPool/introduction", dto),
        createBulkRejectionJob: (dto: Contracts.BulkRejectJobRequest) => client.execute("/createBulkRejectJob", dto),
        removeFromSharedPool: (dto: Contracts.SharedPoolActionRemove) => client.execute("/api/app/user/sharedPool/remove", dto),
        confirmStaffInterest: (dto: Contracts.ConfirmStaffInterest) => client.execute("/api/app/user/sharedPool/confirmStaffInterest", dto),
        sendStaffRequest: (dto: Contracts.SendStaffRequest) => client.execute("/api/app/user/sharedPool/sendStaffRequest", dto),
        funnelSearchKey: (dto: Contracts.FunnelSearchKeyRequest) => client.execute("/getScopedTypesenseKey", dto),
    };
}
