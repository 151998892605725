import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    padding: 0 ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: space-between;
`;

export const CategoryPickerContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 56px;
`;

type CategoryItemProps = {
    isSelected?: boolean;
    isLast?: boolean;
};

export const CategoryItem = styled.div<CategoryItemProps>`
    padding-top: ${StyleGuide.spacing.small}px;
    padding-bottom: 14px;
    color: ${props =>
        props.isSelected
            ? StyleGuide.palette.secondaryCucumber
            : StyleGuide.palette.baseVanilla};
    margin-right: ${props =>
        props.isLast ? StyleGuide.spacing.base : StyleGuide.spacing.large}px;
    cursor: pointer;
    ${props =>
        props.isSelected ? StyleGuide.typography.headline1 : StyleGuide.typography.body1};
    border-bottom: ${props =>
        props.isSelected
            ? `2px solid ${StyleGuide.palette.secondaryCucumber}`
            : "2px solid transparent"};
`;

export const ToggleBubble = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    margin-top: ${StyleGuide.spacing.small}px;
    margin-bottom: ${StyleGuide.spacing.small}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type FilterBubbleProps = {
    isSelected?: boolean;
};

export const FilterBubble = styled.div<FilterBubbleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${StyleGuide.spacing.large}px;
    height: ${StyleGuide.spacing.large}px;
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSeaSalt : StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.base}px;
    cursor: pointer;
    :hover {
        background-color: ${StyleGuide.palette.baseMsg};
    }
`;

type TimeFilterRowOptionProps = {
    isLast?: boolean;
    isSelected?: boolean;
};

export const TimeFilterRowOption = styled.div<TimeFilterRowOptionProps>`
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px;
    background: ${props =>
        props.isSelected
            ? StyleGuide.palette.secondaryBasil
            : StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.base}px;
    margin-right: ${props =>
        props.isLast ? StyleGuide.spacing.small : StyleGuide.spacing.mini}px;

    cursor: pointer;
    text-align: left;
    ${props =>
        props.isSelected ? StyleGuide.typography.headline1 : StyleGuide.typography.body1};

    color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSugar : StyleGuide.palette.baseVanilla};
`;

export const TimeOptionIcon = styled.div`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    align-items: center;
    margin-right: ${StyleGuide.spacing.mini}px;
`;

export const ToggleBubbleCounter = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: ${StyleGuide.spacing.small}px;
    background-color: ${StyleGuide.palette.secondaryBasil};
    ${() => StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.baseSalt};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
