import styled, { css } from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

const FocusedInput = css`
    background: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.secondaryMint};
    box-shadow: 0px 0px 0px 3px #eef5f5;
`;

const PlaceholderStyle = css`
    opacity: 1;
    color: ${StyleGuide.palette.baseVanilla};
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
`;

export const Input = styled.input`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};
    box-sizing: border-box;
    border: 1px solid transparent;
    outline: none;
    height: 48px;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    background-color: ${StyleGuide.palette.baseSugar};
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        ${() => PlaceholderStyle}
    }
    &::placeholder {
        ${() => PlaceholderStyle}
    }

    &:focus {
        ${FocusedInput}
    }

    &:hover {
        ${FocusedInput}
    }
`;

export default Input;
