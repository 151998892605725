import { TemplateVariables } from "@anna/shared";
import { TemplateProps } from "./types";
export default ({
    applicantName = TemplateVariables.APPLICANT_NAME,
    hiredLink = TemplateVariables.HIRE_LINK,
    senderFirstName = TemplateVariables.SENDER_NAME,
}: TemplateProps) => `Hej ${applicantName},

Før vi kan lave en kontrakt til dig, skal vi lige har formaliteterne på plads.

Udfyld venligst dette skema: ${hiredLink}

Tak!

Bedste hilsner
${senderFirstName}`;
