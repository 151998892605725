import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { MoveActionContainer, ActionTitle, ActionIcon, MoveLabel } from "./styled";
import { StyleGuide } from "../../../../constants/StyleGuide";

const MoveAction = ({ title, Icon, label = "Move", onClick }) => {
    const [isLoading, setisLoading] = useState(false);
    const [isHovered, toggleHovered] = useState(false);
    return (
        <MoveActionContainer
            onMouseEnter={() => toggleHovered(true)}
            onMouseLeave={() => toggleHovered(false)}
            disabled={isLoading}
            onClick={async () => {
                setisLoading(true);
                await onClick();
                setisLoading(false);
            }}>
            <ActionTitle isHovered={isHovered}>
                <ActionIcon>
                    {isLoading ? (
                        <ClipLoader
                            color={StyleGuide.palette.secondaryBasil}
                            loading
                            size={10}
                        />
                    ) : (
                        <Icon isFocused={isHovered} />
                    )}
                </ActionIcon>
                {title}
            </ActionTitle>

            {isHovered && <MoveLabel>{label}</MoveLabel>}
        </MoveActionContainer>
    );
};

export default MoveAction;
