import React from "react";

import InterviewTemplate from "./UserTempateMessages/InterviewTemplate";
import TrialTemplate from "./UserTempateMessages/TrialTemplate";
import RejectTemplate from "./UserTempateMessages/RejectTemplate";
import OnboardingTemplate from "./UserTempateMessages/OnboardingTemplate";
import AutoReplyBubble from "./AutoReplyBubble";
import { Bubble, MessageBubble } from "./MessageBubble";
import { ActivityType, RejectReason, ActivityMessageSource } from "@anna/shared";
import { useChatBubble } from "../../features/activity/useChatbubble";
import InterviewConfirmTemplate from "@components/ChatFeed/ApplicantTemplates/InterviewConfirmed";
import TrialConfirmTemplate from "./ApplicantTemplates/TrialConfirmed";
import FavoriteTemplate from "./UserTempateMessages/Favorite";
import UnFavoriteTemplate from "./UserTempateMessages/UnFavorite";
import InterviewCancelTemplate from "./ApplicantTemplates/InterviewCancel";
import TrialCancelTemplate from "./ApplicantTemplates/TrialCancel";
import CalledTemplate from "./UserTempateMessages/Called";
import InterviewRescheduleTemplate from "./ApplicantTemplates/InterviewReschedule";
import TrialRescheduleTemplate from "./ApplicantTemplates/TrialReschedule";
import ContactedAcceptedTemplate from "./ApplicantTemplates/ContactAccepted";
import ContractInfoTemplate from "@components/ChatFeed/ApplicantTemplates/ContractInfo";
import ContactedRequestTemplate from "./UserTempateMessages/ContactRequest";
import InterviewFinishedTemplate from "./InterviewFinished";
import TrialFinishedTemplate from "./TrialFinished";
import HiredManualTemplate from "./UserTempateMessages/HiredManual";
import ContactedManualTemplate from "./UserTempateMessages/ContactedManualTemplate";
import RejectedManualTemplate from "@components/ChatFeed/UserTempateMessages/RejectedManualTemplate";

const ChatBubble = ({ id }) => {
    let bubbleComponent;
    const { message, userId, applicantName, applicantImage } = useChatBubble(id);

    const isInternal = message.authorSource !== ActivityMessageSource.EXTERNAL;
    const isCurrentUser = message.authorId === userId;
    //TODO: Auto reply wiring logic

    if (message.type === ActivityType.ACTION_APPLIED) {
        return (
            <AutoReplyBubble
                createTime={message.createTime}
                isHiring
                message={message.metaDataMessage}
            />
        );
    }
    if (
        message.type === ActivityType.ACTION_REJECT &&
        message.metaDataRejectionReason === RejectReason.SNOOZED
    ) {
        return (
            <AutoReplyBubble
                createTime={message.createTime}
                message={message.metaDataMessage}
            />
        );
    }
    if (message.type === ActivityType.ACTION_INTERVIEWED) {
        return <InterviewFinishedTemplate startTime={message.metaDataStartTime} />;
    }
    if (message.type === ActivityType.ACTION_TRIALED) {
        return <TrialFinishedTemplate startTime={message.metaDataStartTime} />;
    }

    //TODO: Templates bubbles switch
    switch (message.type) {
        case ActivityType.ACTION_REVIEWED:
            bubbleComponent = <FavoriteTemplate applicantName={applicantName} />;
            break;
        case ActivityType.ACTION_UNDO_REVIEWED:
            bubbleComponent = <UnFavoriteTemplate />;
            break;
        case ActivityType.ACTION_INTERESTED:
            bubbleComponent = <ContactedManualTemplate />;
            break;
        case ActivityType.ACTION_INTRO:
            bubbleComponent = (
                <MessageBubble isCurrentUser message={message.metaDataMessage} />
            );
            break;
        case ActivityType.ACTION_USER_CALL:
            bubbleComponent = <CalledTemplate applicantName={applicantName} />;
            break;
        case ActivityType.ACTION_INTERVIEW_PENDING:
            bubbleComponent = (
                <InterviewTemplate
                    timeSlots={message.metaDataTimeSlots}
                    message={message.metaDataMessage}
                />
            );
            break;
        case ActivityType.ACTION_INTERVIEW_CANCELED:
            if (message.metaDataIsReschedule) {
                bubbleComponent = (
                    <InterviewRescheduleTemplate message={message.metaDataMessage} />
                );
                break;
            } else {
                bubbleComponent = (
                    <InterviewCancelTemplate message={message.metaDataMessage} />
                );
                break;
            }

        case ActivityType.ACTION_INTERVIEW_SCHEDULED:
            bubbleComponent = (
                <InterviewConfirmTemplate
                    startTime={message.metaDataStartTime}
                    // message={message.metaDataMessage}
                />
            );
            break;
        case ActivityType.ACTION_TRIAL_PENDING:
            bubbleComponent = (
                <TrialTemplate
                    timeSlots={message.metaDataPreference}
                    message={message.metaDataMessage}
                />
            );
            break;
        case ActivityType.ACTION_TRIAL_CANCELED:
            if (message.metaDataIsReschedule) {
                bubbleComponent = (
                    <TrialRescheduleTemplate message={message.metaDataMessage} />
                );
                break;
            } else {
                bubbleComponent = (
                    <TrialCancelTemplate message={message.metaDataMessage} />
                );
                break;
            }
        case ActivityType.ACTION_TRIAL_SCHEDULED:
            bubbleComponent = (
                <TrialConfirmTemplate
                    startTime={message.metaDataStartTime}
                    // message={message.metaDataMessage}
                />
            );
            break;
        case ActivityType.ACTION_REJECT:
            bubbleComponent = message?.metaDataManual ? (
                <RejectedManualTemplate applicantName={applicantName} />
            ) : (
                <RejectTemplate message={message.metaDataMessage} />
            );
            break;
        case ActivityType.ACTION_HIRE:
        case ActivityType.ACTION_HIRED:
            bubbleComponent = message.metaDataManual ? (
                <HiredManualTemplate />
            ) : (
                <OnboardingTemplate message={message.metaDataMessage} />
            );
            break;

        case ActivityType.ACTION_CONTRACT_CONFIRMED:
            bubbleComponent = <ContractInfoTemplate />;
            break;
        case ActivityType.ACTION_CONTACTED_PENDING:
            bubbleComponent = <ContactedRequestTemplate />;
            break;
        case ActivityType.ACTION_CONTACTED_ACCEPTED:
            bubbleComponent = <ContactedAcceptedTemplate />;
            break;
        default:
            bubbleComponent = (
                <MessageBubble
                    isCurrentUser={isCurrentUser}
                    message={message.USER_MESSAGE}
                />
            );
            break;
    }

    return (
        <Bubble
            isCurrentUser={isCurrentUser}
            isInternal={isInternal}
            image={isInternal ? message.authorImage : applicantImage}
            name={isInternal ? message.authorDisplayName : applicantName}
            createTime={message.createTime}>
            {bubbleComponent}
        </Bubble>
    );
};

export default ChatBubble;
