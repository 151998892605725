import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";

export const getRestaurantUserTemplates = (restaurantId: string, uid: string) => {
    return firestore
        .collection(COLLECTIONS.RESTAURANTS)
        .doc(restaurantId)
        .collection(COLLECTIONS.TEMPLATES)
        .doc(uid);
};
