import { TemplateProps } from "./types";
import { TemplateVariables } from "@anna/shared";

export function renderRejectionTemplateDE({
    applicantName = TemplateVariables.APPLICANT_NAME,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) {
    return `Hi ${applicantName},

nochmals vielen Dank, dass du dich bei ${restaurantTitle} beworben hast. Wir haben uns leider für einen anderen Bewerber entschieden.

Weiterhin alles Gute für dich und beste Grüße.

${senderFirstName}`;
}
