import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";
import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";

export const Container = styled.div`
    padding: ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.small}px;

    background: ${StyleGuide.palette.baseSalt};
    box-shadow: 0px 12px 44px -8px rgba(0, 0, 0, 0.25);
    border-radius: ${StyleGuide.spacing.xsmall}px;
    box-sizing: border-box;

    .react-calendar {
        border: 0px solid #fff;
    }

    .react-calendar__month-view__weekdays {
        ${StyleGuide.typography.footnote};
        color: ${StyleGuide.palette.baseVanilla};
        font-weight: normal;
        text-transform: initial;
    }

    .react-calendar__month-view__weekdays__weekday {
        padding: 0;
    }

    .react-calendar__tile {
        ${StyleGuide.typography.body2};
        color: ${StyleGuide.palette.basePepper};
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .react-calendar__tile abbr {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .react-calendar__month-view__days__day--weekend {
        ${StyleGuide.typography.body2};
        color: ${StyleGuide.palette.basePepper};
    }

    .react-calendar__tile--active {
        background-color: transparent;
    }

    .react-calendar__tile:disabled {
        background-color: transparent;
        color: ${StyleGuide.palette.baseSeaSalt};
    }

    .react-calendar__tile:hover {
        background-color: transparent;
    }

    .react-calendar__tile--now {
        background-color: transparent;
    }

    .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation__next2-button {
        display: none;
    }

    .react-calendar__navigation button:enabled:hover {
        background-color: transparent;
    }

    .react-calendar__navigation button:enabled:focus {
        background-color: transparent;
    }

    .react-calendar__navigation button[disabled] {
        background-color: transparent;
    }

    .react-calendar__navigation {
        height: 24px;
        justify-content: flex-end;
        align-items: center;
    }

    .react-calendar__navigation__label {
        position: absolute;
        left: 16px;
        text-align: left;
        pointer-events: none;
        ${StyleGuide.typography.body1};
        color: ${StyleGuide.palette.basePepper};
    }

    .react-calendar__navigation__prev-button {
        min-width: 16px;
        padding: 0;
        margin-right: 16px;
    }

    .react-calendar__navigation__next-button {
        min-width: 16px;
        padding: 0;
    }

    .react-calendar__tile--active:enabled:hover {
        background: transparent;
    }

    .react-calendar__tile--active:enabled:focus {
        background: transparent;
    }

    abbr {
        text-decoration: none !important;
    }
`;

export const PrevArrow = styled(ArrowUp)`
    width: 9px;
    height: 9px;
    transform: rotate(-90deg);
`;

export const NextArrow = styled(ArrowUp)`
    width: 9px;
    height: 9px;
    transform: rotate(90deg);
`;

export const SelectedDateCircle = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background-color: ${StyleGuide.palette.secondaryCucumber};
    position: absolute;
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.baseSalt};
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PickedDateCircle = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid ${StyleGuide.palette.secondaryCucumber};
    position: absolute;
`;

export const BookedDateCircle = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${StyleGuide.palette.primaryAnanas};
    position: absolute;
    bottom: 0;
`;
