import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, TemplateBubbleMessage, InterviewIcon } from "./styled";

const InterviewRescheduleTemplate = ({ message }) => {
    return (
        <TemplateBubbleContainer darkText>
            <ActionIconText
                darkText
                title="Needs to reschedule"
                Icon={<InterviewIcon />}
            />

            {Boolean(message) && (
                <TemplateBubbleMessage darkText>{message}</TemplateBubbleMessage>
            )}
        </TemplateBubbleContainer>
    );
};

export default InterviewRescheduleTemplate;
