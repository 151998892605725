import React from "react";

import { timeSlotsDateFormat } from "@utils/dates";
import { SelectedDateCircle, PickedDateCircle, BookedDateCircle } from "./styled";

const TileContent = ({
    date,
    view,
    selectedDate,
    selectedCustomTimes,
    forInterview,
    bookings,
}) => {
    if (view !== "month") {
        return <div />;
    }

    let isSelectedDay = false;
    let isDayAlreadySelected = false;
    const isDayBooked = Boolean(bookings && bookings[timeSlotsDateFormat(date)]);
    if (forInterview) {
        isSelectedDay = timeSlotsDateFormat(date) === timeSlotsDateFormat(selectedDate);
        isDayAlreadySelected = selectedCustomTimes.hasOwnProperty(
            timeSlotsDateFormat(date),
        );
    } else {
        isSelectedDay = selectedCustomTimes.hasOwnProperty(timeSlotsDateFormat(date));
    }

    const renderCircle = () => {
        return isSelectedDay ? (
            <SelectedDateCircle>{date.getDate()}</SelectedDateCircle>
        ) : isDayAlreadySelected ? (
            <PickedDateCircle />
        ) : (
            <div />
        );
    };

    return (
        <>
            {renderCircle()}

            {isDayBooked ? <BookedDateCircle /> : <div />}
        </>
    );
};

export default TileContent;
