import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { FunnelState } from "../funnels/reducer";
import * as ActivityEventAPI from "./api";
import { filterUserActivityEvents, transformActivityEvent } from "./useActivityEvents";

const TAG = "UnseenUserActivityEvents";

export function useUnseenUserActivityEvents() {
    const rid = useSelector<RootState, string | null>(
        state => state.restaurants.selectedActivityRestaurant,
    ) as string;
    const uid = useSelector<RootState, string>(state => state.user.uid);
    const lastSeenActivityCenter = useSelector<RootState, Date | undefined>(
        state => state.user?.lastSeenActivityCenter,
    );

    const [activityEvents, setActivityEvents] = useState<Contracts.DBActivityEvent[]>();

    useEffect(() => {
        let unsubscribe: (() => void) | null = null;

        if (uid && rid && lastSeenActivityCenter) {
            unsubscribe = ActivityEventAPI.getUserActivityEventsAfter(
                uid,
                rid,
                lastSeenActivityCenter,
            ).onSnapshot(
                query =>
                    setActivityEvents(
                        filterUserActivityEvents(
                            transformActivityEvent<Contracts.DBActivityEvent>(query),
                        ),
                    ),
                // TODO: add error logging
                //error =>
                // Logger.notify(
                //     `${TAG}: Failed to fetch user unseen activity events for user ${uid} and date ${lastSeenActivityCenter}. ${error}`,
                // ),
            );
        }

        return () => unsubscribe?.();
    }, [uid, rid, lastSeenActivityCenter]);

    return activityEvents;
}
