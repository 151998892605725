import React, { useState } from "react";

import Slider from "@components/Slider";
import {
    mediumLocalDateFormat,
    stepValueToHalfHourIntervalInMinutes,
    convertMinutesToHoursAndMinutes,
} from "@utils/dates";
import {
    TrialRangePickersContainer,
    RangePickerContainer,
    RangePickerTitle,
} from "./styled";

const minStartTimeStep = 0;
const maxEndTimeStep = 47;

const RangePicker = ({ date, values, onChange }) => {
    const [isHovered, toggleHoverState] = useState(false);

    const rangeLabels = values.map(range => {
        const minutes = stepValueToHalfHourIntervalInMinutes(range);
        return convertMinutesToHoursAndMinutes(minutes);
    });

    return (
        <RangePickerContainer
            onMouseEnter={() => toggleHoverState(true)}
            onMouseLeave={() => toggleHoverState(false)}>
            <RangePickerTitle>
                <div>{mediumLocalDateFormat(new Date(date))}</div>

                <div>
                    {rangeLabels[0]} - {rangeLabels[1]}
                </div>
            </RangePickerTitle>

            <Slider
                defaultValue={values}
                min={minStartTimeStep}
                max={maxEndTimeStep}
                step={1}
                onChange={newValues => onChange(date, newValues)}
                isFocused={isHovered}
            />
        </RangePickerContainer>
    );
};

const TrialRangePickers = ({
    selectedCustomTimes,
    onModifySelectedTimes,
    isTimeSlotsLinkBroken,
}) => {
    if (isTimeSlotsLinkBroken) {
        return <TrialRangePickersContainer />;
    }
    return (
        <TrialRangePickersContainer>
            {Object.keys(selectedCustomTimes).map((date: string) => (
                <RangePicker
                    key={date}
                    date={date}
                    values={[
                        selectedCustomTimes[date].startTimeInSteps,
                        selectedCustomTimes[date].endTimeInSteps,
                    ]}
                    onChange={(date, newValues) => {
                        onModifySelectedTimes(date, newValues[0], newValues[1]);
                    }}
                />
            ))}
        </TrialRangePickersContainer>
    );
};

export default TrialRangePickers;
