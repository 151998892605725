import { Client, ClientAuth, Api } from "@anna/shared";
import config from "../config";
// import Logger from "@core/services/logger";
import { auth } from "../firebase/configure";

// prettier-ignore

const clientAuth: ClientAuth = {
    getToken: async () => auth.currentUser?.getIdToken() ?? null,
};

// TODO: ADD logger
const client = new Client(config.API_BASE, undefined, clientAuth);
export default Api(client);
