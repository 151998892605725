import React from "react";
import { StyleGuide } from "../../../constants/StyleGuide";
import ApplicantsList from "../../../components/ApplicantsList/index";
import { useApplicants } from "./useApplicants";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { selectApplicants } from "../../../features/funnels/reducer";
import useApplicantsCount from "../../../features/counts/useApplicantsCount";
import { selectApplicantCount } from "../../../features/counts/reducer";
import { setApplicantPagination } from "../../../features/pagination/reducer";
import useApplicantsWithFilters from "@routes//Home/Applicants/useApplicantsWithFilter";
import { hasFiltersSelected } from "../../../features/filters/homeFilterReducer";

function ApplcantsContainer() {
    useApplicantsCount();

    const applicantIds = useAppSelector(selectApplicants);
    const count = useAppSelector(selectApplicantCount);

    const isFiltersUsed = useAppSelector(hasFiltersSelected);

    if (isFiltersUsed) return <ElasticList count={count} applicantIds={applicantIds} />;
    return <FirebaseList count={count} applicantIds={applicantIds} />;
}

function FirebaseList({ count, applicantIds }) {
    useApplicants();
    const dispatch = useAppDispatch();
    const limit = useAppSelector(state => state.pagination.applicantPagination.limit);
    const isLoading = useAppSelector(
        state => state.pagination.applicantPagination.isLoading,
    );
    const isEndReached = useAppSelector(
        state => state.pagination.applicantPagination.isEndReached,
    );

    const handleLoadMore = () => {
        if (isLoading || isEndReached) return;
        dispatch(setApplicantPagination({ limit: limit + 10 }));
    };
    return (
        <ApplicantsList
            key="applicantsList"
            title="Applicants"
            count={count}
            applicantIds={applicantIds}
            isOpenedByDefault
            colorTheme={StyleGuide.palette.secondaryCucumber}
            handleLoadMore={handleLoadMore}
        />
    );
}
function ElasticList({ count, applicantIds }) {
    useApplicantsWithFilters();
    const dispatch = useAppDispatch();
    const pagination = useAppSelector(state => state.pagination.applicantPagination);
    const noMoreDataToLoad = pagination?.totalPages <= pagination.page;

    const handleLoadMore = () => {
        if (noMoreDataToLoad) return;
        dispatch(setApplicantPagination({ page: pagination.page + 1 }));
    };
    return (
        <ApplicantsList
            key="applicantsListElastic"
            title="Applicants"
            count={count}
            applicantIds={applicantIds}
            isOpenedByDefault
            colorTheme={StyleGuide.palette.secondaryCucumber}
            handleLoadMore={handleLoadMore}
        />
    );
}

export default React.memo(ApplcantsContainer);
