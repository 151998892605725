import React from "react";
import { StyleGuide } from "../../../constants/StyleGuide";
import ApplicantsList from "../../../components/ApplicantsList/index";
import { useShortlist } from "./useShortlist";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { selectShortlist } from "../../../features/funnels/reducer";
import useShortlistCount from "../../../features/counts/useShortlistCount";
import { selectShortlistCount } from "../../../features/counts/reducer";
import { setShortlistPagination } from "../../../features/pagination/reducer";
import useShortlistWithFilters from "./useShortlistWithFilters";
import { hasFiltersSelected } from "../../../features/filters/homeFilterReducer";

export default function ShortlistContainer() {
    useShortlistCount();

    const shortlistIds = useAppSelector(selectShortlist);
    const count = useAppSelector(selectShortlistCount);
    const isFiltersUsed = useAppSelector(hasFiltersSelected);

    if (isFiltersUsed) return <ElasticList count={count} shortlistIds={shortlistIds} />;
    return <FirebaseList count={count} shortlistIds={shortlistIds} />;
}

function FirebaseList({ shortlistIds, count }) {
    useShortlist();

    const dispatch = useAppDispatch();

    const limit = useAppSelector(state => state.pagination.shortlistPagination.limit);
    const isLoading = useAppSelector(
        state => state.pagination.shortlistPagination.isLoading,
    );
    const isEndReached = useAppSelector(
        state => state.pagination.shortlistPagination.isEndReached,
    );

    const handleLoadMore = () => {
        if (isLoading || isEndReached) return;
        dispatch(setShortlistPagination({ limit: limit + 10 }));
    };
    return (
        <ApplicantsList
            key="shortList"
            title="Favourite"
            applicantIds={shortlistIds}
            isOpenedByDefault
            count={count}
            handleLoadMore={handleLoadMore}
            colorTheme={StyleGuide.palette.primaryAnanas}
        />
    );
}

function ElasticList({ count, shortlistIds }) {
    useShortlistWithFilters();
    const dispatch = useAppDispatch();
    const pagination = useAppSelector(state => state.pagination.applicantPagination);
    const noMoreDataToLoad = pagination?.totalPages <= pagination.page;

    const handleLoadMore = () => {
        if (noMoreDataToLoad) return;
        dispatch(setShortlistPagination({ page: pagination.page + 1 }));
    };
    return (
        <ApplicantsList
            key="shortListElastic"
            title="Favourite"
            applicantIds={shortlistIds}
            isOpenedByDefault
            count={count}
            handleLoadMore={handleLoadMore}
            colorTheme={StyleGuide.palette.primaryAnanas}
        />
    );
}
