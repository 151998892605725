import styled, { css } from "styled-components";

import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";

export const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding-right: ${StyleGuide.spacing.micro}px;
    margin-right: ${StyleGuide.spacing.micro}px;
    border-radius: ${StyleGuide.spacing.xsmall}px;

    overflow-y: scroll;

    ${CustomScrollBar}
`;

export const ApplicantHeader = styled.div`
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.xsmall}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.base}px;
`;

export const ApplicantHeaderInfo = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
`;

export const ApplicantBasicInfo = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${StyleGuide.spacing.mini}px;
`;

export const ApplicantProfilePicture = styled.img`
    object-fit: cover;
    width: 72px;
    height: 72px;
    border-radius: 36px;
    margin-right: ${StyleGuide.spacing.small}px;
`;

export const ApplicantStatus = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
`;

export const ApplicantName = styled.div`
    ${StyleGuide.typography.title};
    font-weight: bold;
    color: ${StyleGuide.palette.basePepper};
`;

export const ApplicantTitle = styled.div`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.coal};
`;

export const ApplicantDetailRow = styled.div`
    margin: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.medium}px 0 0;
    display: flex;
    justify-content: space-between;
`;

export const ApplicantDetailTitle = styled.div`
    ${StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.baseVanilla};
    margin-bottom: ${StyleGuide.spacing.micro}px;
    text-align: center;
`;

export const ApplicantDetailDescription = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
`;

export const ProfileBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: column;
    flex: 1;
`;

export const InfoSectionContainer = styled.div`
    background: ${StyleGuide.palette.baseSalt};
`;

export const InfoSectionDetails = styled.div`
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.base}px;
`;

export const InfoSectionSeparator = styled.div`
    height: ${StyleGuide.spacing.mini}px;
    background: ${StyleGuide.palette.baseSugar};
    box-shadow: inset 0px 4px 0px -3px #f5f5f5;
`;

export const InfoSectionTitle = styled.div`
    ${StyleGuide.typography.body2};
    font-weight: bold;
    color: ${StyleGuide.palette.coal};
`;

interface InfoSectionDescriptionProps {
    hasTitle?: boolean;
}

export const InfoSectionDescription = styled.div<InfoSectionDescriptionProps>`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};

    ${({ hasTitle }) => hasTitle && `margin-top: ${StyleGuide.spacing.mini}px`}
`;

export const WorkExperienceContainer = styled.div`
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.base}px;
`;

export const WorkExperienceHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${StyleGuide.spacing.small}px;
`;

export const WorkExperienceTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const CvButton = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.secondaryBasil};
    cursor: pointer;
    margin-left: ${StyleGuide.spacing.micro}px;
`;

interface WorkExperienceItemContainerProps {
    isLast?: boolean;
}

export const WorkExperienceItemContainer = styled.div<WorkExperienceItemContainerProps>`
    padding-left: ${StyleGuide.spacing.small}px;
    position: relative;
    ${({ isLast }) =>
        !isLast &&
        css`
            border-bottom: 1px solid ${StyleGuide.palette.baseMsg};
            margin-bottom: ${StyleGuide.spacing.small}px;
            padding-bottom: ${StyleGuide.spacing.small}px;
        `}
`;

export const WorkExperienceItemTitle = styled.div`
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
`;

export const WorkExperienceItemDot = styled.div`
    width: ${StyleGuide.spacing.micro}px;
    height: ${StyleGuide.spacing.micro}px;
    border-radius: 2px;
    background-color: ${StyleGuide.palette.baseVanilla};
    position: absolute;
    margin-top: 10px;
    margin-left: ${-StyleGuide.spacing.small}px;
`;

export const WorkExperienceShowFullButton = styled.div`
    ${StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.secondaryCucumber};
    margin-bottom: ${StyleGuide.spacing.small}px;
    cursor: pointer;
`;

export const ArrowIcon = styled(ArrowUp)`
    width: 8px;
    height: 9px;
    fill: ${StyleGuide.palette.secondaryCucumber};
    margin-left: ${StyleGuide.spacing.xsmall}px;
    transform: rotate(180deg);
`;

export const ApplicantActionsContainer = styled.div`
    width: 280px;
    height: 100%;
    border-left: 1px solid ${StyleGuide.palette.baseSugar};
`;
