import React from "react";
import { useAppSelector } from "../../store/store";
import { CategoryItem } from "./styled";
import useHomePositionCount from "../../features/counts/useHomePositionCount";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "@constants/routes";

/**
 *  Container to avoid loading counts when not needed
 */
export default function CategoryContainer({ id, value, isSelected, isLast, onClick }) {
    const isHome = useRouteMatch(Routes.HOME);
    if (isHome) {
        return (
            <CategoryHome
                id={id}
                value={value}
                isSelected={isSelected}
                isLast={isLast}
                onClick={onClick}
            />
        );
    }
    return (
        <Category
            id={id}
            value={value}
            isSelected={isSelected}
            isLast={isLast}
            onClick={onClick}
        />
    );
}

export function CategoryWithCount({ value, id }) {
    useHomePositionCount(value);

    const count = useAppSelector(state => state.count.homeAllCount);
    const positionCount = useAppSelector(state => state.count[value]);

    const displayCount = id
        ? `${positionCount ? `(${positionCount})` : ""}`
        : `${count ? `(${count})` : ""}`;
    return (
        <React.Fragment>
            {value} {displayCount}
        </React.Fragment>
    );
}

function CategoryHome({ id, value, isSelected, isLast, onClick }) {
    return (
        <CategoryItem key={id} isSelected={isSelected} onClick={onClick} isLast={isLast}>
            <CategoryWithCount id={id} value={value} />
        </CategoryItem>
    );
}

function Category({ id, value, isSelected, isLast, onClick }) {
    return (
        <CategoryItem key={id} isSelected={isSelected} onClick={onClick} isLast={isLast}>
            {value}
        </CategoryItem>
    );
}
