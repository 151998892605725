import React, { useState } from "react";

import { ReactComponent as HandWave } from "@assets/svg/hand_wave.svg";
import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { formatDistance } from "date-fns";
import {
    AutoReplyContainer,
    AppliedText,
    AutoReplyAccordion,
    AppliedInfoContainer,
    AppliedInfoFlag,
    AppliedInfoText,
    ArrowContainer,
    ExpandedContainer,
    NoteText,
} from "./styled";

const AutoReplyBubble = ({ createTime, message, isHiring = false }) => {
    const [isDropdownOpened, toggleDropdown] = useState(false);
    const formatCreateTime = formatDistance(createTime ?? new Date(), new Date(), {
        addSuffix: true,
    });
    const status = isHiring
        ? "Received “Hiring” auto reply"
        : "Received “Not hiring” auto reply";

    return (
        <AutoReplyContainer>
            <HandWave />

            <AppliedText>Applied {formatCreateTime}</AppliedText>

            <AutoReplyAccordion>
                <AppliedInfoContainer
                    isExpanded={isDropdownOpened}
                    onClick={() => toggleDropdown(!isDropdownOpened)}>
                    <AppliedInfoFlag isHiring={isHiring} />

                    <AppliedInfoText>{status}</AppliedInfoText>

                    <div style={{ flex: 1 }} />

                    <ArrowContainer isExpanded={isDropdownOpened}>
                        <ArrowUp />
                    </ArrowContainer>
                </AppliedInfoContainer>

                {isDropdownOpened && <ExpandedContainer>{message}</ExpandedContainer>}
            </AutoReplyAccordion>

            <NoteText>Applicants receive messages as SMS/mail</NoteText>
        </AutoReplyContainer>
    );
};

export default AutoReplyBubble;
