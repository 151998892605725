import React, { useEffect, useRef } from "react";
import chunk from "lodash.chunk";

import { convertMinutesToHoursAndMinutes, longLocalDateTimeFormat } from "@utils/dates";
import {
    TimeSlotsPreviewContainer,
    popUpHeight,
    popUpBottomMargin,
    PreviewHeader,
    PreviewTitle,
    PreviewDescription,
    PreviewListContainer,
    PreviewList,
    TimeSlotsPreviewItem,
    TimeSlotsPreviewDate,
    TimeSlotPreviewRow,
    TimeSlotPreviewTime,
} from "./styled";

const TimeSlotsPreview = ({
    onClose,
    customTimes = {},
    customTrialTimes = {},
    forInterview,
    style = {},
}: {
    onClose: () => void;
    customTimes?: Contracts.CustomTimeSlotsDTO;
    customTrialTimes?: Contracts.CustomPreferenceDTO;
    forInterview: boolean;
    style?: React.CSSProperties;
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const renderInterviewPreview = () => {
        const dataSource = Object.keys(customTimes).map(date => ({
            title: longLocalDateTimeFormat(new Date(date)),
            data: chunk(Object.values(customTimes[date]), 4),
        }));

        return (
            <>
                {dataSource.map(({ title, data }, firstTierIndex) => {
                    return (
                        <TimeSlotsPreviewItem key={`${title}_${firstTierIndex}`}>
                            <TimeSlotsPreviewDate>{title}</TimeSlotsPreviewDate>

                            {data.map((timeslots, secondTierIndex) => {
                                return (
                                    <TimeSlotPreviewRow
                                        key={`${title}_${firstTierIndex}_row_${secondTierIndex}`}>
                                        {timeslots.map((time: number, thirdTierIndex) => {
                                            return (
                                                <TimeSlotPreviewTime
                                                    key={`${title}_${firstTierIndex}_row_${secondTierIndex}_cell_${thirdTierIndex}`}>
                                                    {convertMinutesToHoursAndMinutes(
                                                        time,
                                                    )}
                                                </TimeSlotPreviewTime>
                                            );
                                        })}
                                    </TimeSlotPreviewRow>
                                );
                            })}
                        </TimeSlotsPreviewItem>
                    );
                })}
            </>
        );
    };

    const renderTrialPreview = () => {
        const dataSource = Object.keys(customTrialTimes).map(date => {
            const dateValue = customTrialTimes[date];
            const start = convertMinutesToHoursAndMinutes(dateValue.startTimeInMinutes);
            const end = convertMinutesToHoursAndMinutes(dateValue.endTimeInMinutes);
            const data = `${start} - ${end}`;
            return {
                title: longLocalDateTimeFormat(new Date(date)),
                data,
            };
        });

        return (
            <>
                {dataSource.map(({ title, data }, firstTierIndex) => {
                    return (
                        <TimeSlotsPreviewItem key={`${title}_${firstTierIndex}`}>
                            <TimeSlotsPreviewDate>{title}</TimeSlotsPreviewDate>

                            <TimeSlotPreviewRow>
                                <TimeSlotPreviewTime>{data}</TimeSlotPreviewTime>
                            </TimeSlotPreviewRow>
                        </TimeSlotsPreviewItem>
                    );
                })}
            </>
        );
    };

    return (
        <TimeSlotsPreviewContainer ref={wrapperRef} style={style}>
            <PreviewHeader>
                <PreviewTitle>Time slots added to the link</PreviewTitle>

                <PreviewDescription>
                    After applicant picks a time the event is added to your calendar and
                    you’ll be notified!
                </PreviewDescription>
            </PreviewHeader>

            <PreviewListContainer>
                <PreviewList>
                    {forInterview ? renderInterviewPreview() : renderTrialPreview()}
                </PreviewList>
            </PreviewListContainer>
        </TimeSlotsPreviewContainer>
    );
};

export default TimeSlotsPreview;
export { popUpHeight, popUpBottomMargin };
