import React, { useState } from "react";

import { ReactComponent as BellIcon } from "@assets/svg/bell.svg";
import ActivityCenterModal from "@components/CustomModals/ActivityCenterModal";
import SettingsDropdown from "@components/Dropdowns/SettingsDropdown";
import SearchDropdown from "@components/Dropdowns/SearchDropdown";
import { useUnseenUserActivityEvents } from "../../features/activityCenter/useUnseenUserActivityEvents";
import {
    NotificationFlagContainer,
    HeaderBellBubble,
    HeaderBellCount,
    NotificationsActionsDivider,
} from "./styled";

const NotificationFlag = () => {
    const [showActiviyCenter, toggleActiviyCenter] = useState(false);

    const unseenEvents = useUnseenUserActivityEvents();

    const count = unseenEvents?.length ?? 0;

    const isActive = count > 0;

    return (
        <NotificationFlagContainer>
            <SearchDropdown />

            <NotificationsActionsDivider />

            <HeaderBellBubble onClick={() => toggleActiviyCenter(true)}>
                <BellIcon />

                {isActive && <HeaderBellCount>{count}</HeaderBellCount>}
            </HeaderBellBubble>

            <NotificationsActionsDivider />

            <SettingsDropdown />

            <ActivityCenterModal
                visible={showActiviyCenter}
                onClose={() => toggleActiviyCenter(false)}
            />
        </NotificationFlagContainer>
    );
};

export default NotificationFlag;
