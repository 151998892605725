import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortRestaurantOptionsAlphabetically } from "../../helpers/sort";
import type { RootState } from "../../store/store";
import keyBy from "lodash.keyby";
import uniq from "lodash.uniq";

interface RestaurantState {
    restaurantIds: string[];
    selectedRestaurantIds: string[];
    membersByRestaurantId: { [id: string]: string[] };
    restaurantMap: { [id: string]: Contracts.DBRestaurantShape };
    selectedActivityRestaurant: string | null;
}

// Define the initial state using that type
const initialState: RestaurantState = {
    restaurantIds: [],
    selectedRestaurantIds: [],
    membersByRestaurantId: {},
    restaurantMap: {},
    selectedActivityRestaurant: null,
};

export const restaurantsSlice = createSlice({
    name: "restaurants",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setRestaurants(state, action: PayloadAction<Contracts.DBRestaurantShape[]>) {
            const membersByRestaurantId = action.payload.reduce(
                (acc, r) => ({ ...acc, [r.id]: Object.keys(r.members) }),
                {},
            );

            let nextState = {
                ...state,
                restaurantIds: action.payload.map(r => r.id),
                membersByRestaurantId,
                restaurantMap: {
                    ...state.restaurantMap,
                    ...keyBy(action.payload, "id"),
                },
            };

            if (!state.selectedActivityRestaurant) {
                nextState = {
                    ...nextState,
                    selectedActivityRestaurant: action.payload[0]?.id,
                };
            }

            // if only 1 restaurant keep it selected
            if (action.payload?.length === 1) {
                nextState = {
                    ...nextState,
                    selectedRestaurantIds: [action.payload[0].id],
                };
            }

            return nextState;
        },
        setSelectedRestaurants(state, action: PayloadAction<string>) {
            if (state.selectedRestaurantIds.includes(action.payload)) {
                return {
                    ...state,
                    selectedRestaurantIds: state.selectedRestaurantIds.filter(
                        s => s !== action.payload,
                    ),
                };
            }
            return {
                ...state,
                selectedRestaurantIds: [...state.selectedRestaurantIds, action.payload],
            };
        },

        setActivityRestaurant(state, action: PayloadAction<string>) {
            return {
                ...state,
                selectedActivityRestaurant: action.payload,
            };
        },
    },
});

export const {
    setRestaurants,
    setSelectedRestaurants,
    setActivityRestaurant,
} = restaurantsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectAllRestaurantIds = (state: RootState) =>
    state.restaurants.restaurantIds;

export const selectSortedRestaurantOptions = (state: RootState) =>
    state.restaurants.restaurantIds
        .map(id => ({ id, value: state.restaurants.restaurantMap?.[id]?.title }))
        .sort(sortRestaurantOptionsAlphabetically);

export const selectSelectedRestaurantIds = (state: RootState) =>
    state.restaurants.selectedRestaurantIds;

export const selectRestaurantIdsToQuery = (state: RootState) =>
    state.restaurants.selectedRestaurantIds?.length < 1
        ? state.restaurants.restaurantIds
        : state.restaurants.selectedRestaurantIds;

export const selectMembersToQuery = (state: RootState): string[] => {
    const filteredValues = Object.entries(state.restaurants.membersByRestaurantId)
        .map(([key, value]) =>
            state.restaurants.selectedRestaurantIds.includes(key) ? value : null,
        )
        .filter(Boolean);

    if (filteredValues?.length < 1) {
        const allMembers = uniq(
            Object.entries(state.restaurants.membersByRestaurantId)
                .map(([_, value]) => value)
                // @ts-ignore
                .flat(),
        );

        return allMembers.filter(m => m !== state.user.uid) as string[];
    }
    // @ts-ignore
    const members = uniq(filteredValues.flat());
    return members.filter(m => m !== state.user.uid) as string[];
};

export default restaurantsSlice.reducer;
