import { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { FunnelState, setSearchResults } from "../../../features/funnels/reducer";
import useFunnelSearch from "../../../features/user/useFunnelSearch";

export default function useSearch(searchTerm: string, opened: boolean) {
    const { loading, onSearchFunnels } = useFunnelSearch();
    const [initialLoading, setInitialLoading] = useState(true);
    // TODO: load more
    // const [loadingMore, setLoadingMore] = useState(false);

    const userId = useAppSelector(state => state.user.uid);

    const dispatch = useAppDispatch();

    useEffect(() => {
        try {
            if (!loading && opened) {
                onSearchFunnels({
                    q: searchTerm,
                    page: { current: 1, size: 40 },
                }).then(({ data }: { data: Partial<FunnelState>[] }) => {
                    // @ts-ignore
                    dispatch(setSearchResults({ funnels: data }));
                    if (initialLoading) {
                        setInitialLoading(false);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        return () => {};
    }, [userId, opened, searchTerm]);
    return { initialLoading };
}
