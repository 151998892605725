import React, { useEffect } from "react";

import { IntroIcon } from "@assets/images/icons";
import { InfoButton, SendButton } from "./Template";
import { IntroTemplateContainer } from "./styled";

const IntroTemplate = ({ closeTemplate, sendTemplate, onTemplateSendRef }) => {
    useEffect(() => {
        onTemplateSendRef.current = sendTemplate;
    }, []);

    return (
        <IntroTemplateContainer>
            <InfoButton label="Intro" Icon={<IntroIcon />} onPress={closeTemplate} />

            <SendButton label="Send intro message" onPress={sendTemplate} />
        </IntroTemplateContainer>
    );
};

export default IntroTemplate;
