import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";

export const ModalOverlay = {
    justifyContent: "flex-end",
};

export const ModalContainer = {
    height: "100%",
};

export const Container = styled.div`
    width: 404px;
    height: 100%;

    background: ${StyleGuide.palette.baseSalt};
    box-shadow: 0px 28px 72px -24px rgba(0, 0, 0, 0.4);
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-right: ${StyleGuide.spacing.micro}px;
`;

export const ActivityHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.base}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.small}px;
`;

export const CloseButton = styled.div`
    width: ${StyleGuide.spacing.large}px;
    height: ${StyleGuide.spacing.large}px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${StyleGuide.palette.baseMsg};
    cursor: pointer;
`;

export const TabsContainer = styled.div`
    display: flex;
    align-items: center;
`;

type TabBubbleProps = {
    isSelected?: boolean;
};

export const TabBubble = styled.div<TabBubbleProps>`
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSugar : "transparent"};
    color: ${props =>
        props.isSelected
            ? StyleGuide.palette.secondaryCucumber
            : StyleGuide.palette.baseVanilla};
    border-radius: ${StyleGuide.spacing.mini}px;
    margin-right: ${StyleGuide.spacing.small}px;
    cursor: pointer;
    ${props =>
        props.isSelected ? StyleGuide.typography.headline1 : StyleGuide.typography.body1};
    :hover {
        background-color: ${StyleGuide.palette.baseSugar};
    }
`;

export const RestaurantPickerContainer = styled.div`
    padding: 0 ${StyleGuide.spacing.base}px;
`;

export const ActivityListContainer = styled.div`
    padding: ${StyleGuide.spacing.small}px;
    overflow: auto;

    ${CustomScrollBar}
`;

interface ActivityItemProps {
    isNewNotification?: boolean;
}

export const ActivityItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${StyleGuide.spacing.mini}px;
    margin-bottom: ${StyleGuide.spacing.medium}px;
    cursor: pointer;
`;

export const UserProfilPicture = styled.img<ActivityItemProps>`
    object-fit: cover;
    width: ${StyleGuide.spacing.xlarge}px;
    height: ${StyleGuide.spacing.xlarge}px;
    border-radius: ${StyleGuide.spacing.base}px;
    margin-right: ${StyleGuide.spacing.small}px;
    opacity: ${props => (props.isNewNotification ? 1 : 0.6)};
`;

export const NotificationDot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${StyleGuide.palette.primaryAnanas};
    margin-left: ${StyleGuide.spacing.small}px;
`;

export const NotificationTextContainer = styled.div`
    display: flex;
    flex: 1;
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.coal};
`;

export const UserName = styled.span<ActivityItemProps>`
    ${StyleGuide.typography.headline1};
    color: ${props =>
        props.isNewNotification
            ? StyleGuide.palette.basePepper
            : StyleGuide.palette.baseVanilla};
`;

export const ActivityText = styled.div<ActivityItemProps>`
    ${StyleGuide.typography.body1};
    color: ${props =>
        props.isNewNotification
            ? StyleGuide.palette.coal
            : StyleGuide.palette.baseVanilla};
`;

export const ActivitySectionText = styled.div<ActivityItemProps>`
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.secondaryBasil};
    margin-bottom: ${StyleGuide.spacing.xsmall}px;
`;
