import styled from "styled-components";

import {
    InterviewIcon as InterviewCalendarIcon,
    TrialIcon as TrialCalendarIcon,
    RejectIcon as RejectTemplateIcon,
    OnboardingIcon as OnboardingTemplateIcon,
    HoorayIcon as HoorayTemplateIcon,
} from "@assets/images/icons";
import { StyleGuide } from "@constants/StyleGuide";

export const TemplateBubbleContainer = styled.div<{ darkText?: boolean }>`
    border-radius: 18px;
    border: 1px solid ${StyleGuide.palette.baseMsg};
    padding: ${StyleGuide.spacing.mini}px;
    background-color: ${props => (props.darkText ? StyleGuide.palette.baseMsg : "")};
`;

export const TemplateBubbleMessage = styled.div<{ darkText?: boolean }>`
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.micro}px 0
        ${StyleGuide.spacing.micro}px;
    ${() => StyleGuide.typography.body1};
    color: ${props =>
        props.darkText ? StyleGuide.palette.basePepper : StyleGuide.palette.baseSalt};
    text-align: left;
    word-wrap: break-word;
    white-space: pre-wrap;
`;

export const InterviewIcon = styled(InterviewCalendarIcon)`
    width: 20px;
    height: 21px;
`;

export const TrialIcon = styled(TrialCalendarIcon)`
    width: 20px;
    height: 21px;
`;

export const RejectIcon = styled(RejectTemplateIcon)`
    width: 20px;
    height: 14px;
`;

export const OnboardingIcon = styled(OnboardingTemplateIcon)`
    width: 16px;
    height: 21px;
`;

export const HoorayIcon = styled(HoorayTemplateIcon)`
    width: 20px;
    height: 21px;
`;
