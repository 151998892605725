import { TemplateVariables } from "@anna/shared";
import { TemplateProps } from "./types";
export default ({
    applicantName = TemplateVariables.APPLICANT_NAME,
    hiredLink = TemplateVariables.HIRE_LINK,
    senderFirstName = TemplateVariables.SENDER_NAME,
    restaurantTitle = TemplateVariables.RESTAURANT_TITLE,
}: TemplateProps) => `Hey ${applicantName},

We’re excited to get you started at ${restaurantTitle}.

Please already share your contract info with us here so we can start the onboarding: ${hiredLink}

Have a great week,
${senderFirstName}`;
