import { useEffect } from "react";
import { setUserPositions } from "./reducer";
import { useAppSelector, useAppDispatch } from "../../store/store";
import * as UserAPI from "./api";
// import { selectUserId } from "../user/reducer";

export function useUserPositions() {
    const userId = useAppSelector(state => state.user.uid);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (userId) {
            // @ts-ignore
            unsubscribe = UserAPI.getUserPositions(userId).onSnapshot(
                query => {
                    const [positionDoc] = query.docs.map(
                        doc =>
                            ({
                                id: doc.id,
                                ...doc.data(),
                            } as { id: string; responsibilityPreference: string[] }),
                    );
                    const positions = positionDoc?.responsibilityPreference ?? [];

                    dispatch(setUserPositions(positions));
                },
                err => {
                    console.log(err);
                },
            );
        }

        return () => unsubscribe?.();
    }, [userId]);
}
