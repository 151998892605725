import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as ActiveSettingsIcon } from "@assets/svg/settings_active.svg";
import { ReactComponent as InactiveSettingsIcon } from "@assets/svg/settings_inactive.svg";
import { ReactComponent as LogoutIcon } from "@assets/svg/logout.svg";
import { signOut } from "../../../firebase/auth";
import {
    Container,
    ToggleBlock,
    DropdownContainer,
    LogoutAction,
    LogoutIconContainer,
} from "./styled";

const SettingsDropdown = () => {
    const [opened, setOpened] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpened(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const renderActions = () => {
        return (
            <>
                <LogoutAction>
                    <LogoutIconContainer>
                        <LogoutIcon />
                    </LogoutIconContainer>
                    Sign out
                </LogoutAction>
            </>
        );
    };

    return (
        <Container ref={wrapperRef}>
            <ToggleBlock onClick={() => setOpened(!opened)} isSelected={opened}>
                {opened ? <ActiveSettingsIcon /> : <InactiveSettingsIcon />}

                {opened && (
                    <DropdownContainer onClick={signOut}>
                        {renderActions()}
                    </DropdownContainer>
                )}
            </ToggleBlock>
        </Container>
    );
};

export default SettingsDropdown;
