import React, { useState, useEffect, useRef } from "react";

import Calendar from "@components/Calendar";
import { ReactComponent as QuestionMark } from "@assets/svg/question_mark_rounded.svg";
import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import { TimeSlotsInfoMessage, TimeSlotsWarningMessage } from "./Popovers";
import {
    DatePickerContainer,
    DatePickerTitle,
    DatePickerSelector,
    DatePickerSelectorDisabled,
    DatePickerDisabledTitle,
    ArrowIcon,
    CalendarContainer,
} from "./styled";

const DatePicker = ({
    selectedDate,
    setSelectedDate,
    showWarning = false,
    totalTimeslots = 0,
    selectedCustomTimes,
    label,
    isTimeSlotsLinkBroken,
    forInterview,
}) => {
    const [isDatePickerExpanded, toggleDatePickerExpanded] = useState(false);
    const [showTimeslotsInfo, setShowTimeslotsInfo] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                toggleDatePickerExpanded(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    if (isTimeSlotsLinkBroken) {
        return (
            <DatePickerContainer ref={wrapperRef}>
                <DatePickerDisabledTitle>
                    Time slots addon disabled
                </DatePickerDisabledTitle>

                <DatePickerSelectorDisabled />
            </DatePickerContainer>
        );
    }

    return (
        <DatePickerContainer ref={wrapperRef}>
            {showWarning && <TimeSlotsWarningMessage />}

            <DatePickerTitle>
                Select time slots{" "}
                {totalTimeslots > 0 ? `(${totalTimeslots} selected)` : ""}
                <div
                    onMouseEnter={() => setShowTimeslotsInfo(true)}
                    onMouseLeave={() => setShowTimeslotsInfo(false)}>
                    <QuestionMark />
                </div>
            </DatePickerTitle>

            {showTimeslotsInfo && <TimeSlotsInfoMessage />}

            <DatePickerSelector
                onClick={() => toggleDatePickerExpanded(!isDatePickerExpanded)}>
                {label}
                <ArrowIcon isExpanded={isDatePickerExpanded}>
                    <ArrowUp />
                </ArrowIcon>
            </DatePickerSelector>

            {isDatePickerExpanded && (
                <CalendarContainer>
                    <Calendar
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedCustomTimes={selectedCustomTimes}
                        onClickDay={value => {
                            setSelectedDate(value);
                            if (forInterview) {
                                toggleDatePickerExpanded(false);
                            }
                        }}
                        forInterview={forInterview}
                    />
                </CalendarContainer>
            )}
        </DatePickerContainer>
    );
};

export default DatePicker;
