import styled, { css } from "styled-components";
import { motion } from "framer-motion";

import { StyleGuide } from "@constants/StyleGuide";

export const Link = styled.a`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.secondaryCucumber};
    text-decoration: none;
    cursor: pointer;
`;

export const OrDividerContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: ${StyleGuide.spacing.medium}px;
    margin-bottom: ${StyleGuide.spacing.small}px;
`;

export const OrDivider = styled.div`
    flex: 1;
    height: 1px;
    background-color: ${StyleGuide.palette.baseMsg};
`;

export const OrText = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseVanilla};
    margin: 0 ${StyleGuide.spacing.small}px;
`;

export const TabsRow = styled.div`
    display: flex;
    padding-bottom: 6px;
`;

interface TabProps {
    active: boolean;
}

export const Tab = styled.button<TabProps>`
    position: relative;
    background: none;
    border: none;
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseVanilla};
    ${({ active }) =>
        active &&
        css`
            font-weight: 500;
            color: ${StyleGuide.palette.basePepper};
        `};
    display: flex;
    margin-right: ${StyleGuide.spacing.base}px;
`;

export const TabUnderline = styled(motion.div)`
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${StyleGuide.palette.secondaryCucumber};
`;

export const TabContent = styled.div`
    margin-top: ${StyleGuide.spacing.small}px;
    width: min(calc(100vw - 128px), 336px);
`;

interface TabPagesContainerProps {
    activeTabIndex?: number;
}

export const TabPagesContainer = styled.div<TabPagesContainerProps>`
    display: flex;
    ${({ activeTabIndex }) =>
        activeTabIndex === 0 ? "" : `transform: translateX(-100%);`}
    translate: all 2s
`;

interface TabPageProps {
    visible: boolean;
}

export const TabPage = styled.div<TabPageProps>`
    width: min(calc(100vw - 128px), 336px);
    min-width: min(calc(100vw - 128px), 336px);

    ${({ visible }) =>
        !visible &&
        css`
            visibility: hidden;
        `}
`;

export const SpacedRow = styled.div`
    margin-top: ${StyleGuide.spacing.small}px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CodeSentToText = styled.div`
    ${StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.coal};
`;

export const ResendCode = styled.button`
    border: none;
    background: none;
    ${StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.secondaryCucumber};

    ${({ disabled }) =>
        disabled &&
        css`
            color: #d0d0d0;
        `}
`;
