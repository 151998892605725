import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";

import { StyleGuide } from "@constants/StyleGuide";

const Button = css`
    border: none;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${StyleGuide.spacing.mini}px;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px;
`;

export const FacebookButton = styled.button`
    ${Button};
    background-color: ${StyleGuide.palette.sugar};
`;

export const FacebookButtonLogo = styled.img`
    margin-right: ${StyleGuide.spacing.mini}px;
`;

export const FacebookButtonText = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
`;

export const SubmitButton = styled.button`
    ${Button};
    background-color: ${StyleGuide.palette.secondaryCucumber};

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${StyleGuide.palette.baseSeaSalt};
        `}
`;

interface SubmitButtonTextProps {
    disabled?: boolean;
}

export const SubmitButtonText = styled.div<SubmitButtonTextProps>`
    ${StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.baseSalt};

    ${({ disabled }) =>
        disabled &&
        css`
            color: ${StyleGuide.palette.baseVanilla};
        `}
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Loader = styled.div<{
    size?: number;
    color?: string;
    backgroundColor?: string;
}>`
    box-sizing: border-box;
    border: 2px solid ${props => props.backgroundColor || StyleGuide.palette.baseSalt};
    border-top: 3px solid ${props => props.color || StyleGuide.palette.primaryAnanas};
    border-radius: 50%;
    width: ${props => props.size || 20}px;
    height: ${props => props.size || 20}px;
    animation: ${spin} 1.4s linear infinite;
    -webkit-animation: ${spin} 1.4s linear infinite; /* Safari */
`;
