import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";

export const getRestaurantsByUserId = (id: string) => {
    try {
        return firestore
            .collection(COLLECTIONS.RESTAURANTS)
            .where(`members.${id}.uid`, "==", id);
    } catch (error) {
        console.error(error);
        return null;
    }
};
export const getPlandayIntegrationOnce = (restaurantId: string) =>
    firestore
        .collection(COLLECTIONS.RESTAURANTS)
        .doc(restaurantId)
        .collection(COLLECTIONS.RESTAURANT_INTEGRATIONS)
        .doc("planday")
        .get();
