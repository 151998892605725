import React, { useState, useEffect } from "react";

import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { ReactComponent as ArrowUp } from "@assets/svg/arrow_up.svg";
import Modal from "@components/Modal";
import Applicant from "./Applicant";
import { ModalContainer, CloseButton, ArrowContainer } from "./styled";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { setFocusedProfile } from "../../../features/funnels/reducer";
import { useApplication } from "../../../features/applications/useApplication";

const ApplicantProfileModal = () => {
    const dispatch = useAppDispatch();
    const [showChat, toggleChat] = useState(false);

    const currentFunnelId = useAppSelector(
        state => state.funnels.focusedProfile,
    ) as string;
    const swipableIds = useAppSelector(state => state.funnels.profileSwipeIds);

    const [swipeIndex, setSwipeIndex] = useState(0);
    const previousFunnelId = swipableIds.indexOf(currentFunnelId) - 1;
    const nextFunnelId = swipableIds.indexOf(currentFunnelId) + 1;

    const previousApplicantId = useAppSelector(
        state => state.funnels.funnelMap[swipableIds[previousFunnelId]]?.applicantId,
    );
    const nextApplicantId = useAppSelector(
        state => state.funnels.funnelMap[swipableIds[nextFunnelId]]?.applicantId,
    );
    const currentFunnel = useAppSelector(
        state => state.funnels.funnelMap[currentFunnelId],
    );

    // const nextFunnel = useAppSelector(
    //     state => state.funnels.funnelMap[currentFunnelId],
    // );
    useApplication(previousApplicantId);
    useApplication(currentFunnel?.applicantId);
    useApplication(nextApplicantId);

    useEffect(() => {
        setSwipeIndex(swipableIds.indexOf(currentFunnelId));
        return () => {};
    }, [swipableIds, currentFunnelId]);

    const closeModal = () => {
        dispatch(setFocusedProfile({ funnelId: null, funnelIds: [] }));
        toggleChat(false);
    };

    const swipeLeft = () => {
        const previousIndex = swipableIds.indexOf(currentFunnelId) - 1;
        if (previousIndex < 0) return;
        dispatch(setFocusedProfile({ funnelId: swipableIds[previousIndex] }));
    };

    const swipeRight = () => {
        const nextIndex = swipableIds.indexOf(currentFunnelId) + 1;
        if (nextIndex > swipableIds?.length - 1) return;
        dispatch(setFocusedProfile({ funnelId: swipableIds[nextIndex] }));
    };

    const handleArrowClick = event => {
        if (event.keyCode === 37) {
            // left arrow
            swipeLeft();
        } else if (event.keyCode === 39) {
            // right arrow
            swipeRight();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleArrowClick);

        return () => {
            document.removeEventListener("keydown", handleArrowClick);
        };
    }, [swipeIndex]);

    if (!currentFunnelId) {
        return null;
    }

    return (
        <Modal visible={Boolean(currentFunnel)} onClose={closeModal}>
            <ModalContainer>
                <Applicant
                    showChat={showChat}
                    toggleChat={toggleChat}
                    closeModal={closeModal}
                />
                {!showChat && (
                    <>
                        {swipeIndex > 0 && (
                            <ArrowContainer isDirectionLeft onClick={swipeLeft}>
                                <ArrowUp />
                            </ArrowContainer>
                        )}

                        {swipeIndex < swipableIds?.length - 1 && (
                            <ArrowContainer onClick={swipeRight}>
                                <ArrowUp />
                            </ArrowContainer>
                        )}
                    </>
                )}
            </ModalContainer>

            <CloseButton onClick={closeModal}>
                <CloseIcon />
            </CloseButton>
        </Modal>
    );
};

export default ApplicantProfileModal;
