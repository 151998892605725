import React, { useState } from "react";

import { ReactComponent as SendIcon } from "@assets/svg/send.svg";
import { Loader } from "../../Auth/components/Buttons/styled";
import {
    ChatInputToolbar,
    ChatInputContainer,
    ChatInputComponent,
    SendButton,
} from "./styled";
import { StyleGuide } from "@constants/StyleGuide";

const InputToolbar = ({ sendNewMessage, scrollBottomRef, hideSendButton = false }) => {
    const [message, setMessage] = useState("");
    const [isSendingMessage, setIsSendingMessage] = useState(false);

    const disabled = message.trim() === "";

    const handleKeyDown = event => {
        if (event.key === "Enter") {
            //if user clicks Ctrl or CMD fire onSend
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                onSend();
                return;
            }
        }
    };

    const onSend = async () => {
        if (disabled) return;
        setIsSendingMessage(true);
        await sendNewMessage(message);
        setIsSendingMessage(false);
        setMessage("");
    };

    const renderSendButton = () => {
        if (isSendingMessage) {
            return (
                <SendButton>
                    <Loader color={StyleGuide.palette.secondaryBasil} size={24} />
                </SendButton>
            );
        }
        if (!hideSendButton)
            return (
                <SendButton disabled={disabled} onClick={onSend}>
                    <SendIcon />
                </SendButton>
            );
        return null;
    };

    return (
        <ChatInputToolbar hideSendButton={hideSendButton}>
            <ChatInputContainer>
                <ChatInputComponent
                    minRows={1}
                    maxRows={10}
                    placeholder="Click ctrl + enter to send"
                    value={message}
                    onChange={event => setMessage(event.target.value)}
                    onKeyDown={handleKeyDown}
                    onHeightChange={() => {
                        scrollBottomRef?.current?.();
                    }}
                />

                {renderSendButton()}
            </ChatInputContainer>
        </ChatInputToolbar>
    );
};

export default InputToolbar;
