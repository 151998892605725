import React, { useState } from "react";
import { AutoSizer } from "react-virtualized";

import ApplicantCard from "@components/ApplicantsList/ApplicantCard";
import YourInboxContainer from "./YourInbox/Container";
import TeamInboxContainer from "./TeamInbox/Container";
import OtherInboxContainer from "./OtherInbox/Container";
import { Container, HeaderContainer, HeaderTab } from "./styled";
import { useAppSelector } from "../../../store/store";
import { selectUnreadMessages } from "../../../features/notifications/reducer";

const tabsList = [
    { id: 1, name: "Your inbox" },
    { id: 2, name: "Team inbox" },
    { id: 3, name: "Other" },
];

const renderInboxLists = (activeTab: number) => {
    switch (activeTab) {
        case 1:
            return <YourInboxContainer />;
        case 2:
            return <TeamInboxContainer />;
        case 3:
            return <OtherInboxContainer />;
        default:
            return null;
    }
};
// ;
const Tab = ({
    id,
    isSelected,
    isLast,
    name,
    count,
    onClick,
}: {
    id: number;
    isSelected: boolean;
    isLast?: boolean;
    name: string;
    count?: number;
    onClick: () => void;
}) => {
    return (
        <React.Fragment key={`${id}`}>
            {isLast && <div style={{ flex: 1 }} />}

            <HeaderTab
                key={`${id}`}
                selected={isSelected}
                isLast={isLast}
                onClick={onClick}>
                {name} {Boolean(count) && `(${count})`}
            </HeaderTab>
        </React.Fragment>
    );
};

function YourInboxRenderProp({ children }: { children(props: { count: number }) }) {
    const count = useAppSelector(selectUnreadMessages);
    return children({ count });
}

const InboxList = () => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <Container>
            <HeaderContainer>
                <YourInboxRenderProp>
                    {({ count }) => (
                        <Tab
                            id={1}
                            isSelected={activeTab === 1}
                            name="Your inbox"
                            count={count}
                            onClick={() => setActiveTab(1)}
                        />
                    )}
                </YourInboxRenderProp>
                <Tab
                    id={2}
                    isSelected={activeTab === 2}
                    name="Team inbox"
                    onClick={() => setActiveTab(2)}
                />
                <Tab
                    id={3}
                    isSelected={activeTab === 3}
                    isLast
                    name="Other"
                    onClick={() => setActiveTab(3)}
                />

                {/* })} */}
            </HeaderContainer>

            {renderInboxLists(activeTab)}
            {/* <ListContainer>
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <VListContainer
                                width={width}
                                height={height}
                                rowHeight={rowHeight}
                                rowRenderer={renderRow}
                                rowCount={list.length}
                                overscanRowCount={3}
                            />
                        );
                    }}
                </AutoSizer>
            </ListContainer> */}
        </Container>
    );
};

export default InboxList;
