import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleBorder } from "./styled";
import { FavoriteIcon } from "../../../assets/images/icons";

const FavoriteTemplate = ({ applicantName }) => {
    //TODO: Use method above "getFavoriteTimeSlotsText" to get description text based on timeslots list
    return (
        <TemplateBubbleBorder>
            <ActionIconText
                title={`Favorited ${applicantName}`}
                Icon={<FavoriteIcon />}
            />
        </TemplateBubbleBorder>
    );
};

export default FavoriteTemplate;
