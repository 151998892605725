import {
    ActivityType,
    ActivityMessageSource,
    LastActivityType,
    ApplicationState,
    RejectReason,
} from "@anna/shared";
import { getFirstName } from "./names";

export function getFunnelState({
    lastActivity,
    state,
    reasonRejected,
    currentUserId,
    onlyDisplayLastAction = false,
}: {
    lastActivity: any;
    state: ApplicationState;
    reasonRejected?: RejectReason;
    currentUserId?: string;
    onlyDisplayLastAction?: boolean;
}) {
    if (lastActivity?.action || lastActivity?.message) {
        const shouldDisplayMessage = lastActivity.type === LastActivityType.Message;

        if (shouldDisplayMessage && !onlyDisplayLastAction) {
            const messageActivity = lastActivity?.message!;
            const author = messageActivity.authorMetadata;
            const authorFirstName = author?.displayName
                ? getFirstName(author?.displayName)
                : "User";
            const message = messageActivity[ActivityType.USER_MESSAGE];

            // If the last message was sent by the applicant
            if (author?.source === ActivityMessageSource.EXTERNAL) {
                return message;
            }
            // Otherwise, it was sent either by the user
            else if (author?.uid === currentUserId) {
                return `You: ${message}`;
            }
            // Or the team member
            else {
                return `${authorFirstName}: ${message}`;
            }
        } else if (lastActivity.action) {
            const actionActivity = lastActivity?.action!;
            const author = actionActivity.authorMetadata;
            const metaData = actionActivity.metaData;

            // If the last action was sent by the applicant
            if (author?.source === ActivityMessageSource.EXTERNAL) {
                switch (actionActivity.type) {
                    case ActivityType.ACTION_APPLIED:
                        return "Applied";
                    case ActivityType.ACTION_INTERVIEW_SCHEDULED:
                        return "Confirmed the interview";
                    case ActivityType.ACTION_INTERVIEW_CANCELED:
                        if (
                            (metaData as Contracts.ActivityCancelInterviewTrial)
                                ?.isReschedule
                        )
                            return "Requested to reschedule the interview";
                        return "Cancelled the interview";
                    case ActivityType.ACTION_TRIAL_SCHEDULED:
                        return "Confirmed the trial";
                    case ActivityType.ACTION_TRIAL_CANCELED:
                        if (
                            (metaData as Contracts.ActivityCancelInterviewTrial)
                                ?.isReschedule
                        )
                            return "Requested to reschedule the trial";
                        return "Cancelled the interview";
                    case ActivityType.ACTION_CONTRACT_CONFIRMED:
                        return "Sent their contract information";
                }
            }
            // If the last action was sent by either the user or the team member
            else if (author?.source === ActivityMessageSource.INTERNAL) {
                const authorFirstname = getFirstName(author?.displayName);
                const authorName =
                    author?.uid === currentUserId ? "You" : authorFirstname;

                switch (actionActivity.type) {
                    case ActivityType.ACTION_REJECT:
                        return `${authorName} rejected`;
                    case ActivityType.ACTION_REVIEWED:
                        return `${authorName} favorited`;
                    case ActivityType.ACTION_INTERVIEW_PENDING:
                        return `${authorName} invited for an interview`;
                    case ActivityType.ACTION_INTERVIEW_CANCELED:
                        return `${authorName} cancelled the interview`;
                    case ActivityType.ACTION_INTERVIEW_RESCHEDULED:
                        return `${authorName} rescheduled the interview`;
                    case ActivityType.ACTION_TRIAL_PENDING:
                        return `${authorName} invited for a trial`;
                    case ActivityType.ACTION_TRIAL_CANCELED:
                        return `${authorName} cancelled the trial`;
                    case ActivityType.ACTION_TRIAL_RESCHEDULED:
                        return `${authorName} rescheduled the trial`;
                    case ActivityType.ACTION_CONTRACT_PENDING:
                        return `${authorName} requested contract info`;
                    case ActivityType.ACTION_HIRE:
                        return `${authorName} hired`;
                }
            }
        }
    }

    // Fallback to application state if no last activity is available
    return getHumanReadableStates(state, reasonRejected);
}

export const getHumanReadableStates = (
    state: ApplicationState,
    reason?: Contracts.RejectReason,
): string => {
    switch (state) {
        case ApplicationState.APPLIED:
            return "Applied";
        case ApplicationState.REVIEWED:
            return "Favorited";

        case ApplicationState.INTERESTED:
            return "Started a conversation";

        case ApplicationState.INTERVIEW_PENDING:
            return "Invited for interview";
        case ApplicationState.INTERVIEW_SCHEDULED:
            return "Scheduled interview";
        case ApplicationState.INTERVIEW_CANCELED:
            return "Canceled interview";
        case ApplicationState.INTERVIEWED:
            return "Finished interview";

        case ApplicationState.TRIAL_PENDING:
            return "Invited for trial";
        case ApplicationState.TRIAL_SCHEDULED:
            return "Scheduled trial";
        case ApplicationState.TRIAL_CANCELED:
            return "Canceled trial";
        case ApplicationState.TRIALED:
            return "Finished trial";

        case ApplicationState.CONTACTED_ACCEPTED:
            return "Accepted contact request";
        case ApplicationState.CONTRACT_PENDING:
            return "Requested contract info";
        case ApplicationState.CONTRACT_CANCELED:
            return "Contract canceled";
        case ApplicationState.CONTRACT_CONFIRMED:
            return "Hired";
        case ApplicationState.HIRED:
            return "Hired";

        case ApplicationState.AUTO_REJECTED:
            return `"Not hiring" auto reply`;
        case ApplicationState.REJECTED:
            if (reason === RejectReason.SNOOZED) {
                return `"Not hiring" auto reply`;
            }
            return "Rejected";
        default:
            return "unknown";
    }
};
