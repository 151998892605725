import { format, isToday, isTomorrow, addDays, formatDistanceToNow } from "date-fns";
import range from "lodash.range";
import omitBy from "lodash.omitby";
import omit from "lodash.omit";
import isEmpty from "lodash.isempty";
import { Dates } from "@anna/shared";

export function localDateFormat(date: Date) {
    return format(date, "LLLL d, yyyy", Dates.locale);
}

export function shortLocalDateFormat(date: Date) {
    try {
        return format(date, "LLL dd", Dates.locale);
    } catch (error) {
        return "Invalid date";
    }
}

export function mediumLocalDateFormat(date: Date) {
    try {
        return format(date, "iii, LLL dd", Dates.locale);
    } catch (error) {
        return "Invalid date";
    }
}

export function monthNameFormat(date: Date) {
    return format(date, "LLL", Dates.locale);
}

export function longLocalDateFormat(date: Date | string) {
    const [weekDay, dayAndMonth] = format(
        Dates.parsed(date),
        "iiii, d LLLL",
        Dates.locale,
    ).split(",");
    return { weekDay, dayAndMonth };
}

export function shortLocalDateTimeFormat(date: Date | number) {
    return format(date, "MMM dd 'at' HH:mm", Dates.locale);
}
export function mediumLocalDateTimeFormat(date: Date | number) {
    return format(date, "iii, MMM dd 'at' HH:mm", Dates.locale);
}

export function longLocalDateTimeFormat(date: Date) {
    return format(date, "cccc - LLLL dd", Dates.locale);
}

export function timeDistanceInWords(date: Date) {
    return `${formatDistanceToNow(date)} `
        .replace("about ", "")
        .replace("over ", "")
        .replace("almost ", "")
        .replace("less than a minute", "1 min")
        .replace(/minute./, "min") // minute and minutes
        .replace(/hour./, "h") // hour and hours
        .replace(/day./, "d") // day and days
        .replace(/month./, "mo") // month and months
        .replace(/year./, "y"); // year and years
}

export function timeDistanceToNowInWords(date: Date) {
    if (isToday(date)) {
        return "Today";
    }
    if (isTomorrow(date)) {
        return "Tomorrow";
    }
    return `${formatDistanceToNow(date, { addSuffix: true })}`.replace("about ", "");
}

function extractDateString(date: Date) {
    const [dateString] = date.toISOString().split("T");
    return dateString;
}

export function generateDaysFromDate(
    numberOfDaysInTheFuture: number,
    date: Date = new Date(),
) {
    const days = range(1, numberOfDaysInTheFuture);

    return [
        extractDateString(date),
        ...days.map(day => extractDateString(addDays(date, day))),
    ];
}

export function generateTimeSlots(
    startMinutes: number,
    endMinutes: number,
    minuteInterval: number,
) {
    if (startMinutes > endMinutes)
        throw new Error("Start time should be before end time");
    if (startMinutes > endMinutes)
        throw new Error(
            "Start time should be before end time or endtime should be after start time",
        );
    if (startMinutes < 0 || endMinutes <= 0 || minuteInterval <= 0)
        throw new Error("One or more of the parameters er less than or equal to 0");

    let timeSlots: number[] = [];

    for (let i = startMinutes; i <= endMinutes; i += minuteInterval) {
        timeSlots.push(i);
    }
    return timeSlots;
}

export function convertMinutesToHoursAndMinutes(minutes: number) {
    const hours = minutes / 60;
    const resultHours = Math.floor(hours);
    const derivedMinutes = (hours - resultHours) * 60;
    const resultMinutes = Math.round(derivedMinutes);
    return `${resultHours
        .toString()
        .padStart(2, "0")}:${resultMinutes.toString().padStart(2, "0")}`;
}

const minutesPrHour = 60;
const halfHoursPrHour = 2;
const minutesInHalfHour = minutesPrHour / halfHoursPrHour;

/**
 * @param stepValue number between 14 and 48
 */
export function stepValueToHalfHourIntervalInMinutes(stepValue: number) {
    return stepValue * minutesInHalfHour;
}

export function halfHourIntervalInMinutesToStepValue(halfHourIntervalInMinutes: number) {
    return halfHourIntervalInMinutes / minutesInHalfHour;
}

export function omitDatesAndTime(
    selectedCustomTimes: Contracts.CustomTimeSlotsDTO,
    day: string,
    time: number,
) {
    return omitBy(
        {
            ...selectedCustomTimes,
            [day]: omit(selectedCustomTimes[day], time),
        },
        isEmpty,
    );
}

export function timeSlotsDateFormat(date: Date) {
    return format(date, "yyyy-LL-dd", Dates.locale);
}

export function shortTimeFormat(date: Date | number) {
    return format(date, "HH:mm", Dates.locale);
}
