import React from "react";

import RestaurantsPicker from "./RestaurantsPicker";
import TabsNavigator from "./TabsNavigator";
import NotificationFlag from "./NotificationFlag";
import { Container } from "./styled";

const Header = () => {
    return (
        <Container>
            <RestaurantsPicker />

            <TabsNavigator />

            <NotificationFlag />
        </Container>
    );
};

export default Header;
