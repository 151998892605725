type Object<T> = { [key: string]: T };

/**
 * * Creates a new object with sorted keys
 * @param object Object with keys to be sorted
 * @param keyExtractor Function to extract a new key based on the current key and value, can be omitted to use the same keys
 */
export function withSortedKeys<T>(
    object: Object<T>,
    keyExtractor?: (key: string, value: T) => string,
) {
    return Object.keys(object)
        .sort()
        .reduce((items, item) => {
            items[keyExtractor?.(item, object[item]) ?? item] = object[item];
            return items;
        }, {} as Object<T>);
}
