import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: hidden;
    position: static;
`;

export const LocationPickerContainer = styled.div`
    margin: ${StyleGuide.spacing.medium}px 0 0 0;
`;

export const LocationDropdown = styled.div`
    position: absolute;
    width: 248px;
    padding-top: ${StyleGuide.spacing.mini}px;
    background: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    box-shadow: 0px 12px 44px -8px rgba(0, 0, 0, 0.25);
    border-radius: ${StyleGuide.spacing.mini}px;
    z-index: 20;
    max-height: 400px;
    overflow: auto;
`;

interface LocationItemProps {
    isSelected?: boolean;
}

export const LocationItem = styled.div<LocationItemProps>`
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.micro}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    margin 0 2px;
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.baseMsg : StyleGuide.palette.baseSalt};
    ${StyleGuide.typography.body1};
    color: ${props =>
        props.isSelected
            ? StyleGuide.palette.basePepper
            : StyleGuide.palette.baseVanilla};
    text-align: left;
    cursor: pointer;

    :hover {
        background-color: ${props =>
            props.isSelected ? StyleGuide.palette.baseMsg : StyleGuide.palette.baseSugar};
        color: ${StyleGuide.palette.basePepper};
    }
`;

export const ManageLocationsButton = styled.div`
    width: 100%;
    padding: ${StyleGuide.spacing.small}px 0 ${StyleGuide.spacing.mini}px 0;
    border-top: 1px solid ${StyleGuide.palette.baseMsg};
    ${StyleGuide.typography.headline2};
    color: ${StyleGuide.palette.secondaryCucumber};
    display: flex;
    justify-content: center;
    cursor: pointer;
`;

export const DatePickerContainer = styled.div`
    margin: ${StyleGuide.spacing.base}px 0 ${StyleGuide.spacing.mini}px 0;
    position: static;
    z-index: 10;
`;

export const DatePickerTitle = styled.div`
    display: flex;
    flex: 1;
    flex-flow: width;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${StyleGuide.spacing.mini}px;
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseVanilla};
`;

export const DatePickerSelector = styled.div`
    display: flex;
    flex: 1;
    flex-flow: width;
    justify-content: space-between;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    margin-bottom: 2px;
    border: 1px solid ${StyleGuide.palette.baseSeaSalt};
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.mini}px;
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.coal};
    text-align: left;
    cursor: pointer;
`;

export const DatePickerSelectorDisabled = styled.div`
    display: flex;
    flex: 1;
    flex-flow: width;
    height: ${StyleGuide.spacing.large}px;
    background-color: ${StyleGuide.palette.baseSugar};
    margin-bottom: 2px;
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
`;

export const DatePickerDisabledTitle = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.error};
    font-weight: bold;
    text-align: left;
    margin-bottom: ${StyleGuide.spacing.mini}px;
`;

interface ArrowIconProps {
    isExpanded?: boolean;
}

export const ArrowIcon = styled.div<ArrowIconProps>`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.isExpanded ? `rotate(0deg)` : `rotate(180deg)`)};
`;

export const CalendarContainer = styled.div`
    width: 297px;
    position: absolute;
    right: 32px;
`;

export const TimeSlotsInfo = styled.div`
    position: absolute;
    box-shadow: 0px 12px 44px -8px rgba(0, 0, 0, 0.25);
    border-radius: ${StyleGuide.spacing.mini}px;
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.medium}px
        ${StyleGuide.spacing.small}px ${StyleGuide.spacing.small}px;
    background-color: ${StyleGuide.palette.baseSalt};
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
    line-height: ${StyleGuide.spacing.base}px;
    text-align: left;
    right: 8px;
    z-index: 1;
`;

export const TimeSlotsWarning = styled.div`
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
    background-color: ${StyleGuide.palette.baseSalt};
`;

export const WarningContainer = styled.div`
    border-radius: ${StyleGuide.spacing.small}px;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px;
    background-color: ${StyleGuide.palette.error};
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.baseSalt};
    text-align: left;
`;

export const WarningTriangle = styled.div`
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #e46161;
`;

export const TimeSlotsContainer = styled.div`
    flex: 1;
    padding-bottom: ${StyleGuide.spacing.medium}px;
    margin-bottom: ${StyleGuide.spacing.mini}px;
    overflow: auto;
    padding-right: ${StyleGuide.spacing.micro}px;

    ${CustomScrollBar}
`;

export const TimeSlotsRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${StyleGuide.spacing.mini}px;
`;

interface TimeSlotProps {
    isSelected?: boolean;
    isDisabled?: boolean;
    isBroken?: boolean;
}

const timeSlotsDisabled = `
    background-color: ${StyleGuide.palette.baseSalt};
    color: ${StyleGuide.palette.baseMsg};
    border: 1px solid ${StyleGuide.palette.baseSugar};
`;

const timeSlotsBroken = `
    background-color: ${StyleGuide.palette.baseSugar};
    color: ${StyleGuide.palette.baseMsg};
    border: 1px solid ${StyleGuide.palette.baseSugar};

    :hover {
        background-color: ${StyleGuide.palette.baseSugar};
    }
`;

export const TimeSlotContainer = styled.div<TimeSlotProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: ${StyleGuide.spacing.large}px;
    border: 1px solid
        ${props =>
            props.isSelected ? StyleGuide.palette.coal : StyleGuide.palette.baseSeaSalt};
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.mini}px;
    cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.coal : StyleGuide.palette.baseSalt};
    ${StyleGuide.typography.body1};
    color: ${props =>
        props.isSelected ? StyleGuide.palette.baseSalt : StyleGuide.palette.coal};

    :hover {
        background-color: ${props =>
            props.isSelected ? StyleGuide.palette.coal : StyleGuide.palette.baseSugar};
        ${props => (props.isDisabled ? timeSlotsDisabled : "")}
    }

    ${props => (props.isDisabled ? timeSlotsDisabled : "")}
    ${props => (props.isBroken ? timeSlotsBroken : "")}
`;

export const EmptyTimeSlot = styled.div`
    display: flex;
    flex: 1;
`;

export const TimeSlotSeparator = styled.div`
    width: ${StyleGuide.spacing.mini}px;
`;

export const TimeSlotsShadow = styled.div`
    width: 253px;
    height: 36px;
    bottom: 64px;
    background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
`;

export const TimeSlotVariableStyle = `color: ${StyleGuide.palette.secondaryBasil};`;

export const ResetTemplateAddonContainer = styled.div`
    background: ${StyleGuide.palette.error};
    padding: ${StyleGuide.spacing.xsmall}px;

    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.mini}px;
`;

export const AddonTitle = styled.div`
    ${StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.baseSalt};
    text-align: left;
    margin-bottom: ${StyleGuide.spacing.micro}px;
`;

export const AddonDescription = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.baseSalt};
    text-align: left;
    margin-bottom: ${StyleGuide.spacing.small}px;
`;

export const AddonResetButton = styled.div`
    width: 100%;
    padding: ${StyleGuide.spacing.mini}px 0;
    border: 1px solid ${StyleGuide.palette.baseSalt};
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.micro}px;
    cursor: pointer;

    ${StyleGuide.typography.body2};
    font-weight: bold;
    color: ${StyleGuide.palette.baseSalt};
    text-align: center;
`;
