import { Intl } from "@anna/shared";
import languageMap, { TranslationKeys } from "./languageMap";

const supportedLanguages = [Intl.en_US, Intl.de_DE, Intl.da_DK];

function translateText(
    key: TranslationKeys,
    value: string,
    lang: Intl = Intl.en_US,
): string {
    const langIndex = supportedLanguages.findIndex(l => l === lang);
    if (langIndex < 0) {
        console.error(`language ${lang} is not supported`);
        return value;
    }
    const options = languageMap[key];
    if (!options) {
        console.warn(`key ${key} is not supported`);
        return value;
    }
    let translatedText = "";
    options.forEach((array: string[]) => {
        array.forEach(item => {
            if (item === value) {
                translatedText = array[langIndex];
                return;
            }
        });
    });
    if (!translatedText) {
        console.log(`translation not available for ${value} and in language ${lang}`);
        return value;
    }
    return translatedText;
}

export { translateText, TranslationKeys };
