import React from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setFocusedChat } from "../../../features/funnels/reducer";
import Chat from "../../../routes/Inbox/Chat";
import ApplicantProfile from "./ApplicantProfile";
import ApplicantActions from "./ApplicantActions";
import { BackToProfileButton } from "./ApplicantActions/CtaButton";
import { UserProfileContainer } from "./styled";
import { useMarkNotificationAsSeen } from "../../../features/notifications/useMarkNotificationAsSeen";

const Applicant = ({ showChat, toggleChat, closeModal }) => {
    const funnelId = useAppSelector(state => state.funnels.focusedProfile);
    useMarkNotificationAsSeen(funnelId);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const openApplicantChat = () => {
        const isOpenedFromChat = history.location.pathname === "/inbox";
        //check if the modal is opened in the inbox route
        //if so close the modal since the chat is under the overlay
        if (isOpenedFromChat) {
            closeModal();
            return;
        }
        dispatch(setFocusedChat(funnelId));
        toggleChat(true);
    };

    const closeChat = () => {
        toggleChat(false);
    };

    if (showChat) {
        return (
            <UserProfileContainer>
                <Chat
                    isModalChat
                    BackToProfileButton={<BackToProfileButton onClick={closeChat} />}
                />
            </UserProfileContainer>
        );
    }

    return (
        <UserProfileContainer>
            <ApplicantProfile />

            <ApplicantActions openApplicantChat={openApplicantChat} />
        </UserProfileContainer>
    );
};

export default Applicant;
