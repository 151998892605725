import { PlandaySectionInfo, PlandaySectionTitle } from "../styled";
import React from "react";
import { useAppSelector } from "../../../../../store/store";

export default function PlandayInfoSection({ applicantId }: { applicantId: string }) {
    const applicant = useAppSelector(
        state => state.applications.applicationMap[applicantId],
    );
    return (
        <>
            <PlandaySectionTitle>Full name:</PlandaySectionTitle>

            <PlandaySectionInfo>
                {applicant?.firstName} {applicant?.lastName}
            </PlandaySectionInfo>

            <PlandaySectionTitle>E-mail:</PlandaySectionTitle>

            <PlandaySectionInfo>{applicant?.email}</PlandaySectionInfo>

            <PlandaySectionTitle>Phone:</PlandaySectionTitle>

            <PlandaySectionInfo>{applicant?.phoneNumber}</PlandaySectionInfo>
        </>
    );
}
