import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

interface TemplateState {
    templateMap: {};
}

// Define the initial state using that type
const initialState: TemplateState = {
    templateMap: {},
};

export const templatesSlice = createSlice({
    name: "templates",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setTemplates(state, action: PayloadAction<Contracts.DBRestaurantTemplate>) {

            return {
                ...state,
                templateMap: action.payload,
            };
        },
    },
});

export const { setTemplates } = templatesSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default templatesSlice.reducer;
