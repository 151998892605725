import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleBorder } from "./styled";
import { CalledIcon } from "../../../assets/images/icons";

const CalledTemplate = ({ applicantName }) => {
    return (
        <TemplateBubbleBorder>
            <ActionIconText title={`Called ${applicantName}`} Icon={<CalledIcon />} />
        </TemplateBubbleBorder>
    );
};

export default CalledTemplate;
