import * as firebase from "firebase";

import { auth, facebookProvider } from "./configure";

function handleAuthErrors({ code, message }) {
    switch (code) {
        case FIREBASE_ERROR_CODES.WRONG_PASSWORD:
            return alert("Wrong password.");
        case FIREBASE_ERROR_CODES.WEAK_PASSWORD:
            return alert("Your password is too weak.");
        case FIREBASE_ERROR_CODES.INVALID_EMAIL:
            return alert(message);
        case FIREBASE_ERROR_CODES.USER_NOT_FOUND:
            return alert(message);

        default:
            return alert(message);
    }
}

const FIREBASE_ERROR_CODES = {
    WEAK_PASSWORD: "auth/weak-password",
    WRONG_PASSWORD: "auth/wrong-password",
    INVALID_EMAIL: "auth/invalid-email",
    USER_NOT_FOUND: "auth/user-not-found",
};

/**
 *
 * @param {email, password} (sign in user)
 */
export async function signIn({ email, password, rememberMe = true }) {
    try {
        await auth.setPersistence(
            rememberMe
                ? firebase.auth.Auth.Persistence.LOCAL
                : firebase.auth.Auth.Persistence.SESSION,
        );
        await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
        handleAuthErrors(error);
    }
}

export async function signUp({ email, password }) {
    try {
        await auth.createUserWithEmailAndPassword(email, password);
        return true;
    } catch (error) {
        handleAuthErrors(error);
        return false;
    }
}

export async function resetPassword({ email }) {
    // [START sendpasswordemail]
    try {
        await auth.sendPasswordResetEmail(email);
        // Password Reset Email Sent!
        alert("Password Reset Email Sent!");
    } catch (error) {
        // @ts-ignore
        handleAuthErrors(error);
        // @ts-ignore
        return error.message;
    }
    // [END sendpasswordemail];
}

export function signOut() {
    auth.signOut();
}

export async function signInWithPhone(phoneNumber: string, recaptchaVerifier: any) {
    try {
        const confirmResult = await auth.signInWithPhoneNumber(
            phoneNumber,
            recaptchaVerifier,
        );
        return confirmResult;
    } catch (error) {
        // @ts-ignore
        throw new Error(error);
    }
}

export async function facebookLogin(response) {
    if (response.error) {
        console.log(`Facebook Error:`, response.error);
        return;
    }

    try {
        let accessToken = response.accessToken;

        const credential = firebase.auth.FacebookAuthProvider.credential(accessToken);
        const { user } = await auth.signInWithCredential(credential);
    } catch (error) {
        console.log("Facebook Login Error:");
    }
}
