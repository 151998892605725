import React, { useEffect } from "react";

import { OnboardingIcon } from "@assets/images/icons";
import { TemplateVariables, TemplateLinks } from "@anna/shared";
import { useAppSelector } from "../../../store/store";
import { ResetTemplateAddon } from "./InterviewTemplate/Popovers";
import { InfoButton, SendButton } from "./Template";
import { TimeSlotVariableStyle } from "./InterviewTemplate/styled";
import {
    IntroTemplateContainer,
    OnboardingDescription,
    OnboardingTitle,
    OnboardingList,
    OnboardingDisabledTitle,
} from "./styled";

const OnboardTemplate = ({
    templateText,
    closeTemplate,
    sendTemplate,
    onTemplateSendRef,
    setTemplateVariables,
    resetTemplate,
}) => {
    const isLinkBroken = templateText.includes(TemplateLinks.BASE_LINK);
    const isLinkIncluded = templateText.includes(TemplateVariables.HIRE_LINK);
    const showExpandedAction = isLinkIncluded || isLinkBroken;

    const funnelId = useAppSelector(state => state.funnels.focusedChat) as string;
    const funnel = useAppSelector(state => state.funnels.funnelMap[funnelId]);
    const restaurant = useAppSelector(
        state => state.restaurants.restaurantMap[funnel.restaurantId],
    );

    useEffect(() => {
        onTemplateSendRef.current = sendTemplate;
    }, []);

    useEffect(() => {
        setTemplateVariables([
            {
                variable: TemplateVariables.HIRE_LINK,
                customStyle: TimeSlotVariableStyle,
                text: TemplateLinks.ONBOARDING_LINK,
                onPress: () => {
                    if (restaurant.hireForm) {
                        window.open(restaurant.hireForm, "_blank");
                    }
                },
            },
        ]);
    }, [showExpandedAction]);

    const renderOnboardingInfo = () => {
        if (isLinkBroken) {
            return (
                <>
                    <OnboardingDescription>
                        <OnboardingDisabledTitle>
                            Onboarding addon disabled
                        </OnboardingDisabledTitle>
                    </OnboardingDescription>

                    <ResetTemplateAddon
                        title="Onboarding link is disabled"
                        description="Because you changed the onboarding link. To use this template, you need the onboarding addon."
                        resetTemplate={resetTemplate}
                    />
                </>
            );
        }
        return (
            <>
                <OnboardingDescription>
                    <OnboardingTitle>Onboarding link</OnboardingTitle>
                    The link asks for the applicant’s contract info:
                    <br />
                    <br />
                    <OnboardingList>
                        <li>Name</li>
                        <li>Address</li>
                        <li>CPR</li>
                        <li>Bank account info</li>
                    </OnboardingList>
                    <br />
                    You will be notified when it has been filled out!
                    <br />
                    <br />
                    This will become more flexible in a next version.
                </OnboardingDescription>

                <SendButton label="Send onboard message" onPress={sendTemplate} />
            </>
        );
    };

    return (
        <IntroTemplateContainer>
            <InfoButton
                label="Onboard"
                Icon={<OnboardingIcon />}
                onPress={closeTemplate}
            />

            {renderOnboardingInfo()}
        </IntroTemplateContainer>
    );
};

export default OnboardTemplate;
