import React, { useEffect, useRef } from "react";

import ChatBubble from "./ChatBubble";
import { ChatPanel, ChatHistory, LoaderWrapper } from "./styled";
import { useActivity } from "../../features/activity/useActivity";
import { useAppSelector } from "../../store/store";

import { StyleGuide } from "../../constants/StyleGuide";
import { Loader } from "../../routes/Auth/components/Buttons/styled";

const useDelayedLoading = loading => {
    const [delayedLoading, setDelayedLoading] = React.useState(false);
    const timeoutRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    React.useEffect(() => {
        if (timeoutRef?.current) {
            clearTimeout(timeoutRef.current);
        }
        if (loading !== delayedLoading) {
            // TODO: Look into a good value for the delay
            timeoutRef.current = setTimeout(() => setDelayedLoading(loading), 250);
        }
    }, [loading]);

    return delayedLoading;
};

const ChatFeed = ({ scrollBottomRef }) => {
    const { isLoading } = useActivity();
    const delayedIsLoading = useDelayedLoading(isLoading);
    const chatRef = useRef<HTMLDivElement | null>(null);
    const messages = useAppSelector(state => state.activity.chatIds);

    const scrollToBottom = () => {
        const scrollHeight = chatRef.current?.scrollHeight || 0;
        const height = chatRef.current?.clientHeight || 0;
        const maxScrollTop = scrollHeight - height;
        if (chatRef.current) {
            chatRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    };

    useEffect(() => {
        if (!delayedIsLoading) {
            scrollToBottom();
            if (scrollBottomRef) {
                scrollBottomRef.current = scrollToBottom;
            }
        }
    }, [messages, scrollBottomRef, delayedIsLoading]);

    const renderMessages = () => {
        //TODO: Grouping message logic
        if (delayedIsLoading) {
            return (
                <LoaderWrapper>
                    <Loader color={StyleGuide.palette.secondaryBasil} />
                </LoaderWrapper>
            );
        }
        const messageNodes = messages.map(id => <ChatBubble id={id} key={id} />);

        return messageNodes;
    };

    return (
        <ChatPanel>
            <ChatHistory key="chatfeed" ref={chatRef}>
                {renderMessages()}
            </ChatHistory>
        </ChatPanel>
    );
};

export default ChatFeed;
