import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";

import { Dates } from "@anna/shared";
import { convertMinutesToHoursAndMinutes } from "@utils/dates";
import TimeSlotsPreview from "@components/TimeSlotsPreview";
import ActionIconText from "../ActionIconText";
import {
    TemplateBubbleContainer,
    TemplateBubbleMessage,
    TrialIcon,
    previewStyle,
} from "./styled";

const getTrialSlotsText = ({
    timeSlots = {},
    isReschedule = false,
}: {
    timeSlots: Contracts.CustomPreferenceDTO | undefined;
    isReschedule?: boolean;
}): string => {
    const totalTimeSlots = Object.keys(timeSlots);

    if (totalTimeSlots.length > 1) {
        return `Sent ${totalTimeSlots.length} time slots`;
    }

    if (totalTimeSlots.length === 1) {
        const timeslot = totalTimeSlots[0];
        return `${isReschedule ? "to " : ""}${format(
            new Date(timeslot),
            "dd LLL",
            Dates.locale,
        )} at ${convertMinutesToHoursAndMinutes(timeSlots[timeslot].startTimeInMinutes)}`;
    }

    return " ";
};

const TrialTemplate = ({ message, timeSlots }) => {
    const [showTimeslotsPreview, toggleTimeslotsPreview] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                toggleTimeslotsPreview(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <TemplateBubbleContainer ref={wrapperRef}>
            {showTimeslotsPreview && (
                <TimeSlotsPreview
                    onClose={() => {}}
                    forInterview={false}
                    customTrialTimes={timeSlots}
                    style={previewStyle}
                />
            )}

            <ActionIconText
                title="Invited for trial"
                description={getTrialSlotsText({ timeSlots })}
                Icon={<TrialIcon />}
                onClick={() => toggleTimeslotsPreview(!showTimeslotsPreview)}
            />

            <TemplateBubbleMessage>{message}</TemplateBubbleMessage>
        </TemplateBubbleContainer>
    );
};

export default TrialTemplate;
