import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as HomeOutlinedIcon } from "@assets/svg/home_outlined.svg";
import { ReactComponent as InboxOutlinedIcon } from "@assets/svg/inbox_outlined.svg";
import { ReactComponent as HomeFilledIcon } from "@assets/svg/home_filled.svg";
import { ReactComponent as InboxFilledIcon } from "@assets/svg/inbox_filled.svg";
import { ReactComponent as TeamIcon } from "@assets/svg/team.svg";
import { StyleGuide } from "@constants/StyleGuide";
import {
    TabNavigatorContainer,
    TabLink,
    DisbaledTabLink,
    DisbaledTabBubble,
    TabBubble,
    TabBubbleIcon,
} from "./styled";
import { selectUnreadMessages } from "../../features/notifications/reducer";
import { useAppSelector } from "../../store/store";

const tabsConfig = [
    {
        id: 0,
        title: "Home",
        route: "/home",
        ActiveIcon: <HomeFilledIcon />,
        InactiveIcon: <HomeOutlinedIcon />,
    },
    {
        id: 1,
        title: "Inbox",
        route: "/inbox",
        ActiveIcon: <InboxFilledIcon />,
        InactiveIcon: <InboxOutlinedIcon />,
    },
    {
        id: 2,
        title: "Team (coming soon)",
        route: "/",
        disabled: true,
        ActiveIcon: <TeamIcon fill={StyleGuide.palette.secondaryCucumber} />,
        InactiveIcon: <TeamIcon />,
    },
];

const TabsNavigator = () => {
    const [activeTab, setActiveTab] = useState<number | null>(null);
    const unreadMessages = useAppSelector(selectUnreadMessages);
    const location = useLocation();

    useEffect(() => {
        const selectedTab = tabsConfig.find(tab => tab.route === location.pathname);
        if (selectedTab) {
            setActiveTab(selectedTab.id);
        }
    }, [location]);

    return (
        <TabNavigatorContainer>
            {tabsConfig.map(
                ({ id, title, route, InactiveIcon, ActiveIcon, disabled }) => {
                    const isSelected = id === activeTab;
                    const displayCount = id === 1 && unreadMessages;
                    if (disabled) {
                        return (
                            <DisbaledTabLink key={id}>
                                <DisbaledTabBubble>
                                    <TabBubbleIcon>{InactiveIcon}</TabBubbleIcon>
                                    {title}
                                </DisbaledTabBubble>
                            </DisbaledTabLink>
                        );
                    }
                    return (
                        <TabLink to={route} key={id}>
                            <TabBubble
                                isSelected={isSelected}
                                onClick={() => setActiveTab(id)}>
                                <TabBubbleIcon>
                                    {isSelected ? ActiveIcon : InactiveIcon}
                                </TabBubbleIcon>
                                {title} {displayCount && `(${unreadMessages})`}
                            </TabBubble>
                        </TabLink>
                    );
                },
            )}
        </TabNavigatorContainer>
    );
};

export default TabsNavigator;
