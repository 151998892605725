import React from "react";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer } from "./styled";
import { PineappleIcon } from "../../../assets/images/icons";

const ContactedAcceptedTemplate = () => {
    return (
        <TemplateBubbleContainer>
            <ActionIconText
                title="Contact accepted!"
                description="Via the shared talent pool"
                Icon={<PineappleIcon />}
            />

            {/* <TemplateBubbleMessage>{message}</TemplateBubbleMessage> */}
        </TemplateBubbleContainer>
    );
};

export default ContactedAcceptedTemplate;
