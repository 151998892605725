import styled from "styled-components";
import { List } from "react-virtualized";

import { StyleGuide } from "@constants/StyleGuide";
import { CustomScrollBar } from "@constants/CommonStyles";

export const rowHeight = 94;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px;
`;

interface HeaderTabProps {
    selected?: boolean;
    isLast?: boolean;
}

export const HeaderTab = styled.div<HeaderTabProps>`
    ${props =>
        props.selected ? StyleGuide.typography.headline1 : StyleGuide.typography.body1};
    color: ${props =>
        props.selected
            ? StyleGuide.palette.secondaryCucumber
            : StyleGuide.palette.baseVanilla};
    padding-bottom: ${StyleGuide.spacing.micro}px;
    border-bottom: 2px solid
        ${props =>
            props.selected ? StyleGuide.palette.secondaryCucumber : "transparent"};
    margin-right: ${props => (props.isLast ? 0 : StyleGuide.spacing.base)}px;
    cursor: pointer;
`;

export const ListContainer = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const VListContainer = styled(List)`
    ${CustomScrollBar}
`;

export const InboxCardItem = styled.div`
    box-sizing: border-box;
    padding-right: ${StyleGuide.spacing.mini}px;
`;

export const inboxCardStyle = {
    paddingLeft: StyleGuide.spacing.mini,
};

export const InboxBadge = styled.div`
    position: absolute;
    z-index: 1;
    top: -14px;
    margin: auto;
    left: 0;
    right: 0;
    padding: 6px 0px;
    background: ${StyleGuide.palette.cucumber};
    color: ${StyleGuide.palette.baseSalt};
    width: 30%;
    border-radius: 14px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
`;
