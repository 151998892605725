import styled from "styled-components";

import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { StyleGuide } from "@constants/StyleGuide";

interface ContainerProps {
    topMargin?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${StyleGuide.spacing.small}px;
    overflow: hidden auto;
    position: static;

    ${({ topMargin }) => topMargin && `margin-top: ${StyleGuide.spacing.small}px`}
`;

export const SectionTitle = styled.div`
    ${() => StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
    margin: ${StyleGuide.spacing.mini}px;
`;

export const SectionDescription = styled.div`
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
    margin: 0 ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px;
`;

interface TemplateItemProps {
    marginTop?: number;
}

export const TemplateItem = styled.div<TemplateItemProps>`
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.xsmall}px ${StyleGuide.spacing.mini}px;
    margin-top: ${props => props.marginTop || 0}px;
    cursor: pointer;
    box-sizing: border-box;
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
    text-align: left;

    :hover {
        background-color: ${StyleGuide.palette.baseSugar};
        color: ${StyleGuide.palette.basePepper};
        border-radius: ${StyleGuide.spacing.mini}px;
    }
`;

export const TemplateIcon = styled.div`
    width: ${StyleGuide.spacing.small}px;
    margin-right: ${StyleGuide.spacing.xsmall}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const UseTemplateText = styled.div`
    ${() => StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.coal};
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const PlusIcon = styled(CloseIcon)`
    width: ${StyleGuide.spacing.mini}px;
    height: ${StyleGuide.spacing.mini}px;
    fill: #d0d0d0;
    transform: rotate(45deg);
`;

export const IntroTemplateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: hidden;
`;

export const TemplateInfoButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${StyleGuide.spacing.large}px;
    background: ${StyleGuide.palette.baseSugar};
    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.mini}px;
    padding: 0 ${StyleGuide.spacing.small}px 0 ${StyleGuide.spacing.mini}px;
    cursor: pointer;
`;

export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
`;

export const InfoIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    margin-right: ${StyleGuide.spacing.xsmall}px;
`;

export const CancelButton = styled.div`
    ${() => StyleGuide.typography.footnote};
    color: ${StyleGuide.palette.error};
`;

interface TemplateSendButtonProps {
    isDisabled?: boolean;
}

export const TemplateSendButton = styled.div<TemplateSendButtonProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${StyleGuide.spacing.large}px;
    background: ${props =>
        props.isDisabled
            ? StyleGuide.palette.baseMsg
            : StyleGuide.palette.secondaryBasil};
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.mini}px;
    cursor: pointer;
    ${() => StyleGuide.typography.headline2};
    color: ${props =>
        props.isDisabled ? StyleGuide.palette.baseVanilla : StyleGuide.palette.baseSalt};
`;

export const SendIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${StyleGuide.spacing.small}px;
    height: ${StyleGuide.spacing.small}px;
    position: absolute;
    right: ${StyleGuide.spacing.xsmall}px;
`;

export const PlaceholderFixedContainer = styled.div`
    display: flex;
    position: absolute;
    height: 26px;
    top: -44px;
`;

export const OnboardingDescription = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: ${StyleGuide.spacing.base}px;
    padding: 0 ${StyleGuide.spacing.mini}px;
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
`;

export const OnboardingDisabledTitle = styled.div`
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.error};
    font-weight: bold;
    text-align: left;
`;

export const OnboardingTitle = styled.div`
    ${() => StyleGuide.typography.headline1};
    color: ${StyleGuide.palette.basePepper};
    margin-bottom: ${StyleGuide.spacing.mini}px;
`;

export const OnboardingList = styled.ul`
    margin: 0;
    padding: 0;
    padding-inline-start: ${StyleGuide.spacing.small}px;
`;

export const ModalCtaContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    flex: 1;
`;
