import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    flex: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column;
`;

export const InboxContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: ${StyleGuide.palette.baseMsg};
    padding: ${StyleGuide.spacing.small}px;
    overflow: hidden;
`;

export const InboxListContainer = styled.div`
    width: 367px;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    margin-right: ${StyleGuide.spacing.small}px;
    border-radius: ${StyleGuide.spacing.xsmall}px;
    background-color: ${StyleGuide.palette.baseSalt};
`;
