import { firestore } from "../../firebase/configure";
import { COLLECTIONS } from "../../constants/enums";
import { Gender } from "@anna/shared";

export const getApplication = (id: string) => {
    try {
        return firestore.collection(COLLECTIONS.APPLICATIONS).doc(id);
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getEnrichedApplicantData = (id: string) =>
    firestore
        .collection(COLLECTIONS.APPLICATIONS)
        .doc(id)
        .collection(COLLECTIONS.ENRICHED_APPLICANT_DATA)
        .orderBy("updateTime", "desc")
        .limit(1);

export const updateEnrichedApplicantGender = ({
    applicantId,
    enrichedId,
    gender,
}: {
    applicantId: string;
    enrichedId: string;
    gender: Gender;
}) =>
    firestore
        .collection(COLLECTIONS.APPLICATIONS)
        .doc(applicantId)
        .collection(COLLECTIONS.ENRICHED_APPLICANT_DATA)
        .doc(enrichedId)
        .set(
            {
                gender: {
                    value: gender,
                },
            },
            { merge: true },
        );
export const setEnrichedApplicantGender = ({
    applicantId,
    gender,
}: {
    applicantId: string;
    gender: Gender;
}) =>
    firestore
        .collection(COLLECTIONS.APPLICATIONS)
        .doc(applicantId)
        .collection(COLLECTIONS.ENRICHED_APPLICANT_DATA)
        .doc()
        .set(
            {
                updateTime: new Date(),
                applicationId: applicantId,
                gender: {
                    value: gender,
                },
            },
            { merge: true },
        );

const TAG = "Planday/Modal";

export async function updateGender({
    applicantId,
    enrichedId,
    gender,
}: {
    applicantId: string;
    enrichedId?: string;
    gender: Gender;
}) {
    try {
        // if the enrichedId is not provided, we will set it
        if (!enrichedId) {
            await setEnrichedApplicantGender({ applicantId, gender });
            console.log(`${TAG}: Updated gender on applicant ${applicantId}`);

            return null;
        }
        await updateEnrichedApplicantGender({
            applicantId,
            enrichedId,
            gender,
        });
        return null;
    } catch (error) {
        console.error(`${TAG}: Failed to update gender`, error);
        return error;
    }
}
