import { format, getDate } from "date-fns";
import { enUS } from "date-fns/locale";

export const locale = { locale: enUS };

export function parsed(date: Date | string) {
    return typeof date === "string" ? new Date(date) : date;
}

export function getShortDate(date: Date) {
    return format(date, "yyyy-LL-dd", locale);
}

export function getMonthShort(date: Date | string) {
    return format(parsed(date), "LLL", locale);
}

export function getDayOfMonth(date: Date | string) {
    return getDate(parsed(date));
}

export function getDayOfWeekShort(date: Date | string) {
    return format(parsed(date), "EEE", locale);
}

export function getDayOfWeekFull(date: Date | string) {
    return format(parsed(date), "EEEE", locale);
}

export function dateToTime(date: Date) {
    return format(date, "HH:mm", locale);
}

export function shortLocalDateFormat(date: Date) {
    return format(parsed(date), "do 'of' LLLL", locale);
}

export function mediumLocalDateFormat(date: Date | string) {
    return format(parsed(date), "iiii, d LLLL", locale);
}

export function mediumLocalDateAndTimeFormat(date: Date) {
    return format(date, "LLLL do',' HH:mm", locale);
}

export function longLocalDateFormat(date: Date) {
    return format(parsed(date), "cccc do MMMM yyyy", locale);
}
