import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: 0;
    margin: 0;
`;

export const DropdownContainer = styled.div`
    min-width: 300px;
    margin: 0;
    position: absolute;
    right: 0;
    top: ${StyleGuide.spacing.xlarge}px;
    filter: drop-shadow(0px 9px 26px rgba(0, 0, 0, 0.08));
    background: ${StyleGuide.palette.baseSalt};
    border-radius: ${StyleGuide.spacing.xsmall}px;
    z-index: 100;
`;

export const ListContainer = styled.ul`
    padding: ${StyleGuide.spacing.small}px ${StyleGuide.spacing.small}px 0
        ${StyleGuide.spacing.small}px;
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
`;

type SectionContainerProps = {
    isFirst?: boolean;
};

export const SectionContainer = styled.div<SectionContainerProps>`
    margin-top: ${props => (props.isFirst ? 0 : StyleGuide.spacing.base)}px;
`;

export const SectionTitle = styled.li`
    ${() => StyleGuide.typography.body2};
    color: ${StyleGuide.palette.basePepper};
    margin-bottom: ${StyleGuide.spacing.mini}px;
    font-weight: bold;
    text-align: left;
`;

type OptionItemProps = {
    isSelected?: boolean;
    isLast?: boolean;
};

export const FilterOption = styled.li<OptionItemProps>`
    height: 40px;
    margin-bottom: ${props => (props.isLast ? 0 : StyleGuide.spacing.micro)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    text-align: left;
    ${StyleGuide.typography.body2};
    color: ${StyleGuide.palette.coal};
`;

type FilterCheckboxProps = {
    checked?: boolean;
};

export const FilterCheckbox = styled.div<FilterCheckboxProps>`
    width: 20px;
    height: 20px;
    margin-left: ${StyleGuide.spacing.micro}px;
    border-radius: ${StyleGuide.spacing.micro}px;
    background-color: ${props =>
        props.checked ? StyleGuide.palette.secondaryBasil : StyleGuide.palette.baseMsg};
    display: flex;
    justify-content: center;
    align-items: center;
`;

type TimeFilterRowOptionProps = {
    isLast?: boolean;
};

export const TimeFilterRowOption = styled.div<TimeFilterRowOptionProps>`
    display: flex;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.small}px;
    background: ${StyleGuide.palette.baseSugar};
    border-radius: ${StyleGuide.spacing.base}px;
    margin-right: ${props =>
        props.isLast ? StyleGuide.spacing.small : StyleGuide.spacing.mini}px;

    cursor: pointer;
    text-align: left;
    ${() => StyleGuide.typography.body1};
    color: ${StyleGuide.palette.baseVanilla};
`;

type ClearFiltersButtonProps = {
    disabled?: boolean;
};

export const ClearFiltersButton = styled.div<ClearFiltersButtonProps>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid ${StyleGuide.palette.baseMsg};
    padding: ${StyleGuide.spacing.mini}px 0;
    margin-top: ${StyleGuide.spacing.mini}px;
    ${() => StyleGuide.typography.body2};
    color: ${props =>
        props.disabled
            ? StyleGuide.palette.baseSeaSalt
            : StyleGuide.palette.secondaryCucumber};
`;
