import React from "react";
import { format } from "date-fns";

import { Times, Dates } from "@anna/shared";
import ActionIconText from "../ActionIconText";
import { TemplateBubbleContainer, TemplateBubbleMessage, InterviewIcon } from "./styled";

const interval = 30; // minutes
const getInterviewTimeSlotsText = ({ startTime }: { startTime?: Date }): string => {
    if (!startTime) return "Invalid date";
    return `${format(startTime, "dd LLL", Dates.locale)} at ${format(
        startTime,
        "HH:mm",
        Dates.locale,
    )}`;
};

const InterviewConfirmTemplate = ({ startTime }) => {
    //TODO: Use method above "getInterviewTimeSlotsText" to get description text based on timeslots list
    return (
        <TemplateBubbleContainer>
            <ActionIconText
                title="Interview confirmed!"
                description={getInterviewTimeSlotsText({ startTime })}
                Icon={<InterviewIcon />}
            />

            {/* <TemplateBubbleMessage>{message}</TemplateBubbleMessage> */}
        </TemplateBubbleContainer>
    );
};

export default InterviewConfirmTemplate;
