import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { ReactComponent as InboxIcon } from "@assets/svg/inbox_filled_nolines.svg";
import { RejectIcon, WhiteRejectIcon } from "@assets/images/icons";
import { StyleGuide } from "@constants/StyleGuide";
import {
    CtaButton,
    SendButtonText,
    CtaIcon,
    BackIcon,
    BackToProfileTitle,
    CustomRejectTitle,
    CustomRejectSubTitle,
} from "./styled";

export const SendMessageButton = ({ onClick }) => {
    return (
        <CtaButton isPrimary onClick={onClick}>
            <CtaIcon>
                <InboxIcon fill={StyleGuide.palette.baseSalt} />
            </CtaIcon>
            <SendButtonText>Send a message</SendButtonText>
        </CtaButton>
    );
};

export const BackToProfileButton = ({ onClick }) => {
    return (
        <CtaButton onClick={onClick}>
            <CtaIcon>
                <BackIcon />
            </CtaIcon>
            <BackToProfileTitle>Go back to profile</BackToProfileTitle>
        </CtaButton>
    );
};

export const CustomRejectButton = ({ onClick }) => {
    return (
        <CtaButton onClick={onClick}>
            <CtaIcon>
                <RejectIcon />
            </CtaIcon>
            <BackToProfileTitle>
                <CustomRejectTitle>
                    Reject{" "}
                    <CustomRejectSubTitle>(Customize message)</CustomRejectSubTitle>
                </CustomRejectTitle>
            </BackToProfileTitle>
        </CtaButton>
    );
};

export const RejectWithMessageButton = ({ onClick }) => {
    const [isLoading, setisLoading] = useState(false);
    return (
        <CtaButton
            onClick={async () => {
                setisLoading(true);
                await onClick();
                setisLoading(false);
            }}
            disabled={isLoading}
            style={{ backgroundColor: StyleGuide.palette.error }}>
            <CtaIcon>
                {isLoading ? (
                    <ClipLoader color={StyleGuide.palette.baseSalt} loading size={10} />
                ) : (
                    <WhiteRejectIcon />
                )}
            </CtaIcon>
            <SendButtonText>Reject and send message</SendButtonText>
        </CtaButton>
    );
};
