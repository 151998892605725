import { ApplicationState } from "@anna/shared";

export function getSearchFilter({
    include,
    exclude,
}: {
    include?: {
        stateFilter?: ApplicationState[];
        subscribeIds?: string[];
        positionsFilter?: string[];
        workHourFilter?: string[];
        visaFilter?: string[];
        communicationStateFilter?: string[];
    };
    exclude?: {
        stateFilter?: string[];
        subscribeIds?: string[];
    };
}) {
    let filterBy: string[] = [];

    // include Subscribers
    if (include?.subscribeIds?.length) {
        filterBy.push(`subscribers:=[${include?.subscribeIds.join(",")}]`);
    }
    // exclude Subscribers
    if (exclude?.subscribeIds?.length) {
        filterBy.push(`subscribers:!=[${exclude?.subscribeIds.join(",")}]`);
    }
    // include Positions
    if (include?.positionsFilter?.length) {
        filterBy.push(`userMeta.position:=[${include?.positionsFilter.join(",")}]`);
    }
    // include Work Hours
    if (include?.workHourFilter?.length) {
        filterBy.push(`workhourFilter:=[${include?.workHourFilter.join(",")}]`);
    }

    // include Visa
    if (include?.visaFilter?.length) {
        filterBy.push(`userMeta.visaType:=[${include?.visaFilter.join(",")}]`);
    }

    // exclude States
    if (exclude?.stateFilter?.length) {
        filterBy.push(`state:!=[${exclude?.stateFilter.join(",")}]`);
    }
    // include States
    if (include?.stateFilter?.length) {
        filterBy.push(`state:=[${include?.stateFilter.join(",")}]`);
    }

    // include Communication State
    if (include?.communicationStateFilter?.length) {
        filterBy.push(
            `communicationState:=[${include?.communicationStateFilter.join(",")}]`,
        );
    }

    return filterBy.join(" && ");
}
