import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    flex: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column;
`;

export const ApplicantsWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: ${StyleGuide.palette.baseMsg};
    padding: ${StyleGuide.spacing.small}px;
    overflow-x: scroll;
    ::after {
        content: "";
        min-width: 1px;
    }
`;
