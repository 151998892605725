import styled from "styled-components";

import { StyleGuide } from "@constants/StyleGuide";

export const Container = styled.div`
    position: relative;
`;

export const PickerSelector = styled.div`
    display: flex;
    flex: 1;
    flex-flow: width;
    justify-content: space-between;
    align-items: center;
    padding: ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.mini}px
        ${StyleGuide.spacing.mini}px ${StyleGuide.spacing.xsmall}px;
    margin-bottom: ${StyleGuide.spacing.mini}px;
    box-sizing: border-box;
    border-radius: ${StyleGuide.spacing.micro}px;
    background-color: ${StyleGuide.palette.baseSugar};
    ${StyleGuide.typography.body1};
    color: ${StyleGuide.palette.basePepper};
    text-align: left;
    cursor: pointer;
`;

interface ArrowIconProps {
    isExpanded?: boolean;
}

export const ArrowIcon = styled.div<ArrowIconProps>`
    width: ${StyleGuide.spacing.base}px;
    height: ${StyleGuide.spacing.base}px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.isExpanded ? `rotate(0deg)` : `rotate(180deg)`)};
`;

export const DropdownContainer = styled.div`
    position: absolute;
    width: 100%;
    padding: ${StyleGuide.spacing.micro}px 1px;
    background: ${StyleGuide.palette.baseSalt};
    border: 1px solid ${StyleGuide.palette.baseSugar};
    box-sizing: border-box;
    box-shadow: 0px 12px 20px -8px rgba(0, 0, 0, 0.07);
    border-radius: ${StyleGuide.spacing.mini}px;
    z-index: 20;
    max-height: 400px;
    overflow: auto;
`;

interface DropdownItemProps {
    isSelected?: boolean;
}

export const DropdownItem = styled.div<DropdownItemProps>`
    padding: ${StyleGuide.spacing.xsmall}px;
    background-color: ${props =>
        props.isSelected ? StyleGuide.palette.baseMsg : StyleGuide.palette.baseSalt};
    ${StyleGuide.typography.body1};
    color: ${props =>
        props.isSelected
            ? StyleGuide.palette.basePepper
            : StyleGuide.palette.baseVanilla};
    text-align: left;
    cursor: pointer;

    :hover {
        background-color: ${props =>
            props.isSelected ? StyleGuide.palette.baseMsg : StyleGuide.palette.baseSugar};
        color: ${props =>
            props.isSelected ? StyleGuide.palette.basePepper : StyleGuide.palette.coal};
    }
`;
